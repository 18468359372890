export const texte = {
  Angebote: {
    Kopftexte: {
      WerkAPP: {
        desc: "Standart",
        text: "heute erhalten Sie Ihr persönliches Angebot. Wir freuen uns auf eine großartige Zusammenarbeit.",
      }
    },
    Schlusstexte: {
      WerkAPP: {
        desc: "Standart",
        text: "Bitte beachten Sie, dass ein Vertrag erst durch schriftliche Beauftragung zustandekommt.\n\nMit freundlichen Grüßen"
      }
    },
    Textbausteine: {},
  },
  Rechnungen: {
    Kopftexte: {
      WerkAPP: {
        desc: "Standart",
        text: "heute stellen wir die von uns erbrachten Leistungen in Rechnung. Bitte prüfen Sie unsere Rechnung und begleichen Sie diese umgehend."
      }
    },
    Schlusstexte: {
      WerkAPP: {
        desc: "Standart",
        text: "Bitte begleichen Sie die Rechnung innerhalb der angegebenen Frist, spätestens jedoch zum genannten Fälligkeitsdatum.\n\nMit freundlichen Grüßen"
      }
    },
    Textbausteine: {},
  },
  Zahlungserinnerung: {
    Kopftexte: {
      WerkAPP: {
        desc: "Standart",
        text: "im Alltag kann mal etwas untergehen, wir verstehen das. Wir bitten daher um sofortigen Ausgleich unser überfälligen Forderung."
      }
    },
    Schlusstexte: {
      WerkAPP: {
        desc: "Standart",
        text: "Sollten Sie den Betrag bereits beglichen haben, kontaktieren Sie uns bitte umgehend und senden uns den Zahlungsnachweis zu."
      }
    },
    Textbausteine: {},
  },
  Mahnungen: {
    Kopftexte: {
      WerkAPP: {
        desc: "Standart",
        text: "leider haben Sie unsere Forderung noch nicht vollständig ausgeglichen. Wir erinnern hiermit letzmalig an den Ausgleich unserer nachstehenden Forderungen."
      }
    },
    Schlusstexte: {
      WerkAPP: {
        desc: "Standart",
        text: "Sollten Sie den Betrag bereits beglichen haben, kontaktieren Sie uns bitte umgehend und senden uns den Zahlungsnachweis. Bei anhaltendem Zahlungsverzug sehen wir uns gezwungen rechtliche Schritte einzuleiten, die mit weiteren Kosten verbunden sind."
      }
    },
    Textbausteine: {},
  }
}