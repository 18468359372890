export const defaultData = {
  anrede: "",
  vorname: "",
  nachname: "",
  geburtsdatum: "",
  bezeichnung: "",
  anschrift: "",
  PLZ: "",
  ort: "",
  land: "DEUTSCHLAND",
  eintrittsdatum: "",
  austrittsdatum: "",
  status: "aktuell",
  fSGültigkeit: "",
  fSPrüfen: "6",
  fS: [],
};
export const driverClasses = ["B", "BE", "C1", "C1E", "C", "CE", "T"];
export const defaultStates = ["aktuell", "ehemalige"];