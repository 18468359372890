import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const Rechnen = () => {

  const {rights, sales} = useSelector(state => state.auth.tokens);
  
  return (
    <div className="menuNav">
      <p className="menuNavBtn">Rechnen</p>
      <div className="menuSubmenu">
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("eom") && rights["eom"] > 0)) && <NavLink to="/offers" className="menuSubBtn">Angebote
          <span className="menuSubBtnSmall">Angebote erstellen oder überarbeiten</span></NavLink>
        }
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("emm") && rights["emm"] > 0)) && <NavLink to="/measurments" className="menuSubBtn">Aufmaße
          <span className="menuSubBtnSmall">Aufmaße erstellen und ändern</span></NavLink>
        }
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("bill") && rights["bill"] > 0)) && <NavLink to="/invoices" className="menuSubBtn">Rechnungen
          <span className="menuSubBtnSmall">Rechnungen erstellen und einsehen</span></NavLink>
        }
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("bill") && rights["bill"] > 0) || (rights.hasOwnProperty("pay") && rights["pay"] > 0)) && <NavLink to="/payments" className="menuSubBtn">Offene Forderungen
          <span className="menuSubBtnSmall">Forderungen und Zahlungen erfassen</span></NavLink>
        }
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("ovd") && rights["ovd"] > 0)) && <NavLink to="/overdue" className="menuSubBtn">Mahnwesen
          <span className="menuSubBtnSmall">Überfällige Rechnungen mahnen</span></NavLink>
        }
      </div>
    </div>);
}

export default Rechnen