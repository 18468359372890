import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Article from "./Article";


const Offer = (props) => {

  const {offerData, offerIndex} = props;
  let calc = { title: { open: false, index: 0, position: 0 }, subtitle: { open: false, index: 0, position: 0 }, subsubtitle: { name: "", open: false, index: 0, position: 0, sum: 0, }, total: { sum: 0, position: 0, lines: 0, } };
  const posName = offerIndex === 0 ? "" : "NA-" + offerIndex + " ";

  const articles = Object.entries(offerData);


  
  const RenderArticle = (props) => {
    const position = props.articleData;
    const title = (position.typ === "TK" || position.typ === "TS");
    let pos = "";
    if(position.typ === "TK") {
      if (calc.subtitle.open) {
        calc.subsubtitle.index++;
        calc.subsubtitle.open = true;
      } else if (calc.title.open) {
        calc.subtitle.index++;
        calc.subtitle.open = true;
      } else {
        calc.title.index++;
        calc.title.open = true;
      }
      pos = (calc.title.open ? ("00" + calc.title.index).slice(-2) : "")
          + (calc.subtitle.open ? "."+("00" + calc.subtitle.index).slice(-2) : "")
          + (calc.subsubtitle.open ? "."+("00" + calc.subsubtitle.index).slice(-2) : "");
    } else if (position.typ === "TS") {
      pos = (calc.title.open ? ("00" + calc.title.index).slice(-2) : "")
          + (calc.subtitle.open ? "."+("00" + calc.subtitle.index).slice(-2) : "")
          + (calc.subsubtitle.open ? "."+("00" + calc.subsubtitle.index).slice(-2) : "");
      if (calc.subsubtitle.open) {
        calc.subsubtitle.open = false;
      } else if (calc.subtitle.open) {
        calc.subtitle.open = false;
      } else if (calc.title.open) {
        calc.title.sum = 0;
        calc.title.open = false;
      }
    } else {
      if (calc.subsubtitle.open) {
        calc.subsubtitle.position++;
      } else if (calc.subtitle.open) {
        calc.subtitle.position++;
      } else if (calc.title.open) {
        calc.title.position++;
      } else {
        calc.total.position++;
      }
      pos = (calc.title.open ? ("00" + calc.title.index).slice(-2) + "." : "")
          + (calc.subtitle.open ? ("00" + calc.subtitle.index).slice(-2) + "." : "")
          + (calc.subsubtitle.open ? ("00" + calc.subsubtitle.index).slice(-2) + "." : "")
          + (calc.subsubtitle.open ? ("000" + calc.subsubtitle.position).slice(-3) :
              (calc.subtitle.open ? ("000" + calc.subtitle.position).slice(-3) :
                (calc.title.open ? ("000" + calc.title.position).slice(-3) : ("000" + calc.total.position).slice(-3))));
    }
    if(title) return (<></>);

    return (<Article {...props} posName={posName} pos={pos} />);
  }

  return (
    <>
      <Row>
        <Col className="px-4 py-2">
          <p className="fw-semibold my-1">
        {
          offerIndex === 0 ? "Hauptauftrag" : "Nachtragsauftrag " + offerIndex
        }
          </p>
        </Col>
      </Row>
      
        <Accordion flush alwaysOpen style={{fontSize:"0.875rem"}}>
        {
          articles.map(([articleId, articleData], index) => <RenderArticle {...props} articleId={articleId} articleData={articleData} key={index} articleIndex={index} />)
        }
        </Accordion>
    </>
  )
}

export default Offer