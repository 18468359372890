export const defaultData = {
  name: "",
  bauteil: "",
  bezeichnung: "",
  anschrift: "",
  PLZ: "",
  ort: "",
  beginn: "",
  technisch: "",
  status: "offen",
  kfm: "",
};
