export const defaultTL = {
  art: "Löhne",
  desc: "",
  menge: 0,
  EP: 0,
  ZS: 0,
  VP: 0
};

export const defaultStates = [
  {search: "Offene Anfragen", name: "Anfrage"},
  {search: "Angebote in Bearbeitung", name: "Angebot"},
  {search: "Angebote versandt", name: "Versandt"},
  {search: "Beauftrage Angebote", name: "Beauftragt"},
  {search: "Verlorene Angebote", name: "Verloren"},
  {search: "Nicht abgegebene Anfragen", name: "Keine Abgabe"}
];
export const defaultStatus = ["Offene Anfrage", "Angebot offen", "Angebot versandt", "Auftrag", "Kein Angebot", "Verloren"];
export const defaultTyp = ["Löhne", "Stoffe", "Geräte", "Fremdleistung", "Sonstige"];
export const defaultArt = ["Verkauf", "Leistung", "Mietleistung"];
export const defaultEinheit = ["cm", "m", "stgm", "m²", "m³", "l", "kg", "min", "h", "Tag", "St", "VE", "Palette", "psch"];
export const einheitsTyp = {cm: "l", m: "l", stgm: "h", "m²": "lh" , "m³": "lbh", l: "e", kg: "e", min: "e", h: "e", Tag: "e", St: "", VE: "e", Palette: "e", psch: ""};
export const defaultMieteinheit = ["min", "h", "WrkTg", "ArbTg", "Tg", "Wo", "Mo"];
export const umrechnungMiete = {min: (24*60), h: 24, WrkTg: (1/(7*6)), ArbTg: (1/(7*5)), Tg: 1, Wo: (1/7), Mo: (1/(365*12))};
export const defaultArticleTyp = {P: "Normalposition", BP: "Bedarfsposition", GP: "Position mit Miete", MP: "Mietposition", TK: "Titelkopf", TS: "Titelsumme"};

export const defaultArticle = {
  typ: "P",
  kurztext: "",
  langtext: "",
  einheit: defaultEinheit[1],
  VorhalteDauer: 0,
  VorhalteEinheit: defaultMieteinheit[5],
  TK: {
    
    Menge: "0,000",
    NumericMenge: 0,
    
    EP: "0,000",
    NumericEP: 0,

    Zeit: "0,000",
    NumericZeit: 0,
    NumericLohn: 0,

    Stoffkosten: "0,000",
    NumericStoffkosten: 0,
    NumericStoffe: 0,

    Gerätekosten: "0,000",
    NumericGerätekosten: 0,
    NumericGeräte: 0,

    Sonstigekosten: "0,000",
    NumericSonstigekosten: 0,
    NumericSonstige: 0,

    Fremdkosten: "0,000",
    NumericFremdkosten: 0,
    NumericFremd: 0,
    
  }
};
export const defaultData = {
  name: "",
  bauteil: "",
  anschrift: "",
  PLZ: "",
  ort: "",
  land: "",
  herkunft: "",
  projectId: "",
  contactId: "",
  contact: {
    anrede: "",
    vorname: "",
    name: "",
    anschrift: "",
    PLZ: "",
    ort: "",
    email: "",
    telefon: "",
  },
  vorgaben: {
    Stundenlohn: "0,000",
    NumericStundenlohn: 0,
    StoffZuschlag: "0,000",
    NumericStoffZuschlag: 0,
    GeräteZuschlag: "0,000",
    NumericGeräteZuschlag: 0,
    SonstigeZuschlag: "0,000",
    NumericSonstigeZuschlag: 0,
    FremdZuschlag: "0,000",
    NumericFremdZuschlag: 0,
    Nachlass: "0,000",
    NumericNachlass: 0,
    Skonto: "0,000",
    NumericSkonto: 0,
    SkontoTage: "0",
    NumericSkontoTage: 0,
    ZielAR: "21",
    NumericZielAR: 21,
    ZielSR: "30",
    NumericZielSR: 30,
    locked: false,
  },
  architectId: "",
  architect: {},
  articles: [],
  SummeNetto: 0,
  USt: 0.19,
  SummeUSt: 0,
  skonto: 0,
  skontoTage: 0,
  zielAR: 21,
  zielSR: 30,
  SummeBrutto: 0,
  angebotsnummer: "",
  nachtragsangebot: false,
  

  nachlassProzent: 0,
  nachlassSumme: 0,
  SummeNachNachlass: 0,
  massnahmenNummer: "",
  vergabeNummer: "",
  langtext: true,
  status: "Angebot",
  skontoFrist: 14,
  skontoEinheit: "Tage",
  skontoProzent: 0,
  vergabeart: "",
};

// Ausgangseinheit * faktor / neue Einheit
// Grundeinheit = 1 Tag