import {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { BsFillTrash3Fill } from "react-icons/bs";
import InputGroup from "react-bootstrap/InputGroup";
import { defaultArt, defaultArticle, defaultEinheit, defaultMieteinheit, defaultStatus } from "./defaultData";
import { saver } from "../../../redux/actions/database/saver";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../redux/actions/database/loader";
import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";
import { updater } from "../../../redux/actions/database/updater";
import History from "../../APP/History/History";
import { pushToDB } from "../../../functions/settings/pushToDB";

const digits3 = {
  style: "decimal",
  useGrouping: true,
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
  minimumIntegerDigits: 1,
  currency: undefined,
  currencyDisplay: "symbol",
  notation: "standard",
  compactDisplay: "short",
  localeMatcher: "best fit",
  numberingSystem: "latn",
  signDisplay: "auto",
  signStyle: "standard",
};
const digits2 = {...digits3, minimumFractionDigits: 2, maximumFractionDigits: 2};

const MultiTool = ({obj = {}, objId = null, mode = "detail", closeModal}) => {

  const { settings, settingsLoaded, settingsError } = useSelector(state => state.crm);
  
  const vorgaben = (settings.settings.hasOwnProperty("kalkulation") ? {...settings.settings.kalkulation} : {});

  const isLocked = (settings.settings.hasOwnProperty("kalkulation") && settings.settings.kalkulation.hasOwnProperty("locked") && settings.settings.kalkulation.locked);

  const [article, setArticle] = useState({...defaultArticle, ...obj, TK: {...defaultArticle.TK, ...vorgaben, ...obj?.TK}});

  // State for view modus
  const [showMode, setMode] = useState(mode);
  // Show Mode for History
  const [page, setPage] = useState("");
  // State for Editing active or not.
  const [editMode, setEdit] = useState(false);
    // State to see if there are any changes.
  const [isDirty, setDirty] = useState(false);
  // State to validate the form.
  const [validated, setValidated] = useState(false);
  

  const dispatch = useDispatch();
  
  // handle changes in the form.
  const handleChange = e => {
    setDirty(true);
    setArticle(prev => ({...prev, [e.target.name]: e.target.value }));
  }
  
  const handleReset = e => {
    if(window.confirm("Alle Eingaben verwerfen?")) {
      if(showMode === "edit") {
        setArticle({...defaultArticle, ...obj, TK: {...defaultArticle.TK, ...obj?.TK}});
        setValidated(false);
        setDirty(false);
        setMode("detail");
      }
      else {
        setArticle({...defaultArticle});
        setValidated(false);
        setDirty(false);
        closeModal();
      }
    }
  }
  const handleSave = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    } 
    const lv = (article.newLV !== "") ? article.newLV : article.lv;
    if(article.newLV !== "") {
      const existing = (settings?.Leistungsverzeichnisse ? Object.values(settings.Leistungsverzeichnisse) : []);
      if(existing.length === 0 || !existing.includes(article.newLV)) {
        pushToDB("root/Leistungsverzeichnisse", article.newLV);
      }
    }
    const cleanData = {...article, lv: lv, newLV: null}
    if(showMode === "edit" && objId !== null) {
      dispatch(updater("articles", cleanData, objId, obj));
    }
    if(showMode === "add") {
      saver("articles", cleanData);
    }
    setArticle(cleanData)
    setDirty(false);
    setValidated(false);
    setMode("detail");
  }

  useEffect(()=>{
    if(!settingsLoaded) {
      dispatch(loader("settings"));
    }
  },[settingsLoaded, dispatch]);

  useEffect(() => {
    setEdit((showMode === "edit" || showMode === "add"));
  }, [showMode, mode]);

  if(!settingsLoaded) {
    return <Loading />;
  } else if (settingsError !== null) {
    return <Error />;
  }

  const handleResetLV = () => {
    setArticle(prev => ({...prev, newLV: "", lv: ""}));
  }

  // const SearchProject = () => { return (<><h6>Projektdaten</h6><Alert variant="warning" className="text-center"><p>Aufgaben müssen einem Projekt zugeordnet sein.</p><Button variant="warning" onClick={() => setShowModal(true)}>Projekt suchen</Button></Alert></>);}
  /* const ProjectDetail = ({id}) => {
    if(!projects.hasOwnProperty(id)) {
      return <SearchProject />;
    } else if(!contacts.hasOwnProperty(projects[id].contactId)) {
      return <SearchProject />;
    }
    const project = projects[id];
    const contact = contacts[project.contactId];
    const architect = contacts[project?.architectId];
    return (<Card className="mb-4"><Card.Body><Row>
      <Col>
        <div className="fw-semibold">Projektdaten</div>
        { (project?.name) ? project.name + " - " : null }{ project?.bezeichnung || ""}<br/>
        { project?.anschrift || null }<br/>
        { (project?.PLZ) ? project.PLZ + " " : null }{ project?.ort || null }
      </Col>
      <Col>
        <div className="fw-semibold">Auftraggeber</div>
        { contact?.name || null }<br/>
        { contact?.anschrift || null }<br/>
        { (contact?.PLZ) ? contact.PLZ + " " : null }{ contact?.ort || null }
      </Col>
      <Col>
        <div className="fw-semibold">Architekt / Planer</div>
        { architect?.name || null }<br/>
        { architect?.anschrift || null }<br/>
        { (architect?.PLZ) ? architect.PLZ + " " : null }{ architect?.ort || null }
      </Col>
    </Row>{ editMode && <Button variant="outline-primary" className="mt-2" size="sm" onClick={() => setShowModal(true)}>Ändern</Button> }</Card.Body></Card>);
  }
  */

  if(page === "history") {
    return <History obj={{...obj, id: objId}} type="articles" goBack={() => setPage("")} />;
  }

  const handleBlur = e => {
    const {name, value} = e.target;
    const teilkosten = {...defaultArticle.TK, ...article.TK};
    if(value === "," || value === "" || value === "0") {
      teilkosten[name] = "0,000";
    } else if(value.includes(".") && value.includes(",")) {
      if(value.indexOf(".") < value.indexOf(",")) {
        const [, decimalPart] = value.split(",");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        teilkosten[name] = value + zeros;
      } else {
        const [, decimalPart] = value.split(".");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        teilkosten[name] = value + zeros;
      }
    } else if(value.includes(",")) {
      const [, decimalPart] = value.split(",");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        teilkosten[name] = value + zeros;
    } else if(value.includes(".")) {
        const [, decimalPart] = value.split(".");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        teilkosten[name] = value + zeros;
    } else if(!value.includes(",") && !value.includes(".")) {
        const [, decimalPart] = value.split(",");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        teilkosten[name] = value + "," + zeros;
    }
    setArticle(prev => ({...prev, TK: teilkosten}));
  }

  const handleNumeric = e => {
    const {name, value} = e.target;
    let EP = 0;
    const newValue = value.replace(/[^0-9.,]/g, '');
    const numericName = "Numeric" + name;
    const teilkosten = {...defaultArticle.TK, ...article.TK};
    if(newValue === ",") {
      teilkosten[name] = "0,";
      teilkosten[numericName] = 0;
    } else if(newValue.includes(".") && newValue.includes(",")) {
      if(newValue.indexOf(".") < newValue.indexOf(",")) {
        teilkosten[name] = newValue;
        teilkosten[numericName] = parseFloat("0" + newValue.replace(".","").replace(",",".") + "0");
      } else {
        teilkosten[name] = newValue;
        teilkosten[numericName] = parseFloat("0" + newValue.replace(",","") + "0");
      }
    } else if(newValue.includes(",")) {
      if(newValue.indexOf(",") === 0) {
        teilkosten[name] = "0" + newValue;
      } else {
        teilkosten[name] = newValue;
      }
      teilkosten[numericName] = parseFloat("0" + newValue.replace(",",".") + "0");
    } else if(newValue.includes(".")) {
      if(newValue.indexOf(".") === 0) {
        teilkosten[name] = "0" + newValue;
      } else {
        teilkosten[name] = newValue;
      }
      teilkosten[numericName] = parseFloat("0" + newValue + "0");
    } else if(newValue==="") {
      teilkosten[name] = newValue;
      teilkosten[numericName] = 0;
    } else {
      teilkosten[name] = newValue;
      teilkosten[numericName] = parseFloat(newValue);
    }
    teilkosten.NumericLohn = (teilkosten?.NumericZeit || 0) * (teilkosten?.NumericStundenlohn || 0);
    teilkosten.NumericStoffe = (teilkosten?.NumericStoffkosten || 0) * (1 + ((teilkosten?.NumericStoffZuschlag || 0) / 100));
    teilkosten.NumericGeräte = (teilkosten?.NumericGerätekosten || 0) * (1 + ((teilkosten?.NumericGeräteZuschlag || 0) / 100));
    teilkosten.NumericSonstige = (teilkosten?.NumericSonstigekosten || 0) * (1 + ((teilkosten?.NumericSonstigeZuschlag || 0) / 100));
    teilkosten.NumericFremd = (teilkosten?.NumericFremdkosten || 0) * (1 + ((teilkosten?.NumericFremdZuschlag || 0) / 100));
    if(name!== "EP") {
      EP = parseFloat((teilkosten?.NumericLohn || 0) + (teilkosten?.NumericGeräte || 0) + (teilkosten?.NumericStoffe || 0) + (teilkosten?.NumericFremd || 0) + (teilkosten?.NumericSonstige || 0));
      teilkosten.NumericEP = EP;
      teilkosten.EP = EP.toLocaleString("de-DE",digits3);
    }
    setArticle(prev => ({...prev, TK: teilkosten}));
  }

  return (
    <Form onSubmit={handleSave} noValidate validated={validated}>
      
      <Row>
        <Col xs={12} md={6} lg={4}>
          <Row  className="mb-2">
            <Col>
              <FormGroup>
                <Form.Text>Verwendung</Form.Text>
                <Form.Control size="sm" as="select" name="status" disabled={!editMode} value={article.status} onChange={handleChange} required>
                <option value="">{(editMode ? "Bitte wählen" : "") }</option>
                {
                  defaultStatus.map((value, index) => <option key={index} value={value}>{value}</option>)
                }
                </Form.Control>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Artikeltyp</Form.Text>
                <Form.Control as="select" size="sm" name="art" disabled={!editMode} value={article.art} onChange={handleChange} required>
                <option value="">{(editMode ? "Bitte wählen" : "") }</option>
                {
                  defaultArt.map((value, index) => <option key={index} value={value}>{value}</option>)
                }
                </Form.Control>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Einheit</Form.Text>
                <Form.Control as="select" size="sm" name="einheit" disabled={!editMode} value={article.einheit} onChange={handleChange} required>
                <option value="">{(editMode ? "Bitte wählen" : "") }</option>
                {
                  defaultEinheit.map((value, index) => <option key={index} value={value}>{value}</option>)
                }
                </Form.Control>
              </FormGroup>
            </Col>
          </Row>
          {
            (article.art === "Mietleistung") && <>
          <Row className="mb-2">
            <Col>
              <FormGroup>
                <Form.Text>Mieteinheit</Form.Text>
                <Form.Control as="select" size="sm" name="VorhalteEinheit" disabled={!editMode} value={article.VorhalteEinheit} onChange={handleChange} required>
                {
                  defaultMieteinheit.map((value, index) => <option key={index} value={value}>{value}</option>)
                }
                </Form.Control>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Enthaltene Miete</Form.Text>
                <InputGroup>
                  <Form.Control size="sm" type="number" className="inputEuro" min="0" step="1" name="VorhalteDauer" disabled={!editMode} value={article.VorhalteDauer} onChange={handleChange} required/>
                  <Form.Control as="select" size="sm" name="gvhEinheit" disabled={!editMode} value={article.VorhalteEinheit} onChange={handleChange} required>
                    {
                      defaultMieteinheit.map((value, index) => <option key={index} value={value}>{value}</option>)
                    }
                  </Form.Control>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row></> }
          
        </Col>
        <Col xs={12} md={8}>
          <Row>
            <Col xs={12} md={8}>
              <FormGroup className="mb-2">
                <Form.Text>Kurztext</Form.Text>
                <Form.Control name="kurztext" size="sm" readOnly={!editMode} plaintext={!editMode} value={article.kurztext} onChange={handleChange} required />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="mb-2">
                <Form.Text>Leistungsgruppe</Form.Text>
                {
                  ((settings.hasOwnProperty('Leistungsverzeichnisse') && Object.keys(settings.Leistungsverzeichnisse).length > 0) && article.lv !== "new") ?
                  <Form.Control as="select" size="sm" name="lv" value={article.lv} disabled={!editMode} onChange={handleChange} required>
                    <option value="">Bitte wählen</option>
                    {
                      settings?.Leistungsverzeichnisse && Object.values(settings.Leistungsverzeichnisse).map((value, index) => <option key={index} value={value}>{value}</option>)
                    }
                    <option value="new">Neu erstellen</option>
                  </Form.Control>
                  :
                  <InputGroup size="sm">
                    <Form.Control name="newLV" readOnly={!editMode} value={article.newLV} onChange={handleChange} placeholder="Neue Gruppe" required />
                    <Button variant="outline-danger" onClick={handleResetLV}><BsFillTrash3Fill /></Button>
                  </InputGroup>
                }
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={8}>
              <FormGroup className="mb-2">
                <Form.Text>Langtext</Form.Text>
                <Form.Control name="langtext" size="sm" readOnly={!editMode} plaintext={!editMode} as="textarea" rows={4} value={article.langtext} onChange={handleChange} required={article.status === "Extern" } />
              </FormGroup>
            </Col>
            <Col>
              <Form.Group className="mb-2">
                <Form.Text>Einheitspreis</Form.Text>
                <InputGroup size="sm">
                  <Form.Control className="inputEuro" readOnly disabled value={article.TK.EP} />
                  <InputGroup.Text>€</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Text>Zeitwert</Form.Text>
                <InputGroup size="sm">
                  <Form.Control className="inputEuro" readOnly disabled value={(article.TK.NumericZeit * 60).toLocaleString("de-DE", digits2)} />
                  <InputGroup.Text>Minuten</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      
      <Row className="mb-4">
      <Col><p className="fw-semibold mt-4 mb-0" >Preisermittlung</p></Col>
      <Col/>
      <Col>
      </Col>
      <Col/>
    </Row>
    <Row className="mb-2">
      <Col>
        <Form.Group>
          <Form.Text>Zeitansatz</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" value={article.TK.Zeit} name="Zeit" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>h</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Verrechnungslohn</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" disabled={isLocked} value={article.TK.Stundenlohn} name="Stundenlohn" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Lohnkosten</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" readOnly disabled value={(article.TK.NumericLohn.toLocaleString("de-DE",digits3) || 0)} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
      </Col>
    </Row>
    <Row className="mb-2">
      <Col>
        <Form.Group>
          <Form.Text>Stoffe (Material)</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" value={article.TK.Stoffkosten} name="Stoffkosten" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Zuschlag</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" disabled={isLocked} value={article.TK.StoffZuschlag} name="StoffZuschlag" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Stoffkosten</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" readOnly disabled value={(article.TK.NumericStoffe.toLocaleString("de-DE",digits3) || 0)} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col />
    </Row>
    <Row className="mb-2">
      <Col>
        <Form.Group>
          <Form.Text>Geräte (Maschinen)</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" value={article.TK.Gerätekosten} name="Gerätekosten" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Zuschlag</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" disabled={isLocked} value={article.TK.GeräteZuschlag} name="GeräteZuschlag" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Gerätekosten</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" readOnly disabled value={(article.TK.NumericGeräte.toLocaleString("de-DE",digits3) || 0)} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col />
    </Row>
    <Row className="mb-2">
      <Col>
        <Form.Group>
          <Form.Text>Fremdleistungen</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" value={article.TK.Fremdkosten} name="Fremdkosten" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Zuschlag</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" disabled={isLocked} value={article.TK.FremdZuschlag} name="FremdZuschlag" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Fremdkosten</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" readOnly disabled value={(article.TK.NumericFremd.toLocaleString("de-DE",digits3) || 0)} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col />
    </Row>
    <Row className="mb-2">
      <Col>
        <Form.Group>
          <Form.Text>Sonstiges</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" value={article.TK.Sonstigekosten} name="Sonstigekosten" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Zuschlag</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" disabled={isLocked} value={article.TK.SonstigeZuschlag} name="SonstigeZuschlag" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Sonstigekosten</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" readOnly disabled value={(article.TK.NumericSonstige.toLocaleString("de-DE",digits3) || 0)} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col />
    </Row>
    {
      isLocked && <p className="text-center">Zuschläge und Stundenlöhne wurden in den Stammdaten festgelegt und gesperrt.</p>
    }
      <Row className="mt-4">
      <Col align="end">
        {
          (editMode) ? <ButtonGroup>
            {
              (showMode === "add") ? <Button onClick={handleReset} disabled={!isDirty} variant="outline-primary">Verwerfen</Button> :
              (isDirty) ? <Button onClick={handleReset} variant="outline-primary">Verwerfen</Button> :
              <Button onClick={() => setMode("detail")} variant="outline-primary">Abbrechen</Button>
            } 
            <Button type="submit" disabled={!isDirty}>Speichern</Button>
          </ButtonGroup> :
          <ButtonGroup>
            <Button onClick={() => setPage("history") } variant="outline-primary">Historie</Button>
            <Button onClick={() => setMode("edit")} variant="outline-primary">Bearbeiten</Button>
            <Button onClick={closeModal}>Schließen</Button>
          </ButtonGroup>
        }
        
      </Col>
    </Row>
  </Form>
  )
}

export default MultiTool