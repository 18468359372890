const initialState = {
  isLoaded: false,
  isEmpty: true,
  isLoggedIn: false,
  hasError: false,
  rights: {},
  licenses: {},
  displayName: null,
  photoUrl: null,
  userImage: null,
};
export default initialState;