import { NavLink } from "react-router-dom";

const MeinBereich = () => {

  return (
    <div className="menuNav">
      <p className="menuNavBtn">Mein Bereich</p>
      <div className="menuSubmenu">
        { /*
        <NavLink to="/mysettings/details" className="menuSubBtn">
          Meine Daten ändern
          <span className="menuSubBtnSmall">Name und Erreichbarkeit aktualisieren</span>
        </NavLink>
        <NavLink to="/mysettings/security" className="menuSubBtn">
          Passwort ändern
          <span className="menuSubBtnSmall">Sicherheitseinstellungen ändern</span>
        </NavLink>
        */ }
        <NavLink to="/user/vacation" className="menuSubBtn">
          Meine Urlaubsanträge
          <span className="menuSubBtnSmall">Urlaub beantragen und einsehen</span>
        </NavLink>
        <NavLink to="/user/absence" className="menuSubBtn">
          Krankmeldung
          <span className="menuSubBtnSmall">Krankmeldung übermitteln</span>
        </NavLink>
      </div>
    </div>);
}

export default MeinBereich