import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import { BsTextRight, BsTextLeft, BsTextCenter,
  BsArrowUpLeft, BsArrowUp, BsArrowUpRight,
  BsArrowLeft, BsArrowsFullscreen, BsArrowRight,
  BsArrowDownLeft, BsArrowDown, BsArrowDownRight
} from "react-icons/bs";

const Briefpapier = () => {
  
  const [logoSize, setLogoSize] = useState(50);
  const [logoAlign, setLogoAlign] = useState("flex-end");
  const [logoVAlign, setLogoVAlign] = useState("center");
  const [footerText, setFooterText] = useState("");
  const [footerSize, setFooterSize] = useState(10);
  const [footerAlignment, setFooterAlignment] = useState("center");

  const fontSize = (s) => {
    if (s === "+" && footerSize < 14) {
      setFooterSize((prev) => prev + 1);
    } else if (s === "-" && footerSize > 6) {
      setFooterSize((prev) => prev - 1);
    }
  };

  const handleAlign = (align,valing) => {
    setLogoAlign(align);
    setLogoVAlign(valing);
  }
  return (
    <Row>
      <Col>
        <div
          style={{
            fontFamily: "Arial",
            width: "210mm",
            height: "297mm",
            border: "1px solid rgba(0,0,0,0.2)",
            boxShadow: "3px 3px 3px rgba(0,0,0,0.2)",
            position: "relative",
          }} >
          <div style={{
            backgroundColor: "rgba(255,255,0,0.2)",
            position: "absolute",
            top: "1.5cm",
            left: "2cm",
            right: "2cm",
            height: "2.5cm",
            display: "flex",
            alignItems: logoVAlign,
            justifyContent: logoAlign,
          }}>
            <p>Logo</p>
          </div>
          <div
            style={{
              backgroundColor: "rgba(255,255,0,0.2)",
              position: "absolute",
              top: "10.346cm",
              right: "2cm",
              left: "2cm",
              bottom: "4cm",
              fontSize: "10pt",
            }}
          ></div>
          <div
            style={{
              backgroundColor: "rgba(255,255,0,0.2)",
              position: "absolute",
              top: "4.6cm",
              left: "2cm",
              width: "8.5cm",
              height: "4.6cm",
              paddingTop: "1.5cm",
              paddingLeft: ".5cm",
              fontSize: "10pt",
            }}
          >
            <p></p>
            Herr
            <br />
            Max Mustermann
            <br />
            c/o Katrin Mustermann
            <br />
            Bahnhofstraße 23
            <br />
            12345 Berlin
          </div>
          <div
            style={{
              backgroundColor: "rgba(255,255,0,0.2)",
              position: "absolute",
              top: "5cm",
              right: "2cm",
              width: "7.5cm",
              height: "4cm",
              fontSize: "10pt",
            }}
          >
            <table>
              <tbody>
                <tr>
                  <td>Ansprechpartner:</td>
                  <td>Max Mustermann</td>
                </tr>
                <tr>
                  <td>Abteilung:</td>
                  <td>Bauleitung</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>Telefon</td>
                  <td>030 - 321 24 323</td>
                </tr>
                <tr>
                  <td>E-Mail</td>
                  <td>mustermann@werkapp.de</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>Datum:</td>
                  <td>15.05.2023</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style={{
              fontSize: footerSize + "pt",
              position: "absolute",
              backgroundColor: "rgba(255,255,0,0.2)",
              bottom: ".5cm",
              right: "2cm",
              left: "2cm",
              textAlign: footerAlignment,
              whiteSpace: "pre-line",
            }}
          >
            {footerText}
          </div>
        </div>
        
      </Col>
      <Col>
        <p>Hier können Sie das Briefpapier anpassen, welches für Angebote, Rechnungen und andere Dokumente verwendet wird.</p>
        <Form>
          <FormGroup>
            <Form.Text>Firmenlogo</Form.Text>
            <Form.Control type="file" />
          </FormGroup>
          <FormGroup>
            <Form.Text>Logo Größe</Form.Text>
            <Form.Range value={logoSize}
            onChange={(e) => setLogoSize(parseInt(e.target.value))}/> 
          </FormGroup>
          <Row>
            <Col align="center">
            <ButtonGroup vertical>
              <ButtonGroup>
                <Button size="sm" onClick={() => handleAlign("flex-start","flex-start")} variant={(logoAlign==="flex-start" && logoVAlign==="flex-start")?"primary":"primary-outline"}><BsArrowUpLeft /></Button>
                <Button size="sm" onClick={() => handleAlign("center","flex-start")} variant={(logoAlign==="center" && logoVAlign==="flex-start")?"primary":"primary-outline"}><BsArrowUp /></Button>
                <Button size="sm" onClick={() => handleAlign("flex-end","flex-start")} variant={(logoAlign==="flex-end" && logoVAlign==="flex-start")?"primary":"primary-outline"}><BsArrowUpRight /></Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button size="sm" onClick={() => handleAlign("flex-start","center")} variant={(logoAlign==="flex-start" && logoVAlign==="center")?"primary":"primary-outline"}><BsArrowLeft /></Button>
                <Button size="sm" onClick={() => handleAlign("center","center")} variant={(logoAlign==="center" && logoVAlign==="center")?"primary":"primary-outline"}><BsArrowsFullscreen /></Button>
                <Button size="sm" onClick={() => handleAlign("flex-end","center")} variant={(logoAlign==="flex-end" && logoVAlign==="center")?"primary":"primary-outline"}><BsArrowRight /></Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button size="sm" onClick={() => handleAlign("flex-start","flex-end")} variant={(logoAlign==="flex-start" && logoVAlign==="flex-end")?"primary":"primary-outline"}><BsArrowDownLeft /></Button>
                <Button size="sm" onClick={() => handleAlign("center","flex-end")} variant={(logoAlign==="center" && logoVAlign==="flex-end")?"primary":"primary-outline"}><BsArrowDown /></Button>
                <Button size="sm" onClick={() => handleAlign("flex-end","flex-end")} variant={(logoAlign==="flex-end" && logoVAlign==="flex-end")?"primary":"primary-outline"}><BsArrowDownRight /></Button>
              </ButtonGroup>
              </ButtonGroup>
            </Col>
          </Row>
          
          <br/><br/><br/><br/><br/><br/><br/>
          <FormGroup>
            <Form.Text>Fußzeile</Form.Text>
            <Form.Control
              as="textarea"
              style={{ textAlign: footerAlignment }}
              value={footerText}
              onChange={(e) => setFooterText(e.target.value)}
            />
          </FormGroup>
          <Row>
            <Col align="center">
              <ButtonGroup>
                <Button
                  onClick={() => setFooterAlignment("left")}
                  variant={footerAlignment === "left" ? "primary" : "outline-primary"}
                >
                  <BsTextLeft />
                </Button>
                <Button
                  onClick={() => setFooterAlignment("center")}
                  variant={footerAlignment === "center" ? "primary" : "outline-primary"}
                >
                  <BsTextCenter />
                </Button>
                <Button
                  onClick={() => setFooterAlignment("right")}
                  variant={footerAlignment === "right" ? "primary" : "outline-primary"}
                >
                  <BsTextRight />
                </Button>
              </ButtonGroup>
              {" "}
              <ButtonGroup>
                <Button onClick={() => fontSize("-")} size="sm">
                  A
                </Button>
                <Button onClick={() => fontSize("+")} size="lg" style={{ padding: "0.25rem 0.5rem" }}>
                  A
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default Briefpapier;
