import { getAuth, getIdTokenResult } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';

let listener = {};

const loadSuccess = (typ, data) => ({
  type: 'LOAD_' + typ.toUpperCase() + '_SUCCESS',
  payload: data,
});

const loadFailure = (typ, error) => ({
  type: 'LOAD_' + typ.toUpperCase() + '_FAILURE',
  error,
});

export const loader = (where, as = '') => async dispatch => {

  const user = getAuth().currentUser;
  const claims = await getIdTokenResult(user);
  const {client, sales} = claims.claims;
  
  const databaseId = (sales === undefined) ? client : user.uid;
  if(sales === undefined && (client === null || client === undefined)) return {error: true};

  if(listener.hasOwnProperty(where)) {
    const unlisten = listener[where];
    unlisten();
    delete(listener[where]);
  }
  const typ = (as==='' ? where : as);

  const sourceRef = ref(getDatabase(), databaseId + "/" + where);
  listener[where] = onValue(sourceRef, (snapshot) => {
      const data = snapshot.val();
    dispatch(loadSuccess(typ, data));
  }, (error) => {
    dispatch(loadFailure(typ, error));
  });
};

export const disableListeners = (specific = null) => {
  if(specific !== null && listener.hasOwnProperty(specific)) {
    const unlisten = listener[specific];
    unlisten();
  } else if (specific === null) {
    Object.values(listener).forEach(unlisten => unlisten());
  }
};