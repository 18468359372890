import { store } from "../../redux/store";
const { PDFDocument, StandardFonts } = require("pdf-lib");

const digits3 = {
  style: "decimal",
  useGrouping: true,
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
  minimumIntegerDigits: 1,
  currency: undefined,
  currencyDisplay: "symbol",
  notation: "standard",
  compactDisplay: "short",
  localeMatcher: "best fit",
  numberingSystem: "latn",
  signDisplay: "auto",
  signStyle: "standard",
};

export async function createmeasurmentPDF(obj, docDate = null, blank = false) {
  const { crm } = store.getState();
  const { settings } = crm;
  const documentNameId = "Aufmaß";
  const mm = (1 / .35);
  const A4 = [841.89, 595.28];
  const pdf = await PDFDocument.create();
  const doc = {
    width: A4[0],
    height: A4[1],
    margin: {
      left: 12,
      right: 12,
      top: 20,
      bottom: 12,
    },
    fontSize: 10,
    lineHeight: 11,
    pos: 16 * mm,
    measure: 22 * mm,
    price: 25 * mm,
    font: {
      normal: await pdf.embedFont(StandardFonts.Helvetica),
      bold: await pdf.embedFont(StandardFonts.HelveticaBold),
    }
  };
  // Set CM to PX
  for ( const size in doc.margin ) {
    doc.margin[size] = doc.margin[size] * mm;
  }
  
  const documentDate = () => {
    if(docDate !== null) {
      const date = new Date(docDate);
      return date.getDate().toString().padStart(2, "0") + "." + (date.getMonth() + 1).toString().padStart(2,"0") + "." + date.getFullYear().toString().padStart(2,"0");
    }
    const date = new Date();
      return date.getDate().toString().padStart(2, "0") + "." + (date.getMonth() + 1).toString().padStart(2,"0") + "." + date.getFullYear().toString().padStart(2,"0");
  }

  // Faltmarken hinzufügen
  const addFoldmarks = (thisPage) => {
    thisPage.drawLine({
      start: {x: doc.width - (105 * mm), y: doc.height - (4 * mm)},
      end: {x: doc.width - (105 * mm), y: doc.height - (8 * mm)},
      thickness: .5,
    });
    thisPage.drawLine({
      start: {x: doc.width - (210 * mm), y: doc.height - (4 * mm)},
      end: {x: doc.width - (210 * mm), y: doc.height - (8 * mm)},
      thickness: .5,
    });
    thisPage.drawLine({
      start: {x:  doc.width / 2, y: doc.height},
      end: {x: doc.width / 2, y: doc.height - (8 * mm)},
      thickness: .75,
    });
  }
  const addFooter = (thisPage) => {
    thisPage.drawLine({
      start: {x: doc.margin.left, y: doc.height - (20.5 * mm)},
      end: {x: doc.width - doc.margin.right, y: doc.height - (20.5 * mm)},
      thickness: .5,
    });

    let companyInfo = (settings?.companyName ? settings.companyName : "") 
    + (settings?.anschrift ? " | " + settings.anschrift : "")
    + (settings?.PLZ ? " | " + settings.PLZ + " " : "")
    + (settings?.ort ? settings.ort : "");

    companyInfo = (companyInfo === "") ? "Aufmaß erstellt mit WerkAPP - Die Software fürs Handwerk" : companyInfo;

    thisPage.drawText(companyInfo, {
      x: (doc.margin.left),
      y: doc.height - (14 * mm),
      font: doc.font.normal,
      maxWidth: doc.width - (doc.margin.right + doc.margin.left),
      size: 10,
      lineHeight: 10.8,
    });

    const aufmassString = "Aufmaß zu Projekt: " + (obj?.name ? obj.name + " " : "") + (obj?.bauteil ? obj.bauteil + " " : "")
                          + (obj?.anschrift ? obj.anschrift + " " : "") + (obj?.PLZ ? ", " + obj.PLZ + " " : "") + (obj?.ort ? obj.ort : "");
    thisPage.drawText(aufmassString, {
      x: (doc.margin.left),
      y: doc.height - (19 * mm),
      font: doc.font.bold,
      maxWidth: doc.width - (doc.margin.right + doc.margin.left),
      size: 10,
      lineHeight: 10.8,
    });
    const dateString = "Druckdatum: " + documentDate();
    thisPage.drawText(dateString, {
      x: doc.width - doc.margin.right - doc.font.normal.widthOfTextAtSize(dateString, 9),
      y: doc.height - (19 * mm),
      font: doc.font.normal,
      size: 9,
      lineHeight: 9.8,
    });
  }

  const addNewPage = () => {
    const newPage = pdf.addPage(A4);
    if(!blank) {
      addFoldmarks(newPage);
      addFooter(newPage);
    }
    return newPage;
  }

  let actualPage = addNewPage();
  let currentY = doc.height - doc.margin.top;

  const articleSum = (lines) => {
    let articleQty = 0;
    let articleRentQty = 0;
    if(lines === null || lines === undefined) return { articleQty: articleQty, articleRentQty: articleRentQty};
    Object.values(lines).forEach((line) => {
      if(line.hasOwnProperty("totalQty") && typeof line.totalQty === "number") {
        articleQty += line.totalQty;
      }
      if(line.hasOwnProperty("totalRentQty") && typeof line.totalRentQty === "number") {
        articleRentQty += line.totalRentQty;
      }
    });
    return {articleQty: articleQty.toLocaleString("de-DE", digits3), articleRentQty: articleRentQty.toLocaleString("de-DE", digits3)};
  }
  const parseDate = (date, emptyIsToday = true) => {
    if((date === null || date === undefined || date === 0 || date === "") && emptyIsToday) {
      const dateObj = new Date();
      return dateObj.getDate().toString().padStart(2, "0") + "." + (dateObj.getMonth() + 1).toString().padStart(2, "0") + "." + dateObj.getFullYear();
    }
    else if(date.includes("-")) {
      const part = date.split("-");
      console.log(part);
      return part[2] + "." + part[1] + "." + part[0];
    }
    else if (date !== null && date !== undefined && date !== 0 && date !== "") {
      const dateObj = new Date(date);
      return dateObj.getDate().toString().padStart(2, "0") + "." + (dateObj.getMonth() + 1).toString().padStart(2, "0") + "." + dateObj.getFullYear();
    }
    else return "";
  }

  const offers = (obj.hasOwnProperty("offers") ? Object.values(obj.offers) : []);
  
  offers.forEach((articles, offerIndex) => {
    const helper = {
      title: {
        open: false,
        index: 0,
        article: 0,
      },
      subtitle: {
        open: false,
        index: 0,
        article: 0,
      },
      subsubtitle: {
        open: false,
        index: 0,
        article: 0,
      },
      total: {
        article: 0,
      }
    };
    articles.forEach((article) => {
      let pos = "";
      if(article.typ === "TK") {
        if (helper.subtitle.open) {
          helper.subsubtitle.index++;
          helper.subsubtitle.open = true;
        } else if (helper.title.open) {
          helper.subtitle.index++;
          helper.subtitle.open = true;
        } else {
          helper.title.index++;
          helper.title.open = true;
        }
        pos = (helper.title.open ? ("00" + helper.title.index).slice(-2) : "")
            + (helper.subtitle.open ? "."+("00" + helper.subtitle.index).slice(-2) : "")
            + (helper.subsubtitle.open ? "."+("00" + helper.subsubtitle.index).slice(-2) : "");
      } else if (article.typ === "TS") {
        pos = (helper.title.open ? ("00" + helper.title.index).slice(-2) : "")
            + (helper.subtitle.open ? "."+("00" + helper.subtitle.index).slice(-2) : "")
            + (helper.subsubtitle.open ? "."+("00" + helper.subsubtitle.index).slice(-2) : "");
        if (helper.subsubtitle.open) {
          helper.subsubtitle.article = 0;
          helper.subsubtitle.open = false;
        } else if (helper.subtitle.open) {
          helper.subtitle.article = 0;
          helper.subtitle.open = false;
        } else if (helper.title.open) {
          helper.title.article = 0
          helper.title.open = false;
        }
      } else {
        if (helper.subsubtitle.open) {
          helper.subsubtitle.article++;
        } else if (helper.subtitle.open) {
          helper.subtitle.article++;
        } else if (helper.title.open) {
          helper.title.article++;
        } else {
          helper.total.article++;
        }
        pos = (helper.title.open ? ("00" + helper.title.index).slice(-2) + "." : "")
            + (helper.subtitle.open ? ("00" + helper.subtitle.index).slice(-2) + "." : "")
            + (helper.subsubtitle.open ? ("00" + helper.subsubtitle.index).slice(-2) + "." : "")
            + (helper.subsubtitle.open ? ("000" + helper.subsubtitle.article).slice(-3) :
                (helper.subtitle.open ? ("000" + helper.subtitle.article).slice(-3) :
                  (helper.title.open ? ("000" + helper.title.article).slice(-3) : ("000" + helper.total.article).slice(-3))));
      }
      let {articleQty, articleRentQty} = articleSum(article.lines);
      if(article.typ !== "TK" && article.typ !== "TS" && articleQty !== 0) {
        if(currentY - doc.margin.bottom < 40) {
          actualPage = addNewPage();
          currentY = doc.height - doc.margin.top;
        }
        const posPrefix = (offerIndex === 0 ? "HA " : "NA" + offerIndex + " ");
        currentY -= 18;
        actualPage.drawLine({
          start: {x: doc.margin.left, y: currentY - 3},
          end: {x: doc.width - doc.margin.right, y: currentY - 3},
          thickness: 1
        });
        actualPage.drawText(posPrefix + pos, {
          x: doc.margin.left,
          y: currentY,
          font: doc.font.bold,
          size: 10,
          lineHeight: 11,
        });
        actualPage.drawText(article.kurztext, {
          x: doc.margin.left + (25 * mm),
          y: currentY,
          font: doc.font.bold,
          size: 10,
          lineHeight: 11,
        });
        actualPage.drawText("Gesamt: " + articleQty, {
          x: doc.margin.left + (60 * mm) + (18 * mm) + (4 * 16 * mm) + (1 * 20 * mm) - doc.font.bold.widthOfTextAtSize("Gesamt: " + articleQty, 10),
          y: currentY,
          font: doc.font.bold,
          size: 10,
          lineHeight: 11,
        });
        actualPage.drawText(article.einheit, {
          x: doc.margin.left + (60 * mm) + (18 * mm) + (4 * 16 * mm) + (1 * 21 * mm),
          y: currentY,
          font: doc.font.bold,
          size: 10,
          lineHeight: 11,
        });
        if(article.typ === "MP") {
          articleRentQty = "Mietmenge: " + articleRentQty + " " + article.einheit + article.VorhalteEinheit;
          actualPage.drawText(articleRentQty, {
            x: doc.width - doc.margin.right - doc.font.bold.widthOfTextAtSize(articleRentQty, 10),
            y: currentY,
            font: doc.font.bold,
            size: 10,
            lineHeight: 11,
          });
        }
        currentY -= 15;
        actualPage.drawText("Zeile", {
          x: doc.margin.left + 2,
          y: currentY,
          font: doc.font.normal,
          size: 9,
        });
        actualPage.drawText("Bezeichnung", {
          x: doc.margin.left + (10 * mm),
          y: currentY,
          font: doc.font.normal,
          size: 9,
        });
        actualPage.drawText("Achse", {
          x: doc.margin.left + (60 * mm),
          y: currentY,
          font: doc.font.normal,
          size: 9,
        });
        actualPage.drawText("Stück", {
          x: doc.margin.left + (60 * mm) + (18 * mm) + (1 * 16 * mm)- doc.font.normal.widthOfTextAtSize("Stück", 9),
          y: currentY,
          font: doc.font.normal,
          size: 9,
        });
        actualPage.drawText("Länge", {
          x: doc.margin.left + (60 * mm) + (18 * mm) + (2 * 16 * mm) - doc.font.normal.widthOfTextAtSize("Länge", 9),
          y: currentY,
          font: doc.font.normal,
          size: 9,
        });
        actualPage.drawText("Breite", {
          x: doc.margin.left + (60 * mm) + (18 * mm) + (3 * 16 * mm) - doc.font.normal.widthOfTextAtSize("Breite", 9),
          y: currentY,
          font: doc.font.normal,
          size: 9,
        });
        actualPage.drawText("Höhe", {
          x: doc.margin.left + (60 * mm) + (18 * mm) + (4 * 16 * mm) - doc.font.normal.widthOfTextAtSize("Höhe", 9),
          y: currentY,
          font: doc.font.normal,
          size: 9,
        });
        actualPage.drawText("Summe", {
          x: doc.margin.left + (60 * mm) + (18 * mm) + (4 * 16 * mm) + (1 * 20 * mm) - doc.font.normal.widthOfTextAtSize("Summe", 9),
          y: currentY,
          font: doc.font.normal,
          size: 9,
        });
        actualPage.drawText("Übergabe", {
          x: doc.width - doc.margin.right - (100 * mm),
          y: currentY,
          font: doc.font.normal,
          size: 9,
        });
        if(article.typ === "MP") {
          actualPage.drawText("Mietbeginn", {
            x: doc.width - doc.margin.right - (80 * mm),
            y: currentY,
            font: doc.font.normal,
            size: 9,
          });
          actualPage.drawText("Mietende", {
            x: doc.width - doc.margin.right - (60 * mm),
            y: currentY,
            font: doc.font.normal,
            size: 9,
          });
          actualPage.drawText("Mietzeit", {
            x: doc.width - doc.margin.right - (30 * mm) - doc.font.normal.widthOfTextAtSize("Mietzeit", 9),
            y: currentY,
            font: doc.font.normal,
            size: 9,
          });
          actualPage.drawText("Mietmenge", {
            x: doc.width - doc.margin.right - doc.font.normal.widthOfTextAtSize("Mietmenge", 9),
            y: currentY,
            font: doc.font.normal,
            size: 9,
          });
        }
        actualPage.drawLine({
          start: {x: doc.margin.left, y: currentY - 4},
          end: {x: doc.width - doc.margin.right, y: currentY - 4},
          thickness: .5
        });
        currentY -= 14.2;

        article.lines.forEach((line, index) => {
          if(currentY - doc.margin.bottom < 13) {
            actualPage = addNewPage();
            currentY = doc.height - doc.margin.top;
          }
          if(index % 2 === 0) {
            actualPage.drawRectangle({
              x: doc.margin.left,
              y: currentY - 3,
              width: doc.width - doc.margin.left - doc.margin.right,
              height: 12.8,
              borderWidth: 0,
              color: { type: "RGB", red: .95, green: .95, blue: .95}
            })
          }
          const lineNumber = (index + 1).toString().padStart(3, "0");
          actualPage.drawText(lineNumber, {
            x: doc.margin.left + 2,
            y: currentY,
            font: doc.font.normal,
            size: 9,
          });
          actualPage.drawText(line.desc, {
            x: doc.margin.left + (10 * mm),
            y: currentY,
            font: doc.font.normal,
            size: 9,
          });
          actualPage.drawText(line.axis, {
            x: doc.margin.left + (60 * mm),
            y: currentY,
            font: doc.font.normal,
            size: 9,
          });
          actualPage.drawText((line.qty === "0,000" ? "" : line.qty), {
            x: doc.margin.left + (60 * mm) + (18 * mm) + (1 * 16 * mm) - doc.font.normal.widthOfTextAtSize((line.qty === "0,000" ? "" : line.qty), 9),
            y: currentY,
            font: doc.font.normal,
            size: 9,
          });
          actualPage.drawText((line.l === "0,000" ? "" : line.l), {
            x: doc.margin.left + (60 * mm) + (18 * mm) + (2 * 16 * mm) - doc.font.normal.widthOfTextAtSize((line.l === "0,000" ? "" : line.l), 9),
            y: currentY,
            font: doc.font.normal,
            size: 9,
          });
          actualPage.drawText((line.b === "0,000" ? "" : line.b), {
            x: doc.margin.left + (60 * mm) + (18 * mm) + (3 * 16 * mm) - doc.font.normal.widthOfTextAtSize((line.b === "0,000" ? "" : line.b), 9),
            y: currentY,
            font: doc.font.normal,
            size: 9,
          });
          actualPage.drawText((line.h === "0,000" ? "" : line.h), {
            x: doc.margin.left + (60 * mm) + (18 * mm) + (4 * 16 * mm) - doc.font.normal.widthOfTextAtSize((line.h === "0,000" ? "" : line.h), 9),
            y: currentY,
            font: doc.font.normal,
            size: 9,
          });
          actualPage.drawText((line.sum === "0,000" ? "" : line.sum), {
            x: doc.margin.left + (60 * mm) + (18 * mm) + (4 * 16 * mm) + (1 * 20 * mm) - doc.font.normal.widthOfTextAtSize((line.sum === "0,000" ? "" : line.sum), 9),
            y: currentY,
            font: doc.font.normal,
            size: 9,
          });
          actualPage.drawText(parseDate(line?.dateFinished, false), {
            x: doc.width - doc.margin.right - (100 * mm),
            y: currentY,
            font: doc.font.normal,
            size: 9,
          });
          if(article.typ === "MP") {
            actualPage.drawText(parseDate(line?.rentBeginn, false), {
              x: doc.width - doc.margin.right - (80 * mm),
              y: currentY,
              font: doc.font.normal,
              size: 9,
            });
            actualPage.drawText(parseDate(line?.rentEnd, false), {
              x: doc.width - doc.margin.right - (60 * mm),
              y: currentY,
              font: doc.font.normal,
              size: 9,
            });
            actualPage.drawText(line.rentUnits + " " + article.VorhalteEinheit, {
              x: doc.width - doc.margin.right - (30 * mm) - doc.font.normal.widthOfTextAtSize(line.rentUnits + " " + article.VorhalteEinheit, 9),
              y: currentY,
              font: doc.font.normal,
              size: 9,
            });
            actualPage.drawText(line.rentQty + " " + article.einheit + article.VorhalteEinheit, {
              x: doc.width - doc.margin.right - doc.font.normal.widthOfTextAtSize(line.rentQty + " " + article.einheit + article.VorhalteEinheit, 9),
              y: currentY,
              font: doc.font.normal,
              size: 9,
            });
          }
          actualPage.drawLine({
            start: {x: doc.margin.left, y: currentY - 3},
            end: {x: doc.width - doc.margin.right, y: currentY - 3},
            thickness: .2,
            color: { type: "RGB", red: .7, green: .7, blue: .7},
          });
          currentY -= 13;
        });
      }
    });

  });

  // Add Pagination
  const allPages = pdf.getPages();
  const totalPages = allPages.length;
  let counter = 1;
  for (const page of allPages) {
    const string = "Blatt " + counter + " von " + totalPages;
    counter++;
    page.drawText(string , {
      x: doc.width - doc.font.normal.widthOfTextAtSize(string, 9) - doc.margin.right,
      y: 4 * mm,
      size: 9,
      maxWidth: 50 * mm,
      lineHeight: 9,
      font: doc.font.normal,
    });
  }
  const pdfBytes = await pdf.save();
  return {pdf: pdfBytes, name: documentNameId};
}