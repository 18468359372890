export function convertDateFormat(dateString) {
  if(dateString===undefined || dateString === null) return null;
  else if(dateString === "") return "";
  var parts = dateString.split('-');
  var year = parts[0];
  var month = parts[1];
  var day = parts[2];

  if (day.charAt(0) === '0') {
    day = day.substr(1);
  }

  if (month.charAt(0) === '0') {
    month = month.substr(1);
  }

  return `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`;
}