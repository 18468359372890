import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { digits2 } from '../../defaultData';
import { useState } from 'react';

const ShowPayments = () => {

  const [detail, setDetail] = useState(null);
  const {invoicesOverview} = useSelector(state => state.erp);
  const {sales, rights} = useSelector(state => state.auth.tokens);

  if(Object.keys(invoicesOverview).length === 0) {
    return (<div>Keine Daten vorhanden</div>);
  }

  const parseDate = (seconds) => {
    const date = new Date(seconds);
    return date.getDate().toString().padStart(2,"0")
      + "." + (date.getMonth() + 1).toString().padStart(2,"0")
      + "." + date.getFullYear();
  }
  const getSum = (obj, netto = true) => {
    const arr = (typeof obj === "object" ? Object.values(obj) : obj);
    return arr.reduce((accumulator, current) => accumulator + (netto ? current.netto : current.brutto), 0);
  }
  const getDueAmount = (payments, brutto) => {
    return (brutto - getSum(payments, true)).toLocaleString("de-DE", digits2);
  }
  return (
    <div>
      <Table striped hover bordered>
        <thead>
          <tr>
            <th>Rechnung</th>
            <th style={{textAlign: "right"}}>Vom</th>
            <th style={{textAlign: "right"}}>Fälligkeit</th>
            <th style={{textAlign: "right"}}>Netto</th>
            <th style={{textAlign: "right"}}>Brutto</th>
            <th style={{textAlign: "right"}}>Bezahlt</th>
            <th style={{textAlign: "right"}}>Offen</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {
          Object.values(invoicesOverview).map((invoice, idx) => <tr key={idx}>
            <td>{ invoice.doc }</td>
            <td align="end">{ parseDate(invoice.date) }</td>
            <td align="end">{ parseDate(invoice.dueDate) }</td>
            <td align="end">{ invoice.netto.toLocaleString("de-DE", digits2) }</td>
            <td align="end">{ invoice.brutto.toLocaleString("de-DE", digits2) }</td>
            <td align="end">{ (invoice.hasOwnProperty("payment") ? getSum(invoice.payment, false).toLocaleString("de-DE", digits2) : "0,00")}</td>
            <td align="end" style={{fontWeight:600}}>{ (invoice.hasOwnProperty("payment") ? getDueAmount(invoice.payment, invoice.brutto) : invoice.brutto.toLocaleString("de-DE", digits2)) }</td>
            <td algin="end">
              { ((sales!==undefined && sales === true) || (rights.hasOwnProperty("pay") && rights["pay"] > 2)) && <span className="overLayLink">Zahlung erfassen</span> }
              { ((sales!==undefined && sales === true) || (rights.hasOwnProperty("pay") && rights["pay"] > 0)) && <span className="overLayLink">Details ansehen</span> }
            </td>
          </tr>)
        }
        </tbody>
      </Table>
    </div>
  )
}

export default ShowPayments