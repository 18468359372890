const removeEmptyValues = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      var value = obj[key];
      if (value === "") {
        delete obj[key];
      }
    }
  }
  return obj;
}

export default removeEmptyValues;