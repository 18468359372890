import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux"
import { loader } from "../../../redux/actions/database/loader";
import { serverTimestamp } from "firebase/database";
import { updateDB } from "../../../functions/database/update";
import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";

import "./absence.css";
import Add from "./Add";

const AbsencePlan = () => {
  const today = new Date();
  const {uid, tokens, displayName } = useSelector(state => state.auth);
  const {sales, rights} = tokens;
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const [startDate, setStartDate] = useState(firstDay.getFullYear() + "-" + (firstDay.getMonth() + 1).toString().padStart(2, "0") + "-" + firstDay.getDate().toString().padStart(2,"0"))
  const [filtered, setFiltered] = useState([]);
  const [endDate, setEndDate] = useState(lastDay.getFullYear() + "-" + (lastDay.getMonth() + 1).toString().padStart(2, "0") + "-" + lastDay.getDate().toString().padStart(2,"0"))
  const {team, teamLoaded, teamError} = useSelector(state => state.crm);
  const {staff, staffLoaded, staffError, absence, absenceLoaded, absenceError} = useSelector(state => state.dispo);
  const [modal, setModal] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [modalImageURL, setModalImageURL] = useState("");
  const [selected, setSelected] = useState({});
  const reasonRef = useRef(null);

  const dispatch = useDispatch();
  console.log(sales, rights);
  useEffect(() => {
    if(!staffLoaded) {
      dispatch(loader("staff"));
    }
    if(!teamLoaded) {
      dispatch(loader("team"));
    }
    if(!absenceLoaded) {
      dispatch(loader("absence"));
    }
    // eslint-disable-next-line
  }, [teamLoaded, staffLoaded, absenceLoaded]);

  const parseIntFromDate = (date) => {
    if(date === undefined || date === null || date === "") return null;
    return parseInt(date.replaceAll("-",""));
  }

  const parseDateFromTimestamp = (timestamp) => {
    if(timestamp === undefined || timestamp === null || timestamp === "") return null;
    const date = new Date(timestamp);
    const daynames = ["So","Mo","Di","Mi","Do","Fr","Sa"];
    return daynames[date.getDay()] + ". " + date.getDate().toString().padStart(2,"0") + "." + (date.getMonth() + 1).toString().padStart(2, "0") + "." + date.getFullYear() + " - " + date.getHours() + ":" + date.getMinutes().toString().padStart(2,"0") + " Uhr";
  }
  useEffect(() => {
    if (absence === null) {
      setFiltered({});
      return;
    }
    const filteredAbsence = () => {
      const obj = {};
      const start = parseIntFromDate(startDate);
      const end = parseIntFromDate(endDate);
      for ( const key in absence ) {
        const itemStart = parseIntFromDate(absence[key]?.start);
        const itemEnd = parseIntFromDate(absence[key]?.ende);
        if(itemStart === null || itemEnd === null || (itemStart > end) || (itemEnd < start) ) { continue; } 

        const uid = absence[key].uid;
        if(!obj.hasOwnProperty(uid)) { obj[uid] = {}; }
        for (var i = parseIntFromDate(absence[key].start); i <= parseIntFromDate(absence[key].ende); ) {
          const regexPattern = /(\d{4})(\d{2})(\d{2})/;
          const matchArray = i.toString().match(regexPattern);
          const [, year, month, day] = matchArray;
          const dateString = year + '-' + month + '-' + day;
          const d = new Date(year, month - 1, day);
          d.setDate(d.getDate() + 1);
          if(!obj[uid].hasOwnProperty(dateString)) {
            obj[uid][dateString] = {};
          }
          Object.assign(obj[uid][dateString], {[key]: absence[key]});
          i = parseIntFromDate(d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2,"0") + "-" + d.getDate().toString().padStart(2,"0"));
        }
      }
      return obj;
    }

    setFiltered(filteredAbsence());

  }, [startDate, endDate, absence]);

  if (teamError !== null || staffError !== null || absenceError !== null) {
    return <Error />;
  }

  if (!staffLoaded || !teamLoaded || !absenceLoaded) {
    return <Loading />;
  }
  
  const rejectAbsence = () => {
    updateDB("absence", {rejectedBy: uid, rejectedAt: serverTimestamp(), rejectedReason: reasonRef.current.value}, selected.id);
    setModal(false);
    setSelected({});
  }
  const acceptAbsence = () => {
    updateDB("absence", {acceptedBy: uid, acceptedAt: serverTimestamp(), acceptedReason: reasonRef.current.value, rejectedBy: null, rejectedAt: null, rejectedReason: null}, selected.id);
    setModal(false);
    setSelected({});
  }

  const getPersonname = (personid) => {
    if (teamLoaded && team !== null && team.hasOwnProperty(personid)) return team[personid].name + ", " + team[personid].vorname;
    else if (staffLoaded && staff !== null && staff.hasOwnProperty(personid)) return staff[personid].nachname + ", " + staff[personid].vorname;
    else return personid;
  };

  const isWeekend = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  const getDaysInRange = () => {
    const days = [];
    const currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      const dateString = currentDate.toISOString().slice(0, 10);
      days.push(dateString);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return days;
  };

  const daysInRange = getDaysInRange();

  const sortByName = obj => {
    if(obj === null || Object.keys(obj).length === 0) return [];
    const arr = Object.entries(obj).sort((a, b) => {
      const nameA = a[1].name.toLowerCase();
      const nameB = b[1].name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
    return arr;
  }

  const renderTableHeader = (date) => {
    const d = date.split('-');
    return d[2] + "." + d[1] + ".";
  }
  const readableDate = (date) => {
    if(date === undefined || date === null) return;
    const d = date.split('-');
    return d[2] + "." + d[1] + "." + d[0];
  }

  const weekday = (date) => {
    if(date === undefined || date === null) return;
    const d = date.split('-');
    const day = new Date(d[0], d[1] - 1, d[2]);
    const daynames = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
    return daynames[day.getDay()];
  }

  const styling = (entry, day) => {
    if (isWeekend(day)) return {backgroundColor: "rgba(0, 0, 0, 0.05)"};
    else if (day >= entry.start && day <= entry.ende) {
      if ( entry?.krank ) return {backgroundColor: "var(--danger)"};
      else if ( entry.hasOwnProperty("acceptedBy") ) return {backgroundColor: "var(--primary)"};
      else if ( entry.hasOwnProperty("rejectedBy") ) return {background: "var(--bs-gray-400)"};
      else return {backgroundColor: "var(--warning)"};
    }
    else return {};
  }
  const affected = (entry, day) => {
    if (isWeekend(day)) return false;
    return (day >= entry.start && day <= entry.ende);
  }

  const openModal = (id, entry, day) => {
    if(affected(entry, day)) {
      setSelected({id: id, ...entry});
      setModal(true);
    }
    else return;
  }

  const openModalWithImage = (imageUrl) => {
    setModalImageURL(imageUrl);
  };

  const closeModal = () => {
    setModalImageURL("");
  };
  

  return (
    <>
      <h4 className="pageTitle my-3">Abwesenheitsplan</h4>
      <Card>
        <Card.Header>
          <Row>
            <Col xs={12} md={5} lg={4}>
              <InputGroup>
                <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                <Form.Control type="date" min={startDate} value={endDate} onChange={(e) => setEndDate(e.target.value)} />
              </InputGroup>
            </Col>
            <Col xs={true} align="end">
              <Button variant="outline-primary" onClick={() => setAddNew(true)}>Neu</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body style={{position: 'relative', display: "flex", overflow: 'hidden', padding:0}}>
          <div className="table-container pt-b">
          <Table bordered striped>
            <thead>
              <tr style={{height: "40px"}}  valign="middle">
                <th className="fixedPositionLeft" style={{minWidth:"220px"}} >Mitarbeiter</th>
                {daysInRange.map((day) => <th key={day} className="fixedPositionTop" style={{fontWeight:"400", minWidth:"60px", backgroundColor: isWeekend(day) ? "rgba(0, 0, 0, 0.05)" : ""}}>
                    {renderTableHeader(day)}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {
                sortByName(staff).map(([idx, person]) => <tr key={idx} style={{height:"35px"}}>
                  <td className="fixedPositionLeft" valign="middle">{ person.nachname }, { person.vorname }</td>
                  {
                    daysInRange.map((day) => <td key={day} className="plannerTD" style={isWeekend(day) ? {backgroundColor: "rgba(0, 0, 0, 0.05)"} : {}}>
                      <div className="plannerDay">
                      {
                        (filtered.hasOwnProperty(idx) && filtered[idx].hasOwnProperty(day) && !isWeekend(day)) &&
                        Object.entries(filtered[idx][day]).map(([vid, vacation], ) => <div key={day + "#" +vid}
                        className="plannerTab" style={styling(vacation, day)} onClick={() => openModal(vid, vacation, day)}/>)
                      }
                      </div>
                    </td>)
                  } 
                </tr>)
              }
              {
                sales && <tr style={{height:"35px"}}><td className="fixedPositionLeft" valign="middle">{ displayName }</td>
                {
                  daysInRange.map((day) => <td key={day} className="plannerTD" style={isWeekend(day) ? {backgroundColor: "rgba(0, 0, 0, 0.05)"} : {}}>
                    <div className="plannerDay">
                    {
                      (filtered.hasOwnProperty(uid) && filtered[uid].hasOwnProperty(day) && !isWeekend(day)) &&
                      Object.entries(filtered[uid][day]).map(([vid, vacation], ) => <div key={day + "#" +vid}
                      className="plannerTab" style={styling(vacation, day)} onClick={() => openModal(vid, vacation, day)}/>)
                    }
                    </div></td>)
                }
                  </tr>
              }
              <tr style={{height: "5px", padding:0, backgroundColor:"var(--primary)"}} >
                <td style={{padding:0}} colSpan={daysInRange.length + 1} />
              </tr>
              {
                sortByName(team).map(([idx, person]) => <tr key={idx} style={{height:"35px"}}>
                  <td className="fixedPositionLeft" valign="middle">{ person.name }, { person.vorname }</td>
                  {
                    daysInRange.map((day) => <td key={day} className="plannerTD" style={isWeekend(day) ? {backgroundColor: "rgba(0, 0, 0, 0.05)"} : {}}>
                      <div className="plannerDay">
                      {
                        (filtered.hasOwnProperty(idx) && filtered[idx].hasOwnProperty(day) && !isWeekend(day)) &&
                        Object.entries(filtered[idx][day]).map(([vid, vacation], ) => <div key={day + "#" +vid}
                        className="plannerTab" style={styling(vacation, day)} onClick={() => openModal(vid, vacation, day)}/>)
                      }
                      </div>
                    </td>)
                  }
                </tr>)
              }
            {filtered.length > 0 &&
                filtered.map(([id, entry]) => (
                  <tr key={id} style={{height: "35px"}}>
                    <td className="fixedPositionLeft" valign="middle">{getPersonname(entry.uid)}</td>
                    {daysInRange.map((day) => (
                      <td key={day} style={styling(entry, day)}
                      onClick={() => openModal(id, entry, day)}
                      >
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={daysInRange.length + 1}/>
              </tr>
            </tfoot>
          </Table>
          </div>
        </Card.Body>
        <Card.Footer>
            <h6>Legende:</h6>
            <div className="d-flex gap-3 align-items-center"><div style={{backgroundColor: "var(--primary)", height:"30px", border:"0.5px solid #000", minWidth: "60px"}} /><span>Genehmigter Urlaub</span></div>
            <div className="d-flex gap-3 align-items-center"><div style={{backgroundColor: "var(--warning)", height:"30px", border:"0.5px solid #000", minWidth: "60px"}} /><span>Offener Urlaubsantrag</span></div>
            <div className="d-flex gap-3 align-items-center"><div style={{backgroundColor: "var(--bs-gray-400)", height:"30px", border:"0.5px solid #000", minWidth: "60px"}} /><span>Abgelehnter Urlaub</span></div>
            <div className="d-flex gap-3 align-items-center"><div style={{backgroundColor: "var(--danger)", height:"30px", border:"0.5px solid #000", minWidth: "60px"}} /><span>Krankheit</span></div>
        </Card.Footer>
      </Card>
      {
        addNew && <Add show={addNew} onHide={() => setAddNew(false)} />
      }
      {
        <Modal show={modal} onHide={() => setModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table className="mb-0">
            <tbody>
              <tr>
                <td align="right" className="fw-semibold">Mitarbeiter</td>
                <td>{ selected?.uid === uid ? displayName : getPersonname(selected?.uid)}</td>
              </tr>
              <tr>
                <td align="right" className="fw-semibold">Zeitraum</td>
                <td>
                  <Row>
                    <Col align="right" style={{paddingRight:"3px"}}>
                      { weekday(selected?.start) } -<br/>
                      { readableDate(selected?.start) } -
                    </Col>
                    <Col style={{padding:0}}>
                      { weekday(selected?.ende) }<br/>
                      { readableDate(selected?.ende) }
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr>
                <td align="right" className="fw-semibold">Eingereicht</td>
                <td>{ selected?.grund && <>{selected.grund}<br/></> }<span style={{fontSize:"12px"}}>{ parseDateFromTimestamp( selected?.addedAt ) } - { (selected?.addedBy === uid ? displayName : getPersonname(selected?.addedBy)) }</span></td>
              </tr>
              <tr>
                <td align="right" className="fw-semibold">Status</td>
                <td>
                  {
                    selected.hasOwnProperty("acceptedBy") && <span className="text-success fw-semibold">Genehmigt</span>
                  }
                  {
                    selected.hasOwnProperty("rejectedBy") && <span className="text-danger fw-semibold">Abgelehnt</span>
                  }
                  {
                    selected?.krank && <span className="text-danger fw-semibold">Krankheit</span>
                  }
                  {
                    (!selected.hasOwnProperty("acceptedBy") && !selected.hasOwnProperty("rejectedBy") && !selected?.krank) && <span style={{color:"grey"}} className="fw-semibold">Offen</span>
                  }
                </td>
              </tr>
              {
                selected.hasOwnProperty("acceptedBy") && <tr>
                  <td align="right" className="fw-semibold">Genehmigt</td>
                  <td>{ selected?.acceptedReason && <>{selected.acceptedReason}<br/></> }<span style={{fontSize:"12px"}}>{ parseDateFromTimestamp(selected?.acceptedAt) } - { getPersonname(selected.acceptedBy) }</span></td>
                </tr>
                
              }
              {
                selected.hasOwnProperty("rejectedBy") && <tr>
                  <td align="right" className="fw-semibold">Abgelehnt</td>
                  <td>{ selected?.rejectedReason && <>{selected.rejectedReason}<br/></> }<span style={{fontSize:"12px"}}>{ parseDateFromTimestamp(selected?.rejectedAt) }, { getPersonname(selected.rejectedBy) }</span></td>
                </tr> 
              }
              {
                selected?.krank && <tr>
                  <td align="right" className="fw-semibold">Krankschreibung</td>
                  <td>{ selected?.file ? <Button onClick={() => openModalWithImage(selected?.file)}>Ansehen</Button> : "Keine Krankschreibung" }</td>
                </tr>
              }
            </tbody>
          </Table>
          {
            (!selected.hasOwnProperty("acceptedBy") && !selected.hasOwnProperty("rejectedBy") && !selected?.krank) &&
          <Form.Group>
            <Form.Text>Anmerkung hinzufügen</Form.Text>
            <Form.Control as="textarea" rows="3" ref={reasonRef} />
          </Form.Group> }
        </Modal.Body>
        <Modal.Footer>
          {
            (!selected.hasOwnProperty("acceptedBy") && !selected.hasOwnProperty("rejectedBy") && !selected?.krank) &&
          
            <Col className="d-flex gap-3">
              <Button variant="accent1" className="text-light" onClick={acceptAbsence}>
                Genehmigen
              </Button>
              <Button variant="danger" onClick={rejectAbsence}>
                Ablehnen
              </Button>
            </Col> }
            <Col align="right">
              <Button variant="primary" onClick={() => setModal(false)}>
                Schließen
              </Button>
            </Col>
          
        </Modal.Footer>
      </Modal>
      }
      {
        !!modalImageURL &&
      <Modal show={!!modalImageURL} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Krankmeldung</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={modalImageURL} alt="Krankmeldung" style={{ width: "100%" }} />
      </Modal.Body>
    </Modal>
    }
    </>
  );
};

export default AbsencePlan;
