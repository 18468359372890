import { Card } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Briefpapier from './Briefpapier';
import Stammdaten from './Stammdaten';
import Nutzung from './Nutzung';
import Lizenzen from './Lizenzen';
import Rechte from './Rechte';
import Texte from './Texte';

const Settings = () => {

  return (
    <Card>
      <Card.Header>
        Systemeinstellungen
      </Card.Header>
      <Card.Body>
        <Tabs defaultActiveKey="stammdaten" className="mb-3" justify flush="true">
          <Tab eventKey="stammdaten"  title="Stammdaten">
            <Stammdaten />
          </Tab>
          <Tab eventKey="briefpapier" title="Briefpapier">
            <Briefpapier />
          </Tab>
          <Tab eventKey="nutzung" title="Nutzung">
            <Nutzung />
          </Tab>
          <Tab eventKey="lizenzen" title="Lizenzen">
            <Lizenzen />
          </Tab>
          <Tab eventKey="rechte" title="Rechte">
            <Rechte />
          </Tab>
          <Tab eventKey="text" title="Texte">
            <Texte />
          </Tab>
          <Tab eventKey="einheiten" title="Einheiten">
            <p>EInheiten</p>
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>
    
  )
}

export default Settings