import { BsCalendarCheckFill, BsFillXCircleFill, BsTools, BsQuestionCircleFill, BsExclamationTriangleFill, BsFillCheckCircleFill} from "react-icons/bs";
import Badge from "react-bootstrap/Badge";
import { objToYMDString } from "../../../functions/datetime/objToYMDString";
import { strToInteger } from "../../../functions/datetime/strtoIntger";

const TaskBadge = ({task}) => {
  const today = strToInteger(objToYMDString(new Date()));
  
  if (task.hasOwnProperty("erledigt")) {
    return (<Badge style={{fontWeight:500, fontSize:".8rem", marginRight:"1rem"}} pill bg="accent1">Erledigt <BsFillCheckCircleFill /></Badge>);
  } else if (!task.hasOwnProperty("datumInt")) {
    return (<Badge style={{fontWeight:500, fontSize:".8rem", marginRight:"1rem"}} pill bg="danger">Ohne Termin <BsExclamationTriangleFill /></Badge>);
  } 
  if (task.datumInt <= today && task.hasOwnProperty("staff") && task.staff.length > 0) {
    return (<Badge style={{fontWeight:500, fontSize:".8rem", marginRight:"1rem"}} pill bg="primary">In Bearbeitung <BsTools /></Badge>);
  } else if (task.datumInt > today && task.hasOwnProperty("staff") && task.staff.length > 0) {
    return (<Badge style={{fontWeight:500, fontSize:".8rem", marginRight:"1rem"}} pill bg="primary">In Planung <BsCalendarCheckFill /></Badge>)
  } else if (task.datumInt <= today && (!task.hasOwnProperty("staff") || task.staff.length === 0)) {
    return (<Badge style={{fontWeight:500, fontSize:".8rem", marginRight:"1rem"}} pill bg="danger">Überfällig <BsExclamationTriangleFill /></Badge>)
  } else if (task.datumInt > today && (!task.hasOwnProperty("staff") || task.staff.length === 0)) {
    return (<Badge style={{fontWeight:500, fontSize:".8rem", marginRight:"1rem"}} pill bg="warning">Nicht besetzt <BsQuestionCircleFill /></Badge>);
  } else return (<Badge style={{fontWeight:500, fontSize:".8rem", marginRight:"1rem"}} pill bg="danger">Ungültig <BsFillXCircleFill /></Badge>);
}

export default TaskBadge