import initialState from './initialState';
import types from '../../types';

function deepMerge(target, source) {
  const merged = { ...target };
  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if(key === "footer") {
        merged[key] = source[key];
      }
      else if (merged.hasOwnProperty(key) && typeof merged[key] === 'object' && typeof source[key] === 'object') {
        merged[key] = deepMerge(merged[key], source[key]);
      } else {
        merged[key] = source[key];
      }
    }
  }
  return merged;
}


export default function crm(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_CONTACTS_SUCCESS:
      return {
        ...state,
        contactsLoaded: true,
        contactsError: null,
        contacts: {...state.contacts, ...action.payload},
      };
    case types.LOAD_CONTACTS_FAILURE:
      return {
        ...state,
        contactsLoaded: true,
        contactsError: action.error,
      };
    case types.LOAD_PROJECTS_SUCCESS:
      return {
        ...state,
        projectsLoaded: true,
        projectsError: null,
        projects: {...state.projects, ...action.payload},
      };
    case types.LOAD_PROJECTS_FAILURE:
      return {
        ...state,
        projectsLoaded: true,
        projectsError: action.error,
      };
    case types.LOAD_TEAM_SUCCESS:
      return {
        ...state,
        teamLoaded: true,
        teamError: null,
        team: {...state.team, ...action.payload},
      };
    case types.LOAD_TEAM_FAILURE:
      return {
        ...state,
        teamLoaded: true,
        teamError: action.error,
      };
    case types.LOAD_SETTINGS_SUCCESS:
      return {
        ...state,
        settingsLoaded: true,
        settingsError: null,
        settings: deepMerge(state.settings, action.payload),
      };
    case types.LOAD_SETTINGS_FAILURE:
      return {
        ...state,
        settingsLoaded: true,
        settingsError: action.error,
      };
    case types.AUTH_SIGNOUT:
      return {
        ...initialState
      };
    default: return state;
  }
}