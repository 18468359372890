import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Unauthorized from "../../APP/Messages/Unauthorized";
import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";
import { saveDB } from "../../../functions/database/save";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loader } from "../../../redux/actions/database/loader";
import { defaultData } from "./defaultData";

const AddNew = () => {

  const {id} = useParams();
  const {tokens, displayName} = useSelector(state => state.auth);
  const {sales, rights} = tokens;
  const {measurements, measurementsLoaded, measurementsError} = useSelector(state => state.erp);
  const {projects, projectsLoaded, projectsError} = useSelector(state => state.crm);
  const [saved, setSaved] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if(!measurementsLoaded) {
      dispatch(loader("measurements"));
    }
    if(!projectsLoaded) {
      dispatch(loader("projects"));
    }
  }, [dispatch, measurementsLoaded, projectsLoaded]);

  useEffect(() => {
    if(id !== "" && measurements !== null && projects !== null && !saved) {
      const projectM = Object.values(measurements).filter((a) => a?.projectId === id);
      const measurementsObj = (projectM.slice(-1))[0];
      let cleanMeasurements = {};
      let sumNetto = 0;
      for ( const offerId in measurementsObj.offers ) {
        cleanMeasurements[offerId] = {};
        for ( const article in measurementsObj.offers[offerId]) {
          let qty = 0;
          let rentQty = 0;
          for ( const line in measurementsObj.offers[offerId][article].lines) {
            const obj = measurementsObj.offers[offerId][article].lines[line];
            qty = (obj.hasOwnProperty("totalQty") ? qty + obj.totalQty : qty);
            rentQty = (obj.hasOwnProperty("totalRentQty") ? rentQty + obj.totalRentQty : rentQty);
          }
          cleanMeasurements[offerId][article] = {
            ...measurementsObj.offers[offerId][article],
            qty: qty,
            rentQty: rentQty,
          };
          sumNetto = sumNetto + (measurementsObj.offers[offerId][article].TK.NumericEP * qty);
        }
      }
      //measurements[Object.keys(measurements)[Object.keys(measurements).length - 1]]?.offers;
      
      /*
      let measurementsObj = {};
      for (const i in measurements) {
        console.log(i);
        const item = measurements[i];
        if(item.hasOwnProperty("projectId") && item.projectId === id) {
          measurementsObj = {...item.offers};
        }
      }
      */
      const projectData = (projects.hasOwnProperty(id) ? {...projects[id]} : {});
      if(Object.keys(cleanMeasurements).length > 0 && Object.keys(projectData).length > 0) {
        saveDB("invoices", {
          ...defaultData,
          ...projectData,
          status: "In Bearbeitung",
          doc: null,
          docDate: null,
          headerText: null,
          langtext: false,
          creator: displayName,
          measurements: {...cleanMeasurements},
          SummeNetto: (Math.round(sumNetto * 1000) / 1000),
          SummeUSt: (Math.round((sumNetto * 0.19) * 1000) / 1000),
          SummeBrutto: (Math.round(sumNetto * 1000) / 1000) + (Math.round((sumNetto * 0.19) * 1000) / 1000),
          projectId: id,
        })
        .then((results) => {
          setSaved(true);
          console.log("Done");
          navigate("/invoices/edit/"+results.key);
        })
        .catch((err) => {
          console.error(err);
        });
      }
    }
    // eslint-disable-next-line
  }, [id, navigate, measurements, projects, saved]);

  if(sales !== true && !rights.hasOwnProperty("eom")) return <Unauthorized license={'erp'} />
  else if(sales !== true && rights["bill"] < 3) return <Unauthorized />;  
 
  if(!measurementsLoaded || !projectsLoaded) {
    return <Loading />;
  }
  if(measurementsError !== null || projectsError !== null) {
    return <Error />;
  }
  

  return <Loading />;
}

export default AddNew