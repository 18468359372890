import React from 'react'
import { Col, Form, FormGroup, Row } from 'react-bootstrap'

const Stammdaten = () => {

  

  return (
    <Form>
      <Row>
        <Col xs={12} md={4}>
          <h6>Firmendaten</h6>
          <FormGroup>
            <Form.Text>Firmenname</Form.Text>
            <Form.Control />
          </FormGroup>
          <FormGroup>
            <Form.Text>Anschrift</Form.Text>
            <Form.Control />
          </FormGroup>
          <FormGroup>
            <Form.Text>Postleitzahl</Form.Text>
            <Form.Control />
          </FormGroup>
          <FormGroup>
            <Form.Text>Ort</Form.Text>
            <Form.Control />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default Stammdaten