export const defaultData = {
  uid: "",
  approvedBy: "",
  approvedAt: "",
  rejectedBy: "",
  rejectedAt: "",
  start: "",
  ende: "",
  grund: "",
  dauer: "",
  krank: false,
  erstellt: "",
  file: "",
  anlass: ""
}