import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getIdTokenResult, getAuth } from "firebase/auth";

const resizeImage = (file, maxWidth) => {
  return new Promise((resolve) => {
    const img = new Image();
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const scaleFactor = maxWidth / img.width;
        const width = maxWidth;
        const height = img.height * scaleFactor;
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob((blob) => {
          resolve(blob);
        }, file.type);
      };
    };
  });
};

export const uploadFileToCloud = async (path, file, resize = null) => {
  const user = getAuth().currentUser;
  const claims = await getIdTokenResult(user);

  const {client, sales} = claims.claims;

  const databaseId = (sales === undefined) ? "customer/" + client : "demo/" + user.uid;
  if(sales === undefined && (client === null || client === undefined)) return {error: true};

  try {
    if (resize && typeof resize === "number") {
      // Verkleinere das Bild, bevor es hochgeladen wird
      file = await resizeImage(file, resize);
    }

    const storage = getStorage();
    const storageRef = ref(storage, databaseId + "/" + path);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error("Fehler beim Hochladen der Datei:", error);
    throw error;
  }
};
