import { getAuth, getIdTokenResult } from "firebase/auth";
import { getDatabase, push, ref, serverTimestamp, update} from "firebase/database";
import detectChanges from "../../../functions/objects/detectChanges";
import removeEmptyValues from "../../../functions/objects/removeEmptyValues";

const loadSuccess = (typ, data) => ({
  type: 'LOAD_' + typ.toUpperCase() + '_SUCCESS',
  payload: data,
});

const loadFailure = (typ, error) => ({
  type: 'LOAD_' + typ.toUpperCase() + '_FAILURE',
  error,
});

export const updater = (target, object, id = null, before = {}) => async dispatch => {
  
  const user = getAuth().currentUser;
  const claims = await getIdTokenResult(user);
  const {client, sales} = claims.claims;
  
  const databaseId = (sales === undefined) ? client : user.uid;
  if(sales === undefined && (client === null || client === undefined)) return {error: true};

  const cleanObject = removeEmptyValues(object);
  const cleanBefore = removeEmptyValues(before);
  if(Object.keys(before).length !== 0) {
    const changes = detectChanges(cleanBefore, cleanObject, ["datumInt","dokumente", "projectId", "teilleistungen", "contactId", "architectId", "id", "addedAt", "addedBy"]);
    changes.at = serverTimestamp();
    changes.by = user.uid;
    changes.target = id;
    push(ref(getDatabase(), databaseId + "/history/"), changes);
  }
  const thisRef = ref(getDatabase(), databaseId + "/" + target + "/" + id);
  update(thisRef, {...cleanObject})
  .then(() => {
    const data = {[thisRef.key]: {...before, ...cleanObject}}
    dispatch(loadSuccess(target, data));
  })
  .catch((error) => {
    dispatch(loadFailure(target, error));
  });
};