export const defaultData = {
  projectId: "",
  contactId: "",
  architectId: "",

  zeitvorgabe: 0,

  status: "offen",
  beschreibung: "",
  aufgabe: "",

  dokumente: {fotos: [], listen: [], pläne: [], sicherheit: []},

  ansprechpartnerIntern: "",
  ansprechpartnerExter: "",

  auftraggeber: "",
  architekt: "",
  projektName: "",
  projektBeschreibung: "",
  anschrift: "",
  PLZ: "",
  ort: "",

  datum: "",
  uhrzeit: "",
  
  ma: "0",
  stunden: "0",
  tage: "0",

  todoAufmass: false,
  todoBericht: false,
  todoAbnahme: false,
};
export const defaultStates = ["Kritisch", "Überfällig", "Ohne Termin", "In Bearbeitung", "In Planung", "Nicht besetzt", "Erledigt"];
