import React from 'react'
import { Table } from 'react-bootstrap'

const Nutzung = () => {

  return (
    <Table>
      <thead>
        <tr>
          <th>Bezeichnungen</th>
          <th>Verfügbar</th>
          <th>Nutzung</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Dokumentenspeicher</td>
          <td>5.0 GB</td>
          <td>0.4 GB</td>
        </tr>
        <tr>
          <td>Kontaktspeicher</td>
          <td>3.500</td>
          <td>12</td>
        </tr>
        <tr>
          <td>Kontaktspeicher</td>
          <td>3.500</td>
          <td>10</td>
        </tr>
      </tbody>
    </Table>
  )
}

export default Nutzung