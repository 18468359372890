import { useEffect, useState } from "react"
import { defaultData } from "./defaultData"
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Alert from "react-bootstrap/Alert";
import { loaderOnce } from "../../../functions/database/loaderOnce";
import { useSelector } from "react-redux";
import { saveDB } from "../../../functions/database/save";
import { Table } from "react-bootstrap";
import removeEmptyValues from "../../../functions/objects/removeEmptyValues";
import { removeItem } from "../../../functions/database/removeItem";
import { TiTimes } from "react-icons/ti";

const VacationUser = () => {

  const { uid } = useSelector(state => state.auth);
  const [data, setData] = useState(defaultData);
  const [absence, setAbsence] = useState({});
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isDirty, setDirty] = useState(false);

  const handleChange = e => {
    const {name, value} = e.target;
    setData(prev => ({...prev, [name]: value}));
    setDirty(true);
  }
  const handleReset = () => {
    setData(defaultData);
    setDirty(false);
    setValidated(false);
  }
  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      return;  
    }
    setValidated(false);
    saveDB("absence", {...data, uid: uid})
    .then((result) => {
      setAbsence(prev => ({...prev, [result.key]: result.obj}));
      setData(defaultData);
      setDirty(false);
    })
    .catch((error) => {
      console.error(error);
    })
  }

  const revocation = key => {
    removeItem("absence", key);
    setAbsence(prev => (removeEmptyValues({...prev, [key]: null})));
  }

  useEffect(() => {
    if(loading) {
      loaderOnce("absence", "uid", uid)
      .then((result) => {
        if(result.result !== null) {
          setAbsence(result.result);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      })
    }
  }, [loading, uid]);

  const getStatus = obj => {
    if(obj.hasOwnProperty("acceptedBy")) return <span className="text-success fw-semibold">Genehmigt</span>;
    else if(obj.hasOwnProperty("rejectedBy")) return <span className="text-danger fw-semibold">Abgelehnt</span>;
    else return "Ausstehend";
  }
  const renderDate = (date) => {
    
    if(date === null || date === undefined || date === "") return "";

    const temp = new Date(date);
    return temp.getDate().toString().padStart(2, '0') + "." + (temp.getMonth() + 1).toString().padStart(2, '0') + "." + (temp.getFullYear());
  }

  const orderObject = (obj) => {
    if(obj === null || Object.keys(obj).length === 0) return {};
    let arr = Object.entries(obj);
    arr.sort((a, b) => b[1].start.localeCompare(a[1].start));
    arr = arr.filter(([,a]) => (!a?.krank || !a.krank));
    return Object.fromEntries(arr);
  }

  const ordered = orderObject(absence);

  return (
    <>
    <h4 className="pageTitle my-3">Neuer Urlaubsantrag</h4>
    <Card>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Card.Body>
        <Row>
          <Col xs={6} md={3} lg={2} className="mb-3">
            <Form.Group>
              <Form.Text>Erster Urlaubstag</Form.Text>
              <Form.Control name="start" value={data.start} onChange={handleChange} type="date" />
            </Form.Group>
          </Col>
          <Col xs={6} md={3} lg={2} className="mb-3">
            <Form.Group>
              <Form.Text>Bis einschließlich</Form.Text>
              <Form.Control name="ende" min={data.start} value={data.ende} onChange={handleChange} type="date" />
            </Form.Group>
          </Col>
          <Col xs={12} md={true} className="mb-3">
            <Form.Group>
              <Form.Text>Begründung (freiwillig)</Form.Text>
              <Form.Control name="grund" value={data.grund} onChange={handleChange} as="textarea" rows="1" />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col align="end">
            <ButtonGroup>
              <Button variant="outline-primary" onClick={handleReset}>Verwerfen</Button>
              <Button type="submit" disabled={!isDirty}>Einreichen</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Card.Body>
      </Form>
    </Card>
    <h4 className="pageTitle my-3">Meine bisherige Urlaubsanträge</h4>
    <Card>
      <Card.Body>
        {
          loading && <Alert variant="primary">Daten werden geladen...</Alert>
        }
        {
          Object.keys(absence).length > 0 ? <Table bordered striped>
            <thead>
              <tr>
                <th>Status</th>
                <th>Von</th>
                <th>Bis</th>
                <th>Anmerkung</th>
                <th><span className="d-none d-md-inline">Zurückziehen</span></th>
              </tr>
            </thead>
            <tbody>
            {
              Object.entries(ordered).map(([key, data], ) => <tr key={key}>
                <td valign="middle">{ getStatus(data) }</td>
                <td valign="middle">{ renderDate(data?.start) }</td>
                <td valign="middle">{ renderDate(data?.ende) }</td>
                <td valign="middle">
                  { data?.grund && <>{data?.grund}<br/></> }
                  { data?.rejectedReason && <span style={{fontSize:"12px"}}>Antwort: "{data?.rejectedReason}"</span> }
                  { data?.acceptedReason && <span style={{fontSize:"12px"}}>Antwort: "{data?.acceptedReason}"</span> }
                </td>
                <td valign="middle">{ (!data.hasOwnProperty("acceptedBy")) ? <Button size="sm" variant="outline-danger" onClick={() => revocation(key)}><span className="d-none d-md-inline">Zurückziehen</span><TiTimes className="d-md-none" /></Button> : <Button size="sm" variant="outline-dark" disabled><span className="d-none d-md-inline">Nicht möglich</span><TiTimes className="d-md-none" /></Button> }</td>
              </tr>)
            }
            </tbody>
          </Table> : <Alert variant="primary">Keine Daten vorhanden.</Alert>
        }
      </Card.Body>
    </Card>
    </>
  )
}

export default VacationUser