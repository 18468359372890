import { redirect, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { ImEye, ImEyeBlocked, ImCheckmark } from "react-icons/im";
import { useEffect, useRef, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";

const FirstLogin = () => {

  const [validated, setValidated] = useState(false);
  const [pwToggle, setPwToggle] = useState(false);
  const {emailAddr} = useParams();
  const [email, setEmail] = useState(emailAddr);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const emailRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    setValidated(true);
    const sendVerify = httpsCallable(getFunctions(), "Sales_SendCode");
    setLoading(true);
    sendVerify({email: email.toLowerCase()})
    .then(() => {
      const now = Math.floor(Date.now() / 1000);
      setValidated(false);
      localStorage.setItem("@WerkAPP/Verify", now);
      setLoading(false);
      setStep(1);
    })
    .catch((error) => { setLoading(false); console.log(error)}); 
  };
  const handlePassword = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    if(password !== repeatPassword) {
      console.log("Password validation failed");
      return;
    }
    setValidated(true);

    const changePassword = httpsCallable(getFunctions(), "ChangePasswordWithCode");
    changePassword({email: email.toLowerCase(), password: password, code: code})
    .then(() => {
      localStorage.removeItem("@WerkAPP/Verify");
      redirect("/");
    })
    .catch((err) => { console.log(err);});
  }

  const sendAgain = () => {
    localStorage.removeItem("@WerkAPP/Verify");
    setValidated(false);
    setStep(0);
  }

  useEffect(() => {
    const emailSent = localStorage.getItem("@WerkAPP/Verify");
    if(emailSent) {
      setStep(1);
    }
    emailRef.current.focus();
  }, []);

  if (step === 0) {
    return (
      <Container>
        <div className="auth">
          <Form onSubmit={handleSubmit} noValidate validated={validated}>
            <h1 className="fs-2">Erste Anmeldung</h1>
            <FormGroup className="mt-3">
              <Form.Text>E-Mail</Form.Text>
              <Form.Control
                type="email"
                id="email"
                value={email} 
                onChange={e => setEmail(e.target.value)} 
                required 
                ref={emailRef} />
            </FormGroup>
            <div className="d-grid gap-3 mt-3">
              <Button type="submit" disabled={loading}>
                {loading ? "In Bearbeitung" : "Code zusenden"}
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    )
  }
  else if (step === 1) {
    return (
      <Container>
        <div className="auth">
          <Form onSubmit={handlePassword} noValidate validated={validated}>
            <h1 className="fs-2">Erste Anmeldung</h1>
            <Alert variant="success">
              <ImCheckmark /> E-Mail gesendet.
            </Alert>
            <FormGroup className="mt-3">
              <Form.Text>E-Mail</Form.Text>
              <Form.Control
                type="email"
                id="email"
                value={email} 
                onChange={e => setEmail(e.target.value)} 
                required 
                ref={emailRef} />
            </FormGroup>
            <FormGroup className="mt-3">
              <Form.Text>Code</Form.Text>
              <Form.Control
                value={code} 
                style={{letterSpacing:"10px",fontWeight:600,textAlign:"center"}}
                onChange={e => setCode(e.target.value)} 
                required />
            </FormGroup>
            <FormGroup className="mt-3">
              <Form.Text>Passwort</Form.Text>
              <InputGroup>
                <Form.Control
                  type={pwToggle?"text":"password"} 
                  id="password"
                  name="password"
                  value={password}
                  minLength={10}
                  onChange={e => setPassword(e.target.value)}
                  required/>
                <InputGroup.Text onClick={() => setPwToggle(!pwToggle)}>{pwToggle?<ImEyeBlocked />:<ImEye />}</InputGroup.Text>
              </InputGroup>
              <Form.Control.Feedback type="invalid">Passwort muss 10 Zeichen lang sein.</Form.Control.Feedback>
            </FormGroup>
            <FormGroup className="mt-3">
              <Form.Text>Passwort Wiederholung</Form.Text>
              <Form.Control
                type={pwToggle?"text":"password"} 
                id="repeatPassword" 
                name="repeatPassword"
                value={repeatPassword} 
                minLength={10}
                onChange={e => setRepeatPassword(e.target.value)}
                required/>
                <Form.Control.Feedback type="invalid">Passwörter müssen übereinstimmen.</Form.Control.Feedback>
            </FormGroup>
            <div className="d-grid gap-3 mt-3">
              <Button type="submit">Passwort speichern</Button>
              <Button variant="outline-primary" onClick={sendAgain}>E-Mail nochmal senden</Button>
            </div>
          </Form>
        </div>
      </Container>
    )
  }
}

export default FirstLogin