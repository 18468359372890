import {useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { defaultData } from "./defaultData";
import { saver } from "../../../redux/actions/database/saver";

const AddProject = ({contactId = null}) => {

  const [project, setProject] = useState({...defaultData, contactId: contactId});
  const [isDirty, setDirty] = useState(false);
  const [validated, setValidated] = useState(false);

  const projectstatus = ["offen","laufend","abgeschlossen"];
  // const leadstatus = ["Wartet auf Angebot","Angebot erstellt","Auftrag erhalten","Verloren"];


  const handleChange = e => {
    setDirty(true);
    setProject(prev => ({...prev, [e.target.name]: e.target.value }));
  }
  const handleReset = e => {
    if(window.confirm("Alle Eingaben verwerfen?")) {
      setProject({...defaultData, contactId: contactId});
      setValidated(false);
      setDirty(false);
    }
  }
  const handleSave = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    saver("projects",project);
    setDirty(false);
    setValidated(false);
  }

  return (
    <Form onSubmit={handleSave} noValidate validated={validated}>
      <Row>
        <Col xs={12} md={4}>
          <h6>Projektdetails</h6>
          <FormGroup>
            <Form.Text>Projektname</Form.Text>
            <Form.Control name="name" value={project.name} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Form.Text>Projektbezeichnung</Form.Text>
            <Form.Control name="bezeichnung" value={project.bezeichnung} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Form.Text>Bauteil</Form.Text>
            <Form.Control name="bauteil" value={project.bauteil} onChange={handleChange} />
          </FormGroup>
        </Col>
        <Col xs={12} md={4}>
          <h6>Projektadresse</h6>
          <FormGroup>
            <Form.Text>Straße, Nr.</Form.Text>
            <Form.Control name="anschrift" pattern="[a-zA-ZäöüÄÖÜß0-9\s/-]+" value={project.anschrift} onChange={handleChange} required/>
          </FormGroup>

          <Row>
            <Col xs={4}>
              <FormGroup>
                <Form.Text>PLZ</Form.Text>
                <Form.Control pattern="[0-9]{5}" name="PLZ" value={project.PLZ} onChange={handleChange} required/>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Ort</Form.Text>
                <Form.Control name="ort" pattern="[A-Za-zäöüßÄÖÜ-]+\s*([A-Za-zäöüßÄÖÜ-]+\s*)*" value={project.ort} onChange={handleChange} required/>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Form.Text>Projekt Status</Form.Text>
            <Form.Select name="status" value={project.status} onChange={handleChange}>
              {
                projectstatus.map((status, id) => <option key={id} value={status}>{ status }</option>)
              }
            </Form.Select>
          </FormGroup>
        </Col>
        <Col xs={12} md={4}>
          <h6>Bearbeitung</h6>
          <FormGroup>
            <Form.Text>Technisch</Form.Text>
            <Form.Control name="technisch" value={project.technisch} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Form.Text>Kaufmännisch</Form.Text>
            <Form.Control name="kfm" value={project.kfm} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Form.Text>Projektbeginn</Form.Text>
            <Form.Control type="date" name="beginn" value={project.beginn} onChange={handleChange} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
      <Col align="end">
        <ButtonGroup>
          <Button onClick={handleReset} disabled={!isDirty} variant="outline-primary">Verwerfen</Button>
          <Button type="submit" disabled={!isDirty}>Speichern</Button>
        </ButtonGroup>
      </Col>
    </Row>
    </Form>
  )
}

export default AddProject