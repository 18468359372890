import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {TbPencil, TbTrashX} from "react-icons/tb";
import { useState } from "react";
import { updateDB } from "../../../../functions/database/update";

const MeasurmentLine = (props) => {

  const {lineData, lineId, articleData, articleId, offerId, status, id, editData} = props;
  const [active, setActive] = useState(false);

  const handleEdit = () => {
    setActive(true);
    editData({lineData: lineData, lineId: lineId});
  }

  const handleDelete = () => {
    if(window.confirm("Eintrag unwiderruflich löschen?")) {
      let arr = [...Object.values(articleData.lines)];
      if (arr.length === 1) {
        arr = null;
      } else {
        arr[lineId] = null;
      }
      updateDB("measurements/"+id+"/offers/"+offerId+"/"+articleId, arr, "lines");
    }
  }

  return (
    <Row style={(active ? {borderTop: "1px solid #ccc", backgroundColor: "#0076ba19"} : {borderTop: "1px solid #ccc"})} className="py-1 measurementLine">
      <Col xs={12} md={4} lg={3}>
        { lineData?.desc || "Ohne Bezeichnung" }</Col>
      <Col xs={12} md={2} lg={2}>{ lineData?.axis || ""}</Col>
      <Col xs={12} md={6} lg={7}>
        <Row>
          <Col align="end">
            <p className="measurementHeaderSmall">Stück</p>
            { (lineData?.qty && lineData.qty !== "0,000" ? lineData.qty : <span className="measurementNumberGrey">0,000</span>) }
          </Col>
          <Col align="end">          
            <p className="measurementHeaderSmall">Länge</p>
            { (lineData?.l && lineData.l !== "0,000" ? lineData.l : <span className="measurementNumberGrey">0,000</span>) }
          </Col>
          <Col align="end">          
            <p className="measurementHeaderSmall">Breite</p>
            { (lineData?.b && lineData.b !== "0,000" ? lineData.b : <span className="measurementNumberGrey">0,000</span>) }
          </Col>
          <Col align="end">          
            <p className="measurementHeaderSmall">Höhe</p>
            { (lineData?.h && lineData.h !== "0,000" ? lineData.h : <span className="measurementNumberGrey">0,000</span>) }
          </Col>
          <Col align="end" xs={6} md={4} lg={3}>          
            <p className="measurementHeaderSmall">Höhe</p>
            { (lineData?.sum && lineData.sum !== "0,000" ? lineData.sum + " " + articleData?.einheit : <span className="measurementNumberGrey">0,000 {articleData?.einheit}</span>) }
            { (lineData?.rentTotalQty > 0 ? <><br/>{lineData?.rentTotalQty} { articleData?.einheit + articleData?.VorhalteEinheit }</> : "")}
          </Col>
          {
            status === "Offen" ?
              <Col align="end" xs={6} md={3} lg={2}>
                <Button size="sm" variant="outline-primary" onClick={handleEdit}><TbPencil /></Button>
                <Button size="sm" variant="outline-danger" className="mx-1" onClick={handleDelete}><TbTrashX /></Button>
              </Col> : <Col />
          }
        </Row>
      </Col>
      {
        (articleData.typ === "Pos") && <Col xs={12}></Col>
      }
    </Row>
  )
}

export default MeasurmentLine