import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { loader } from "../../../redux/actions/database/loader";
import { BsPinMapFill, BsSortAlphaDown, BsSortAlphaUp, BsSortNumericUp, BsSortNumericDown } from "react-icons/bs";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Error from "../../APP/Messages/Error";
import Loading from "../../APP/Messages/Loading";
import Badge from "react-bootstrap/Badge";

const FilterAndSort = ({ q = null, status = null, toggleModal }) => {
  const dispatch = useDispatch();
  const { projects, projectsLoaded, projectsError } = useSelector(state => state.crm);
  const [sortedContacts, setSortedContacts] = useState([]);
  const [sortProperty, setSortProperty] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const pageSize = 25;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSort = (property) => {
    if (property === sortProperty) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortProperty(property);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    if (!projectsLoaded) {
      dispatch(loader("projects"));
    }
  }, [projectsLoaded, dispatch]);

  useEffect(() => {
    let sorted = Object.entries(projects).sort(([, a], [, b]) => {
      if (a && b) {
        const nameA = a.name || "";
        const nameB = b.name || "";
        return nameA.localeCompare(nameB);
      }
      return 0;
    });

    if (sortProperty) {
      sorted.sort(([, a], [, b]) => {
        const valueA = a && a[sortProperty] ? a[sortProperty].toLowerCase() : "";
        const valueB = b && b[sortProperty] ? b[sortProperty].toLowerCase() : "";

        if (sortOrder === "asc") {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      });
    }
    if(status && status !== "alle") {
      sorted = sorted.filter(([, project]) => {
        return (project?.status && project.status === status);
      })
    }

    if (q) {
      sorted = sorted.filter(([, project]) => {
        const searchQuery = q
          .toLowerCase()
          .replace(/[^a-z0-9]/gi, "")
          .replace(/strasse|straße|str./g, "str");

        const name = project.name || "";
        const address = project.anschrift || "";
        const zipCodeAndCity = (project.PLZ || "") + " " + (project.ort || "");

        return (
          name.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          address.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          zipCodeAndCity.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery)
        );
      });
    }

    setSortedContacts(sorted);
  }, [projects, sortProperty, sortOrder, q, status]);

  if (projectsError !== null) {
    return <Error />;
  }

  if (!projectsLoaded) {
    return <Loading />;
  }

  const totalContacts = sortedContacts.length;
  const totalPages = Math.ceil(totalContacts / pageSize);
  const projectsToShow = sortedContacts.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const renderTooltip = (title) => (
    <Tooltip id="button-tooltip" placement="top" delay={{ show: 250, hide: 400 }}>
      {title}
    </Tooltip>
  );

  const color = (status) => {
    if(status === "offen") return "accent1";
    else if(status === "laufend") return "primary";
    else if(status === "abgeschlossen") return "dark";
    else return "secondary";
  }

  if (Object.keys(projects).length === 0) {
    return (
      <Alert variant="primary">
        Sie haben noch keine Projekte, legen Sie jetzt eines an.
        <br />
        <br />
        <Button variant="primary" onClick={toggleModal}>
          Neues Projekt
        </Button>
      </Alert>
    );
  }
  else if (projectsToShow.length === 0) {
    return (
      <Alert variant="primary">
        Es gibt keine Übereinstimmungen.
      </Alert>
    );
  }
  const renderMapsString = p => {
    const str = (p?.anschrift ? p.anschrift + ', ' : '');
    const plz = (p?.PLZ ? p.PLZ + ' ' : '');
    const ort = (p?.ort ? p.ort : '');
    return (str + '' + plz + ort).replace(' ','+');
  }
  

  return (
    <>
      <Table striped hover>
        <thead>
          <tr>
            <th>
              Auftraggeber
            </th>
            <th onClick={() => handleSort("name")} style={{cursor:"pointer"}}>
                Name {sortProperty === "name" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("anschrift")} style={{cursor:"pointer"}}>
              Anschrift {sortProperty === "anschrift" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th>
              <span onClick={() => handleSort("PLZ")} style={{cursor:"pointer"}}>PLZ {sortProperty === "PLZ" && (sortOrder === "asc" ? <BsSortNumericDown /> : <BsSortNumericUp />)}</span>
              <span onClick={() => handleSort("ort")} style={{cursor:"pointer"}}>Ort {sortProperty === "ort" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}</span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {projectsToShow.map(([key, project], index) => (
            <tr key={index}>
              <td valign="middle">{project?.contact?.name || ""}</td>
              <td valign="middle">{project?.name || ""}</td>
              <td valign="middle">{project?.anschrift || ""}</td>
              <td valign="middle">{ project?.PLZ ? project.PLZ + " " : ""}{ project?.ort || ""}</td>
              <td valign="middle" align="end">
                <Badge bg={color(project?.status)} style={{fontWeight:500, textTransform: "capitalize", marginRight:"1rem"}} pill>
                  { project?.status }
                </Badge>
                <ButtonGroup>
                  <OverlayTrigger overlay={renderTooltip("Projekt Manager öffnen")}>
                    <Button as={Link} to={"/projects/"+key} variant="outline-primary">
                      Projekt Manager
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger overlay={renderTooltip("In Google Maps anzeigen")}>
                    <Button as={Link} to={"https://www.google.com/maps/place/" + renderMapsString(project)} rel="nofollow" target="_blank" variant="outline-primary">
                      <BsPinMapFill />
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination className="text-center">
        {
          (currentPage > 1) && <>
            <Pagination.First onClick={() => handlePageChange(1)}/>
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}/>
          </>
        }
        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
          <Pagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Pagination.Item>
        ))}
        {
          (currentPage < totalPages) && <>
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}/>
            <Pagination.Last onClick={() => handlePageChange(totalPages)}/>
          </>
        }
        </Pagination>
      </div>
    </>
  );
};

export default FilterAndSort;
