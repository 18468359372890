import initialState from './initialState';
import types from '../../types';

export default function erp(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_ARTICLES_SUCCESS:
      return {
        ...state,
        articlesLoaded: true,
        articlesError: null,
        articles: {...action.payload},
      };
    case types.LOAD_ARTICLES_FAILURE:
      return {
        ...state,
        articlesLoaded: true,
        articlesError: action.error,
      };
    case types.LOAD_LEADS_SUCCESS:
      return {
        ...state,
        leadsLoaded: true,
        leadsError: null,
        leads: {...action.payload},
      };
    case types.LOAD_LEADS_FAILURE:
      return {
        ...state,
        leadsLoaded: true,
        leadsError: action.error,
      };
    case types.LOAD_OFFERS_SUCCESS:
      return {
        ...state,
        offersLoaded: true,
        offersError: null,
        offers: {...action.payload},
      };
    case types.LOAD_OFFERS_FAILURE:
      return {
        ...state,
        offersLoaded: true,
        offersError: action.error,
      };
    case types.LOAD_OVERDUES_SUCCESS:
      return {
        ...state,
        overduesLoaded: true,
        overduesError: null,
        overdues: {...action.payload},
      };
    case types.LOAD_OVERDUES_FAILURE:
      return {
        ...state,
        overduesLoaded: true,
        overduesError: action.error,
      };
    case types.LOAD_INVOICES_SUCCESS:
      return {
        ...state,
        invoicesLoaded: true,
        invoicesError: null,
        invoices: {...action.payload},
      };
    case types.LOAD_INVOICES_FAILURE:
      return {
        ...state,
        invoicesLoaded: true,
        invoicesError: action.error,
      };
    case types.LOAD_INVOICESOVERVIEW_SUCCESS:
      return {
        ...state,
        invoicesOverviewLoaded: true,
        invoicesOverviewError: null,
        invoicesOverview: {...action.payload},
      };
    case types.LOAD_INVOICESOVERVIEW_FAILURE:
      return {
        ...state,
        invoicesOverviewLoaded: true,
        invoicesOverviewError: action.error,
      };
    case types.LOAD_MEASUREMENTS_SUCCESS:
      return {
        ...state,
        measurementsLoaded: true,
        measurementsError: null,
        measurements: {...action.payload},
      };
    case types.LOAD_MEASUREMENTS_FAILURE:
      return {
        ...state,
        measurementsLoaded: true,
        measurementsError: action.error,
      };
    case types.LOAD_PAYMENTS_SUCCESS:
      return {
        ...state,
        paymentsLoaded: true,
        paymentsError: null,
        payments: {...action.payload},
      };
    case types.LOAD_PAYMENTS_FAILURE:
      return {
        ...state,
        paymentsLoaded: true,
        paymentsError: action.error,
      };
    case types.AUTH_SIGNOUT:
      return {
        ...initialState
      };
    default: return state;
  }
}