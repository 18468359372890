import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getPerformance } from "firebase/performance";
import { getStorage, connectStorageEmulator } from "firebase/storage";

const domainCheck = () => {
  var liveDomains = ["app.werkapp.de", "mobile.werkapp.de", "localhost", "demo.werkapp.de"];
  var actualDomain = window.location.hostname;
  if (liveDomains.includes(actualDomain)) {
    
    if (actualDomain === "localhost") {
      return {allowed: true, live: true, demo: false};
    } else if (actualDomain === "demo.werkapp.de") {
      return {allowed: true, live: true, demo: true};
    } else {
      return {allowed: true, live: true, demo: false};
    }
  }
  else {
    return {allowed: false, live: false, demo: true};
  }
}

const state = domainCheck();

let firebaseConfig = {};

if (state.allowed && !state.live) {
  firebaseConfig = {
    apiKey: "AIzaSyCtREu0X_trXeXB5zDaYdS2n43rE0QYc1U",
    authDomain: "werkapp-de.firebaseapp.com",
    databaseURL: "https://werkapp-customers.europe-west1.firebasedatabase.app",
    projectId: "werkapp-de",
    storageBucket: "werkapp-de.appspot.com",
    messagingSenderId: "489912178576",
    appId: "1:489912178576:web:1353094191350857604515",
    measurementId: "G-99N7LB9SCE"
  };
}
else if (state.allowed && state.live && !state.demo) {
  firebaseConfig = {
    apiKey: "AIzaSyCtREu0X_trXeXB5zDaYdS2n43rE0QYc1U",
    authDomain: "werkapp-de.firebaseapp.com",
    databaseURL: "https://werkapp-customers.europe-west1.firebasedatabase.app",
    projectId: "werkapp-de",
    storageBucket: "werkapp-de.appspot.com",
    messagingSenderId: "489912178576",
    appId: "1:489912178576:web:1353094191350857604515",
    measurementId: "G-99N7LB9SCE"
  };
}
else if (state.allowed && state.live && state.demo) {
  firebaseConfig = {
    apiKey: "AIzaSyCtREu0X_trXeXB5zDaYdS2n43rE0QYc1U",
    authDomain: "werkapp-de.firebaseapp.com",
    databaseURL: "https://werkapp-demo.europe-west1.firebasedatabase.app",
    projectId: "werkapp-de",
    storageBucket: "werkapp-de.appspot.com",
    messagingSenderId: "489912178576",
    appId: "1:489912178576:web:f7dd8393119c3820604515",
    measurementId: "G-3HZF9BQB3S"
  }
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(app, {provider: new ReCaptchaV3Provider('6LdM_XohAAAAAGuzEnhU7h7e7Us35qpRssg2uAlY'),isTokenAutoRefreshEnabled: true});
const auth = getAuth(app);
const analytics = getAnalytics(app);
const perf = getPerformance(app);
const storage = getStorage(app);
const functions = getFunctions(app);
// const firestore = getFirestore(app);
const database = getDatabase(app);

if (window.location.hostname === "localhost") {
  // Point to the RTDB emulator running on localhost.
  connectAuthEmulator(auth, "http://localhost:9099");
  connectDatabaseEmulator(database, "localhost", 9000);
  connectStorageEmulator(storage, "localhost", 9199);
  connectFunctionsEmulator(functions, "localhost", 5001);
} 

export { app as firebase,
  appCheck,
  auth,
  analytics,
  perf,
  storage,
  functions,
  database };