import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const Planen = () => {

  const {rights, sales} = useSelector(state => state.auth.tokens);

  return (
    <div className="menuNav">
      <p className="menuNavBtn">Planen</p>
      <div className="menuSubmenu">
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("dpd") && rights["dpd"] > 0)) && <NavLink to="/tasks" className="menuSubBtn">Aufgabenplanung
          <span className="menuSubBtnSmall">Projekttermine planen</span></NavLink>
        }
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("dpd") && rights["dpd"] > 0)) && <NavLink to="/dispoboard" className="menuSubBtn">Projekt Disposition
          <span className="menuSubBtnSmall">Alles im Überblick</span></NavLink>
        }
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("dvp") && rights["dvp"] > 0)) && <NavLink to="/absence" className="menuSubBtn">Urlaub & Abwesenheit
          <span className="menuSubBtnSmall">Urlaubs- und Abwesenheitsplanung</span></NavLink>
        }
      </div>
    </div>);
}

export default Planen