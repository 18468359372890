import React from 'react'
import { Alert, Card, Table } from 'react-bootstrap';
import FileIcons from '../../../APP/Helper/FileIcons';

const Documents = ({project}) => {

  const { documents } = project;
  
  // {id: {url, desc, addedAt, addedBy}}
  const FileType = ({docName}) => {
    const ft = docName.split('.').pop().toLowerCase();
    return <FileIcons typ={ft} />;
  }

  return (
    <Card>
      <Card.Body>
        <p className="my-1 fw-semibold">Fotos und Dokumente</p>
        {
          (documents === undefined || documents === null) ? 
          <Alert variant="primary">Es sind keine Dokumente vorhanden.</Alert> :
          <Table>
            <tbody>
              {
                 Object.entries(documents).map(([key, doc], _) => <tr key={key}>
                  <td>{ doc?.desc || "Unbenannt" }</td>
                  <td><FileType docName={ key.url }/></td>
                 </tr>)
              }
            </tbody>
          </Table>
         

        }
      </Card.Body>
    </Card>
  )
}

export default Documents