import { useState} from "react";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FormGroup } from "react-bootstrap";
import { updateDB } from "../../../functions/database/update";

const Texte = () => {

  const { texte } = useSelector(state => state.crm.settings);

  const [texts, setTexte] = useState(texte);
  const [selected, setSelected] = useState("");
  const [subSelected, setSubSelected] = useState("");
  const [isDirty, setDirty] = useState(false);

  const handleSelect = i => {
    setSelected(i);
  }
  const handleSubSelect = i => {
    setSubSelected(i);
  }

  const handleReset = () => {
    setTexte(texte);
    setDirty(false);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    const [key, prop] = name.split("-");
  
    setTexte((prevTexte) => {
      const updatedTexte = { ...prevTexte };
      updatedTexte[selected][subSelected][key][prop] = value;
      return updatedTexte;
    });
  
    setDirty(true);
  };
  
  const addItem = () => {
    setTexte((prevTexte) => {
      const updatedTexte = { ...prevTexte };
      const newItem = { desc: "", text: "" };
      updatedTexte[selected][subSelected]["custom" + Object.keys(updatedTexte[selected][subSelected]).length] = newItem;
      return updatedTexte;
    });
  
    setDirty(true);
  };
  
  const deleteItem = (index) => {
    setTexte((prevTexte) => {
      const updatedTexte = { ...prevTexte };
      delete updatedTexte[selected][subSelected][index];
      return updatedTexte;
    });
  
    setDirty(true);
  };
  
  const cleanObj = obj => {
    for (const cat in obj) {
      for (const subCat in obj[cat]) {
        for(const item in obj[cat][subCat]) {
          if ((obj[cat][subCat][item].desc === "" || obj[cat][subCat][item].text === "") || item === "WerkAPP") {
            delete obj[cat][subCat][item];
          }
        }
      }
    }
    return obj;
  }

  const handleSave = () => {
    // delete default texts & remove empty items
    const obj = cleanObj({...texts});
    updateDB("_default/root", obj, "texte");
    setDirty(false);
  }

  return (
    <>
    <Row>
      <Col xs={12} md={3}>
      <ListGroup>
        {
          Object.keys(texts).map((categorie, index) => <ListGroup.Item style={{textTransform: "capitalize"}} key={index} onClick={() => handleSelect(categorie)} active={selected === categorie} action >{ categorie }</ListGroup.Item>)
        }
      </ListGroup>
      </Col>
      <Col xs={12} md={3}>
        <ListGroup>
          {
            selected !== "" && Object.keys(texts[selected]).map((subcategorie, index) => <ListGroup.Item style={{textTransform: "capitalize"}} key={index} onClick={() => handleSubSelect(subcategorie)} active={subSelected === subcategorie} action>{ subcategorie }</ListGroup.Item>)
            // Object.entries(texts[selected]).map(([subcatorie, _], index) => <ListGroup.Item key={index}>{subcatorie}</ListGroup.Item>)
          }
        </ListGroup>
      </Col>
      <Col align="end">
        {
          isDirty && <><Button variant="outline-primary" className="mb-3" onClick={handleReset}>Alle Änderungen verwerfen</Button><br/>
          <Button variant="primary" onClick={handleSave}>Alle Änderungen speichern</Button></>
        }
      </Col>
    </Row>
    {
      subSelected !== "" && Object.entries(texts[selected][subSelected]).map(([key, value], index) => <div key={index}>
        {
          (key === "WerkAPP") ?
          <div className="mt-3 p-3 card">
            <h6>WerkAPP Standart</h6>
            <p>{ value?.text }</p>
            <p className="text-muted mb-0" style={{fontSize:"12px"}}>Dieser Eintrag kann nicht gelöscht werden.</p>
          </div> :
          <FormGroup className="mt-3">
            <hr/>
            <InputGroup className="mb-3">
              <Form.Control value={value?.desc || ""} name={key + "-desc"} placeholder="Name" onChange={handleChange}/>
              <Button variant="outline-danger" onClick={() => deleteItem(index)}>Löschen</Button>
            </InputGroup>
            <Form.Control as="textarea" rows="4" name={key + "-text"} onChange={handleChange} value={value?.text || ""} />
          </FormGroup>
          }
          
        
        </div>)
    }
      <div className="mt-4" style={{display: "flex", justifyContent: "flex-end"}}>
        <ButtonGroup>
          {
            (selected !== "" && subSelected !== "") && <Button variant="outline-primary" onClick={addItem}>Eintrag hinzufügen</Button>
          }
          {
            isDirty && <><Button variant="outline-primary" onClick={handleReset}>Verwerfen</Button>
            <Button variant="primary" onClick={handleSave}>Speichern</Button></>
          }
          
        </ButtonGroup>
      </div>
    </>
  )
}

export default Texte