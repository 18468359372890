const initialState = {
  articlesLoaded: false,
  articlesError: null,
  articles: {},
  leadsLoaded: false,
  leadsError: null,
  leads: {},
  offersLoaded: false,
  offersError: null,
  offers: {},
  measurementsLoaded: false,
  measurementsError: null,
  measurements: {},
  invoicesLoaded: false,
  invoicesError: null,
  invoices: {},
  invoicesOverviewLoaded: false,
  invoicesOverviewError: null,
  invoicesOverview: {},
  paymentsLoaded: false,
  paymentsError: null,
  payments: {},
  overduesLoaded: false,
  overduesError: null,
  overdues: {},
};
export default initialState;