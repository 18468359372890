export const defaultData = {
  contactId: "",
  projectId: "",
  anrede: "",
  vorname: "",
  nachname: "",
  funktion: "",
  mobil: "",
  telefon: "",
  email: "",
};
