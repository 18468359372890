import { useState } from "react";
import { useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";
import Unauthorized from "../../APP/Messages/Unauthorized";
import FilterAndSort from "./FilterAndSort";
import { FormSelect, InputGroup } from "react-bootstrap";
import { defaultStates } from "./defaultData";
import MultiTool from "./MultiTool";
import { convertDateFormat } from "../../../functions/datetime/convertDateFormat";

const Tasks = () => {

  const {rights, sales} = useSelector(state => state.auth.tokens);
  
  const [mode, setMode] = useState(null);
  const [obj, setObj] = useState(null);
  const [objId, setObjId] = useState(null);

  const [q, setQ] = useState("");
  const [status, setStatus] = useState(defaultStates[0]);

  const closeModal = () => { setMode(null); setObj(null); setObjId(null); }
  const modalAdd = () => { setObj(null); setObjId(null); setMode("add");}
  const modalDetail = ({id, el}) => {console.log(el); setObj(el); setObjId(id); setMode("detail");}
  

  if(sales !== true && !rights.hasOwnProperty("dpd")) return <Unauthorized license={'prp'} />
  else if(sales !== true && rights["dpd"] === 0) return <Unauthorized />;

  return (<>
    <h4 className="pageTitle my-3">Aufgabenverwaltung</h4>
    <Card>
      <Card.Header>
        <Row>
          <Col xs={8} md={5}>
            <InputGroup>
            <FormControl value={q} onChange={e => {setQ(e.target.value)}} placeholder="Suche"/>
            <FormSelect style={{maxWidth:"200px"}} value={status} onChange={e => {setStatus(e.target.value)}}>
              {
                defaultStates.map((x,id) => <option key={id} value={x}>{x}</option>)
              }
              <option value="Alle">Alle</option>
            </FormSelect>
            </InputGroup>
          </Col>
          {
            (sales || rights["dpd"] > 3) && <Col xs={4} md={7} align="end"><Button variant="outline-primary" onClick={modalAdd}>Neue Aufgabe</Button></Col>
          }
        </Row>
      </Card.Header>
      <Card.Body>
        <FilterAndSort q={q} status={status} modalAdd={modalAdd} modalDetail={modalDetail} />
      </Card.Body>
      <Card.Footer align="end">
        
             { (sales || rights["dpd"] > 3) && <Button variant="outline-primary" onClick={modalAdd}>Neue Aufgabe</Button> }
        
      </Card.Footer>
    </Card>
    <Modal show={(mode!==null)} onHide={closeModal} size="xl" backdrop="static">
      <Modal.Header closeButton>
        { (mode === "add" && "Neue Aufgabe anlegen") }
        { (mode !== "add" && "Aufgabe" + (obj?.projektName ? " - " + obj.projektName : "") + (obj?.datumGeplant ? " (" + convertDateFormat(obj.datumGeplant) + ")" : "")) }
      </Modal.Header>
      <Modal.Body>
        <MultiTool obj={obj} objId={objId} mode={mode} closeModal={closeModal} />
      </Modal.Body>
    </Modal>
  </>);
}

export default Tasks