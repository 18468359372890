import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { loader } from "../../../redux/actions/database/loader";
import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";
import ListGroup from "react-bootstrap/ListGroup";
import Alert from "react-bootstrap/Alert";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";

const digits3 = {
  style: "decimal",
  useGrouping: true,
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
  minimumIntegerDigits: 1,
  currency: undefined,
  currencyDisplay: "symbol",
  notation: "standard",
  compactDisplay: "short",
  localeMatcher: "best fit",
  numberingSystem: "latn",
  signDisplay: "auto",
  signStyle: "standard",
};
const digits2 = {...digits3, minimumFractionDigits: 2, maximumFractionDigits: 2};

const ArticlesMini = ({show, onHide, onSelect, filter = null}) => {
  
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const {articles, articlesLoaded, articlesError} = useSelector(state => state.erp);
  const [sortedArticles, setSortedArticles] = useState([]);

  useEffect(() => {
    if(!articlesLoaded) {
      dispatch(loader("articles"));
    }
  }, [articlesLoaded, dispatch]);

  useEffect(() => {
    let sorted = Object.entries(articles).sort(([, a], [, b]) => {
      if (a && b) {
        const kurztextA = a.kurztext || "";
        const kurztextB = b.kurztext || "";
        return kurztextA.localeCompare(kurztextB);
      }
      return 0;
    });
    if(filter) {
      sorted = sorted.filter(([, article]) => {
        return (article?.status && article.status === filter);
      })
    }
    if (query.length >= 1) {
      sorted = sorted.filter(([, article]) => {
        const searchQuery = query
          .toLowerCase()
          .replace(/[^a-z0-9]/gi, "")

        const kurztext = article.kurztext || "";

        return (
          kurztext.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery)
        );
      });
    }
    setSortedArticles(sorted);
  }, [articles, query, filter]);

  if(articlesError !== null) {
    return (<Modal show={show} onHide={onHide} scrollable><Modal.Header><FormControl value={query} onChange={e => {setQuery(e.target.value);}} placeholder="Artikel Suche"/></Modal.Header><Modal.Body><Error /></Modal.Body></Modal>);
  } else if(!articlesLoaded) {
    return (<Modal show={show} onHide={onHide} scrollable><Modal.Header><FormControl value={query} onChange={e => {setQuery(e.target.value);}} placeholder="Artikel Suche"/></Modal.Header><Modal.Body><Loading /></Modal.Body></Modal>);
  } else if (sortedArticles.length === 0) {
    return (<Modal show={show} onHide={onHide} scrollable><Modal.Header><FormControl value={query} onChange={e => {setQuery(e.target.value);}} placeholder="Artikel Suche"/></Modal.Header><Modal.Body><Alert variant="primary">Es sind keine Daten vorhanden</Alert></Modal.Body></Modal>);
  }

  return (
    <Modal show={show} onHide={onHide} scrollable>
      <Modal.Header>
        <FormControl value={query} onChange={e => {setQuery(e.target.value);}} placeholder="Artikel Suche"/>
      </Modal.Header>
      <Modal.Body>
        <ListGroup variant="flush">
        {
            (sortedArticles.length > 0) && sortedArticles.map(([id, article], idx) => <ListGroup.Item key={idx} action onClick={() => onSelect(article)}>
                    <span className="fw-bold">{ article.kurztext }</span> ({ article.lv})<br/>
                    1,000 { article.einheit } á { (article.TK.NumericEP).toLocaleString("de-DE", digits2) } €
                  </ListGroup.Item>
              )
          }
        </ListGroup>
      </Modal.Body>
    </Modal>
  )
}

export default ArticlesMini