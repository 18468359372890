const CRM = {
  LOAD_CONTACTS_FAILURE: 'LOAD_CONTACTS_FAILURE',
  LOAD_CONTACTS_SUCCESS: 'LOAD_CONTACTS_SUCCESS',

  LOAD_PROJECTS_FAILURE: 'LOAD_PROJECTS_FAILURE',
  LOAD_PROJECTS_SUCCESS: 'LOAD_PROJECTS_SUCCESS',

  LOAD_TEAM_FAILURE: 'LOAD_TEAM_FAILURE',
  LOAD_TEAM_SUCCESS: 'LOAD_TEAM_SUCCESS',

  LOAD_SETTINGS_FAILURE: 'LOAD_SETTINGS_FAILURE',
  LOAD_SETTINGS_SUCCESS: 'LOAD_SETTINGS_SUCCESS',
};
const ERP = {
  LOAD_ARTICLES_FAILURE: 'LOAD_ARTICLES_FAILURE',
  LOAD_ARTICLES_SUCCESS: 'LOAD_ARTICLES_SUCCESS',
  
  LOAD_LEADS_FAILURE: 'LOAD_LEADS_FAILURE',
  LOAD_LEADS_SUCCESS: 'LOAD_LEADS_SUCCESS',
  
  LOAD_INVOICES_FAILURE: 'LOAD_INVOICES_FAILURE',
  LOAD_INVOICES_SUCCESS: 'LOAD_INVOICES_SUCCESS',
  
  LOAD_INVOICESOVERVIEW_FAILURE: 'LOAD_INVOICESOVERVIEW_FAILURE',
  LOAD_INVOICESOVERVIEW_SUCCESS: 'LOAD_INVOICESOVERVIEW_SUCCESS',


  LOAD_OFFERS_FAILURE: 'LOAD_OFFERS_FAILURE',
  LOAD_OFFERS_SUCCESS: 'LOAD_OFFERS_SUCCESS',

  LOAD_PAYMENTS_FAILURE: 'LOAD_PAYMENTS_FAILURE',
  LOAD_PAYMENTS_SUCCESS: 'LOAD_PAYMENTS_SUCCESS',

  LOAD_MEASUREMENTS_FAILURE: 'LOAD_MEASUREMENTS_FAILURE',
  LOAD_MEASUREMENTS_SUCCESS: 'LOAD_MEASUREMENTS_SUCCESS',

  LOAD_OVERDUES_FAILURE: 'LOAD_OVERDUES_FAILURE',
  LOAD_OVERDUES_SUCCESS: 'LOAD_OVERDUES_SUCCESS',
};
const DISPO = {
  LOAD_TASKS_FAILURE: 'LOAD_TASKS_FAILURE',
  LOAD_TASKS_SUCCESS: 'LOAD_TASKS_SUCCESS',

  LOAD_STAFF_FAILURE: 'LOAD_STAFF_FAILURE',
  LOAD_STAFF_SUCCESS: 'LOAD_STAFF_SUCCESS',

  LOAD_HOURS_FAILURE: 'LOAD_HOURS_FAILURE',
  LOAD_HOURS_SUCCESS: 'LOAD_HOURS_SUCCESS',

  LOAD_ABSENCE_FAILURE: 'LOAD_ABSENCE_FAILURE',
  LOAD_ABSENCE_SUCCESS: 'LOAD_ABSENCE_SUCCESS',
};

const types = {
  AUTH_SIGNIN: 'AUTH_SIGNIN',
  AUTH_SIGNOUT: 'AUTH_SIGNOUT',

  FIREBASE_CONNECTED: 'FIREBASE_CONNECTED',
  FIREBASE_ERROR: 'FIREBASE_ERROR',

  MODAL_TOGGLE: 'MODAL_TOGGLE',

  ...CRM,
  ...ERP,
  ...DISPO
}
export default types;