export const branch = [
  "Maurer und Betonbauer",
  "Zimmerei",
  "Dachdecker",
  "Straßenbau",
  "Wärme-, Kälte- und Schallschutz",
  "Brunnenbau",
  "Gerüstbau",
  "Betonstein- und Terrazzoherstellung",
  "Ofen- und Luftheizungsbau",
  "Stuckateure",
  "Maler und Lackierer",
  "Metallbau",
  "Kälteanlagenbau",
  "Klempnerei",
  "Installateur und Heizungsbau",
  "Elektrotechnik",
  "Tischlerei",
  "Glaserei",
  "Fliesen-, Platten- und Mosaikleger",
  "Estrichleger",
  "Parkettleger",
  "Rolladen- und Sonnenschutz",
  "Schilder- und Leuchtreklamen",
  "Raumausstatter",
  "Gebäudereinigung",
  "Holz- und Bautenschutz",
  "Eisenflechterei",
  "Bautentrocknung",
  "Bodenleger",
  "Asphaltierer",
  "Fuger im Hochbau",
  "Rammgewerbe",
  "Betonbohrer und -schneider",
  "Rohr- und Kanalreinigung",
  "Kabelverlegung im Hochbau"
].sort((a, b) => a.localeCompare(b));

export const defaultSalutation = [
  "Firma",
  "Frau",
  "Herr"
];
export const defaultPersonSalutation = [
  "Herr",
  "Frau"
]
export const contactState = [
  "Auftraggeber",
  "Architekt / Planer"
];
export const contactDefault = {
  state: {value: "", type: "select", from: contactState, i18n: {de: "Status"}, filterBy: true, searchBy: false, required: true},
  salutation: {value: "", type: "select", from: defaultSalutation, i18n: {de: "Anrede"}, required: true},
  surname:  {value: "", type: "text", i18n: {de: "Vorname"}, condition: ["salutation", "!==", "Firma"], required: true},
  name: {value: "", type: "text", i18n: {de: "Name"}, searchBy: true, required: true},
  address: {value: "", type: "text", i18n: {de: "Anschrift"}, searchBy: true},
  zip: {value: "", type: "text", regex: /[0-9]{5}/, i18n: {de: "Postleitzahl"}, searchBy: true},
  city: {value: "", type: "text", i18n: {de: "Ort"}, searchBy: true},
  country: {value: "", type: "text", i18n: {de: "Land"}},
  email: {value: "", type: "email", i18n: {de: "E-Mail"}, searchBy: true},
  phone: {value: "", type: "filtered", i18n: {de: "Telefon"}, regex: /[0-9]+/, searchBy: true},
};
// Order for List
export const contactList = ["state", "name", "address", "zip", "city"];
// Order for Edit Form || Array = div
export const contactEdit = [
  ["state", "branch", ["salutation", "surname"],"name"],
  ["address2", "address", ["zip", "city"], "country"],
  ["phone", "fax", "email", "website"]
];
export const contactDetailDefault = {
  address2: {value: "", type: "text", i18n: {de: "Adresszusatz (c/o)"}},
  website: {value: "", type: "url", i18n: {de: "Webseite"}},
  fax: {value: "", type: "phone", i18n: {de: "Fax"}},
  branch: {value: "", type: "select", from: branch, i18n: {de: "Branche"}, filterBy: true, searchBy: false},
  phone: {value: "", type: "phone", i18n: {de: "Telefon"}},
};

export const projectState = [
  "In Ausführung",
  "Fertiggestellt",
  "Beendet"
];
export const projectDefault = {
  customername: "",
  architectname: "",
  name: "",
  address: "",
  zip: "",
  city: "",
  country: "",
};
export const projectDetailDefault = {
  customer: {},
  customerId: "",
  architect: {},
  architectId: "",
  articles: [],

}

export const staffState = [
  "Auszubildender",
  "Aushilfe",
  "Ferienjobber",
  "Lagerarbeiter",
  "Helfer",
  "Geselle",
  "Kolonnenführer",
  "Meister"
];
export const staffDefault = {
  state: "",
  surname: "",
  name: "",
  displayName: "",
};
export const staffDetailDefault = {
  address: "",
  zip: "",
  city: "",
  country: "",
  email: "",
  phone: "",
  startDate: "",
  endDate: "",
  birthday: "",
  driversLicense: "",
}

export const offerState = [];

export const offerDefault = {
  state: "",
  customername: "",
  architectname: "",
  name: "",
  address: "",
  zip: "",
  city: "",
  country: "",
};
export const offerDetailDefault = {
  documents: {},
  articles: [],
  
};

export const measurmentState = [
  "In Bearbeitung",
  "Fertiggestellt",
  "Abgerechnet"
];
export const measurmentDefault = {
  state: "",
  name: "",
  editedBy: "",
  editedAt: "",
}


export const digits3 = {
  style: "decimal",
  useGrouping: true,
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
  minimumIntegerDigits: 1,
  currency: undefined,
  currencyDisplay: "symbol",
  notation: "standard",
  compactDisplay: "short",
  localeMatcher: "best fit",
  numberingSystem: "latn",
  signDisplay: "auto",
  signStyle: "standard",
};
export const digits2 = {...digits3, minimumFractionDigits: 2, maximumFractionDigits: 2};