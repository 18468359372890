import { getIdTokenResult, getAuth } from "firebase/auth";
import { getDatabase, push, ref} from "firebase/database";

export const pushToDB = async (target, str) => {
  
  const user = getAuth().currentUser;
  const claims = await getIdTokenResult(user);
  const {client, sales} = claims.claims;
  
  const databaseId = (sales === undefined) ? client : user.uid;
  if(sales === undefined && (client === null || client === undefined)) return {error: true};

  const thisRef = ref(getDatabase(), databaseId + "/" + target);
  push(thisRef, str).then(() => { }).catch(err => console.log(err));
};