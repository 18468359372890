import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { ImEye, ImEyeBlocked, ImSphere } from "react-icons/im";
import { useEffect, useRef, useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";

const Login = ({toggle}) => {
  const [pwToggle, setPwToggle] = useState(false);
  const [validated, setValidated] = useState(false);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    setValidated(true);
    signInWithEmailAndPassword(getAuth(), emailRef.current.value, passwordRef.current.value);
  };

  useEffect(() => {
    if(pathname !== "/") {
      navigate("/");
    }
  }, [pathname, navigate]);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  return (
    <Container>
      <div className="auth">
        <Form onSubmit={handleSubmit} noValidate validated={validated}>
          <h1 className="fs-2 text-center"><span className="Werk">Werk</span><span className="APP">APP</span> Login</h1>
          <FormGroup className="mt-3">
            <Form.Text>E-Mail</Form.Text>
            <Form.Control type="email" id="email" required ref={emailRef} />
          </FormGroup>
          <FormGroup className="mt-3">
            <Form.Text>Passwort</Form.Text>
            <InputGroup>
              <Form.Control type={pwToggle?"text":"password"} id="password" ref={passwordRef} required/>
              <InputGroup.Text onClick={() => setPwToggle(!pwToggle)}>{pwToggle?<ImEyeBlocked />:<ImEye />}</InputGroup.Text>
            </InputGroup>
          </FormGroup>
          <FormGroup className="mt-3">
            <Form.Text>Region</Form.Text>
            <InputGroup>
              <InputGroup.Text><ImSphere /></InputGroup.Text>
              <Form.Select value="de" disabled>
                <option value="de">Deutschland</option>
                <option value="at">Österreich</option>
                <option value="us">United States</option>
                <option value="ca">Canada</option>
              </Form.Select>
            </InputGroup>
          </FormGroup>
          <div className="d-grid gap-3 mt-3">
            <Button variant="link" onClick={() => toggle(false)}>Passwort vergessen?</Button>
            <Button type="submit">Anmelden</Button>
          </div>
        </Form>
      </div>
    </Container>
  )
}

export default Login