import { useState } from "react";
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button";
import { BsLifePreserver } from "react-icons/bs";
import Support from "../Support/Support";


const Footer = () => {

  const [modal, setModal] = useState(false);
  const today = new Date();
  const hide = () => setModal(false);
  const show = () => setModal(true);

  return (
    <>
      <Row className="mt-4 mb-0">
        <Col className="text-center" style={{fontSize:'10px'}}>
          2021 - {today.getFullYear()} WerkAPP Web Version {process.env.REACT_APP_VERSION}
        </Col>
      </Row>
      <Button style={{position:"fixed", right:0, bottom: "30px", zIndex:10, borderTopRightRadius:0, borderBottomRightRadius:0}} variant="danger" onClick={show}>
        <BsLifePreserver />
      </Button>
      <Modal show={modal} onHide={hide}>
        <Modal.Header closeButton>Support</Modal.Header>
        <Modal.Body>
          <Support />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Footer