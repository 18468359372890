import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import { convertDateFormat } from "../../../functions/datetime/convertDateFormat";

const Detail = ({staff}) => {

  const driversLicenses = ((staff?.fS) ? (staff.fS.sort()).join(", ") : "");

  return (
    <Row>
      <Col xs={12} md={4}>
        <h6>Daten zur Person</h6>
        <FormGroup>
          <Form.Text>Anrede</Form.Text>
          <Form.Control plaintext readOnly defaultValue={staff.anrede} />
        </FormGroup>
        <FormGroup>
          <Form.Text>Vorname</Form.Text>
          <Form.Control plaintext readOnly defaultValue={staff.vorname} />
        </FormGroup>
        <FormGroup>
          <Form.Text>Nachname</Form.Text>
          <Form.Control plaintext readOnly defaultValue={staff.nachname} />
        </FormGroup>
        <Row>
          <Col>
            <FormGroup>
              <Form.Text>Geburtsdatum</Form.Text>
              <Form.Control plaintext readOnly defaultValue={convertDateFormat(staff.geburtsdatum)} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Form.Text>Status</Form.Text>
              <Form.Control plaintext readOnly defaultValue={staff.status} />
            </FormGroup>
          </Col>
        </Row>
        
      </Col>
      <Col xs={12} md={4}>
        <h6>Anschrift</h6>
        <FormGroup>
          <Form.Text>Straße, Nr.</Form.Text>
          <Form.Control plaintext readOnly defaultValue={staff.anschrift} />
        </FormGroup>
        <Row>
          <Col xs={4}>
            <FormGroup>
              <Form.Text>PLZ</Form.Text>
              <Form.Control plaintext readOnly defaultValue={staff.PLZ} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Form.Text>Ort</Form.Text>
              <Form.Control plaintext readOnly defaultValue={staff.ort} />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Form.Text>Land</Form.Text>
          <Form.Control plaintext readOnly defaultValue={staff.land} />
        </FormGroup>
      </Col>
      <Col xs={12} md={4}>
        <h6>Weitere Daten</h6>
        <Row>
          <Col>
            <FormGroup>
              <Form.Text>Eintrittsdatum</Form.Text>
              <Form.Control plaintext readOnly defaultValue={convertDateFormat(staff.eintrittsdatum)} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Form.Text>Austrittsdatum</Form.Text>
              <Form.Control plaintext readOnly defaultValue={convertDateFormat(staff.austrittsdatum)} />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Form.Text>Führerschein</Form.Text>
          <Form.Control plaintext readOnly defaultValue={driversLicenses} />
        </FormGroup>
        <Row>
          <Col>
            <FormGroup>
              <Form.Text>Führerschein gültig bis</Form.Text>
              <Form.Control plaintext readOnly defaultValue={convertDateFormat(staff.fSGültigkeit)} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Form.Text>Sichtprüfung</Form.Text>
              <Form.Control plaintext readOnly defaultValue={"Alle " + staff.fSPrüfen + " Monate"} />
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Detail