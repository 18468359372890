import { useEffect, useRef, useState } from "react"
import { defaultData } from "./defaultData"
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { BsFileEarmarkMedical } from "react-icons/bs";
import { loaderOnce } from "../../../functions/database/loaderOnce";
import { useSelector } from "react-redux";
import { saveDB } from "../../../functions/database/save";

import { uploadFileToCloud } from "../../../functions/storage/uploadFileToCloud";

const AbsenceUser = () => {

  const { uid } = useSelector(state => state.auth);
  const [data, setData] = useState(defaultData);
  const [absence, setAbsence] = useState({});
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalImageURL, setModalImageURL] = useState("");
  const [isDirty, setDirty] = useState(false);
  const fileRef = useRef(null);

  const handleChange = e => {
    const {name, value} = e.target;
    setData(prev => ({...prev, [name]: value}));
    setDirty(true);
  }
  const handleReset = () => {
    setData(defaultData);
    fileRef.current.value = "";
    setDirty(false);
    setValidated(false);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      return;  
    }
    setValidated(false);
    try {
      const fileInput = fileRef.current;
      const hasFile = fileInput && fileInput.files && fileInput.files.length > 0;
      let downloadURL = null;

      if (hasFile) {
        const file = fileInput.files[0];
        const path = `krankmeldungen/${uid}/${file.name}`;
        downloadURL = await uploadFileToCloud(path, file, 1024);
      }

      // Hier speichern wir die Krankmeldung in der Realtime Database
      saveDB("absence", { ...data, uid: uid, krank: true, file: downloadURL })
        .then((result) => {
          setAbsence((prev) => ({ ...prev, [result.key]: result.obj }));
          setData(defaultData);
          fileRef.current.value = "";
          setDirty(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const openModalWithImage = (imageUrl) => {
    setModalImageURL(imageUrl);
  };

  const closeModal = () => {
    setModalImageURL("");
  };

  useEffect(() => {
    if(loading) {
      loaderOnce("absence", "uid", uid)
      .then((result) => {
        if(result.result !== null) {
          setAbsence(result.result);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      })
    }
    // eslint-disable-next-line
  }, []);

  const renderDate = (date) => {
    
    if(date === null || date === undefined || date === "") return "";

    const temp = new Date(date);
    return temp.getDate().toString().padStart(2, '0') + "." + (temp.getMonth() + 1).toString().padStart(2, '0') + "." + (temp.getFullYear());
  }

  const orderObject = (obj) => {
    if(obj === null || Object.keys(obj).length === 0) return {};
    let arr = Object.entries(obj);
    arr.sort((a, b) => b[1].start.localeCompare(a[1].start));
    arr = arr.filter(([,a]) => (a?.krank && a.krank));
    return Object.fromEntries(arr);
  }

  const ordered = orderObject(absence);

  return (
    <>
    <h4 className="pageTitle my-3">Krankmeldung</h4>
    <Card>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Card.Body>
        <Row>
          <Col xs={6} md={3} lg={2} className="mb-3">
            <Form.Group>
              <Form.Text>Von</Form.Text>
              <Form.Control name="start" value={data.start} onChange={handleChange} type="date" required />
            </Form.Group>
          </Col>
          <Col xs={6} md={3} lg={2} className="mb-3">
            <Form.Group>
              <Form.Text>Bis einschließlich</Form.Text>
              <Form.Control name="ende" min={data.start} value={data.ende} onChange={handleChange} type="date" required />
            </Form.Group>
          </Col>
          <Col xs={12} md={true} className="mb-3">
            <Form.Group>
              <Form.Text>Krankschreibung hochladen</Form.Text>
              <Form.Control ref={fileRef} type="file" accept="image/png, image/jpeg" />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col align="end">
            <ButtonGroup>
              <Button variant="outline-primary" onClick={handleReset}>Verwerfen</Button>
              <Button type="submit" disabled={!isDirty}>Einreichen</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Card.Body>
      </Form>
    </Card>
    <h4 className="pageTitle my-3">Bisherige Krankmeldungen</h4>
    <Card>
      <Card.Body>
        {
          loading && <Alert variant="primary">Daten werden geladen...</Alert>
        }
        {
          Object.keys(absence).length > 0 ? <Table bordered striped>
            <thead>
              <tr>
                <th>Status</th>
                <th>Von</th>
                <th>Bis</th>
                <th>Krankmeldung</th>
              </tr>
            </thead>
            <tbody>
            {
              Object.entries(ordered).map(([key, data], ) => <tr key={key}>
                <td valign="middle">Krankheit</td>
                <td valign="middle">{ renderDate(data?.start) }</td>
                <td valign="middle">{ renderDate(data?.ende) }</td>
                <td valign="middle">
                  {
                    data?.file ? <Button onClick={() => openModalWithImage(data?.file)}><BsFileEarmarkMedical /></Button> : "Nicht vorhanden"
                  }
                  
                </td>
              </tr>)
            }
            </tbody>
          </Table> : <Alert variant="primary">Keine Daten vorhanden.</Alert>
        }
      </Card.Body>
    </Card>
    <Modal show={!!modalImageURL} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Krankmeldung</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={modalImageURL} alt="Krankmeldung" style={{ width: "100%" }} />
      </Modal.Body>
    </Modal>
    </>
  )
}

export default AbsenceUser