import { useState, useEffect } from "react";
import { Alert, Card, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../../redux/actions/database/loader";
import { useNavigate } from "react-router-dom";
import Error from "../../../APP/Messages/Error";
import Loading from "../../../APP/Messages/Loading";


const Bills = ({projectId}) => {

  const dispatch = useDispatch();
  const {invoices, invoicesLoaded, invoicesError} = useSelector(state => state.erp);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if(!invoicesLoaded) {
      dispatch(loader("invoices"));
    }
  }, [invoicesLoaded, dispatch]);

  useEffect(() => {
    if(invoicesLoaded && invoicesError === null) {
      const result = [];
      for (const i in invoices) {
        const item = invoices[i];
        if(item.projectId === projectId) {
          result.push({...item, id: i});
        }
      }
      setData(result.reverse());
    }

  }, [invoices, invoicesLoaded, invoicesError, projectId]);

  if(!invoicesLoaded) {
    return <Loading />;
  }
  if(invoicesError !== null) {
    return <Error />;
  }

  const renderTimestamp = (date) => {
    const thisDate = (date === null || date === undefined) ? new Date() : new Date(date);
    return (thisDate.getDate()).toString().padStart(2,"0") + "." + (thisDate.getMonth() + 1).toString().padStart(2,"0") + "." + thisDate.getFullYear();
  }

  return (
    <Card>
      <Card.Body>
        <p className="my-1 fw-semibold">Rechnungen</p>
        { data.length === 0 ? <Alert variant="primary">Es sind keine Rechnungen vorhanden.</Alert> :
          
          <Table hover>
            <tbody>
              {
                 data.map((doc, index) => <tr key={index} style={{cursor:"pointer"}} onClick={() => navigate("/invoices/edit/" + doc?.id)}>
                  <td style={{cursor:"pointer"}} >Rechnung { doc.doc } vom { renderTimestamp(doc.docDate) }{ (doc.status === "Verworfen" ? " (Storniert)" : "") }</td>
                  <td align="end" style={(doc.status === "Verworfen" ? {textDecoration: "line-through" ,cursor: "pointer"} : {cursor: "pointer"})}>{ (doc.SummeNetto).toLocaleString() } €</td>
                 </tr>)
              }
            </tbody>
          </Table>
         
        }
      </Card.Body>
    </Card>
  )
}

export default Bills