import {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import { defaultData } from "./defaultData";
import { saver } from "../../../redux/actions/database/saver";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../redux/actions/database/loader";
import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";
import ProjectsMini from "../../CRM/Projects/ProjectsMini";
import { updater } from "../../../redux/actions/database/updater";
import { convertDateFormat } from "../../../functions/datetime/convertDateFormat";
import History from "../../APP/History/History";

const MultiTool = ({obj = null, objId = null, mode = "detail", closeModal}) => {
  // obj = the saved object
  // objId = the ID from the object
  // mode = detail / edit / add
  // Get redux states
  const { contacts, contactsLoaded, contactsError,
          projects, projectsLoaded, projectsError,
          team,  teamLoaded, teamError
        } = useSelector(state => state.crm);
  // State for view modus
  const [showMode, setMode] = useState(mode);
  // Show Mode for History
  const [page, setPage] = useState("");
  // State for Editing active or not.
  const [editMode, setEdit] = useState(false);
  // Set old data if there is some.
  const [tasks, setTasks] = useState((obj !== null ? {...defaultData, ...obj}:{...defaultData}));
  // State to see if there are any changes.
  const [isDirty, setDirty] = useState(false);
  // State to validate the form.
  const [validated, setValidated] = useState(false);
  // State to handle the child modal for Projects or Clients.
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  // Closer for child modal
  const onHide = () => setShowModal(false);
  // Select handler from child modal
  const onSelect = id => {
    setDirty(true);
    setTasks(prev => ({...prev, projectId: id }));
    setShowModal(false);
  }
  // handle changes in the form.
  const handleChange = e => {
    setDirty(true);
    setTasks(prev => ({...prev, [e.target.name]: e.target.value }));
  }
  const handleCheckbox = e => {
    setDirty(true);
    setTasks(prev => ({...prev, [e.target.name]: e.target.checked }));
  }
  const handleReset = e => {
    if(window.confirm("Alle Eingaben verwerfen?")) {
      if(showMode === "edit") {
        setTasks({...defaultData, ...obj});
        setValidated(false);
        setDirty(false);
        setMode("detail");
      }
      else {
        setTasks({...defaultData});
        setValidated(false);
        setDirty(false);
        closeModal();
      }
    }
  }
  const handleSave = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    } else if(tasks.projectId === "") {
      setValidated(true);
      return;
    }
    const title = (c) => {
      if (c?.vorname) {
        return (c?.anrede + " " || "") + (c?.vorname + " " || "") + (c?.name);
      } else {
        return c?.name || "";
      }
    };
    const contactId = projects[tasks.projectId]?.contactId;
    const contactData = (contactId!== undefined ? {
      contactId: contactId,
      auftraggeber: title(contacts[contactId]),
    } : {});
    const architectId = projects[tasks.projectId]?.architectId;
    const architectData = (architectId !== undefined ? {
      architectId: architectId,
      architekt: title(contacts[architectId]),
    } : {});
    const dateToInteger = date => {
      if(date === "") return null;
      return parseInt(date.replaceAll("-",""));
    }
    const cleanData = {...tasks,
      status: ((tasks.datum !== "") ? "in Planung" : "offen"),
      zeitvorgabe: (tasks.ma * 1) * ((tasks.tage * 8) + (tasks.stunden * 1)),
      ...architectData,
      ...contactData,
      datumInt: dateToInteger(tasks.datum),
      projektName: (projects[tasks.projectId]?.name || null),
      projektBeschreibung: (projects[tasks.projectId]?.bezeichnung || null),
      anschrift: (projects[tasks.projectId]?.anschrift || null),
      PLZ: (projects[tasks.projectId]?.PLZ || null),
      ort: (projects[tasks.projectId]?.ort || null),
    }
    if(showMode === "edit" && objId !== null) {
      console.log(cleanData, objId, obj);
      dispatch(updater("tasks", cleanData, objId, obj));
    }
    if(showMode === "add") {
      saver("tasks",cleanData);
    }
    setTasks(cleanData)
    setDirty(false);
    setValidated(false);
    setMode("detail");
  }

  useEffect(()=>{
    if(!contactsLoaded) {
      dispatch(loader("contacts"));
    }
    if(!projectsLoaded) {
      dispatch(loader("projects"));
    }
    if(!teamLoaded) {
      dispatch(loader("team"));
    }
  },[contactsLoaded, projectsLoaded, teamLoaded, dispatch]);

  useEffect(() => {
    setEdit((showMode === "edit" || showMode === "add"));
  }, [showMode, mode]);

  if(!contactsLoaded || !projectsLoaded || !teamLoaded) {
    return <Loading />;
  } else if (contactsError !== null || projectsError !== null || teamError !== null) {
    return <Error />;
  }

  const SearchProject = () => { return (<><h6>Projektdaten</h6><Alert variant="warning" className="text-center"><p>Aufgaben müssen einem Projekt zugeordnet sein.</p><Button variant="warning" onClick={() => setShowModal(true)}>Projekt suchen</Button></Alert></>);}
  const ProjectDetail = ({id}) => {
    if(!projects.hasOwnProperty(id)) {
      return <SearchProject />;
    } else if(!contacts.hasOwnProperty(projects[id].contactId)) {
      return <SearchProject />;
    }
    const project = projects[id];
    const contact = contacts[project.contactId];
    const architect = contacts[project?.architectId];
    return (<Card className="mb-4"><Card.Body><Row>
      <Col>
        <div className="fw-semibold">Projektdaten</div>
        { (project?.name) ? project.name + " - " : null }{ project?.bezeichnung || ""}<br/>
        { project?.anschrift || null }<br/>
        { (project?.PLZ) ? project.PLZ + " " : null }{ project?.ort || null }
      </Col>
      <Col>
        <div className="fw-semibold">Auftraggeber</div>
        { contact?.name || null }<br/>
        { contact?.anschrift || null }<br/>
        { (contact?.PLZ) ? contact.PLZ + " " : null }{ contact?.ort || null }
      </Col>
      <Col>
        <div className="fw-semibold">Architekt / Planer</div>
        { architect?.name || null }<br/>
        { architect?.anschrift || null }<br/>
        { (architect?.PLZ) ? architect.PLZ + " " : null }{ architect?.ort || null }
      </Col>
    </Row>{ editMode && <Button variant="outline-primary" className="mt-2" size="sm" onClick={() => setShowModal(true)}>Ändern</Button> }</Card.Body></Card>);
  }

  if(page === "history") {
    return <History obj={{...obj, id: objId}} type="tasks" goBack={() => setPage("")} />;
  }

  return (
    <Form onSubmit={handleSave} noValidate validated={validated}>
          {
            tasks.projectId === "" && <SearchProject />
          }
          {
            tasks.projectId !== "" && <ProjectDetail id={tasks.projectId} />
          }
        <h6>Freigegebene Dokumente</h6>
        <Card className="mb-4">
          <Card.Body>
            <Row>
              <Col xs={12} md={3}>
                <h6>Zeichnungen / Pläne</h6>
                <div style={{maxHeight:"100px",overflowY:"scroll"}}>
                { editMode && <Button size="sm" variant="outline-primary">Freigeben</Button>}
                </div>
              </Col>
              <Col xs={12} md={3}>
                <h6>Materialliste</h6>
                <div style={{maxHeight:"100px",overflowY:"scroll"}}>
                { editMode && <Button size="sm" variant="outline-primary">Freigeben</Button>}
                </div>
              </Col>
              <Col xs={12} md={3}>
                <h6>Fotos / Skizzen</h6>
                <div style={{maxHeight:"100px",overflowY:"scroll"}}>
                { editMode && <Button size="sm" variant="outline-primary">Freigeben</Button>}
                </div>
              </Col>
              <Col xs={12} md={3}>
                <h6>Gefährdungsbeurteilung</h6>
                <div style={{maxHeight:"100px",overflowY:"scroll"}}>
                { editMode && <Button size="sm" variant="outline-primary">Freigeben</Button>}
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      <Row>
        <Col xs={12} md={6} lg={4}>
          <h6>Geplanter Termin</h6>
          <Row>
            <Col>
              <FormGroup>
                <Form.Text>Datum</Form.Text>
                {
                  (editMode) ? <Form.Control type="date" name="datum" value={tasks.datum} onChange={handleChange} required/>
                  : <Form.Control value={convertDateFormat(tasks.datum)} readOnly={!editMode} />
                }
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Uhrzeit</Form.Text>
                {
                  (editMode) ? <Form.Control type="time" readOnly={!editMode} name="uhrzeit" value={tasks.uhrzeit} onChange={handleChange}/>
                  : <Form.Control value={tasks.uhrzeit} readOnly={!editMode} />
                }
                
              </FormGroup>
            </Col>
          </Row>
          <h6 className="mt-4">Leistungsvorgaben</h6>
          <p>
            { ((tasks.ma * 1) * ((tasks.tage * 8) + (tasks.stunden * 1))).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") } Arbeitsstunden
          </p>
          <Row>
            <Form.Text as={Col}>Personen</Form.Text>
            <Form.Text as={Col} xs={1}>x</Form.Text>
            <Form.Text as={Col}>Tage á 8h</Form.Text>
            <Form.Text as={Col} xs={1}>+</Form.Text>
            <Form.Text as={Col}>Stunden</Form.Text>
          </Row>
          
          <InputGroup className="mb-1">
            <Form.Control type="number" disabled={!editMode} min="0" step="1" name="ma" value={tasks.ma} onChange={handleChange}/>
            <Form.Control type="number" disabled={!editMode} min="0" step="1" name="tage" value={tasks.tage} onChange={handleChange}/>
            <Form.Control type="number" disabled={!editMode} min="0" step=".25" name="stunden" value={tasks.stunden} onChange={handleChange}/>
          </InputGroup>
          
          
        </Col>
        <Col xs={12} md={6} lg={3}>
          <h6>Ansprechpartner</h6>
          <FormGroup>
            <Form.Text>Intern</Form.Text>
            <Form.Select name="ansprechpartnerIntern" disabled={!editMode} value={tasks.ansprechpartnerIntern} onChange={handleChange}>
            <option value="">{(editMode ? "Bitte wählen" : "") }</option>
            {
              Object.entries(team).map(([_, {displayName}], id) => <option key={id} value={displayName}>{displayName}</option>)
            }
            </Form.Select>
          </FormGroup>
          <FormGroup>
            <Form.Text>Extern</Form.Text>
            <Form.Select name="ansprechpartnerExtern" disabled={!editMode} value={tasks.ansprechpartnerExtern} onChange={handleChange}>
            <option value="">{(editMode ? "Bitte wählen" : "") }</option>
            {
              Object.entries(team).map(([_, {displayName}], id) => <option key={id} value={displayName}>{displayName}</option>)
            }
            </Form.Select>          </FormGroup>
          <h6 className="mt-4">Dokumentation</h6>
          <Form.Check type="checkbox" disabled={!editMode}  name="todoAufmass" checked={tasks.Aufmass} onChange={handleCheckbox} label="Aufmaß" />
          <Form.Check type="checkbox" disabled={!editMode}  name="todoAbnahme" checked={tasks.todoAbnahme} onChange={handleCheckbox} label="Abnahme / Übergabe" />
          <Form.Check type="checkbox" disabled={!editMode}  name="todoBericht" checked={tasks.todoBericht} onChange={handleCheckbox} label="Bautagesbericht" />
          
        </Col>
        <Col xs={12} md={12} lg={5}>
          <h6>Aufgaben Details</h6>
          <FormGroup>
            <Form.Text>Ort / Bauteil</Form.Text>
            <Form.Control name="bauteil" readOnly={!editMode}  value={tasks.bauteil} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Form.Text>Kurzbeschreibung</Form.Text>
            <Form.Control name="beschreibung" readOnly={!editMode}  value={tasks.beschreibung} onChange={handleChange} required />
          </FormGroup>
          <FormGroup>
            <Form.Text>Ausführliche Beschreibung</Form.Text>
            <Form.Control name="aufgabe" readOnly={!editMode}  as="textarea" rows={4} value={tasks.aufgabe} onChange={handleChange} required />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-4">
      <Col align="end">
        {
          (editMode) ? <ButtonGroup>
            {
              (showMode === "add") ? <Button onClick={handleReset} disabled={!isDirty} variant="outline-primary">Verwerfen</Button> :
              (isDirty) ? <Button onClick={handleReset} variant="outline-primary">Verwerfen</Button> :
              <Button onClick={() => setMode("detail")} variant="outline-primary">Abbrechen</Button>
            } 
            <Button type="submit" disabled={!isDirty}>Speichern</Button>
          </ButtonGroup> :
          <ButtonGroup>
            <Button onClick={() => setPage("history") } variant="outline-primary">Historie</Button>
            <Button onClick={() => setMode("edit")} variant="outline-primary">Bearbeiten</Button>
            <Button onClick={closeModal}>Schließen</Button>
          </ButtonGroup>
        }
        
      </Col>
    </Row>
    <ProjectsMini show={showModal} onHide={onHide} onSelect={onSelect} />
    </Form>
  )
}

export default MultiTool