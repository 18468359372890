export const defaultData = {

  name: "",
  anschrift: "",
  PLZ: "",
  ort: "",
  land: "",
  herkunft: "",
  projectId: "",
  contactId: "",
  kontakt: {},
  architectId: "",
  architekt: {},
  article: [],
  SummeNetto: 0,
  USt: 0.19,
  SummeUSt: 0,
  skonto: 0,
  skontoTage: 0,
  zielAR: 21,
  zielSR: 30,
  SummeBrutto: 0,
  angebotsnummer: "",
  nachtragsangebot: false,
  nachlass: 0,
  kopftext: "",
  langtext: true,
  schlusstext: "",
  skontoFrist: 14,
  skontoEinheit: "Tage",
  skontoProzent: 0,
  vergabeart: "",
  nachlassProzent: 0,
  nachlassSumme: 0,
  SummeNachlass: 0,
  angebotsTyp: "",
  leistung: "",
  los: "",
  massnahmenNummer: "",
  vergabeNummer: "",
  abgabeDatum: "",
  abgabeZeit: "",
  beginnDatum: "",
  endDatum: "",
  status: "Anfrage",
};
export const defaultStatus = [
  "Anfrage",
  "Kein Angebot"
]