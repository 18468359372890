import { useState } from "react";
import { updateDB } from "../../../../functions/database/update";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { defaultData } from "../defaultData";
import { useParams } from "react-router-dom";

const EditTitle = ({offer}) => {

  const [temp, setTemp] = useState({...defaultData, ...offer});
  const {id} = useParams();

  const handleChange = e => {
    const {name, value} = e.target;
    setTemp(prev => ({...prev, [name]: value}));
  }
  const handleSave = () => {
    updateDB("offers", {...offer, ...temp}, id, offer);
  }
  return (<>
    <Row className="mb-2">
      <Col>
        <Form.Group>
          <Form.Text>Projektbezeichnung</Form.Text>
          <Form.Control size="sm" value={temp.name} name="name" onChange={handleChange}/>
        </Form.Group>  
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Bauteil</Form.Text>
          <Form.Control size="sm" value={temp.bauteil} name="bauteil" onChange={handleChange} />
        </Form.Group>  
      </Col>
    </Row>
    <Row className="mb-4">
      <Col>
        <Form.Group>
          <Form.Text>Anschrift</Form.Text>
          <Form.Control size="sm" value={temp.anschrift} name="anschrift" onChange={handleChange}/>
        </Form.Group>  
      </Col>
      <Col>
        <Row>
          <Col xs={4}>
            <Form.Group>
              <Form.Text>PLZ</Form.Text>
              <Form.Control size="sm" value={temp.PLZ} name="PLZ" onChange={handleChange} />
            </Form.Group>  
          </Col>
          <Col>
            <Form.Group>
              <Form.Text>Ort</Form.Text>
              <Form.Control size="sm" value={temp.ort} name="ort" onChange={handleChange} />
            </Form.Group>  
          </Col>
        </Row>
      </Col>
    </Row>
    <Row className="mb-2">
      <Col>
        <Form.Group>
          <Form.Text>Abgabedatum</Form.Text>
          <Form.Control type="date" size="sm" value={temp.abgabeDatum} name="abgabeDatum" onChange={handleChange}/>
        </Form.Group>  
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Abgabe Uhrzeit</Form.Text>
          <Form.Control type="time" size="sm" value={temp.abgabeZeit} name="abgabeZeit" onChange={handleChange} />
        </Form.Group>  
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Ausführungsbeginn</Form.Text>
          <Form.Control type="date" size="sm" value={temp.beginn} name="beginn" onChange={handleChange}/>
        </Form.Group>  
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Ausführungsende</Form.Text>
          <Form.Control type="date" size="sm" value={temp.ende} min={temp.beginn} name="ende" onChange={handleChange} />
        </Form.Group>  
      </Col>
    </Row>
    <Row className="mb-2">
      <Col>
        <Form.Group>
          <Form.Text>Maßnahmennummer</Form.Text>
          <Form.Control size="sm" value={temp.massnahmenNummer} name="massnahmenNummer" onChange={handleChange}/>
        </Form.Group>  
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Vergabenummer</Form.Text>
          <Form.Control size="sm" value={temp.vergabeNummer} name="vergabeNummer" onChange={handleChange} />
        </Form.Group>  
      </Col>
    </Row>
    <Button size="sm" onClick={handleSave}>Speichern</Button>
    </>)
}

export default EditTitle;