import {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { updateDB } from "../../../../functions/database/update";
import { defaultArticle, defaultArticleTyp, defaultEinheit } from "../defaultData";
import ArticlesMini from "../../Articles/ArticlesMini";
import { ButtonGroup } from "react-bootstrap";

const digits3 = {
  style: "decimal",
  useGrouping: true,
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
  minimumIntegerDigits: 1,
  currency: undefined,
  currencyDisplay: "symbol",
  notation: "standard",
  compactDisplay: "short",
  localeMatcher: "best fit",
  numberingSystem: "latn",
  signDisplay: "auto",
  signStyle: "standard",
};
const digits2 = {...digits3, minimumFractionDigits: 2, maximumFractionDigits: 2};

const EditPosition = ({index, offer, setHelper, id}) => {

  const isDoc = (offer.hasOwnProperty("doc") && offer.doc !== "");

  const vorgaben = {
    Stundenlohn: offer.vorgaben.Stundenlohn,
    NumericStundenlohn: offer.vorgaben.NumericStundenlohn,
    StoffZuschlag: offer.vorgaben.StoffZuschlag,
    NumericStoffZuschlag: offer.vorgaben.NumericStoffZuschlag,
    GeräteZuschlag: offer.vorgaben.GeräteZuschlag,
    NumericGeräteZuschlag: offer.vorgaben.NumericGeräteZuschlag,
    SonstigeZuschlag: offer.vorgaben.SonstigeZuschlag,
    NumericSonstigeZuschlag: offer.vorgaben.NumericSonstigeZuschlag,
    FremdZuschlag: offer.vorgaben.FremdZuschlag,
    NumericFremdZuschlag: offer.vorgaben.NumericFremdZuschlag
  };

  const [article, setArticle] = useState({...defaultArticle, ...offer.articles[index], TK: {...defaultArticle.TK, ...vorgaben, ...offer.articles[index]?.TK}});
  const [showArticles, setShowArticles] = useState(false);

  useEffect(() => {
    setArticle({...defaultArticle, ...offer.articles[index], TK: {...defaultArticle.TK, ...vorgaben, ...offer.articles[index]?.TK}});
    // eslint-disable-next-line
  },[index, offer]);

  const handleChange = e => {
    const {name, value} = e.target;
    setArticle(prev => ({...prev, [name]: value}));
  }
  const handleBlur = e => {
    const {name, value} = e.target;
    const teilkosten = {...defaultArticle.TK, ...vorgaben, ...article.TK};
    if(value === "," || value === "" || value === "0") {
      teilkosten[name] = "0,000";
    } else if(value.includes(".") && value.includes(",")) {
      if(value.indexOf(".") < value.indexOf(",")) {
        const [, decimalPart] = value.split(",");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        teilkosten[name] = value + zeros;
      } else {
        const [, decimalPart] = value.split(".");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        teilkosten[name] = value + zeros;
      }
    } else if(value.includes(",")) {
      const [, decimalPart] = value.split(",");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        teilkosten[name] = value + zeros;
    } else if(value.includes(".")) {
        const [, decimalPart] = value.split(".");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        teilkosten[name] = value + zeros;
    } else if(!value.includes(",") && !value.includes(".")) {
        const [, decimalPart] = value.split(",");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        teilkosten[name] = value + "," + zeros;
    }
    setArticle(prev => ({...prev, TK: teilkosten}));
  }

  const handleNumeric = e => {
    const {name, value} = e.target;
    const newValue = value.replace(/[^0-9.,]/g, '');
    const numericName = "Numeric" + name;
    const teilkosten = {...defaultArticle.TK, ...vorgaben, ...article.TK};
    if(newValue === ",") {
      teilkosten[name] = "0,";
      teilkosten[numericName] = 0;
    } else if(newValue.includes(".") && newValue.includes(",")) {
      if(newValue.indexOf(".") < newValue.indexOf(",")) {
        teilkosten[name] = newValue;
        teilkosten[numericName] = parseFloat("0" + newValue.replace(".","").replace(",",".") + "0");
      } else {
        teilkosten[name] = newValue;
        teilkosten[numericName] = parseFloat("0" + newValue.replace(",","") + "0");
      }
    } else if(newValue.includes(",")) {
      if(newValue.indexOf(",") === 0) {
        teilkosten[name] = "0" + newValue;
      } else {
        teilkosten[name] = newValue;
      }
      teilkosten[numericName] = parseFloat("0" + newValue.replace(",",".") + "0");
    } else if(newValue.includes(".")) {
      if(newValue.indexOf(".") === 0) {
        teilkosten[name] = "0" + newValue;
      } else {
        teilkosten[name] = newValue;
      }
      teilkosten[numericName] = parseFloat("0" + newValue + "0");
    } else if(newValue==="") {
      teilkosten[name] = newValue;
      teilkosten[numericName] = 0;
    } else {
      teilkosten[name] = newValue;
      teilkosten[numericName] = parseFloat(newValue);
    }
    teilkosten.NumericLohn = (teilkosten?.NumericZeit || 0) * (teilkosten?.NumericStundenlohn || 0);
    teilkosten.NumericStoffe = (teilkosten?.NumericStoffkosten || 0) * (1 + ((teilkosten?.NumericStoffZuschlag || 0) / 100));
    teilkosten.NumericGeräte = (teilkosten?.NumericGerätekosten || 0) * (1 + ((teilkosten?.NumericGeräteZuschlag || 0) / 100));
    teilkosten.NumericSonstige = (teilkosten?.NumericSonstigekosten || 0) * (1 + ((teilkosten?.NumericSonstigeZuschlag || 0) / 100));
    teilkosten.NumericFremd = (teilkosten?.NumericFremdkosten || 0) * (1 + ((teilkosten?.NumericFremdZuschlag || 0) / 100));
    if(name!== "EP" && name!== "Menge") {
      const EP = parseFloat(((teilkosten?.NumericLohn || 0) + (teilkosten?.NumericGeräte || 0) + (teilkosten?.NumericStoffe || 0) + (teilkosten?.NumericFremd || 0) + (teilkosten?.NumericSonstige || 0)).toFixed(3));
      teilkosten.NumericEP = EP;
      teilkosten.EP = EP.toLocaleString("de-DE",digits3);
    }
    setArticle(prev => ({...prev, TK: teilkosten}));
  }
  const handleSave = (nextItem) => {
    const arr = [...offer.articles];
    arr[index] = article;
    const sum = {SummeNetto: 0, SummeUSt: 0, SummeBrutto: 0};
    for (const i in arr) {
      const item = arr[i];
      if(item.typ !== "TS" && item.typ !== "TK" && item.typ !== "BP") {
        sum.SummeNetto += (item.TK.NumericMenge * item.TK.NumericEP);
      }
    }
    sum.SummeUSt = sum.SummeNetto * 0.19;
    sum.SummeBrutto = sum.SummeNetto + sum.SummeUSt;
    if(typeof nextItem === "boolean") {
      if (index === offer.articles.length - 1) {
        arr.push(defaultArticle);
      }
    }
    updateDB("offers", {...offer, articles: arr, ...sum}, id)
    .then(() => {
      if(typeof nextItem === "boolean") {
        setHelper(index + 1);
      }
    }).catch(err => {console.error(err);});
  }
  const handleSaveAndForward = () => {
    handleSave(true);
  }
  const goForward = () => {
    if(index === offer.articles.length - 1) return;
    setHelper(index + 1);
  }
  const goBack = () => {
    if(index === 0) return;
    setHelper(index - 1);
  }

  const handleSelectArticle = (selectedArticle) => {
    if(window.confirm("Den Artikel importieren und alle vorhandenen Einträge überschreiben?")) {
      const newArticle = {...defaultArticle, ...article};
      const teilkosten = {...defaultArticle.TK, ...vorgaben, ...article.TK}
      console.log(selectedArticle);
      
      newArticle.art = (selectedArticle.art === "Normal" ? "P" : "MP");
      newArticle.einheit = selectedArticle.einheit;
      newArticle.kurztext = selectedArticle.kurztext;
      newArticle.langtext = selectedArticle.langtext;

      if(selectedArticle.art === "Mietleistung") {
       newArticle.VorhalteDauer = parseInt(selectedArticle.VorhalteDauer,10);
       newArticle.VorhalteEinheit = selectedArticle.VorhalteEinheit;
      }

      for ( const name in selectedArticle.TK ) {
        if(name.includes("Numeric") && !name.includes("EP")) {
          const stringName = name.replace("Numeric","");
          teilkosten[name] = (vorgaben.hasOwnProperty(name) && vorgaben[name] > 0) ? vorgaben[name] : selectedArticle.TK[name];
          if(teilkosten.hasOwnProperty(stringName)) teilkosten[stringName] = (vorgaben.hasOwnProperty(name) && vorgaben[name] > 0) ? vorgaben[stringName] : selectedArticle.TK[stringName];
        } else continue;
      }
      teilkosten.NumericLohn = (teilkosten?.NumericZeit || 0) * (teilkosten?.NumericStundenlohn || 0);
      teilkosten.NumericStoffe = (teilkosten?.NumericStoffkosten || 0) * (1 + ((teilkosten?.NumericStoffZuschlag || 0) / 100));
      teilkosten.NumericGeräte = (teilkosten?.NumericGerätekosten || 0) * (1 + ((teilkosten?.NumericGeräteZuschlag || 0) / 100));
      teilkosten.NumericSonstige = (teilkosten?.NumericSonstigekosten || 0) * (1 + ((teilkosten?.NumericSonstigeZuschlag || 0) / 100));
      teilkosten.NumericFremd = (teilkosten?.NumericFremdkosten || 0) * (1 + ((teilkosten?.NumericFremdZuschlag || 0) / 100));
      const EP = parseFloat(((teilkosten?.NumericLohn || 0) + (teilkosten?.NumericGeräte || 0) + (teilkosten?.NumericStoffe || 0) + (teilkosten?.NumericFremd || 0) + (teilkosten?.NumericSonstige || 0)).toFixed(3));
      teilkosten.NumericEP = EP;
      teilkosten.EP = EP.toLocaleString("de-DE",digits3);
      setArticle({...newArticle, TK: teilkosten});
    }
    setShowArticles(false);
  }
  return (
    <>
    <Row className="mb-2">
      <Col>
        <Form.Group>
          <Form.Text>Positionstyp</Form.Text>
          <Form.Control size="sm" as="select" disabled={isDoc} value={article.typ} name="typ" onChange={handleChange}>
            {
              Object.entries(defaultArticleTyp).map(([key, value], i) => <option key={i} value={key}>{value}</option>)
            }
          </Form.Control>
        </Form.Group>    
      </Col>
      <Col className="d-flex align-items-end">
        { (article.typ !== "TS" && article.typ !== "TK") && <Button disabled={isDoc} style={{flex: "1 0 0"}} size="sm" variant="outline-primary" onClick={() => setShowArticles(true)}>Artikel aus Artikelstamm einfügen</Button>}
      </Col>
    </Row>
    {
      (article.typ !== "TS") &&
      <Form.Group className="mb-2">
        <Form.Text>{ article.typ === "TK" ? "Titel Name" : "Kurztext" }</Form.Text>
        <Form.Control size="sm" disabled={isDoc} value={article.kurztext} name="kurztext" onChange={handleChange} />
      </Form.Group>   
    }
    {
      (article.typ !== "TS" && article.typ !== "TK") && <>
    
    <Form.Group className="mb-2">
      <Form.Text>Langtext</Form.Text>
      <Form.Control size="sm" disabled={isDoc} as="textarea" rows="4" value={article.langtext} name="langtext" onChange={handleChange} />
    </Form.Group>
    <Row className="mb-4">
      <Col>
        <Form.Group>
          <Form.Text>Menge</Form.Text>
          <Form.Control size="sm" className="inputEuro" disabled={isDoc} value={article.TK.Menge} name="Menge" onChange={handleNumeric} onBlur={handleBlur} />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Einheit</Form.Text>
          <Form.Control as="select" size="sm" disabled={isDoc} value={article.einheit} name="einheit" onChange={handleChange}>
            {
              defaultEinheit.map((unit, i) => <option key={i} value={unit}>{unit}</option>)
            }
            </Form.Control>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Einheitspreis</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" size="sm" disabled={offer.vorgaben?.locked || isDoc} value={article.TK.EP} name="EP" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Summe</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" readOnly disabled value={((article.TK.NumericMenge * 1) * (article.TK.NumericEP * 1)).toLocaleString("de-DE",digits3)} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
    <Row className="mb-2">
      <Col>
        <Form.Group>
          <Form.Text>Zeitansatz</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" value={article.TK.Zeit} disabled={isDoc} name="Zeit" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>h</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Verrechnungslohn</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" disabled={offer.vorgaben?.locked || isDoc} value={article.TK.Stundenlohn} name="Stundenlohn" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Lohnkosten</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" readOnly disabled value={(article.TK.NumericLohn.toLocaleString("de-DE",digits3) || 0)} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>entspricht je {article.einheit}</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" readOnly disabled value={(article.TK.NumericZeit * 60).toLocaleString("de-DE", digits2)} />
            <InputGroup.Text>Minuten</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
    <Row className="mb-2">
      <Col>
        <Form.Group>
          <Form.Text>Stoffe (Material)</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" disabled={isDoc} value={article.TK.Stoffkosten} name="Stoffkosten" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Zuschlag</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" value={article.TK.StoffZuschlag} disabled={offer.vorgaben?.locked || isDoc} name="StoffZuschlag" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Stoffkosten</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" readOnly disabled value={(article.TK.NumericStoffe.toLocaleString("de-DE",digits3) || 0)} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col />
    </Row>
    <Row className="mb-2">
      <Col>
        <Form.Group>
          <Form.Text>Geräte (Maschinen)</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" disabled={isDoc} value={article.TK.Gerätekosten} name="Gerätekosten" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Zuschlag</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" value={article.TK.GeräteZuschlag} disabled={offer.vorgaben?.locked || isDoc} name="GeräteZuschlag" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Gerätekosten</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" readOnly disabled value={(article.TK.NumericGeräte.toLocaleString("de-DE",digits3) || 0)} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col />
    </Row>
    <Row className="mb-2">
      <Col>
        <Form.Group>
          <Form.Text>Fremdleistungen</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" disabled={isDoc} value={article.TK.Fremdkosten} name="Fremdkosten" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Zuschlag</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" value={article.TK.FremdZuschlag} disabled={offer.vorgaben?.locked || isDoc} name="FremdZuschlag" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Fremdkosten</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" readOnly disabled value={(article.TK.NumericFremd.toLocaleString("de-DE",digits3) || 0)} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col />
    </Row>
    <Row className="mb-2">
      <Col>
        <Form.Group>
          <Form.Text>Sonstiges</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" disabled={isDoc} value={article.TK.Sonstigekosten} name="Sonstigekosten" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Zuschlag</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" value={article.TK.SonstigeZuschlag} disabled={offer.vorgaben?.locked || isDoc} name="SonstigeZuschlag" onChange={handleNumeric} onBlur={handleBlur} />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Text>Sonstigekosten</Form.Text>
          <InputGroup size="sm">
            <Form.Control className="inputEuro" readOnly disabled value={(article.TK.NumericSonstige.toLocaleString("de-DE",digits3) || 0)} />
            <InputGroup.Text>€</InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col />
    </Row>
    </>}
    {
      isDoc ? <ButtonGroup>
          {(index > 0) && <Button size="sm" variant="outline-primary" onClick={goBack}>Vorherige Position</Button>}
          {(index < offer.articles.length - 1) && <Button size="sm" variant="outline-primary" onClick={goForward}>Nächste Position</Button>}
        </ButtonGroup>:
    <ButtonGroup>
      <Button size="sm" variant="outline-primary" disabled={isDoc} onClick={handleSave}>Speichern</Button>
      <Button size="sm" disabled={isDoc} onClick={handleSaveAndForward}>Speichern und Weiter</Button>
    </ButtonGroup>
    }
    {
      showArticles && <ArticlesMini show={showArticles} onHide={() => setShowArticles(false)} onSelect={handleSelectArticle} />
    }
    </>
  );
}

export default EditPosition