import { getAuth, getIdTokenResult } from "firebase/auth";
import { getDatabase, ref, push, set, serverTimestamp} from "firebase/database";
import removeEmptyValues from "../../../functions/objects/removeEmptyValues";

const loadSuccess = (typ, data) => ({
  type: 'LOAD_' + typ.toUpperCase() + '_SUCCESS',
  payload: data,
});

const loadFailure = (typ, error) => ({
  type: 'LOAD_' + typ.toUpperCase() + '_FAILURE',
  error,
});

export const saver = async (target, object) => {
  const user = getAuth().currentUser;
  const claims = await getIdTokenResult(user);
  const {client, sales} = claims.claims;
  
  const databaseId = (sales === undefined) ? client : user.uid;
  if(sales === undefined && (client === null || client === undefined)) return {error: true};

  const newRef = push(ref(getDatabase(), databaseId + "/" + target));
  const cleanObject = removeEmptyValues(object);
  const timestamp = Math.floor(Date.now() / 1000);
  set(newRef, {...cleanObject, addedBy: user.uid, addedAt: serverTimestamp()})
  .then(() => {
    const data = {[newRef.key]: {...cleanObject, addedBy: user.uid, addedAt: timestamp}}
    loadSuccess(target, data);
  })
  .catch((error) => {
    loadFailure(target, error);
  });
};