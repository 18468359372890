import { useState, useEffect } from 'react';
import { loaderOnce } from '../../../functions/database/loaderOnce';
import Loading from '../../APP/Messages/Loading';
import Error from '../../APP/Messages/Error';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { BsTrash3Fill, BsPencilFill } from "react-icons/bs";
import { defaultData } from './defaultData';
import { removeItem } from '../../../functions/database/removeItem';
import { historPush, saveDB } from '../../../functions/database/save';
import { updateDB } from '../../../functions/database/update';


const PersonsList = ({contactId = null}) => {

  const [persons, setPersons] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [addNew, setAddNew] = useState(false);
  
  useEffect(() => {
    loaderOnce("persons", "contactId", contactId)
    .then((persons) => {
      setPersons((persons.result === null ? {} : persons.result));
      setLoaded(true);
    })
    .catch((err) => {
      setLoaded(true);
      setError(err);
    });
  }, [contactId]);

  const handleDelete = person => {
    const {id, data} = person;
    const obj = {...persons};
    delete obj[id];
    removeItem("persons",id)
      .then(() => {
        setPersons({...obj});
        const displayName = ((data?.vorname) ? data.vorname + " ":"") +""+ data?.nachname;
        if(contactId!==null) historPush([{key: "Ansprechpartner", before: displayName}], contactId, "contacts");
      })
      .catch(err => console.error(err.message));
  }
  const addPerson = obj => {
    setPersons(prev => ({...prev, ...obj}));
  }
  if(!loaded) {
    return <Loading />;
  }
  else if(error !== null) {
    return <Error />;
  }
  
  const sortedPersons = Object.entries(persons).sort(([, a], [, b]) => a.nachname.localeCompare(b.nachname));


  return (
    <ListGroup>
    {
      sortedPersons.map(([key, person], _) => <ListGroup.Item key={key}>
        <PersonElement person={person} personId={key} handleDelete={handleDelete} addPerson={addPerson}/>
      </ListGroup.Item>)
    }
    <ListGroup.Item>
      
      {
        (addNew) ?
        <AddNewPerson contactId={contactId} handleClose={() => setAddNew(false)} addPerson={addPerson}/>
        :
        <Row>
          <Col align="end"><Button size="sm" variant="outline-primary" onClick={() => setAddNew(true)}>Neu hinzufügen</Button></Col>
        </Row>
      }
    </ListGroup.Item>
    </ListGroup>
  );
}

const PersonElement = ({personId = null, person = {}, handleDelete, addPerson}) => {
  
  const [edit, setEdit] = useState(false);

  const handleDel = () => {
    if(window.confirm(person.vorname + " " + person.nachname + " endgültig löschen?")) {
      handleDelete({id: personId, data: person});
    }
    return;
  }
  if(!edit) {
    return (<><Row>
      <Col className="fw-semibold" valign="middle">
        { person?.vorname ? person.vorname + " " : "" }{ person?.nachname || ""}
      </Col>
      <Col align="end">
        <ButtonGroup>
          <Button size="sm" variant="outline-danger" onClick={handleDel}><BsTrash3Fill /></Button>
          <Button size="sm" variant="outline-primary" onClick={() => setEdit(true)}><BsPencilFill /></Button>
        </ButtonGroup>
      </Col>
    </Row>
    { person?.funktion ? <>{person.funktion}<br/></>: "" }
    { person?.mobil ? <>Mobil: <a href={"tel:"+person.mobil}>{person.mobil}</a><br/></>: "" }
    { person?.telefon ? <>Telefon: <a href={"tel:"+person.telefon}>{person.telefon}</a><br/></>: "" }
    { person?.email ? <>E-Mail: <a href={"mailto:"+person.email}>{person.email}</a><br/></>: "" }
    </>);
  }
  return(
    <AddNewPerson handleClose={() => setEdit(false)} personData={person} personId={personId} addPerson={addPerson}/>
  )

}

const AddNewPerson = ({contactId = null, handleClose, addPerson, personData = {}, personId = null}) => {
  
  const [person, setPerson] = useState({...defaultData, ...personData});
  const [validated, setValidated] = useState(false);
  const [isDirty, setDirty] = useState(false);

  const handleChange = e => {
    setPerson(prev => ({...prev, [e.target.name]: e.target.value}));
    setDirty(true);
  }
  const handleReset = e => {
    e.preventDefault();
    e.stopPropagation();
    if((personId !== null && window.confirm("Alle Änderungen verwerfen?")) ||
       (personId === null && window.confirm("Alle Eingaben verwerfen?"))) {
      setPerson(defaultData);
      setDirty(false);
      handleClose();
    }
    return;
  }
  const handleSave = e => {
    console.log(e);
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    if(contactId !== null) {
      // New Contact
      const data = {...person, contactId: contactId};
      saveDB("persons", data)
      .then(obj => {
        if(!obj.error) {
          const displayName = ((obj.obj?.vorname) ? obj.obj.vorname + " ":"") +""+ obj.obj?.nachname;
          if (contactId!==null) {
            historPush([{key: "Ansprechpartner", after: displayName}], contactId, "contacts");
          }
          setPerson(defaultData);
          setDirty(false);
          setValidated(false);
          addPerson({[obj.key]: obj.obj});
          handleClose();
        }
      })
      .catch(err => console.error(err));
    } else if(personId !== null) {
      // Existing Person
      updateDB("persons", person, personId, personData)
      .then(obj => {
        if(!obj.error) {
          setDirty(false);
          setValidated(false);
          addPerson({[obj.key]: obj.obj});
          handleClose();
        }
      })
      .catch(err => console.error(err));
    } else return;
    
  }

  if(contactId === null && personId === null) { return <Error />; }

  return (<Form onSubmit={handleSave} noValidate validated={validated}>
    <Row>
      <Col>
        <FormGroup>
          <Form.Text>Anrede</Form.Text>
          <Form.Select name="anrede" value={person.anrede} onChange={handleChange}>
            <option value="">Anrede</option>
            <option value="Herr">Herr</option>
            <option value="Frau">Frau</option>
          </Form.Select>
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Form.Text>Vorname</Form.Text>
          <Form.Control name="vorname" value={person.vorname} onChange={handleChange} required/>
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col>
        <FormGroup>
          <Form.Text>Nachname</Form.Text>
          <Form.Control name="nachname" value={person.nachname} onChange={handleChange} required/>
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Form.Text>Funktion</Form.Text>
          <Form.Control name="funktion" value={person.funktion} onChange={handleChange} required/>
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col>
        <FormGroup>
          <Form.Text>Mobil</Form.Text>
          <Form.Control name="mobil" value={person.mobil} onChange={handleChange}/>
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Form.Text>Telefon</Form.Text>
          <Form.Control name="telefon" value={person.telefon} onChange={handleChange}/>
        </FormGroup>
      </Col>
    </Row>
    <FormGroup>
      <Form.Text>E-Mail</Form.Text>
      <Form.Control name="email" value={person.email} onChange={handleChange}/>
    </FormGroup>
    <Row className="mt-3">
      <Col align="end">
        <ButtonGroup>
          <Button size="sm" onClick={handleReset} variant="outline-primary">Verwerfen</Button>
          <Button size="sm" type="submit" disabled={!isDirty} variant="primary">Speichern</Button>
        </ButtonGroup>
      </Col>
    </Row>
  </Form>)
}

export default PersonsList