import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
//import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { store } from './redux/store';
//import { auth } from './config/firebase';
import reportWebVitals from './functions/reportWebVitals';
import AutoLogin from './pages/autoLogin/AutoLogin';

import './styles';
import './styles/custom.scss';

//console.log(auth.currentUser);
//const router = (auth.currentUser !== null ) ? createBrowserRouter(privateRoutes) : createBrowserRouter(publicRoutes);
// App contains only public routes
// const router = createBrowserRouter(publicRoutes);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <HelmetProvider>
      <Provider store={store}>
        <AutoLogin />
      </Provider>
    </HelmetProvider>
);

// Remove the following line, if you don't want to share WebVitals with Google Analytics
reportWebVitals();