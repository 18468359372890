import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { defaultArticle } from "../defaultData";
import { updateDB } from "../../../../functions/database/update";
import { useSelector } from "react-redux";

const EditSettings = ({offer, id}) => {

  const {settings} = useSelector(state => state.crm);
  const {rights, sales} = useSelector(state => state.auth.tokens);
  const [temp, setTemp] = useState({...offer, vorgaben:{...settings?.kalkulation, ...offer.vorgaben}});

  const handleRootSave = () => {
    if(window.confirm("Die Daten in den Stammdaten speichern?\nDiese Werte gelten dann für alle künftigen Kalkulationen.")) {
      updateDB("root/settings", {...temp.vorgaben, Nachlass: null, NumericNachlass: null}, "kalkulation");
    }
  }

  const handleBlur = e => {
    const {name, value} = e.target;
    const teilkosten = {...defaultArticle.vorgaben, ...temp.vorgaben};
    if(value === "," || value === "" || value === "0") {
      teilkosten[name] = "0,000";
    } else if(value.includes(".") && value.includes(",")) {
      if(value.indexOf(".") < value.indexOf(",")) {
        const [, decimalPart] = value.split(",");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        teilkosten[name] = value + zeros;
      } else {
        const [, decimalPart] = value.split(".");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        teilkosten[name] = value + zeros;
      }
    } else if(value.includes(",")) {
      const [, decimalPart] = value.split(",");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        teilkosten[name] = value + zeros;
    } else if(value.includes(".")) {
        const [, decimalPart] = value.split(".");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        teilkosten[name] = value + zeros;
    } else if(!value.includes(",") && !value.includes(".")) {
        const [, decimalPart] = value.split(",");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        teilkosten[name] = value + "," + zeros;
    }
    setTemp(prev => ({...prev, vorgaben: teilkosten}));
  }

  const handleNumeric = e => {
    const {name, value} = e.target;
    const newValue = value.replace(/[^0-9.,]/g, '');
    const numericName = "Numeric" + name;
    const teilkosten = {...defaultArticle.vorgaben, ...temp.vorgaben};
    if(newValue === ",") {
      teilkosten[name] = "0,";
      teilkosten[numericName] = 0;
    } else if(newValue.includes(".") && newValue.includes(",")) {
      if(newValue.indexOf(".") < newValue.indexOf(",")) {
        teilkosten[name] = newValue;
        teilkosten[numericName] = parseFloat("0" + newValue.replace(".","").replace(",",".") + "0");
      } else {
        teilkosten[name] = newValue;
        teilkosten[numericName] = parseFloat("0" + newValue.replace(",","") + "0");
      }
    } else if(newValue.includes(",")) {
      if(newValue.indexOf(",") === 0) {
        teilkosten[name] = "0" + newValue;
      } else {
        teilkosten[name] = newValue;
      }
      teilkosten[numericName] = parseFloat("0" + newValue.replace(",",".") + "0");
    } else if(newValue.includes(".")) {
      if(newValue.indexOf(".") === 0) {
        teilkosten[name] = "0" + newValue;
      } else {
        teilkosten[name] = newValue;
      }
      teilkosten[numericName] = parseFloat("0" + newValue + "0");
    } else if(newValue==="") {
      teilkosten[name] = newValue;
      teilkosten[numericName] = 0;
    } else {
      teilkosten[name] = newValue;
      teilkosten[numericName] = parseFloat(newValue);
    }
    setTemp(prev => ({...prev, vorgaben: teilkosten}));
  }

  const handleChange = e => {
    const {name, value} = e.target;
    const cleanValue = value.replace(/[^0-9]/g, '');
    const numericName = "Numeric" + name;
    const obj = {...defaultArticle.vorgaben, ...temp.vorgaben};
    obj[numericName] = parseInt(cleanValue, 10);
    obj[name] = parseInt(cleanValue, 10);
    setTemp(prev => ({...prev, vorgaben: obj}));
  }
  const handleBooleanChange = e => {
    const {name, value} = e.target;
    const obj = {...defaultArticle.vorgaben, ...temp.vorgaben};
    obj[name] = (value === "true" || value === true);
    setTemp(prev => ({...prev, vorgaben: obj}));
  }

  const handleSave = () => {
    updateDB("offers", {...temp}, id);
  }

  return (
    <div>
      <p className="m-0" style={{fontSize:"0.875rem"}}>Änderungen die hier vorgenommen werden, haben Auswirkungen auf das gesamte Angebot. Es wird empfohlen diese Einstellungen vor Beginn der Kalkulation festzulegen.</p>
      <p className="fw-semibold mt-4 mb-2">Kurztext oder Langtext?</p>
      <Form.Check type="switch" style={{fontSize:"0.875rem"}}
        id="custom-switch"
        checked={temp.langtext} onChange={() => setTemp(prev => ({...prev, langtext: !temp.langtext}))}
        label={"Die Artikelbeschreibung wird als " + (temp.langtext ? "Langtext" : "Kurztext") + " angezeigt."} />
      <p className="fw-semibold mt-4 mb-2">Kalkulationsvorgaben</p>
      <p className="m-0" style={{fontSize:"0.875rem"}}>Wird nicht auf bereits im Angebot vorhandene Positionen angewandt. Zuschläge werden auch auf importierte Artikel angewandt. Jeder Artikel kann trotzdem abweichend kalkuliert werden.</p>
      <Row>
        <Col>
          <Form.Group className="mb-2">
            <Form.Text>Stundenlohn</Form.Text>
            <InputGroup size="sm">
              <Form.Control value={temp.vorgaben.Stundenlohn} name="Stundenlohn" size="sm" className="inputEuro" onChange={handleNumeric} onBlur={handleBlur} />
              <InputGroup.Text>€</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Text>Zuschlag auf Stoffe/Materialien</Form.Text>
            <InputGroup size="sm">
              <Form.Control value={temp.vorgaben.StoffZuschlag} name="StoffZuschlag" size="sm" className="inputEuro" onChange={handleNumeric} onBlur={handleBlur} />
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Text>Zuschlag auf Geräte/Maschinen</Form.Text>
            <InputGroup size="sm">
              <Form.Control value={temp.vorgaben.GeräteZuschlag} name="GeräteZuschlag" size="sm" className="inputEuro" onChange={handleNumeric} onBlur={handleBlur} />
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-2">
            <Form.Text>Zuschlag auf Fremdleistungen</Form.Text>
            <InputGroup size="sm">
              <Form.Control value={temp.vorgaben.FremdZuschlag} name="FremdZuschlag" size="sm" className="inputEuro" onChange={handleNumeric} onBlur={handleBlur} />
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Text>Zuschlag auf Sonstige Kosten</Form.Text>
            <InputGroup size="sm">
              <Form.Control value={temp.vorgaben.SonstigeZuschlag} name="SonstigeZuschlag" size="sm" className="inputEuro" onChange={handleNumeric} onBlur={handleBlur} />
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Text>Abweichende Werte in Artikeln zulassen?</Form.Text>
            <div className="mt-1">
              <Form.Check type="radio" name="locked" onChange={handleBooleanChange} value={false} checked={temp.vorgaben.locked !== true} inline label="Ja" size="sm"/>
              <Form.Check type="radio" name="locked" onChange={handleBooleanChange} value={true} checked={temp.vorgaben.locked === true} inline label="Nein" size="sm"/>
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Button size="sm" onClick={handleSave}>Alles Speichern</Button>
      <p className="fw-semibold mt-4 mb-2">Zahlungsbedingungen</p>
      <Row>
        <Col>
          <Form.Group className="mb-2">
            <Form.Text>Fälligkeit Abschlagsrechnung</Form.Text>
            <InputGroup size="sm">
              <Form.Control value={temp.vorgaben.ZielAR} name="ZielAR" size="sm" className="inputEuro" onChange={handleChange} />
              <InputGroup.Text>Tage</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-2">
            <Form.Text>Fälligkeit Schlussrechnung</Form.Text>
            <InputGroup size="sm">
              <Form.Control value={temp.vorgaben.ZielSR} name="ZielSR" size="sm" className="inputEuro" onChange={handleChange} />
              <InputGroup.Text>Tage</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-2">
            <Form.Text>Skonto</Form.Text>
            <InputGroup size="sm">
              <Form.Control value={temp.vorgaben.Skonto} name="Skonto" size="sm" className="inputEuro" onChange={handleNumeric} onBlur={handleBlur} />
              <InputGroup.Text>% innerhalb von</InputGroup.Text>
              <Form.Control value={temp.vorgaben.SkontoTage} name="SkontoTage" size="sm" className="inputEuro" onChange={handleChange} />
              <InputGroup.Text>Tagen</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-2">
            <Form.Text>Nachlass</Form.Text>
            <InputGroup size="sm">
              <Form.Control value={temp.vorgaben.Nachlass} name="Nachlass" size="sm" className="inputEuro" onChange={handleNumeric} onBlur={handleBlur} />
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button size="sm" onClick={handleSave}>Alles Speichern</Button>  
        </Col>
        <Col align="end">
          {
            (rights.css > 3 || sales) && <Button size="sm" variant="outline-primary" onClick={handleRootSave}>In den Stammdaten speichern</Button>  
          }
        </Col>
      </Row>
    </div>
  )
}

export default EditSettings