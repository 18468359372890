import { getIdTokenResult, getAuth } from "firebase/auth";
import { getDatabase, ref, push, set, serverTimestamp} from "firebase/database";
import removeEmptyValues from "../objects/removeEmptyValues";

export const pushCloudMail = async (object) => {
  
  const user = getAuth().currentUser;
  const claims = await getIdTokenResult(user);

  const {client, sales} = claims.claims;
  
  const databaseId = (sales === undefined) ? client : user.uid;
  if(sales === undefined && (client === null || client === undefined)) return {error: true};

  return new Promise((resolve, reject) => {  
    if(databaseId === user.uid) {
      resolve();
    }
    const newRef = push(ref(getDatabase(), "email"));
    const cleanObject = removeEmptyValues(object);
    set(newRef, {...cleanObject, addedBy: user.uid, customer: databaseId, addedAt: serverTimestamp()})
    .then(() => resolve())
    .catch(err => reject({error: true, message: err.message}));
  });
};
