import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../../../redux/actions/database/loader";
import Loading from "../../../APP/Messages/Loading";
import Error from "../../../APP/Messages/Error";
import Offer from "./Offer";
import { Link, useNavigate, useParams } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { updateDB } from "../../../../functions/database/update";
import { createmeasurmentPDF } from "../../../../functions/pdf/measurment";

const Create = () => {

  const {measurements, measurementsLoaded, measurementsError} = useSelector(state => state.erp);
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if(!measurementsLoaded) {
      dispatch(loader("measurements"));
    }
  }, [measurementsLoaded, dispatch]);

  if(!measurementsLoaded) { return <Loading />; }
  else if (measurementsError !== null) { return <Error />; }

  const data = (measurements !== null && measurements.hasOwnProperty(id)) ? measurements[id] : {};

  if (data === null) { return <Error />; }

  const handleLockDoc = () => {
    if(window.confirm("Das Dokument wird abgeschlossen und kann nicht mehr verändert werden.\n\nFortfahren?")) {
      updateDB("measurements/", {status: "Fertig"} ,id);
    }
  }

  const openFile = async () => {
    createmeasurmentPDF(data)
    .then((data) => {
      const blob = new Blob([data.pdf], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    })
  }
  const downloadFile = async (obj) => {
    createmeasurmentPDF(data)
    .then((data) => {
      const blob = new Blob([data.pdf], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = data.name + '.pdf';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  };
  const handleCreateInvoices = () => {
    navigate("/invoices/newinvoice/"+data.projectId);
    // create DocNumber and proceed to invoice
    // Add Bill from the articles inside
    // Show Dialog to proceed to billing
  }


  return (
    <Row>
      <Col xs={12} className="mb2">
        <h4 className="pageTitle my-3">Aufmaß</h4>
      </Col>
      <Col xs={12} lg={3}>
        <Row style={{position: "sticky", top:"100px"}}>
          <Col xs={12} md={4} lg={12}>
            <Card className="mb-2">
              <Card.Body>
                <p className="fw-semibold mb-2">Projektdaten</p>
                { data?.name ? <>{data.name}<br/></> : ""}
                { data?.bauteil ? <>Bauteil: {data.anschrift}<br/></> : ""}
                { data?.anschrift ? <>{data.anschrift}<br/></> : ""}
                { data?.PLZ ? data.PLZ + " " : ""}
                { data?.ort ? <>{data.ort}<br/></> : ""}
                <br/><br/>
                {
                  data.status === "Offen" && <><Link onClick={handleLockDoc}>Aufmaß fertigstellen</Link><br/></>
                }
                {
                  data.status !== "Offen" && <><Link onClick={openFile}>Aufmaß PDF öffnen</Link><br/>
                                                <Link onClick={downloadFile}>Aufmaß herunterladen</Link><br/></>
                }
                {
                  data.status === "Fertig" && <Link onClick={handleCreateInvoices}>In Rechnung stellen</Link>
                }
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} lg={12}>
            {
              data.hasOwnProperty("contact") &&
            <Card className="mb-2">
              <Card.Body>
                <p className="fw-semibold mb-2">Bauherr / Auftraggeber</p>
                { data.contact?.vorname ? data.contact.vorname + " " : "" }
                { data.contact?.name || ""}<br/>
                { data.contact?.anschrift || "" }<br/>
                { data.contact?.PLZ ? data.contact.PLZ + " " : ""}
                { data.contact?.ort || ""}<br/>
                { data.contact?.telefon ? "Telefon: " + data.contact.telefon : ""}
              </Card.Body>
            </Card>
            }
          </Col>
          <Col xs={12} md={4} lg={12}>
            {
              data.hasOwnProperty("architect") &&
            <Card className="mb-2">
              <Card.Body>
                <p className="fw-semibold mb-2">Architekt / Planer</p>
                { data.architect?.vorname ? data.architect.vorname + " " : "" }
                { data.architect?.name || ""}<br/>
                { data.architect?.anschrift || "" }<br/>
                { data.architect?.PLZ ? data.architect.PLZ + " " : ""}
                { data.architect?.ort || ""}<br/>
                { data.architect?.telefon ? "Telefon: " + data.architect.telefon : ""}
              </Card.Body>
            </Card>
            }
          </Col>
        </Row>
      </Col>
      <Col style={{overflowY: "auto"}}>
        <Card>
          <Card.Body className="px-0 py-2" style={{overflow:"hidden"}}>
        {
          (data.hasOwnProperty("offers")) && Object.entries(data.offers).map(([offerId, offerData], index) => <Offer status={data.status} offerData={offerData} offerId={offerId} offerIndex={index} id={id} key={index}/>)
        }
        </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default Create