import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import ListGroup from "react-bootstrap/ListGroup";
import "./menu.css";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/auth/logout";
import { useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import Verwalten from "./Menu/Verwalten";
import Planen from "./Menu/Planen";
import Rechnen from "./Menu/Rechnen";
import Auswerten from "./Menu/Auswerten";
import MeinBereich from "./Menu/MeinBereich";
import WerkAPP from "./Menu/WerkAPP";

const Menu = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const companyName = useSelector(state => state.crm.settings.companyName);
  const {tokens} = useSelector(state => state.auth);
  const {rights, sales} = tokens;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSignOut = () => {
    dispatch(logout());
  }
  useEffect(() => {
    handleClose();
  }, [location]);

/*

      <NavLink className="menuLogo" to="/">
        Werk<span>APP</span>
        <p className="menuLogoSubtitle">{ sales? displayName + " | DEMO" : companyName }</p>
      </NavLink>
      <div className="menuLinks">
        <WerkAPP />
      </div>
*/

  return (
    <>
    <div className="menu">
      <WerkAPP />
      <div className="menuLinks">
        <Verwalten />
        <Rechnen />
        <Planen />
        { //<Auswerten />
        }
        <MeinBereich />
        <Button variant="outline-danger" size="sm" style={{borderRadius:"50px"}} onClick={handleSignOut}>Beenden</Button>
      </div>

      <Button className="mobileMenuBtn" variant="outline-primary" size="sm" style={{borderRadius:"50px"}} onClick={handleShow}>Menü</Button>

    </div>
    <Offcanvas show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <NavLink className="menuLogo" to="/">
          Werk<span>APP</span>
          <p className="menuLogoSubtitle">{companyName}</p>
        </NavLink>
      </Offcanvas.Header>
      <Offcanvas.Body style={{display: "flex", flexDirection: "column",justifyContent: 'space-between'}}>
        <Accordion className="mt-4">
        <Accordion.Item eventKey="werkapp">
            <Accordion.Header>WerkAPP</Accordion.Header>
            <Accordion.Body style={{padding:0}}>
            <ListGroup variant="flush">
            <ListGroup.Item style={{paddingLeft:"2rem"}} action as={NavLink} to="/">Dashboard</ListGroup.Item>
            <ListGroup.Item style={{paddingLeft:"2rem"}} action as={NavLink} to="/products">WerkAPP Module</ListGroup.Item>
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("wam") && rights["wam"] > 0)) &&
                <ListGroup.Item style={{paddingLeft:"2rem"}} action as={NavLink} to="/modules">Lizenzverwaltung</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("wam") && rights["wam"] > 0)) &&
                <ListGroup.Item style={{paddingLeft:"2rem"}} action as={NavLink} to="/billing">Monatsrechnungen</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("cur") && rights["cur"] > 0)) &&
                <ListGroup.Item style={{paddingLeft:"2rem"}} action as={NavLink} to="/users">Benutzer und Rechte</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("css") && rights["css"] > 0)) &&
                <ListGroup.Item style={{paddingLeft:"2rem"}} action as={NavLink} to="/settings">Stammdaten</ListGroup.Item>
              }
            </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="verwalten">
            <Accordion.Header>Verwalten</Accordion.Header>
            <Accordion.Body style={{padding:0}}>
            <ListGroup variant="flush">
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("crm") && rights["crm"] > 0)) &&
                <ListGroup.Item style={{paddingLeft:"2rem"}} action as={NavLink} to="/contacts">Kontakte</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("cpm") && rights["cpm"] > 0)) &&
                <ListGroup.Item style={{paddingLeft:"2rem"}} action as={NavLink} to="/projects">Projekte</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("dsm") && rights["dsm"] > 0)) &&
                <ListGroup.Item style={{paddingLeft:"2rem"}} action as={NavLink} to="/staff">Mitarbeiter</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("eam") && rights["eam"] > 0)) &&
                <ListGroup.Item style={{paddingLeft:"2rem"}} action as={NavLink} to="/articles">Artikel & Leistungen</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("erm") && rights["erm"] > 0)) &&
                <ListGroup.Item style={{paddingLeft:"2rem"}} action as={NavLink} to="/leads">Anfragen erfassen</ListGroup.Item>
              }
            </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="rechnen">
            <Accordion.Header>Rechnen</Accordion.Header>
            <Accordion.Body style={{padding:0}}>
            <ListGroup variant="flush">
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("eom") && rights["eom"] > 0)) &&
                <ListGroup.Item action as={NavLink} to="/offers">Angebote</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("emm") && rights["emm"] > 0)) &&
                <ListGroup.Item action as={NavLink} to="/measurments">Aufmaße</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("bill") && rights["bill"] > 0)) &&
                <ListGroup.Item action as={NavLink} to="/invoices">Rechnungen</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("pay") && rights["pay"] > 0) || (rights.hasOwnProperty("bill") && rights["bill"] > 0)) &&
                <ListGroup.Item action as={NavLink} to="/payments">Offene Forderungen</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("ovd") && rights["ovd"] > 0)) &&
                <ListGroup.Item action as={NavLink} to="/overdue">Mahnwesen</ListGroup.Item>
              }
            </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="planen">
            <Accordion.Header>Planen</Accordion.Header>
            <Accordion.Body style={{padding:0}}>
            <ListGroup variant="flush">
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("dpd") && rights["dpd"] > 0)) &&
                <ListGroup.Item action as={NavLink} to="/tasks">Aufgabenplanung</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("dpd") && rights["dpd"] > 0)) &&
                <ListGroup.Item action as={NavLink} to="/dispoboard">Projekt Disposition</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("dvp") && rights["dvp"] > 0)) &&
                <ListGroup.Item action as={NavLink} to="/absence">Urlaubsplanung & Abwesenheit</ListGroup.Item>
              }
            </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          { /*
          <Accordion.Item eventKey="auswerten">
            <Accordion.Header>Auswerten</Accordion.Header>
            <Accordion.Body style={{padding:0}}>
            <ListGroup variant="flush">
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("crm") && rights["crm"] > 0)) &&
                <ListGroup.Item action as={NavLink} to="/contacts">Kontakte</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("cpm") && rights["cpm"] > 0)) &&
                <ListGroup.Item action as={NavLink} to="/projects">Projekte</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("dsm") && rights["dsm"] > 0)) &&
                <ListGroup.Item action as={NavLink} to="/staff">Mitarbeiter</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("eam") && rights["eam"] > 0)) &&
                <ListGroup.Item action as={NavLink} to="/articles">Artikel & Leistungen</ListGroup.Item>
              }
              {
                ((sales!==undefined && sales === true) || (rights.hasOwnProperty("eqm") && rights["eqm"] > 0)) &&
                <ListGroup.Item action as={NavLink} to="/leads">Anfragen erfassen</ListGroup.Item>
              }
            </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
            */ }
          <Accordion.Item eventKey="meinbereich">
            <Accordion.Header>Mein Bereich</Accordion.Header>
            <Accordion.Body style={{padding:0}}>
            <ListGroup variant="flush">
              {/*
              <ListGroup.Item action as={NavLink} to="/mysettings/details">Persönliche Einstellungen</ListGroup.Item>
              <ListGroup.Item action as={NavLink} to="/mysettings/security">Sicherheitseinstellungen</ListGroup.Item>
              */}
              <ListGroup.Item action as={NavLink} to="/user/vacation">Meine Urlaubsanträge</ListGroup.Item>
              <ListGroup.Item action as={NavLink} to="/user/absence">Krankmeldung</ListGroup.Item>
            </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Button variant="danger" style={{borderRadius:"50px"}} className="mb-4" onClick={handleSignOut}>Beenden</Button>
      </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default Menu