import { store } from "../../redux/store";
const { PDFDocument, StandardFonts } = require("pdf-lib");

const digits3 = {
  style: "decimal",
  useGrouping: true,
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
  minimumIntegerDigits: 1,
  currency: undefined,
  currencyDisplay: "symbol",
  notation: "standard",
  compactDisplay: "short",
  localeMatcher: "best fit",
  numberingSystem: "latn",
  signDisplay: "auto",
  signStyle: "standard",
};
const digits2 = {...digits3, minimumFractionDigits: 2, maximumFractionDigits: 2};


export async function createBusinessLetterPDF(obj, id, typ, docId = null, docDate = null, blank = false) {
  const { crm, auth } = store.getState();
  const { settings } = crm;
  const documentNameId = typ.charAt(0).toUpperCase() + typ.slice(1) + ": " + docId;
  const mm = (1 / .35);
  const A4 = [595.28, 841.89];
  const pdf = await PDFDocument.create();
  const doc = {
    width: A4[0],
    height: A4[1],
    margin: {
      left: 20,
      right: 10,
      top: 25,
      bottom: 28,
    },
    fontSize: 10,
    lineHeight: 11,
    pos: 20 * mm,
    price: 25 * mm,
    font: {
      normal: await pdf.embedFont(StandardFonts.Helvetica),
      bold: await pdf.embedFont(StandardFonts.HelveticaBold),
    }
  };
  // Set CM to PX
  for ( const size in doc.margin ) {
    doc.margin[size] = doc.margin[size] * mm;
  }

  const getObjHeight = (text, lineHeight, maxWidth = doc.width) => {
    if( text.includes("\n") ) {
      const lines = text.split("\n");
      let result = 0;
      lines.forEach((line) => {
        result += getObjHeight(line, lineHeight, maxWidth);
      })
      return result;
    }
    else {
      let lines = 1;
      let col = "";
      const words = text.split(" ");
      words.forEach((word, index) => {
        col = (col === "" ? word : col + " " + word);
        const width = doc.font.normal.widthOfTextAtSize(col, lineHeight);
        if(width > maxWidth) {
          if(index < words.length) {
          lines++;
          col = "";
          }
        }
      });
      return lines * lineHeight
    }
  }
  const parseLineBreaks = (text, font = doc.font.normal, fontSize = 10, maxWidth = (doc.width - doc.margin.left - doc.margin.right)) => {
    if( text.includes("\n") ) {
      const lines = text.split("\n");
      let result = [];
      let rows = result.length + 1;
      lines.forEach((line) => {
        const r = parseLineBreaks(line, font, fontSize, maxWidth);
        result.push(r.parsedText);
        rows += r.rows;
      });
      return {parsedText: result.join("\n"), rows: rows};
    }
    else {
      const words = text.split(" ");
      let row = "";
      let result = "";
      let rows = 1;
      words.forEach((word) => {
        const tryRow = row + word + " ";
        const rowWidth = font.widthOfTextAtSize(tryRow, fontSize);
        if(rowWidth > maxWidth) {
          rows++;
          result = (result === "" ? row : result + "\n" + row);
          row = word + " ";
          rows++;
        } else {
          row = tryRow;
        }
      });
      if ( rows === 1 ) result = row;
      if ( rows > 1 && row !== "") result = result + "\n" + row;
      return {parsedText: result, rows: rows};
    }
  }
  const splitTextOverPages = (text, lineHeight, residualHeight, maxHeight) => {
    const rows = text.split("\n");
    if ( (rows.length * lineHeight) < residualHeight ) {
      return {breaks: false};
    }
    else {
      let part = "";
      let breaks = 0;
      let result = [];
      let counter = 0;
      rows.forEach((row) => {
        part = (part === "") ? row : part + "\n" + row;
        counter++;
        if((breaks === 0 && (counter * lineHeight ) > residualHeight) || (breaks > 0 && (counter * lineHeight) > maxHeight)) {
          result.push(part);
          part = "";
          counter = 0;
          breaks++;
        }
      });
      if (part !== "") result.push(part);
      return {breaks: true, text: result};
    }
  }
    
  // Faltmarken hinzufügen
  const addFoldmarks = (thisPage) => {
    thisPage.drawLine({
      start: {x: 4 * mm, y: doc.height - (105 * mm)},
      end: {x: (8 * mm), y: doc.height - (105 * mm)},
      thickness: .5,
    });
    thisPage.drawLine({
      start: {x: 4 * mm, y: doc.height - (210 * mm)},
      end: {x: (8 * mm), y: doc.height - (210 * mm)},
      thickness: .5,
    });
    thisPage.drawLine({
      start: {x: 0, y: doc.height / 2},
      end: {x: (8 * mm), y: doc.height / 2},
      thickness: .75,
    });
  }
  const addFooter = (thisPage) => {
    thisPage.drawLine({
      start: {x: 20 * mm, y: 20.5 * mm},
      end: {x: doc.width - doc.margin.right, y: 20.5 * mm},
      thickness: .5,
    });

    let footerString = [];
    if(!settings.settings.briefpapier.hasOwnProperty("footer") || settings.settings.briefpapier.footer === null) {
      footerString = [{textAlign: "center", text: settings?.companyName
                  + (settings?.anschrift ? " | " + settings.anschrift : "")
                  + (settings?.PLZ ? " | " + settings.PLZ + " " : "")
                  + (settings?.ort ? settings.ort : "")}];
    } else {
      footerString = Object.values(settings.settings.briefpapier?.footer || {});
    }
    const blocks = footerString.length;
    const blockWidth = ((doc.width - doc.margin.right - doc.margin.left) - ((blocks - 1) * (2 * mm))) / blocks;

    footerString.forEach((block, blockindex) => {
      const lines = block.text.split("\n");
      let actualY = 16 * mm;

      if(block.textAlign === "left") {
        if(lines.length > 0) {
          lines.forEach((line, index) => {
            thisPage.drawText(line, {
              x: (doc.margin.left + (blockindex * blockWidth)),
              y: actualY - (index * 8.8),
              font: doc.font.normal,
              maxWidth: (blockWidth - 2*mm),
              size: 8,
              lineHeight: 8.8,
            });
          });
        }
      }
      if(block.textAlign === "right") {
        if(lines.length > 0) {
          lines.forEach((line, index) => {
            thisPage.drawText(line, {
              x: (doc.margin.left + (blockindex * blockWidth) + blockWidth - doc.font.normal.widthOfTextAtSize(line, 8)),
              y: actualY - (index * 8.8),
              font: doc.font.normal,
              size: 8,
              maxWidth: (blockWidth - 2*mm),
              lineHeight: 8.8,
            });
          });
        }
      }
      if(block.textAlign === "center") {
        if(lines.length > 0) {
          lines.forEach((line, index) => {
            thisPage.drawText(line, {
              x: (doc.margin.left + (blockindex * blockWidth) + ((blockWidth - doc.font.normal.widthOfTextAtSize(line, 8)) / 2)),
              y: actualY - (index * 8.8),
              font: doc.font.normal,
              size: 8,
              maxWidth: (blockWidth - 2*mm),
              lineHeight: 8.8,
            });
          });
        }
      }
    });
  }

  const addNewPage = () => {
    const newPage = pdf.addPage(A4);
    if(!blank) {
      addFoldmarks(newPage);
      addFooter(newPage);
    }
    return newPage;
  }

  let actualPage = addNewPage();

  // First Page only!
  // Rücksende Adresse
  let ruecksender = "";
  if( settings.settings.briefpapier.ruecksender === null) {
    ruecksender = settings?.companyName
    + (settings?.anschrift ? " - " + settings.anschrift : "")
    + (settings?.PLZ ? " - " + settings.PLZ + " " : "")
    + (settings?.ort ? settings.ort : "")
  }
  else {
    ruecksender = settings.settings.briefpapier.ruecksender;
  }

  if(!blank) {
  actualPage.drawText(ruecksender, {
    x: doc.margin.left + 5 * mm,
    y: doc.height - (50 * mm),
    font: doc.font.normal,
    size: 6,
  });}

  // Empfänger
  actualPage.drawText(obj.contact.anrede + "\n"
  + ((obj.contact?.vorname && obj.contact.vorname !== "") ? obj.contact.vorname + " ": "") + obj.contact.name + "\n"
  + (obj.contact?.adresszusatz ? obj.contact.adresszusatz + "\n" : "")
  + obj.contact.anschrift + "\n"
  + obj.contact.PLZ + " " + obj.contact.ort + "\n"
  +"\n\nper E-Mail " + (obj?.email ? obj.email : (obj.contact?.email ? obj.contact.email : "")), {
    x: doc.margin.left + 5 * mm,
    y: doc.height - (65 * mm),
    font: doc.font.normal,
    size: 10,
    lineHeight: 12,
  });

  const documentDate = () => {
    if(docDate !== null) {
      const date = new Date(docDate);
      return date.getDate().toString().padStart(2, "0") + "." + (date.getMonth() + 1).toString().padStart(2,"0") + "." + date.getFullYear().toString().padStart(2,"0");
    }
    const date = new Date();
      return date.getDate().toString().padStart(2, "0") + "." + (date.getMonth() + 1).toString().padStart(2,"0") + "." + date.getFullYear().toString().padStart(2,"0");
  }

  // Absender
  const senderText = "Sachbearbeiter" + (auth?.anrede && auth.anrede === "Frau" ? "in":"") + ": " + auth?.displayName + "\n"
  + "E-Mail: " + auth?.email + "\n" + (auth?.telefon ? "Telefon: " + auth?.telefon + "\n" : "") + "\nDatum: " + documentDate();
  actualPage.drawText(senderText, {
    x: doc.width - (85 * mm),
    y: doc.height - ((125 * mm) - getObjHeight(senderText, 12, 85 * mm) * mm),
    font: doc.font.normal,
    size: 10,
    lineHeight: 12,
  });

  // Betreff
  const betreff = documentNameId + "\nProjekt: "
  + (obj?.anschrift ? obj.anschrift + ", " : "")
  + (obj?.PLZ ? obj.PLZ + " " : "")
  + (obj?.ort || "") + "\n"
  + (obj?.massnahmenNummer ? "Maßnahmennumer: " + obj.massnahmenNummer + "\n" : "")
  + (obj?.vergabeNummer ? "Vergabenummer: " + obj.vergabeNummer : "");

  actualPage.drawText(betreff,{
    x: doc.margin.left,
    y: doc.height - (110 * mm),
    font: doc.font.bold,
    size: 11,
    lineHeight: 13,
  });

  const betreffHeight = getObjHeight(betreff, 13);
  let currentY = doc.height - (110 * mm) - betreffHeight - 13;

  // Kopftext
  let headerText = obj?.headerText ? obj.headerText : (obj?.contact?.anrede === "Firma" ? "Sehr geehrte Damen und Herren" : (obj?.contact?.anrede === "Herr" ? "Sehr geehrter Herr " + obj.contact.name : (obj?.contact?.anrede === "Frau" ? "Sehr geehrte Frau " + obj.contact.name : ""))) + ",\n\n" + (typ === "angebot" ? settings.texte.Angebote.Kopftexte.WerkAPP.text : settings.texte.Rechnungen.Kopftexte.WerkAPP.text);

  const headerLines = headerText.split("\n");

  headerLines.forEach((line, index) => {
    actualPage.drawText(line, {
      x: doc.margin.left,
      y: currentY - (11 * index),
      font: doc.font.normal,
      maxWidth: doc.width - doc.margin.left - doc.margin.right,
      size: 10,
      lineHeight: 11,
    });
  });

  currentY -= (getObjHeight(headerText, 10, doc.width - doc.margin.left - doc.margin.right) + 15);

  const addTableHeader = (thisPage, posY) => {
    thisPage.drawText("Pos.", {
      x: doc.margin.left,
      y: posY,
      font: doc.font.normal,
      size: 10,
      lineHeight: 11,
    });
    thisPage.drawText("Beschreibung", {
      x: doc.margin.left + doc.pos,
      y: posY,
      font: doc.font.normal,
      size: 10,
      lineHeight: 11,
    });
    thisPage.drawText("Menge", {
      x: doc.width - doc.margin.right - ((25 + 20) * mm) - doc.font.normal.widthOfTextAtSize("Menge", 10),
      y: posY,
      font: doc.font.normal,
      size: 10,
      lineHeight: 11,
    });
    thisPage.drawText("EP €", {
      x: doc.width - doc.margin.right - (25 * mm) - doc.font.normal.widthOfTextAtSize("EP €", 10),
      y: posY,
      font: doc.font.normal,
      size: 10,
      lineHeight: 11,
    });
    thisPage.drawText("Gesamt €", {
      x: doc.width - doc.margin.right - doc.font.normal.widthOfTextAtSize("Gesamt €", 10),
      y: posY,
      font: doc.font.normal,
      size: 10,
      lineHeight: 11,
    });
    actualPage.drawLine({
      start: {x: doc.margin.left, y: posY - 4},
      end: {x: doc.width - doc.margin.right, y: posY - 4},
      thickness: 1,
      color: { type: "RGB", red: 0, green: 0, blue: 0},
    });
  }

  // Articles Loop
  const helper = {
    title: {
      open: false,
      name: "",
      index: 0,
      article: 0,
      sum: 0,
    },
    subtitle: {
      name: "",
      open: false,
      index: 0,
      article: 0,
      sum: 0,
    },
    subsubtitle: {
      name: "",
      open: false,
      index: 0,
      article: 0,
      sum: 0,
    },
    total: {
      sum: 0,
      article: 0,
      lines: 0,
    }
  };
  
  obj?.articles.forEach((article, index) => {
    const font = (article.typ === "TK" || article.typ === "TS") ? doc.font.bold : doc.font.normal;
    const title = (article.typ === "TK" || article.typ === "TS");
    let titleText = "";
    let pos = "";
    let sum = 0;
    if(article.typ === "TK") {
      if (helper.subtitle.open) {
        helper.subsubtitle.index++;
        helper.subsubtitle.name = article.kurztext;
        helper.subsubtitle.open = true;
      } else if (helper.title.open) {
        helper.subtitle.index++;
        helper.subtitle.name = article.kurztext;
        helper.subtitle.open = true;
      } else {
        helper.title.index++;
        helper.title.name = article.kurztext;
        helper.title.open = true;
      }
      pos = (helper.title.open ? ("00" + helper.title.index).slice(-2) : "")
          + (helper.subtitle.open ? "."+("00" + helper.subtitle.index).slice(-2) : "")
          + (helper.subsubtitle.open ? "."+("00" + helper.subsubtitle.index).slice(-2) : "");
      titleText = "Titel " + article.kurztext;
    } else if (article.typ === "TS") {
      pos = (helper.title.open ? ("00" + helper.title.index).slice(-2) : "")
          + (helper.subtitle.open ? "."+("00" + helper.subtitle.index).slice(-2) : "")
          + (helper.subsubtitle.open ? "."+("00" + helper.subsubtitle.index).slice(-2) : "");
      titleText = "Titelsumme " + (helper.subsubtitle.open ? helper.subsubtitle.name : (helper.subtitle.open ? helper.subtitle.name : (helper.title.open ? helper.title.name : "")));
      if (helper.subsubtitle.open) {
        sum = helper.subsubtitle.sum;
        helper.subsubtitle.sum = 0;
        helper.subsubtitle.article = 0;
        helper.subsubtitle.open = false;
      } else if (helper.subtitle.open) {
        sum = helper.subtitle.sum;
        helper.subtitle.sum = 0;
        helper.subtitle.article = 0;
        helper.subtitle.open = false;
      } else if (helper.title.open) {
        sum = helper.title.sum;
        helper.title.sum = 0;
        helper.title.article = 0
        helper.title.open = false;
      }
    } else {
      const articlePrice = (article.typ === "BP" ? 0 : parseFloat((article.TK.NumericEP * article.TK.NumericMenge).toFixed(2)));
      if (helper.subsubtitle.open) {
        helper.subsubtitle.sum += articlePrice;
        helper.subtitle.sum += articlePrice;
        helper.title.sum += articlePrice;
        helper.total.sum += articlePrice;
        helper.subsubtitle.article++;
      } else if (helper.subtitle.open) {
        helper.subtitle.sum += articlePrice;
        helper.title.sum += articlePrice;
        helper.total.sum += articlePrice;
        helper.subtitle.article++;
      } else if (helper.title.open) {
        helper.title.sum += articlePrice;
        helper.total.sum += articlePrice;
        helper.title.article++;
      } else {
        helper.total.sum += articlePrice;
        helper.total.article++;
      }
      pos = (helper.title.open ? ("00" + helper.title.index).slice(-2) + "." : "")
          + (helper.subtitle.open ? ("00" + helper.subtitle.index).slice(-2) + "." : "")
          + (helper.subsubtitle.open ? ("00" + helper.subsubtitle.index).slice(-2) + "." : "")
          + (helper.subsubtitle.open ? ("000" + helper.subsubtitle.article).slice(-3) :
              (helper.subtitle.open ? ("000" + helper.subtitle.article).slice(-3) :
                (helper.title.open ? ("000" + helper.title.article).slice(-3) : ("000" + helper.total.article).slice(-3))));
      
    }

    if ( helper.total.lines === 0 ) {
      // Kopfzeile einfügen
      addTableHeader(actualPage, currentY);
      currentY -= 16;
    }
    helper.total.lines += 1;

    // Highlight Title Line
    if (title) {
      if( currentY < (doc.margin.bottom + 20) ) {
        actualPage = addNewPage();
        currentY = doc.margin.top;
      }
      currentY = (currentY === doc.margin.top ? doc.margin.top : currentY - 2);
      // Positons Nummer
      actualPage.drawText(pos, {
        x: doc.margin.left,
        y: currentY,
        font: font,
        size: 10,
        lineHeight: 11,
      });
      actualPage.drawText(titleText, {
        x: doc.margin.left + doc.pos,
        y: currentY,
        font: font,
        size: 10,
        lineHeight: 11,
      });
      if(article.typ === "TS") {
        const price = sum.toLocaleString("de-DE", digits2);
        actualPage.drawText(price, {
          x: doc.width - doc.margin.right - font.widthOfTextAtSize(price, 10),
          y: currentY,
          font: font,
          size: 10,
          lineHeight: 11,
        });
      }
      actualPage.drawLine({
        start: {x: doc.margin.left, y: currentY - 4},
        end: {x: doc.width - doc.margin.right, y: currentY - 4},
        thickness: 1,
        color: { type: "RGB", red: 0, green: 0, blue: 0},
      });
      currentY -= 15;
    } else {
      // Position
      actualPage.drawText(pos, {
        x: doc.margin.left,
        y: currentY,
        font: font,
        size: 10,
        lineHeight: 11,
      });
      
      const articleText = parseLineBreaks(obj?.langtext ? (article.langtext === "" ? article.kurztext : article.langtext) : article.kurztext, font, 10, (doc.width - doc.margin.left - doc.margin.right - doc.pos - ((85) *mm)));
      const pageBreaks = splitTextOverPages(articleText.parsedText, 11, currentY - doc.margin.bottom, doc.height - doc.margin.top - doc.margin.bottom);
      let rowHeight = 0;
      if(pageBreaks.breaks) {
        let loops = 0;
        for (const text of pageBreaks.text) {
          actualPage.drawText(text, {
            x: doc.margin.left + doc.pos,
            y: currentY,
            font: font,
            size: 10,
            lineHeight: 11,
          });
          loops++;
          if(pageBreaks.text.length > loops) {
            actualPage = addNewPage();
            currentY = doc.height - doc.margin.top;
            addTableHeader(actualPage, currentY);
            currentY -= 16;
          } 
        }
        const lastText = pageBreaks.text.slice(-1);
        const lastTextHeight = lastText[0].split("\n").length * 11;
        rowHeight = lastTextHeight;
      } else {
        rowHeight = articleText.rows * 11;
        actualPage.drawText(articleText.parsedText, {
          x: doc.margin.left + doc.pos,
          y: currentY,
          font: font,
          size: 10,
          lineHeight: 11,
        });
      }

      const qty = (article.TK.NumericMenge).toLocaleString("de-DE", digits3) + " " + article.einheit;
      actualPage.drawText(qty, {
        x: doc.width - doc.margin.right - ((25 + 20) * mm) - font.widthOfTextAtSize(qty, 10),
        y: currentY - (rowHeight - 11),
        font: font,
        size: 10,
        lineHeight: 11,
      });
      const ep = (article.TK.NumericEP).toLocaleString("de-DE", digits3);
      actualPage.drawText(ep, {
        x: doc.width - doc.margin.right - (25 * mm) - font.widthOfTextAtSize(ep, 10),
        y: currentY - (rowHeight - 11),
        font: font,
        size: 10,
        lineHeight: 11,
      });
      const gp = (article.TK.NumericMenge * article.TK.NumericEP).toLocaleString("de-DE", digits2);
      actualPage.drawText(gp, {
        x: doc.width - doc.margin.right - font.widthOfTextAtSize(gp, 10),
        y: currentY - (rowHeight - 11),
        font: font,
        size: 10,
        lineHeight: 11,
      });
      actualPage.drawLine({
        start: {x: doc.margin.left, y: currentY - (rowHeight - 11) - 4},
        end: {x: doc.width - doc.margin.right, y: currentY - (rowHeight - 11) - 4},
        thickness: .2,
        color: { type: "RGB", red: .7, green: .7, blue: .7},
      });

      currentY -= (rowHeight + 4) ;
    }
  });
  if(currentY - doc.margin.bottom < 56) {
    actualPage = addNewPage();
    currentY = doc.height - doc.margin.top;
  }
  currentY -= 4;
  
  actualPage.drawLine({
    start: {x: doc.margin.left, y: currentY + 15},
    end: {x: doc.width - doc.margin.right, y: currentY + 15},
    thickness: 1,
    color: { type: "RGB", red: .4, green: .4, blue: .4},
  });
  
  const total = {netto: helper.total.sum, tax: parseFloat((helper.total.sum * 0.19).toFixed(2)), brutto: ((helper.total.sum * 1.19).toFixed(2))};
  if(obj.vorgaben.NumericNachlass > 0) {
    const discount = parseFloat((helper.total.sum * (obj.vorgaben.NumericNachlass / 100)).toFixed(2));
    total.netto = helper.total.sum - discount;
    total.tax = parseFloat(((helper.total.sum - discount) * 0.19).toFixed(2));
    total.brutto = total.netto + total.tax;

    actualPage.drawText("abzgl. " + (obj.vorgaben.NumericNachlass).toLocaleString("de-DE",digits2) + " % Nachlass", {
      x: doc.width - doc.margin.right - ((25) * mm) - doc.font.normal.widthOfTextAtSize("abzgl. " + (obj.vorgaben.NumericNachlass).toLocaleString("de-DE",digits2) + " % Nachlass", 10),
      y: currentY,
      font: doc.font.normal,
      size: 10,
      lineHeight: 11,
    });
    actualPage.drawText("- " + discount.toLocaleString("de-DE", digits2) + " €", {
      x: doc.width - doc.margin.right - doc.font.normal.widthOfTextAtSize("- " + discount.toLocaleString("de-DE", digits2) + " €", 10),
      y: currentY,
      font: doc.font.normal,
      size: 10,
      lineHeight: 11,
    });
    currentY -= 14;
  }

  let tempText = typ.charAt(0).toUpperCase() + typ.slice(1) + "ssumme";
  actualPage.drawText(tempText + " netto", {
    x: doc.width - doc.margin.right - ((25) * mm) - doc.font.bold.widthOfTextAtSize(tempText + " netto", 10),
    y: currentY,
    font: doc.font.bold,
    size: 10,
    lineHeight: 11,
  });
  actualPage.drawText(total.netto.toLocaleString("de-DE", digits2) + " €", {
    x: doc.width - doc.margin.right - doc.font.bold.widthOfTextAtSize(total.netto.toLocaleString("de-DE", digits2) + " €", 10),
    y: currentY,
    font: doc.font.bold,
    size: 10,
    lineHeight: 11,
  });
  actualPage.drawText("zzgl. 19% Umsatzsteuer", {
    x: doc.width - doc.margin.right - ((25) * mm) - doc.font.normal.widthOfTextAtSize("zzgl. 19% Umsatzsteuer", 10),
    y: currentY - 14,
    font: doc.font.normal,
    size: 10,
    lineHeight: 11,
  });
  actualPage.drawText(total.tax.toLocaleString("de-DE", digits2) + " €", {
    x: doc.width - doc.margin.right - doc.font.normal.widthOfTextAtSize(total.tax.toLocaleString("de-DE", digits2) + " €", 10),
    y: currentY - 14,
    font: doc.font.normal,
    size: 10,
    lineHeight: 11,
  });
  actualPage.drawText(tempText + " brutto", {
    x: doc.width - doc.margin.right - ((25) * mm) - doc.font.bold.widthOfTextAtSize(tempText + " brutto", 10),
    y: currentY - 28,
    font: doc.font.bold,
    size: 10,
    lineHeight: 11,
  });
  actualPage.drawText(total.brutto.toLocaleString("de-DE", digits2) + " €", {
    x: doc.width - doc.margin.right - doc.font.bold.widthOfTextAtSize(total.brutto.toLocaleString("de-DE", digits2) + " €", 10),
    y: currentY - 28,
    font: doc.font.bold,
    size: 10,
    lineHeight: 11,
  });
  currentY -= 50;

  // schlusstext
  const footerString = "Zahlungsbedingungen: Abschlagsrechnungen sind " +
    (obj.vorgaben.NumericZielAR > 0 ? " innerhalb von " + obj.vorgaben.NumericZielAR + " Tagen" : " sofort") +
    ", die Schlussrechnung" +
    (obj.vorgaben.NumericZielSR > 0 ? " innerhalb von " + obj.vorgaben.NumericZielSR + " Tagen jeweils " : " sofort ") +
    "nach Rechnungslegung fällig. " +
    ((obj.vorgaben.NumericSkonto > 0 && obj.vorgaben.NumericSkontoTage > 0) ? "Bei Zahlung innerhalb von " + obj.vorgaben.NumericSkontoTage +
    " Tagen nach Rechnungslegung wird ein Skonto in Höhe von " + (obj.vorgaben.NumericSkonto).toLocaleString("de-DE",digits2) + " % gewährt.\n\n" : "\n\n") +
    (( obj?.footerText && obj.footerText !== "") ? obj.footerText : ((typ === "angebot" ? settings.texte.Angebote.Schlusstexte.WerkAPP.text : settings.texte.Rechnungen.Schlusstexte.WerkAPP.text) + "\n\n" + (auth?.signatur ? auth.signatur + " " : "") + auth.displayName + (auth?.funktion ? "\n" + auth.funktion : "") + "\n" + settings?.companyName));
  const footerText = parseLineBreaks(footerString, doc.font.normal, 10, (doc.width - (doc.margin.left + doc.margin.right)));
  const pageBreaks = splitTextOverPages(footerText.parsedText, 12, currentY - doc.margin.bottom, doc.height - (doc.margin.top + doc.margin.bottom));
  if(pageBreaks.breaks) {
    let loops = 0;
    for (const text of pageBreaks.text) {
      actualPage.drawText(text, {
        x: doc.margin.left,
        y: currentY,
        font: doc.font.normal,
        size: 10,
        lineHeight: 12,
      });
      loops++;
      if(pageBreaks.text.length > loops) {
        actualPage = addNewPage();
        currentY = doc.height - doc.margin.top;
      } 
    }
  } else {
    actualPage.drawText(footerText.parsedText, {
      x: doc.margin.left,
      y: currentY,
      font: doc.font.normal,
      size: 10,
      lineHeight: 11,
    });
  }

  // Add Pagination
  const allPages = pdf.getPages();
  const totalPages = allPages.length;
  let counter = 1;
  for (const page of allPages) {
    const string = "Seite " + counter + " von " + totalPages;
    counter++;
    page.drawText(string , {
      x: doc.width - doc.font.normal.widthOfTextAtSize(string, 10) - doc.margin.right,
      y: 22 * mm,
      size: 10,
      maxWidth: 50 * mm,
      lineHeight: 11,
      font: doc.font.normal,
    });
  }
  const pdfBytes = await pdf.save();
  return {pdf: pdfBytes, name: documentNameId.replace(": ","_")};

  /*
  // Seitenränder definieren
  const { width, height } = actualPage.getSize();
  const mm = (1 / .35);
  const doc.margin.left = 20 * mm;
  const doc.margin.bottom = 20 * mm;
  const doc.margin.right = 10 * mm;
  const doc.margin.top = 25 * mm;
  const positionCol = 15 * mm;
  const GPCol = 25 * mm;
  

  const getObjHeight = (text, lineHeight, maxWidth) => {
    
    if( text.includes("\n") ) {
      const lines = text.split("\n");
      return lines.length * lineHeight;
    }
    else {
      let lines = 1;
      let col = "";
      const words = text.split(" ");
      words.forEach((word, index) => {
        col = col + word + " ";
        const width = doc.font.normal.widthOfTextAtSize(col, lineHeight);
        if(width > maxWidth) {
          lines = lines + 1;
          col = "";
        }
      });
      return lines * lineHeight
    }
  }

  

  



  


  actualPage.drawText("Projekt: "
  + (obj?.anschrift ? obj.anschrift + ", " : "")
  + (obj?.PLZ ? obj.PLZ + " " : "")
  + (obj?.ort || "") + "\n"
  + (obj?.vergabeNummer ? "Vergabenummer: " + obj.vergabeNummer + "\n" : "")
  + (obj?.massnahmenNummer ? "Maßnahmennumer: " + obj.massnahmenNummer + "\n" : "")
  + documentNameId + "\n",
  {
    x:doc.margin.left,
    y: height - (110 * mm),
    font: fontBold,
    size: 11,
    lineHeight: 13,
  });
  
  const getTextDimensions = (text, fontSize, maxWidth) => {
    // Text mit Absätzen
    if(text.includes("\n")) {
      const lineBreaks = text.split("\n");
      let lines = "";
      lineBreaks.map((line) => {

      });
    }
    // Text ohne Absätze
    else {
      const textWidth = doc.font.normal.widthOfTextAtSize(text, 10);
      let lines = [];
      
      if (textWidth > maxWidth) {
        const words = headerText.split(" ");
        let line = "";
        words.forEach((word) => {
          const testLine = line + word + " ";
          const testWidth = doc.font.normal.widthOfTextAtSize(testLine, 10);
          if (testWidth > maxTextWidth) {
            lines.push(line.trim());
            line = word + " ";
          } else {
            line = testLine;
          }
        });
      } else {
        lines.push(headerText.trim());
      }
      return {text: lines.join("\n"), height: (lines.length + 1) * fontSize}
    }
  }

  const maxTextWidth = (width - doc.margin.left - doc.margin.right) - (positionCol + GPCol);

  let headerText = (obj?.headerText || settings.texte.Angebote.Kopftexte.WerkAPP.text);
  headerText = getTextDimensions(headerText, 10);
  let currentY = height - ((4 * 13) + (100 * mm) + 15);

  actualPage.drawText("Sehr geehrte Damen und Herren,\n\n" + headerText.text, {
    x: doc.margin.left,
    y: currentY,
    font: doc.font.normal,
    maxWidth: width - doc.margin.left,
    size: 10,
    lineHeight: 11,
  });

  currentY -= headerText.height + 10;
  
/*
  const articles = createTestData();
  const lineHeight = 11; // Zeilenhöhe
  
  let firstPage = true;
  
  const maxTextHeight = {first: (height - (5 * 12) - (100 * mm) - doc.margin.top - doc.margin.bottom), last: (height - doc.margin.top - doc.margin.bottom)};
  

  
  articles.map((value, index) => {
    const maxTextWidth = (width - doc.margin.left - doc.margin.right) - (positionCol + GPCol);
    
    const position = ("000" + (index + 1)).slice(-3);
    const text = value.langtext;
    const einheit = "m2";
    const mengeR = Math.random() * 12000;
    const EPR = Math.random() * 54;
    const menge = (mengeR).toLocaleString("de-DE", numberFormatOptions);
    const EP = (EPR).toLocaleString("de-DE", numberFormatOptions);
    const lastLine = menge + " " + einheit + " x " + EP + "";
    const GP = (mengeR * EPR).toLocaleString("de-DE", {...numberFormatOptions, minimumFractionDigits: 2, maximumFractionDigits: 2}) + "";

    const textWidth = doc.font.normal.widthOfTextAtSize(text, 10);

    let lines = [];
    if (textWidth > maxTextWidth) {
      const words = text.split(" ");
      let line = "";
      words.forEach((word) => {
        const testLine = line + word + " ";
        const testWidth = doc.font.normal.widthOfTextAtSize(testLine, 10);
        if (testWidth > maxTextWidth) {
          lines.push(line.trim());
          line = word + " ";
        } else {
          line = testLine;
        }
      });
    } else {
      lines.push(text.trim());
    }
    lines.push("\n" + lastLine);

    
    const heightTotal = (lines.length + 1) * lineHeight;
    if(heightTotal > maxTextHeight[(firstPage ? "first" : "last")]) {
      // Split Text overPages
      
    }

    if(currentY - (lines.length * lineHeight) <= doc.margin.bottom) { 
      if(actualPageNumber === 1) { addPageStamp(actualPage, actualPageNumber); }
      actualPage = pdf.addPage(A4);
      actualPageNumber = actualPageNumber + 1;
      addFoldmarks(actualPage);
      addPageStamp(actualPage, actualPageNumber);
      addFooter(actualPage);
      firstPage = false;
      currentY = height - doc.margin.top;
    }
    const articleText = lines.join(" ");
    actualPage.drawLine({
      start: {x: doc.margin.left, y: currentY - (lines.length ) * lineHeight - 5.5 },
      end: {x: width - doc.margin.right, y: currentY - (lines.length ) * lineHeight - 5.5},
      thickness: .25,
      color: { type: "RGB", red: .7, green: .7, blue: .7},
    });
    actualPage.drawText(position, {
      x: doc.margin.left,
      y: currentY,
      font: doc.font.normal,
      size: 10,
      lineHeight: 11,
    });

    actualPage.drawText(GP, {
      x: width - doc.margin.right - doc.font.normal.widthOfTextAtSize(GP, 10),
      y: currentY - (lines.length ) * lineHeight,
      font: doc.font.normal,
      size: 10,
      lineHeight: 11,
    });

    actualPage.drawText(articleText, {
        x: (doc.margin.left + positionCol),
        y: currentY,
        font: doc.font.normal,
        maxWidth: width - (doc.margin.left + positionCol + doc.margin.right + GPCol),
        size: 10,
        lineHeight: 11,
      });
    //


    currentY -= (lines.length + 1) * lineHeight + 10; // Aktualisiere die Y-Position für den nächsten Text
  });



  
  // Weitere Inhalte einfügen
  // ...

  // Schlussformel und Unterschrift
  actualPage.drawText("Mit freundlichen Grüßen", {
    x: doc.margin.left,
    y: 40 * mm,
    font: doc.font.normal,
    size: 11,
    lineHeight: 12
  });

  const pdfBytes = await pdf.save();
  return pdfBytes;*/
}