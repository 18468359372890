import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../redux/actions/database/loader";
import { BsSortAlphaDown, BsSortAlphaUp } from "react-icons/bs";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Error from "../../APP/Messages/Error";
import Loading from "../../APP/Messages/Loading";
import { ButtonGroup } from "react-bootstrap";

const FilterAndSort = ({ q = null, status = null, art = null, modalAdd, modalDetail}) => {
  const dispatch = useDispatch();
  const { articles, articlesLoaded, articlesError } = useSelector(state => state.erp);
  const [sortedContacts, setSortedContacts] = useState([]);
  const [sortProperty, setSortProperty] = useState("datum");
  const [sortOrder, setSortOrder] = useState("asc");
  const pageSize = 25;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSort = (property) => {
    if (property === sortProperty) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortProperty(property);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    if (!articlesLoaded) {
      dispatch(loader("articles"));
    }
  }, [articlesLoaded, dispatch]);

  useEffect(() => {
    let sorted = Object.entries(articles).sort(([, a], [, b]) => {
      if (a && b) {
        const xA = a.status || "";
        const xB = b.status || "";
        return xA.localeCompare(xB);
      }
      return 0;
    });

    if (sortProperty) {
      sorted.sort(([, a], [, b]) => {
        const valueA = a && a[sortProperty] ? a[sortProperty].toLowerCase() : "";
        const valueB = b && b[sortProperty] ? b[sortProperty].toLowerCase() : "";

        if (sortOrder === "asc") {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      });
    }
    if(art && art !== "Alle") {
      sorted = sorted.filter(([_, task]) => {
        return task.lv === art;
      });
    }
    if(status && status !== "Alle") {
      sorted = sorted.filter(([_, task]) => {
        return task.status === status;
      });
    }
    if (q) {
      sorted = sorted.filter(([, task]) => {
        const searchQuery = q.toLowerCase().replace(/[^a-zäöü0-9]/gi, "")
        
        const kurztext = task.kurztext || "";

        return (
          kurztext.toLowerCase().replace(/[^a-zäöü0-9]/gi, "").includes(searchQuery)
        );
      });
    }

    setSortedContacts(sorted);
  }, [articles, sortProperty, sortOrder, q, status, art]);

  if (articlesError !== null) {
    return <Error />;
  }

  if (!articlesLoaded) {
    return <Loading />;
  }

  const totalContacts = sortedContacts.length;
  const totalPages = Math.ceil(totalContacts / pageSize);
  const articlesToShow = sortedContacts.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const renderTooltip = (title) => (
    <Tooltip id="button-tooltip" placement="top" delay={{ show: 250, hide: 400 }}>
      {title}
    </Tooltip>
  );

  if (Object.keys(articles).length === 0) {
    return (
      <Alert variant="primary">
        Sie haben noch keine Artikel, legen Sie jetzt einen an.
        <br />
        <br />
        <Button variant="primary" onClick={modalAdd}>
          Neuer Artikel
        </Button>
      </Alert>
    );
  }
  else if (articlesToShow.length === 0) {
    return (
      <Alert variant="primary">
        Es gibt keine Übereinstimmungen.
      </Alert>
    );
  }
  

  return (
    <>
      <Table striped hover>
        <thead>
          <tr>
            <th onClick={() => handleSort("kurztext")} style={{cursor:"pointer"}}>
                Kurztext {sortProperty === "kurztext" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("lv")} style={{cursor:"pointer"}}>
              Leistungsverzeichnis {sortProperty === "lv" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("art")} style={{cursor:"pointer"}}>
              Art {sortProperty === "art" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("einheit")} style={{cursor:"pointer"}}>
              Einheit {sortProperty === "einheit" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th style={{textAlign: "right"}}>
              Einheitspreis
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {articlesToShow.map(([key, task], index) => (
            <tr key={index}>
              <td valign="middle">
                <span>
                  { task?.kurztext }
                </span>
              </td>
              <td valign="middle">{ task?.lv }</td>
              <td valign="middle">{ task.status === "Gelöscht" ? task.status : task?.art }</td>
              <td valign="middle">{ task?.einheit}{ task.art === "Mietleistung" ? " ("+ task.VorhalteEinheit+")" : ""}</td>
              <td valign="middle" align="end">{ task.TK.EP }</td>
              <td valign="middle" align="end">
                <ButtonGroup>
                  <OverlayTrigger overlay={renderTooltip("Als Vorlage verwenden")}>
                    <Button onClick={() => modalDetail({ id: key, el: articles[key], copy: true })} variant="outline-primary">
                      Kopieren
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger overlay={renderTooltip("Details öffnen")}>
                    <Button onClick={() => modalDetail({ id: key, el: articles[key] })} variant="outline-primary">
                      Details
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination className="text-center">
        {
          (currentPage > 1) && <>
            <Pagination.First onClick={() => handlePageChange(1)}/>
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}/>
          </>
        }
        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
          <Pagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Pagination.Item>
        ))}
        {
          (currentPage < totalPages) && <>
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}/>
            <Pagination.Last onClick={() => handlePageChange(totalPages)}/>
          </>
        }
        </Pagination>
      </div>
    </>
  );
};

export default FilterAndSort;
