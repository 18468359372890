import { getIdTokenResult, getAuth } from "firebase/auth";
import { getDatabase, push, ref, serverTimestamp, update} from "firebase/database";
import removeEmptyValues from "../objects/removeEmptyValues";
import detectChanges from "../objects/detectChanges";

export const updateDB = async (target, object, id = null, before = {}) => {
  
  const user = getAuth().currentUser;
  const claims = await getIdTokenResult(user);
  const {client, sales} = claims.claims;
  
  const databaseId = (sales === undefined) ? client : user.uid;
  if(sales === undefined && (client === null || client === undefined)) return {error: true};

  const cleanObject = removeEmptyValues(object);
  const cleanBefore = removeEmptyValues(before);

  if(Object.keys(before).length !== 0) {
    const changes = detectChanges(cleanBefore, cleanObject, []);
    changes.at = serverTimestamp();
    changes.by = user.uid;
    changes.target = id;
    changes.typ = target;
    push(ref(getDatabase(), databaseId + "/history"), changes);
  }
  const thisRef = ref(getDatabase(), databaseId + "/" + target + "/" + id);
  update(thisRef, {...cleanObject});
  return {error: false, key: thisRef.key, obj: cleanObject};
};