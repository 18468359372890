import { useEffect, useState } from "react";
import { endAt, get, getDatabase, orderByChild, query, ref } from "firebase/database";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import Loading from "../Messages/Loading";
import Error from "../Messages/Error";

const Products = () => {

  const nofRare = useSelector(state => state.crm.settings.nof);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const nof = {...nofRare,minimumFractionDigits: 2,maximumFractionDigits: 2};
  
  useEffect(() => {
    const today = new Date();
    const timestamp = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2,"0") + "-" + today.getDate().toString().padStart(2,"0");
    if(loading) {
      get(query(ref(getDatabase(),"public/products"),orderByChild("disponibleFrom"),endAt(timestamp)))
      .then((res) => {
        console.log(res.exportVal());
        setProducts(res.exportVal());
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        console.error(err);
      })
    }
    // eslint-disable-next-line
  }, []);

  if(loading) return <Loading />;
  if(error) return <Error />;
 
  return (
    <>
    <h4 className="pageTitle my-3">Produkte</h4>
    <Accordion>
      {
        (products !== null) && Object.entries(products).map(([key, product], index) => 
        <Accordion.Item key={index} eventKey={key}>
          <Accordion.Header>
            <span className="Werk">Werk</span><span className="APP">APP</span>&nbsp;- {product.name} ({ (product.price * 1).toLocaleString("de-DE", nof) } € pro Monat / Lizenz)
          </Accordion.Header>
          <Accordion.Body>
            <p>
              {product?.description.includes("WerkAPP") ? (
                <>
                  {product.description.split("WerkAPP")[0]}
                  <span className="Werk">Werk</span>
                  <span className="APP">APP</span>
                  {product.description.split("WerkAPP")[1]}
                </>
              ) : (
                product?.description
              )}
            </p>
            <h5>Funktionen</h5>
            <Table bordered striped>
              <tbody>
                {
                  Object.values(product?.functions).map((f, i) => <tr key={i}>
                    <td><b>{ f.name }</b><br/>{ f.description }</td>
                  </tr>)
                
                }
              </tbody>
            </Table>
            <h5 className="mt-4">Inklusive pro Lizenz</h5>
            <Table bordered striped>
              <tbody>
                {
                  Object.values(product?.includes).map((f, i) => {
                  if(f.qty === "0" || f.qty === 0) return"";
                  return (
                  <tr className="fw-semibold" key={i}>
                    <td>{ f.name }</td>
                    <td>{ ((f.step * 1) < 1) ? (f.qty * 1).toLocaleString("de-DE", nof) : f.qty } { f.format }</td>
                  </tr>); })
                
                }
              </tbody>
            </Table>
            {
              (product?.requiered && product.requiered.length > 0 ) && <>
            <h5 className="mt-4">Vorraussetzungen</h5>
            <Table bordered striped>
              <tbody>
                {
                  product.requiered.map((f, i) => 
                  <tr className="fw-semibold" key={i}>
                    <td>{ products[f].name }</td>
                  </tr>)
                
                }
              </tbody>
            </Table>
            </>
            }
          </Accordion.Body>
        </Accordion.Item>)
      }
    </Accordion>
    </>
  )
}

export default Products