import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import MeasurmentLine from "./MeasurmentLine";
import { useState } from "react";
import { day, defaultMeasurmentLine, digits0, digits3 } from "../defaultData";
import { updateDB } from "../../../../functions/database/update";

const Article = (props) => {

  const {pos, posName, articleData, articleId, status, offerId, id} = props;
  const lines = (articleData.hasOwnProperty("lines")) ? articleData.lines : {};
  const [data, setData] = useState({...defaultMeasurmentLine});
  const [hasId, setHasId] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const articleSum = () => {
    let articleQty = 0;
    let articleRentQty = 0;
    Object.values(lines).forEach((line) => {
      if(line.hasOwnProperty("totalQty") && typeof line.totalQty === "number") {
        articleQty += line.totalQty;
      }
      if(line.hasOwnProperty("totalRentQty") && typeof line.totalRentQty === "number") {
        articleRentQty += line.totalRentQty;
      }
    });
    return {articleQty: articleQty.toLocaleString("de-DE", digits3), articleRentQty: articleRentQty.toLocaleString("de-DE", digits3)};
  }

  const saveChanges = (directData = null) => {
    const item = (directData === null ? {...data} : {...directData});
    const newData = [...Object.values(lines)];
    if(hasId !== "") {
      newData[hasId] = item;
    }
    else {
      newData.push(item);
    }
    updateDB("measurements/"+id+"/offers/"+offerId+"/"+articleId, newData, "lines");
    setData({...defaultMeasurmentLine});
    setHasId("");
    setIsDirty(false);
  }

  const editData = (d) => {
    const {lineData, lineId} = d;
    console.log(lineId);
    if(isDirty && window.confirm("Die Eingaben verwerfen und die gewählte Zeile bearbeiten?")) {
      setData({...defaultMeasurmentLine, ...lineData, numeric:{...defaultMeasurmentLine.numeric, ...lineData?.numeric}});
      setIsDirty(false);
      setHasId(lineId);
    } else if(!isDirty) {
      setData(prev => ({...prev, ...lineData, numeric:{...prev.numeric, ...lineData?.numeric}}));
      setHasId(lineId);
    } else return;
  }
  const calculating = (tempObj) => {
    const sum = (tempObj.numeric.qty === 0 && tempObj.numeric.l === 0 && tempObj.numeric.b === 0 && tempObj.numeric.h === 0) ? 0 : parseFloat((((tempObj.numeric.qty > 0) ? tempObj.numeric.qty : 1) * ((tempObj.numeric.l > 0) ? tempObj.numeric.l : 1) * ((tempObj.numeric.h > 0) ? tempObj.numeric.h : 1) * ((tempObj.numeric.b > 0) ? tempObj.numeric.b : 1)).toFixed(3));
    let rentQty = 0;
    let rentUnits = 0;
    if(tempObj.rentBeginn !== "") {
      const beginn = new Date(tempObj.rentBeginn);
      const end = (tempObj.rentEnd !== "" ? new Date(tempObj.rentEnd) : new Date());
      const daysDiff = parseInt(((end.getTime() - beginn.getTime()) / day).toFixed(0));
      const weekDiff = Math.ceil(daysDiff / 7);
      const monthDiff = Math.ceil(daysDiff / 360 * 12);
      rentUnits = (articleData.VorhalteEinheit === "Tg" ? daysDiff : (articleData.VorhalteEinheit === "Wo" ? weekDiff : (articleData.VorhalteEinheit === "Mo" ? monthDiff : 0))) - ((articleData?.VorhalteDauer && articleData.VorhalteDauer !== "" && articleData.VorhalteDauer !== 0) ? parseInt(articleData.VorhalteDauer) : 0); 
      rentQty = rentUnits <= 0 ? 0 : parseFloat((sum * rentUnits).toFixed(3));
      console.log(articleData.VorhalteEinheit);
    }
    setData(prev => ({...prev, ...tempObj, rentUnits: rentUnits.toLocaleString("de-DE", digits0), rentQty: rentQty.toLocaleString("de-DE", digits3), sum: sum.toLocaleString("de-DE", digits3), totalQty: sum, totalRentQty: rentQty}));
  };
  const handleTextChange = e => {
    const {name, value} = e.target;
    setIsDirty(true);
    setData(prev => ({...prev, [name]: value}));
  }
  const handleDateChange = e => {
    const {name, value} = e.target;
    calculating({...data, [name]: value});
  };
  const handleBlur = (e, save = false) => {
    const {name, value} = e.target;
    const tempObj = {...data};
    if(value === "," || value === "" || value === "0") {
      tempObj[name] = "";
    } else if(value.includes(".") && value.includes(",")) {
      if(value.indexOf(".") < value.indexOf(",")) {
        const [, decimalPart] = value.split(",");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        tempObj[name] = value + zeros;
      } else {
        const [, decimalPart] = value.split(".");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        tempObj[name] = value + zeros;
      }
    } else if(value.includes(",")) {
      const [, decimalPart] = value.split(",");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        tempObj[name] = ((value + zeros) === "0,000" ? "" : value + zeros);
    } else if(value.includes(".")) {
        const [, decimalPart] = value.split(".");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        tempObj[name] = ((value + zeros) === "0,000" ? "" : value + zeros)
    } else if(!value.includes(",") && !value.includes(".")) {
        const [, decimalPart] = value.split(",");
        const zerosToAdd = 3 - (decimalPart ? decimalPart.length : 0);
        const zeros = "0".repeat(zerosToAdd);
        tempObj[name] = ((value + "," + zeros) === "0,000" ? "" : value + "," + zeros);
    }
    setData(prev => ({...prev, ...tempObj}));
    if(save) {
      saveChanges({...tempObj});
    }
  };

  const handleNumberChange = e => {
    setIsDirty(true);
    const {name, value} = e.target;
    const newValue = value.replace(/[^0-9.,]/g, '');
    const tempObj = {...data};
    const numerics = {...data.numeric};
    if(newValue === ",") {
      tempObj[name] = "0,";
      numerics[name] = 0;
    } else if(newValue.includes(".") && newValue.includes(",")) {
      if(newValue.indexOf(".") < newValue.indexOf(",")) {
        tempObj[name] = newValue;
        numerics[name] = parseFloat("0" + newValue.replace(".","").replace(",",".") + "0");
      } else {
        tempObj[name] = newValue;
        numerics[name] = parseFloat("0" + newValue.replace(",","") + "0");
      }
    } else if(newValue.includes(",")) {
      if(newValue.indexOf(",") === 0) {
        tempObj[name] = "0" + newValue;
      } else {
        tempObj[name] = newValue;
      }
      numerics[name] = parseFloat("0" + newValue.replace(",",".") + "0");
    } else if(newValue.includes(".")) {
      if(newValue.indexOf(".") === 0) {
        tempObj[name] = "0" + newValue;
      } else {
        tempObj[name] = newValue;
      }
      numerics[name] = parseFloat("0" + newValue + "0");
    } else if(newValue==="") {
      tempObj[name] = newValue;
      numerics[name] = 0;
    } else {
      tempObj[name] = newValue;
      numerics[name] = parseFloat(newValue);
    }
    calculating({...tempObj, numeric: numerics});
  };
  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleBlur(event, true);
    }
  }
  const {articleQty, articleRentQty} = articleSum();
  return (
  <Accordion.Item eventKey={articleId}>
    <Accordion.Header>
      <Col>
        Pos: {posName}{pos} - {articleData.kurztext}
      </Col>
      <Col xs={2} align="end">{ articleQty } {articleData.einheit}</Col>
      <Col xs={2} align="end" style={{paddingRight:"15px"}}>
      { (articleData.typ === "MP") && articleRentQty + " " + articleData.einheit + articleData?.VorhalteEinheit }</Col>
      </Accordion.Header>
    <Accordion.Body style={{padding:"var(--bs-accordion-body-padding-y) calc(.5 * var(--bs-gutter-x)) 0"}}>
      <Row className="fw-semibold">
        <Col xs={12} md={4} lg={3}>Bezeichnung</Col>
        <Col xs={12} md={2} lg={2}>Achse</Col>
        <Col xs={12} md={6} lg={7}>
          <Row>
            <Col align="end">Stück</Col>
            <Col align="end">Länge<br/>m</Col>
            <Col align="end">Breite<br/>m</Col>
            <Col align="end">Höhe<br/>m</Col>
            <Col align="end" xs={6} md={4} lg={3}>Summe</Col>
            <Col xs={6} md={3} lg={2} />
          </Row>
        </Col>
      </Row>
      {
        Object.entries(lines).map(([lineId, lineData], index) => <MeasurmentLine key={index} lineId={lineId} lineData={lineData} editData={editData} {...props} />)
      }
      {
        status === "Offen" &&
      <Row className="pb-3" style={{backgroundColor:"#0076ba17"}}>
        <Col xs={12} md={4} lg={3}>
          <Form.Group className="mb-2">
            <Form.Text>Bezeichnung</Form.Text>
            <Form.Control size="sm" name="desc" value={data.desc} onChange={handleTextChange} />
          </Form.Group>
        </Col>
        <Col xs={12} md={2} lg={2}>
          <Form.Group className="mb-2">
            <Form.Text>Achse</Form.Text>
            <Form.Control size="sm" name="axis" value={data.axis} onChange={handleTextChange} />
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={7} className="d-flex flex-column">
          <Row>
            <Col>
              <Form.Group className="mb-2">
                <Form.Text>Stück</Form.Text>
                <Form.Control size="sm" className="inputEuro" name="qty" value={data.qty} onChange={handleNumberChange} onBlur={handleBlur} onKeyDown={handleEnter}/>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-2">
                <Form.Text>Länge (m)</Form.Text>
                <Form.Control size="sm" className="inputEuro" name="l" value={data.l} onChange={handleNumberChange} onBlur={handleBlur} onKeyDown={handleEnter}/>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-2">
                <Form.Text>Breite (m)</Form.Text>
                <Form.Control size="sm" className="inputEuro" name="b" value={data.b} onChange={handleNumberChange} onBlur={handleBlur} onKeyDown={handleEnter}/>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-2">
                <Form.Text>Höhe (m)</Form.Text>
                <Form.Control size="sm" className="inputEuro" name="h" value={data.h} onChange={handleNumberChange} onBlur={handleBlur} onKeyDown={handleEnter}/>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-2">
                <Form.Text>Summe</Form.Text>
                <Form.Control size="sm" className="inputEuro" value={data.sum} readOnly disabled/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-2">
                <Form.Text>Übergabe</Form.Text>
                <Form.Control size="sm" type="date" value={data.dateFinished} name="dateFinished" onChange={handleDateChange} />
              </Form.Group>
            </Col>
            <Col>
              { (articleData.typ === "MP") && <Form.Group className="mb-2">
                <Form.Text>Mietbeginn</Form.Text>
                <Form.Control size="sm" type="date" value={data.rentBeginn} name="rentBeginn" onChange={handleDateChange} />
              </Form.Group>
              }
            </Col>
            <Col>
              { (articleData.typ === "MP") && <Form.Group className="mb-2">
                <Form.Text>Mietende</Form.Text>
                <Form.Control size="sm" type="date" value={data.rentEnd} name="rentEnd" onChange={handleDateChange} />
              </Form.Group>}
            </Col>
            <Col>
              { (articleData.typ === "MP") && <Form.Group className="mb-2">
                <Form.Text>Mietdauer</Form.Text>
                <Form.Control size="sm" className="inputEuro" value={data.rentUnits + " " + articleData.VorhalteEinheit} readOnly disabled/>
              </Form.Group>}
            </Col>
            <Col>
              { (articleData.typ === "MP") && <Form.Group className="mb-2">
                <Form.Text>Mietmenge</Form.Text>
                <Form.Control size="sm" className="inputEuro" value={data.rentQty} readOnly disabled/>
              </Form.Group>}
            </Col>
          </Row>
        </Col>
      </Row>
      }
      
    </Accordion.Body>
  </Accordion.Item>
  )
}

export default Article