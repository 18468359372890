import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { loader } from "../../../redux/actions/database/loader";
import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";
import Unauthorized from "../../APP/Messages/Unauthorized";
import ShowPayments from "./ShowPayments";

const Payments = () => {

  const {sales, rights} = useSelector(state => state.auth.tokens);
  const {invoicesOverviewLoaded, invoicesOverviewError} = useSelector(state => state.erp);
  const dispatch = useDispatch();
  useEffect(() => {
    if((
      (sales!==undefined && sales === true)
      || (rights.hasOwnProperty("pay") && rights["pay"] > 0)
      || (rights.hasOwnProperty("bill") && rights["bill"] > 0))
      && !invoicesOverviewLoaded) {
        dispatch(loader("invoicesOverview"));
      }
    // eslint-disable-next-line
  }, []);

  if((sales===undefined || sales === false)
  && (!rights.hasOwnProperty("pay") && rights["pay"] === 0)
  && (!rights.hasOwnProperty("bill") && rights["bill"] === 0)) {
    return (<Unauthorized />);
  }

  if(!invoicesOverviewLoaded) {
    return (<Loading />);
  }
  else if(invoicesOverviewError !== null) {
    return (<Error />);
  }

  return (
    <>
      <h4 className="pageTitle">Offene Forderungen</h4>
      <ShowPayments />
    </>
  )
}

export default Payments