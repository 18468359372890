
export const defaultStatus = [
  "Offen",
  "Fertig",
  "Abgerechnet",
]
export const defaultMeasurementData = {
  projectId: "",
  datum: "",
  uhrzeit: "",
  ersteller: "",
  status: defaultStatus[0],
};

export const defaultMeasurmentLine = {
  desc: "",
  axis: "",
  qty: "",
  l:"",
  b:"",
  h:"",
  sum: "",
  unit:"",
  rentUnit:"",
  dateFinished: "",
  rentBeginn: "",
  rentEnd:"",
  rentUnits: "",
  rentQty: "", 
  numeric: {
    qty: 0, 
    l: 0, 
    b: 0, 
    h: 0
  }
}
export const digits3 = {
  style: "decimal",
  useGrouping: true,
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
  minimumIntegerDigits: 1,
  currency: undefined,
  currencyDisplay: "symbol",
  notation: "standard",
  compactDisplay: "short",
  localeMatcher: "best fit",
  numberingSystem: "latn",
  signDisplay: "auto",
  signStyle: "standard",
};
export const digits2 = {
  ...digits3,
  minimumFractionDigits: 2,
  maximumFractionDigits: 1,
};
export const digits0 = {
  ...digits3,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};
export const day = (1000 * 60 * 60 * 24);