import { getIdTokenResult, getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes } from "firebase/storage";

export const saveFilesToFirestore = async (pdfBlob, path, fileName) => {

  const user = getAuth().currentUser;
  const claims = await getIdTokenResult(user);

  const {client, sales} = claims.claims;

  const storageId = (sales === undefined) ? "customer/" + client : "demo/" + user.uid;
  if(sales === undefined && (client === null || client === undefined)) return {error: true};


  const storageRef = ref(getStorage(), storageId + "/" + path + "/"+fileName);

  await uploadBytes(storageRef, pdfBlob);
  
  return storageRef.fullPath;
};
