import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { loader } from "../../../redux/actions/database/loader";
import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";
import ListGroup from "react-bootstrap/ListGroup";
import Alert from "react-bootstrap/Alert";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";

const ProjectsMini = ({show, onHide, onSelect}) => {
  
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const {projects, projectsLoaded, projectsError, contacts, contactsLoaded, contactsError} = useSelector(state => state.crm);
  const [sortedContacts, setSortedContacts] = useState([]);


  useEffect(() => {
    if(!projectsLoaded) {
      dispatch(loader("projects"));
    }
    if(!contactsLoaded) {
      dispatch(loader("contacts"));
    }
  }, [projectsLoaded, contactsLoaded, dispatch]);

  useEffect(() => {
    let sorted = Object.entries(projects).sort(([, a], [, b]) => {
      if (a && b) {
        const nameA = a.name || "";
        const nameB = b.name || "";
        return nameA.localeCompare(nameB);
      }
      return 0;
    });
    sorted = sorted.filter(([, project]) => {
      return (project?.status && (project.status === "offen" || project.status === "laufend"));
    })
    if (query.length >= 1) {
      sorted = sorted.filter(([, project]) => {
        const searchQuery = query
          .toLowerCase()
          .replace(/[^a-z0-9]/gi, "")
          .replace(/strasse|straße|str./g, "str");

        const name = project.name || "";
        const address = project.anschrift || "";
        const zipCodeAndCity = (project.PLZ || "") + " " + (project.ort || "");

        return (
          name.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          address.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          zipCodeAndCity.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery)
        );
      });
    }

    setSortedContacts(sorted);
  }, [projects, query]);

  if(projectsError !== null || contactsError !== null) {
    return (<Modal show={show} onHide={onHide} scrollable><Modal.Header><FormControl value={query} onChange={e => {setQuery(e.target.value);}} placeholder="Projekt Suche"/></Modal.Header><Modal.Body><Error /></Modal.Body></Modal>);
  } else if(!projectsLoaded || !contactsLoaded) {
    return (<Modal show={show} onHide={onHide} scrollable><Modal.Header><FormControl value={query} onChange={e => {setQuery(e.target.value);}} placeholder="Projekt Suche"/></Modal.Header><Modal.Body><Loading /></Modal.Body></Modal>);
  } else if (sortedContacts.length === 0) {
    return (<Modal show={show} onHide={onHide} scrollable><Modal.Header><FormControl value={query} onChange={e => {setQuery(e.target.value);}} placeholder="Projekt Suche"/></Modal.Header><Modal.Body><Alert variant="primary">Es sind keine Daten vorhanden</Alert></Modal.Body></Modal>);
  }
  
  const Contact = ({id}) => {
    if(contacts.hasOwnProperty(id)) {
      const contact = contacts[id];
      if(contact.hasOwnProperty("vorname")) {
        return (<>{ contact?.anrede || ""} { contact.vorname } { contact?.name }</>)
      }
      return <>{ contact.name }</>;
    }
    return null;
  }

  return (
    <Modal show={show} onHide={onHide} scrollable>
      <Modal.Header>
        <FormControl value={query} onChange={e => {setQuery(e.target.value);}} placeholder="Projekt Suche"/>
      </Modal.Header>
      <Modal.Body>
        
        
    
        <ListGroup variant="flush">
        {
            (sortedContacts.length > 0) && sortedContacts
              .map(([id, project], idx) => <ListGroup.Item key={idx} action onClick={() => onSelect(id)}>
                  
                    <div className="fw-bold">{ (project?.name) ? project.name + " - " : null }{ project?.bezeichnung || ""}</div>
                    { (project?.anschrift) ? project.anschrift + ", " : null }{ (project?.PLZ) ? project.PLZ + " " : null }{ project?.ort || null }<br/>
                    <Contact id={project?.contactId} />
                  
                  </ListGroup.Item>
              )
          }
        </ListGroup>
      </Modal.Body>
    </Modal>
  )
}

export default ProjectsMini