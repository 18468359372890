import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { loader } from "../../../redux/actions/database/loader";
import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";
import Accordion from "react-bootstrap/Accordion";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import findChildren from "../../../functions/objects/findChildren";
import AddProject from "./AddProject";

const ProjectsList = ({contactId = null}) => {
  const { action } = useParams();
  const dispatch = useDispatch();
  const {projects, projectsLoaded, projectsError} = useSelector(state => state.crm);

  useEffect(() => {
    if(!projectsLoaded) {
      dispatch(loader("projects"));
    }
  }, [projectsLoaded, dispatch]);

  if(projectsError !== null) {
    return (<Error />);
  }

  if(!projectsLoaded) {
    return (<Loading />);
  }

  const filteredProjects = (contactId !== null) ? findChildren(projects, "contactId", contactId) : (action !== null) ? findChildren(projects, "status", action) : projects;

  const color = (status) => {
    if(status === "offen") return "accent-1";
    else if(status === "laufend") return "primary";
    else if(status === "abgeschlossen") return "dark";
    else return "secondary";
  }

  return (
    <Accordion>
      {
        (Object.keys(filteredProjects).length > 0) && Object.entries(filteredProjects)
          .map(([id, project], idx) => <Accordion.Item key={idx} eventKey={id}>
            <Accordion.Header>
              { (project?.name) ? project.name + " - " : null }
              { (project?.anschrift) ? project.anschrift + ", " : null }
              { (project?.PLZ) ? project.PLZ + " " : null }
              { project?.ort || null }
              <Badge color={color(project?.status)} className="mx-2" style={{fontWeight:500, textTransform: "capitalize"}}> { project?.status } </Badge>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col xs={12} md={6}>
                <h6>Projektdetails</h6>
                  <Table>
                    <tbody>
                      {
                        (project?.name) && <tr>
                          <td>Projektname</td>
                          <td>{ project.name }</td>
                        </tr>
                      }
                      {
                        (project?.bezeichnung) && <tr>
                          <td>Bezeichnung</td>
                          <td>{ project.bezeichnung }</td>
                        </tr>
                      }
                      {
                        (project?.anschrift) && <tr>
                          <td>Anschrift</td>
                          <td>{ project.anschrift }</td>
                        </tr>
                      }
                      {
                        (project?.PLZ || project?.ort) && <tr>
                          <td>PLZ, Ort</td>
                          <td>{ project?.PLZ || "" } { project?.ort || ""}</td>
                        </tr>
                      }
                      {
                        (project?.bauteil) && <tr>
                          <td>Bauteil</td>
                          <td>{ project.bauteil }</td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                </Col>
                <Col xs={12} md={6}>
                  <h6>Bearbeitung</h6>
                  <Table>
                    <tbody>
                      {
                        (project?.technisch) && <tr>
                          <td>Technisch</td>
                          <td>{ project.technisch }</td>
                        </tr>
                      }
                      {
                        (project?.kfm) && <tr>
                          <td>Kaufmännisch</td>
                          <td>{ project.kfm }</td>
                        </tr>
                      }
                      {
                        (project?.beginn) && <tr>
                          <td>Projektbeginn</td>
                          <td>{ project.beginn }</td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col align="end">
                  <Button size="sm" as={Link} to={"/crm/projects/"+id} variant="outline-primary">Projektverwaltung öffnen</Button>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>)
      }
      <Accordion.Item eventKey="new">
        <Accordion.Header>
          Neues Projekt anlegen
        </Accordion.Header>
        <Accordion.Body>
          <AddProject contactId={contactId}/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default ProjectsList