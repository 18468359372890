import Accordion from "react-bootstrap/Accordion";

const Historie = ({sentCloudMails}) => {

  const parseTimeStamp = (timeStamp) => {
    const date = new Date(timeStamp);
    return (date.getDate()).toString().padStart(2,"0") + "."
         + (date.getMonth() + 1).toString().padStart(2,"0") + "."
         + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + " Uhr";
  }
  const parseAttachments = (attachments) => {
    let filename = attachments.split("/");
    filename = filename[filename.length - 1];
    return filename;
  }

  return (
    <Accordion>
      {
      sentCloudMails.map((mail, index) => <Accordion.Item eventKey={index} key={index}>
        <Accordion.Header>CloudMail vom {parseTimeStamp(mail.sendAt)}</Accordion.Header>
        <Accordion.Body style={{fontSize: ".9rem"}}>
          <span style={{fontWeight: 600}}>Von: </span>
          <span>{mail?.replyTo || ""}</span><br/>
          <span style={{fontWeight: 600}}>Empfänger: </span>
          <span>{mail?.to ? Object.values(mail.to).join(", ") : ""}</span><br/>
          { mail?.cc ? <><span style={{fontWeight: 600}}>Kopie (CC): </span>
          <span>{Object.values(mail.cc).join(", ")}</span><br/></> : ""}
          { mail?.bcc ? <><span style={{fontWeight: 600}}>Blinkopie (BCC): </span>
          <span>{Object.values(mail.bcc).join(", ")}</span><br/></> : ""}
          <span style={{fontWeight: 600}}>Betreff: </span>
          <span>{mail?.subject || ""}</span><br/>
          <p className="my-1" style={{fontWeight: 600}}>Nachricht:</p>
          <span style={{fontSize: ".9rem", whiteSpace:"pre-line"}}>{ (mail?.text ? mail.text : "Keine Nachricht")}</span><br/>
          <p className="my-1" style={{fontWeight: 600}}>Anhänge:</p>
          { mail?.attachments ? Object.values(mail.attachments).map((entry, idx) => <><span style={{fontSize: ".9rem"}} key={idx}>{parseAttachments(entry)}</span><br/></>) : "Keine Anhänge"}
        </Accordion.Body>
      </Accordion.Item>)
      }
    </Accordion>
  )
}

export default Historie