import {texte} from "../defaultValues";

const domainCheck = () => {
  var liveDomains = ["app.werkapp.de", "mobile.werkapp.de", "localhost", "demo.werkapp.de"];
  var actualDomain = window.location.hostname;

  if (liveDomains.includes(actualDomain)) {
    
    if (actualDomain === "localhost") {
      return {allowed: true, live: false, demo: true};
    } else if (actualDomain === "demo.werkapp.de") {
      return {allowed: true, live: true, demo: true};
    } else {
      return {allowed: true, live: true, demo: false};
    }
  }
  else {
    return {allowed: false, live: false, demo: true};
  }
}

const branchen = ['Maurer und Betonbauer', 'Zimmerei', 'Dachdecker', 'Straßenbau', 'Wärme-, Kälte- und Schallschutz', 'Brunnenbau', 'Gerüstbau', 'Betonstein- und Terrazzoherstellung', 'Ofen- und Luftheizungsbau', 'Stuckateure', 'Maler und Lackierer', 'Metallbau', 'Kälteanlagenbau', 'Klempnerei', 'Installateur und Heizungsbau', 'Elektrotechnik', 'Tischlerei', 'Glaserei', 'Fliesen-, Platten- und Mosaikleger', 'Estrichleger', 'Parkettleger', 'Rolladen- und Sonnenschutz', 'Schilder- und Leuchtreklamen', 'Raumausstatter', 'Gebäudereinigung', 'Holz- und Bautenschutz', 'Eisenflechterei', 'Bautentrocknung', 'Bodenleger', 'Asphaltierer', 'Fuger im Hochbau', 'Rammgewerbe', 'Betonbohrer und -schneider', 'Rohr- und Kanalreinigung', 'Kabelverlegung im Hochbau'];
branchen.sort((a, b) => a < b ? -1 : a > b ? 1 : 0);
const rechtsformen = [['Einzelunternehmen','Einzelunternehmen'],['e.K.','eingetragener Kaufmann (e.K.)'],['GbR','Gesellschaft bürgerlichen Rechts (GbR)'],['AG','Aktiengesellschaft (AG)'],['GmbH','Gesellschaft mit beschränkter Haftung (GmbH)'],['GmbH & Co. KG','Kommanditgesellschaft mit beschränkter Haftung (GmbH & Co. KG)'],['KG','Kommanditgesellschaft (KG)'],['KGaA','Kommanditgesellschaft auf Aktien (KGaA)'],['OHG','Offene Handelsgesellschaft (OHG)'],['UG','Unternehmergesellschaft (UG)'],['SE','Europäische Gesellschaft (SE)'],['eG','Genossenschaft (eG)'],['KdöR','Körperschaft des öffentlichen Rechts']];
const numberFormatOptions = {
  style: 'decimal',
  useGrouping: true,
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
  minimumIntegerDigits: 1,
  currency: undefined, // Währungssymbol nicht anzeigen
  currencyDisplay: 'symbol', // Währungssymbol nicht anzeigen
  notation: 'standard',
  compactDisplay: 'short',
  localeMatcher: 'best fit',
  numberingSystem: 'latn',
  signDisplay: 'auto',
  signStyle: 'standard',
};
const nummernKreise = {
  rechnung: 0,
  projekt: 0,
  angebot: 0,
};
const nummernKreisString = {
  rechnung: ["R-","YYYY","-","num6"],
  angebot: ["A-","YYYY","-","num6"],
  project: ["P-","YYYY","-","num6"]
}
const skonto = {
  standart: {name: "14 Tage 2%", tage: 14, prozent: 2, text: "2% Skonto bei Bezahlung innerhalb von 14 Tagen" }
};
const zahlungsbedingungen = {
  standart: {name:"VOB 21 AR / 30SR", sr: 30, ar: 21},
};
const angebotsTypen = [
  "Freies Angebot",
  "Öffentliche Ausschreibung",
  "Beschränkte Ausschreibung",
  "Andere"
];
const quellen = [
  "Telefonisch",
  "E-Mail",
  "Empfehlung",
  "Webseite",
  "Andere"
];
const initial = domainCheck();

const einheiten = Object.assign({}, ["cm", "m", "stgm", "m²", "m³", "l", "kg", "min", "h", "Tag", "St", "VE", "Palette", "psch"]);

const initialState = {
  
  contactsLoaded: false,
  contactsError: null,
  contacts: {},

  projectsLoaded: false,
  projectsError: null,
  projects: {},

  teamLoaded: false,
  teamError: null,
  team: {},

  settingsLoaded: false,
  settingsError: null,
  settings: {
    angebotsTypen,
    quellen,
    nof: numberFormatOptions,
    companyName: '',
    branchen,
    texte,
    einheiten,
    briefpapier: {
      ruecksender: null,
    },
    settings: {
      nummernKreise,
      nummernKreisString,
    },
    usage: {},
    limits: {},
    rechtsformen,
    zahlungsbedingungen,
    skonto,
    USt: {"19% USt": 0.19, "7% USt": 0.07, "USt befreit": 0}
  },
  ...initial,
};
export default initialState;