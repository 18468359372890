const findChildren = (obj, childKey, childValue) => {
  const newObj = {};
  for (const child in obj) {
    if( obj[child].hasOwnProperty(childKey) && obj[child][childKey] === childValue) {
      Object.assign(newObj, {[child]: obj[child]});
    }
  }
  return newObj;
}

export default findChildren;