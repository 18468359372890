export function findMondayThisWeek() {
  var today = new Date();
  var weekday = today.getDay();
  var diff = today.getDate() - weekday + (weekday === 0 ? -6 : 1); // Berechnung des Datums des Montags

  if (weekday === 0) { // Wenn heute Sonntag ist, füge 1 Tag hinzu, um den kommenden Montag zu erhalten
    diff += 1;
  }
  var mondayThisWeek = new Date(today.getFullYear(), today.getMonth(), diff);
  return mondayThisWeek;
}