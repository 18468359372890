import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AddNew from "./AddNew";
import Unauthorized from "../../APP/Messages/Unauthorized";
import Edit from "./Edit";
import FilterAndSort from "./FilterAndSort";

const Index = () => {

  // Grab ID from the URL
  const {action, id} = useParams();
  const {sales, rights} = useSelector(state => state.auth.tokens);

  if(!sales && !rights.hasOwnProperty("bill")) return <Unauthorized license={'erp'} />
  else if(!sales && rights.bill === 0) return <Unauthorized />;  
 
  // Add new Offer/Lead 

  else if(action === "edit") {
    return <Edit />;
  }
  if(action === "newinvoice" && id !== "") {
    return <AddNew />;
  }

  return (<FilterAndSort />);
}

export default Index