import { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import FormGroup from "react-bootstrap/FormGroup";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { saveDB } from "../../../functions/database/save";
import { updateDB } from "../../../functions/database/update";
import { defaultData } from "../../CRM/Projects/defaultData";
import { useNavigate } from "react-router-dom";

const ProceedToProject = ({obj, objId = null, onHide, show}) => {
  
  const [data, setData] = useState({...defaultData, ...obj});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const {name, value} = e.target;
    setData(prev => ({...prev, [name]: value}));
  }
  const handleContactChange = (e) => {
    const {name, value} = e.target;
    setData(prev => ({...prev, contact: {...prev?.contact, [name]: value}}));
  }
  const handleArchitectChange = (e) => {
    const {name, value} = e.target;
    setData(prev => ({...prev, architect: {...prev?.architect, [name]: value}}));
  }

  const handleSave = () => {
    saveDB("projects", {
      ...data,
      id: null,
      status: "laufend",
    })
    .then((result) => {
      const projectId = result?.key;
      updateDB("offers", {status: "Auftrag",projectId: projectId}, objId)
      .then(() => { navigate("/projects/" + projectId); onHide(); })
      .catch((err) => {console.log(err);});
    })
    .catch((err) => {console.log(err);});
  }

  useEffect(() => {
    if(typeof obj === "object") {
      setData(prev => ({...prev, ...obj}));
    }
  }, [obj]);

  return (
    <Modal show={show} onHide={onHide} backdrop="static" size="xl">
      <Modal.Header>
        Projekt und Auftrag erstellen
      </Modal.Header>
      <Modal.Body>
      <Row>
        <Col xs={12} md={true}>
          <p className="fw-semibold">Projektadresse</p>
          <FormGroup>
            <Form.Text>Bezeichnung</Form.Text>
            <Form.Control name="name" value={data.name} onChange={handleChange}/>
          </FormGroup>
          <FormGroup>
            <Form.Text>Bauteil</Form.Text>
            <Form.Control name="bauteil" value={data.bauteil} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Form.Text>Anschrift</Form.Text>
            <Form.Control name="anschrift" value={data.anschrift} onChange={handleChange}/>
          </FormGroup>
          <Row>
            <Col xs={4}>
              <FormGroup>
                <Form.Text>PLZ</Form.Text>
                <Form.Control name="PLZ" value={data.PLZ} onChange={handleChange}/>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Ort</Form.Text>
                <Form.Control name="ort" value={data.ort} onChange={handleChange}/>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Form.Text>Land</Form.Text>
            <Form.Control name="land" value={data.land} onChange={handleChange} />
          </FormGroup>
        </Col>
        <Col xs={12} md={4}>
          <p className="fw-semibold">Bauherr / Auftraggeber</p>
          <FormGroup>
            <Form.Text>Name</Form.Text>
            <Form.Control name="name" value={data.contact?.name} onChange={handleContactChange}/>
          </FormGroup>
          <FormGroup>
            <Form.Text>E-Mail</Form.Text>
            <Form.Control name="email" value={data.contact?.email} onChange={handleContactChange} />
          </FormGroup>
          <FormGroup>
            <Form.Text>Anschrift</Form.Text>
            <Form.Control name="anschrift" value={data.contact?.anschrift} onChange={handleContactChange}/>
          </FormGroup>
          <Row>
            <Col xs={4}>
              <FormGroup>
                <Form.Text>PLZ</Form.Text>
                <Form.Control name="PLZ" value={data.contact?.PLZ} onChange={handleContactChange}/>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Ort</Form.Text>
                <Form.Control name="ort" value={data.contact?.ort} onChange={handleContactChange}/>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Form.Text>Land</Form.Text>
            <Form.Control name="land" value={data.contact?.land} onChange={handleContactChange} />
          </FormGroup>
        </Col>
        <Col xs={12} md={4}>
          <p className="fw-semibold">Architekt / Planer</p>
          <FormGroup>
            <Form.Text>Name</Form.Text>
            <Form.Control name="name" value={data.architect?.name} onChange={handleArchitectChange}/>
          </FormGroup>
          <FormGroup>
            <Form.Text>E-Mail</Form.Text>
            <Form.Control name="email" value={data.architect?.email} onChange={handleArchitectChange}/>
          </FormGroup>
          <FormGroup>
            <Form.Text>Anschrift</Form.Text>
            <Form.Control name="anschrift" value={data.architect?.anschrift} onChange={handleArchitectChange}/>
          </FormGroup>
          <Row>
            <Col xs={4}>
              <FormGroup>
                <Form.Text>PLZ</Form.Text>
                <Form.Control name="PLZ" value={data.architect?.PLZ} onChange={handleArchitectChange}/>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Ort</Form.Text>
                <Form.Control name="ort" value={data.architect?.ort} onChange={handleArchitectChange}/>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Form.Text>Land</Form.Text>
            <Form.Control name="land" value={data.architect?.land} onChange={handleArchitectChange} />
          </FormGroup>
        </Col>
      </Row>
    </Modal.Body>
    <Modal.Footer>
      <Row>
        <Col align="end">
          <ButtonGroup>
            <Button variant="outline-primary" onClick={onHide}>Zurück</Button>
            <Button onClick={handleSave}>Projekt erstellen</Button>
          </ButtonGroup>
        </Col>
      </Row>
    </Modal.Footer>
    </Modal>
  )
}

export default ProceedToProject