const initialState = {
  isLoaded: false,
  isEmpty: true,
  hasError: false,
  tasksLoaded: false,
  tasksError: null,
  tasks: {},
  staffLoaded: false,
  staffError: null,
  staff: {},
  absenceLoaded: false,
  absenceError: null,
  absence: {},
  measurmentsLoaded: false,
  measurmentsError: null,
  measurments: {},
};
export default initialState;