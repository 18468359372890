import types from '../../types';
import { auth } from '../../../config/firebase';
import { onAuthStateChanged } from 'firebase/auth';

export const authListener = () => dispatch => {
  try {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult(true).then((data) => {
          dispatch({type: types.AUTH_SIGNIN, payload: { displayName: user.displayName, email: user.email, emailVerified: user.emailVerified, isAnonymous: user.isAnonymous, photoURL: user.photoURL, uid: user.uid, tokens: { rights: {}, ...data?.claims } }});
        })
      } else {
        dispatch({type: types.FIREBASE_CONNECTED});
      }
    });
  }
  catch (e) {
    console.error(e);
  }
  
}