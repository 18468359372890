import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row"; 
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { BsXCircleFill } from 'react-icons/bs';
import InputEmail from "./InputEmail";
import { saveFilesToFirestore } from "../../../functions/storage/saveFilesToFirestore";
import { pushCloudMail } from "../../../functions/cloudmailer/pushCloudMail";
import { loaderOnce } from "../../../functions/database/loaderOnce";
import Historie from "./Historie";

const CloudMailer = ({to = [], cc = [], bcc = [], title = "", message = "", files = [], target, show, handleClose}) => {

  const userEmail = useSelector(state => state.auth.email);
  const {email, companyName} = useSelector(state => state.crm.settings.settings);
  const [sentCloudMails, setSentCloudMails] = useState([]);

  const [cloudMailConfirm, setCloudMailConfirm] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const [sender, setSender] = useState(userEmail);
  const [recipient, setTo] = useState([...to]);

  const [copy, setCc] = useState([...cc]);

  const [blindCopy, setBcc] = useState([...bcc]);

  const [mailTitle, setMailTitle] = useState(title);

  const [mailText, setMailText] = useState(message);
  
  const [uploadedFiles, setUploadedFiles] = useState([...files]);

  useEffect(() => {
    loaderOnce("cloudMailer","target",target)
    .then((response) => {
      if(response.result !== null) {
        setSentCloudMails(Object.values(response.result).reverse());
      }
    })
    .catch((error) => {
      console.error(error);
    })
    // eslint-disable-next-line
  }, []);
  
  const handleFile = (e) => {
    const selectedFiles = e.target.files;
    const newFiles = [];
  
    for (const file of selectedFiles) {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
        const blob = new Blob([arrayBuffer], { type: file.type });
  
        newFiles.push({
          name: file.name,
          content: blob,
        });
  
        if (newFiles.length === selectedFiles.length) {
          setUploadedFiles([...uploadedFiles, ...newFiles]);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };
 

  const handleRemoveFile = (indexToRemove) => {
    const updatedFiles = uploadedFiles.filter((_, index) => index !== indexToRemove);
    setUploadedFiles(updatedFiles);
  };
  const handleExport = () => {
    if(!window.confirm("Anhänge müssen der E-Mail manuell angehängt werden.")) {
      return;
    }
    const uniqueCc = copy.filter(email => !recipient.includes(email));
    const uniqueBcc = blindCopy.filter(email => !cc.includes(email) && !recipient.includes(email));

    const toEmails = recipient.join(",");
    const ccEmails = uniqueCc.join(",");
    const bccEmails = uniqueBcc.join(",");
    const subject = encodeURIComponent(mailTitle);
    const body = encodeURIComponent(mailText);

    const mailtoLink = `mailto:${toEmails}?cc=${ccEmails}&bcc=${bccEmails}&subject=${subject}&body=${body}`;
    console.log(mailtoLink);
    window.location.href = mailtoLink;
  };

  const handleSend = async (e) => {
    e.preventDefault();
    if(recipient.length === 0) {
      alert("Es wurde kein Empfänger ausgewählt");
      return;
    }
    const uniqueCc = copy.filter(email => !recipient.includes(email));
    const uniqueBcc = blindCopy.filter(email => !cc.includes(email) && !recipient.includes(email));
    const filesPath = await Promise.all(
      uploadedFiles.map(async (file) => {
        const filePath = await saveFilesToFirestore(file.content, "_temp", file.name);
        return filePath;
      })
    );
    pushCloudMail({
      to: recipient,
      cc: uniqueCc,
      bcc: uniqueBcc,
      name: companyName || "WerkAPP CloudMailer",
      replyTo: sender,
      subject: mailTitle,
      message: mailText,
      attachments: filesPath,
      target: target,
    }).then(() => {
      setCloudMailConfirm(true);
    }).catch((err) => {
      console.error(err);
    });
}

  if(cloudMailConfirm) {
    return (<Modal show={cloudMailConfirm} centered>
      <Modal.Body>
        Die CloudMail ist auf dem Weg und wird in Kürze ankommen.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Okay</Button>
      </Modal.Footer>
    </Modal>);
  }

  if(showHistory) {
    return (<Modal show={showHistory} onHide={() => setShowHistory(false)}>
      <Modal.Header>CloudMail Verlauf</Modal.Header>
      <Modal.Body>
        <Historie sentCloudMails={sentCloudMails} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowHistory(false)}>Zurück</Button>
      </Modal.Footer>
    </Modal>);
  }

  return (
    <>
    <Modal show={show} onHide={handleClose} backdrop="static" >
      <Modal.Header closeButton>
        Cloud Mailer
      </Modal.Header>
      <Modal.Body>
        {
          sentCloudMails.length > 0 && <Button variant="outline-primary" className="mb-3" size="sm" onClick={() => setShowHistory(true)}>Vorheriger Nachrichten anzeigen</Button>
        }
        <Form>
          <Row>
            <Col>
              <Form.Group className="mb-2">
                <Form.Text>Absender:</Form.Text>
                <Form.Control as="select" value={sender} onChange={e => setSender(e.target.value)}>
                  {
                    email !== undefined && <option value={email}>{ email }</option>
                  }
                  <option value={userEmail}>{userEmail}</option>
                </Form.Control>
              </Form.Group>  
              <Form.Group className="mb-2">
                <Form.Text>Empfänger:</Form.Text>
                <InputEmail list={recipient} update={setTo}/>
              </Form.Group>  
            </Col>
            <Col>
              <Form.Group className="mb-2">
                <Form.Text>Kopie (CC):</Form.Text>
                <InputEmail list={copy} update={setCc} tabIndex={-1}/>
              </Form.Group>  
              <Form.Group className="mb-2">
                <Form.Text>Blindkopie (BCC):</Form.Text>
                <InputEmail list={blindCopy} update={setBcc} tabIndex={-1}/>
              </Form.Group>  
            </Col>
          </Row>
          <Form.Group className="mb-2">
            <Form.Text>Betreff:</Form.Text>
            <Form.Control value={mailTitle} onChange={e => setMailTitle(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Text>Nachricht:</Form.Text>
            <Form.Control as="textarea" rows="5" value={mailText} onChange={e => setMailText(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Text>Anhänge:</Form.Text>
            <div className="mb-2">
            {uploadedFiles.map((file, index) => 
              <Badge bg="primary" style={{fontWeight:400, fontSize: ".9rem", marginBottom:"5px", marginRight:"5px"}} key={index}>
                {file.name}
                <BsXCircleFill style={{cursor: "pointer", marginLeft: "5px"}} onClick={() => handleRemoveFile(index)} />
              </Badge>
            )}
            </div>
              <label htmlFor="fileInput" className="btn btn-outline-primary btn-sm">
                Dateien auswählen
              </label>
            <Form.Control type="file" id="fileInput" multiple style={{ display: "none" }} onChange={handleFile} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Col align="left">
          <Button variant="outline-primary" onClick={handleExport}>Exportieren</Button>
        </Col>
        <Col align="end">
          <ButtonGroup>
            <Button onClick={handleClose} variant="outline-primary">Verwerfen</Button>
            <Button onClick={handleSend}>Senden</Button>
          </ButtonGroup>
        </Col>
      </Modal.Footer>
    </Modal>
    </>
  )
}

export default CloudMailer