import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { loader } from "../../../redux/actions/database/loader";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FormControl from "react-bootstrap/FormControl";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsSortAlphaDown, BsSortAlphaUp } from "react-icons/bs";
import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";
import { findMondayThisWeek } from "../../../functions/datetime/findMondayThisWeek";
import { findFridayOfWeek } from "../../../functions/datetime/findFridayThisWeek";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import "./dispoboard.css";
// import { updater } from "../../../redux/actions/database/updater";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import InputGroup from "react-bootstrap/InputGroup";
import { objToYMDString } from "../../../functions/datetime/objToYMDString";

const DispoBoard = () => {
  
  const thisMonday = findMondayThisWeek();
  const mondayClone = findMondayThisWeek();
  const nextFriday = findFridayOfWeek(new Date(mondayClone.setDate(mondayClone.getDate() + 12)));
  const { tasks, tasksLoaded, tasksError } = useSelector((state) => state.dispo);
  //const { rights, sales } = useSelector(state => state.auth);
  const [searchString, setSearchString] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [sortKey, setSortKey] = useState("projektName");
  const [sortOrder, setSortOrder] = useState("asc");
  const [dateHeaders, setHeaders] = useState([]);
  const [sortedTasks, setSortedTasks] = useState([]);
  const [startDate, setStartDate] = useState(objToYMDString(thisMonday));
  const [endDate, setEndDate] = useState(objToYMDString(nextFriday));
  const dispatch = useDispatch();
  // get Tasks
  useEffect(() => {
    if (!tasksLoaded) {
      dispatch(loader("tasks"));
    }
  }, [tasksLoaded, dispatch]);

  const formatDate = (date) => {
    const actual = new Date(date);
    const options = { weekday: "short", day: "2-digit", month: "2-digit" };
    return actual.toLocaleDateString("de-DE", options).replace(".", "");
  };

  const parseIntDate = (date) => {
    const dateObj = new Date(date);
    const y = dateObj.getFullYear();
    const m = "00" + (dateObj.getMonth() + 1);
    const d = "00" + (dateObj.getDate());
    return parseInt(y + "" + m.slice(-2) + "" + d.slice(-2));
  }

  const getDateHeaders = () => {
    const headers = [];
    const currentDate = new Date(startDate);
    const maxDate = new Date(endDate);
    while (currentDate <= maxDate) {
      const date = formatDate(currentDate).split(" ");
      const header = {
        date: date[1],
        day: date[0],
        datekey: parseIntDate(currentDate)
      }
      headers.push(header);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return headers;
  };


  const handleSearchStringChange = (e) => {
    setSearchString(e.target.value);
  };

  const handleSortKeyChange = (key) => {
    setSortKey(key);
  };

  const handleSortOrderChange = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const orderTasks = () => {

    const sortedObj = {};
    const start = parseIntDate(startDate);
    const end = parseIntDate(endDate);
    

    for (const taskId in tasks) {
    
      const task = tasks[taskId];
      const projectId = task.projectId;
    
      if(!task.hasOwnProperty("datumInt")) continue;
    
      if(start <= task.datumInt || task.datumInt <= end) {
        if(!sortedObj.hasOwnProperty(projectId)) {
          Object.assign(sortedObj, {[projectId]: {
              projectId: projectId,
              auftraggeber: task?.auftraggeber,
              architekt: task?.architekt,
              projektName: task?.projektName,
              projektBeschreibung: task?.projektBeschreibung,
              anschrift: task?.anschrift,
              PLZ: task?.PLZ,
              ort: task?.ort,
              tasks: {
                [task.datumInt]: {
                  [taskId]: {
                    taskId: taskId,
                    uhrzeit: task?.uhrzeit,
                    beschreibung: task?.beschreibung,
                    aufgabe: task?.aufgabe,
                    staff: task?.staff
                  }
                }
              }
            }});
          } else if (sortedObj[projectId].tasks.hasOwnProperty(task.datumInt)) {
            Object.assign(sortedObj[projectId].tasks[task.datumInt], {
              [taskId]: {
                taskId: taskId,
                uhrzeit: task?.uhrzeit,
                beschreibung: task?.beschreibung,
                aufgabe: task?.aufgabe,
                staff: task?.staff
              }
            });
          } else {
            Object.assign(sortedObj[projectId].tasks, {[task.datumInt]: {
                [taskId]: {
                  taskId: taskId,
                  uhrzeit: task?.uhrzeit,
                  beschreibung: task?.beschreibung,
                  aufgabe: task?.aufgabe,
                  staff: task?.staff
                }
              }
            });
          }
        }
      }
    return Object.values(sortedObj);
  };

  useEffect(() => {
    setHeaders(getDateHeaders());
    setSortedTasks(orderTasks());
  // eslint-disable-next-line
  }, [startDate, endDate]);

  const changeTaskDate = ({source, destination, draggableId}) => {

    const sourceId = source.droppableId; // ID des Quell-Droppable
    const destinationId = destination.droppableId; // ID des Ziel-Droppable
    const taskId = draggableId.split('@')[1]; // ID der verschobenen Aufgabe
  
    // Finde das Projekt, zu dem die Aufgabe gehört, anhand der ID des Quell-Droppable
    const sourceProjectId = sourceId.split('@')[0];
    const sourceDatekey = sourceId.split('@')[1];
    const sourceProject = sortedTasks.find(
      (project) => project.projectId === sourceProjectId
    );
  
    // Finde das Projekt, zu dem die Aufgabe verschoben wird, anhand der ID des Ziel-Droppable
    const destinationProjectId = destinationId.split('@')[0];
    const destinationDatekey = destinationId.split('@')[1];
    const destinationProject = sortedTasks.find(
      (project) => project.projectId === destinationProjectId
    );
  
    // Finde die verschobene Aufgabe
    const task = sourceProject.tasks[sourceDatekey][taskId];
  
    // Entferne die Aufgabe aus dem Quell-Projekt
    delete sourceProject.tasks[sourceDatekey][taskId];
  
    // Wenn keine Aufgaben mehr im Quell-Datum vorhanden sind, entferne das Datum aus dem Quell-Projekt
    if (Object.keys(sourceProject.tasks[sourceDatekey]).length === 0) {
      delete sourceProject.tasks[sourceDatekey];
    }
  
    // Füge die Aufgabe zum Ziel-Projekt hinzu
    if (!destinationProject.tasks.hasOwnProperty(destinationDatekey)) {
      destinationProject.tasks[destinationDatekey] = {};
    }
    destinationProject.tasks[destinationDatekey][taskId] = task;
  
    // Aktualisiere die sortierten Aufgaben
    const updatedSortedTasks = sortedTasks.map((project) => {
      if (project.projectId === sourceProjectId) {
        return sourceProject;
      } else if (project.projectId === destinationProjectId) {
        return destinationProject;
      }
      return project;
    });
  
    setSortedTasks(updatedSortedTasks);
    
    //dispatch(updater("tasks",))
  };
  
  const sortName = {
    projektName: "Projekt",
    PLZ: "PLZ",
    ort: "Ort",
    auftraggeber: "Auftraggeber",
    architekt: "Architekt"
  };

  // Handle Loading or Error
  if (!tasksLoaded) return <Loading />;
  else if (tasksError !== null) return <Error />;
/*
  const TaskCard = () => {
    if(editMode && (sales || (rights.hasOwnProperty("dpd") && rights["dpd"] >= 3))) {
      return (<p>Drag n Drop</p>);
    } else if (sales || (rights.hasOwnProperty("dpd") && rights["dpd"] >= 1)) {
      return (<p>Read</p>);
    } else return null;
  }
*/

  return (
    <>
      <h4 className="pageTitle my-3">Projekt Disposition</h4>
      <Card style={{maxHeight:"100vh"}}>
        <Card.Header>
          <Row>
            <Col>
              <FormGroup>
                <Form.Text>Suche</Form.Text>
                <Form.Control value={searchString} onChange={handleSearchStringChange} />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Sortieren nach</Form.Text>
                <InputGroup>
                  <DropdownButton
                    variant="outline-primary"
                    title={sortName[sortKey]}
                  >
                    <Dropdown.Item as="button" onClick={() => handleSortKeyChange("projektName")}>Projekt</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => handleSortKeyChange("PLZ")}>PLZ</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => handleSortKeyChange("ort")}>Ort</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => handleSortKeyChange("auftraggeber")}>Auftraggeber</Dropdown.Item>
                  </DropdownButton>
                  <Button
                    variant="outline-primary" onClick={handleSortOrderChange}>
                    {(sortOrder === "asc") ? <BsSortAlphaDown /> : <BsSortAlphaUp />}
                  </Button>
                </InputGroup>
              </FormGroup>
              
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Lese- / Schreibmodus</Form.Text>
                <Button style={{display:"block"}} variant={editMode ? "danger" : "outline-primary"} onClick={() => setEditMode(!editMode)}>
                  { editMode ? "Editier Modus" : "Lese Modus" }
                </Button>
              </FormGroup>
            </Col>
            <Col xs={6} md={2}>
              <FormGroup>
                <Form.Text>Startdatum</Form.Text>
                <FormControl type="date" id="startDate" value={startDate} onChange={handleStartDateChange} />
              </FormGroup>
            </Col>
            <Col xs={6} md={2}>
              <FormGroup>
                <Form.Text>Enddatum</Form.Text>
                <FormControl type="date" id="endDate" value={endDate} onChange={handleEndDateChange} />
              </FormGroup>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body style={{position: 'relative', display: "flex", overflow: 'hidden'}}>
          <div className="table-container">
          <Table bordered style={{fontSize:".9rem"}}>
           
            <thead>
              <tr>
                <th style={{minWidth:"220px"}} className="fixedPositionLeft">Projekt</th>
                {dateHeaders.map(({datekey, day, date}) => (
                  <th style={{minWidth:"200px"}} className="fixedPositionTop" key={"TH_"+datekey} id={"TH_"+datekey}>{day}<br/>{date}</th>
                ))}
              </tr>
            </thead>
            <DragDropContext onDragEnd={changeTaskDate}>
            <tbody>
              {sortedTasks.map((project) => (
                <tr key={project.projectId}>
                  <th className="fixedPositionLeft" key={project.projectId}>{project.projektName}<br/>{project.auftraggeber}</th>
                  {dateHeaders.map(({ datekey }) => (
                    <Droppable droppableId={project.projectId + '@' + datekey} key={project.projectId + '@' + datekey}>
                    {(provided) => ( <td
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      key={project.projectId + '@' + datekey}
                      id={project.projectId + '@' + datekey}
                      className="dispoBoardDay"
                    >
                      {project.tasks.hasOwnProperty(datekey) &&
                      Object.keys(project.tasks[datekey]).length > 0 ? 
                        Object.values(project.tasks[datekey]).map(
                                (task, taskIndex) => (
                                  <Draggable
                                    draggableId={`task@${task.taskId}`}
                                    index={taskIndex}
                                    key={task.taskId}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        key={task.taskId}
                                        className="dispoBoardTaskCard"
                                      ><p className="dispoBoardTaskCardTitle">{task.beschreibung}</p>
                                      <div className="dispoBoardStaffDrop"></div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              
                          
                      ) : null}
                      {provided.placeholder}
                    </td> )}
                    </Droppable>
                  ))}
                  
                </tr>
              ))}
            </tbody>
            </DragDropContext>
          </Table>
          </div>
        </Card.Body>
      </Card>
      
    </>
  );
};

export default DispoBoard;

