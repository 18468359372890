import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../redux/actions/database/loader";
import { BsSortAlphaDown, BsSortAlphaUp, BsSortNumericUp, BsSortNumericDown } from "react-icons/bs";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import Error from "../../APP/Messages/Error";
import Loading from "../../APP/Messages/Loading";
import Badge from "react-bootstrap/Badge";
import ShowDetail from "./ShowDetail";

const FilterAndSort = ({ q = null, status = null, toggleModal }) => {
  const dispatch = useDispatch();
  const { staff, staffLoaded, staffError } = useSelector(state => state.dispo);
  const [showDetail, setShowDetail] = useState(false);
  const [subPage, setSubPage] = useState(null);
  const [selected, setSelected] = useState({});
  const [sortedContacts, setSortedContacts] = useState([]);
  const [sortProperty, setSortProperty] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const pageSize = 15;
  const [currentPage, setCurrentPage] = useState(1);

  const handleClick = (data, target = null) => {
    setSubPage(target);
    setSelected(data);
    setShowDetail(true);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleClose = () => {
    setShowDetail(false);
    setSelected({});
  };

  const handleSort = (property) => {
    if (property === sortProperty) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortProperty(property);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    if (!staffLoaded) {
      dispatch(loader("staff"));
    }
  }, [staffLoaded, dispatch]);

  useEffect(() => {
    let sorted = Object.entries(staff).sort(([, a], [, b]) => {
      if (a && b) {
        const nameA = a.nachname || "";
        const nameB = b.nachname || "";
        return nameA.localeCompare(nameB);
      }
      return 0;
    });

    if (sortProperty) {
      sorted.sort(([, a], [, b]) => {
        const valueA = a && a[sortProperty] ? a[sortProperty].toLowerCase() : "";
        const valueB = b && b[sortProperty] ? b[sortProperty].toLowerCase() : "";

        if (sortOrder === "asc") {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      });
    }
    if(status && status !== "alle") {
      sorted = sorted.filter(([, employee]) => {
        return (employee?.status && employee.status === status);
      })
    }

    if (q) {
      sorted = sorted.filter(([, employee]) => {
        const searchQuery = q
          .toLowerCase()
          .replace(/[^a-z0-9]/gi, "")
          .replace(/strasse|straße|str./g, "str");
        
        const vorname = employee.vorname || "";
        const name = employee.nachname || "";
        const address = employee.anschrift || "";
        const zipCodeAndCity = (employee.PLZ || "") + " " + (employee.ort || "");

        return (
          vorname.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          name.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          address.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          zipCodeAndCity.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery)
        );
      });
    }

    setSortedContacts(sorted);
  }, [staff, sortProperty, sortOrder, q, status]);

  if (staffError !== null) {
    return <Error />;
  }

  if (!staffLoaded) {
    return <Loading />;
  }

  const totalContacts = sortedContacts.length;
  const totalPages = Math.ceil(totalContacts / pageSize);
  const staffToShow = sortedContacts.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const renderTooltip = (title) => (
    <Tooltip id="button-tooltip" placement="top" delay={{ show: 250, hide: 400 }}>
      {title}
    </Tooltip>
  );

  const color = (status) => {
    if(status === "aktuell") return "primary";
    else if(status === "ehemalige") return "dark";
    else return "secondary";
  }

  if (Object.keys(staff).length === 0) {
    return (
      <Alert variant="primary">
        Sie haben noch keine Mitarbeiter, legen Sie jetzt einen an.
        <br />
        <br />
        <Button variant="primary" onClick={toggleModal}>
          Neuer Mitarbeiter
        </Button>
      </Alert>
    );
  }
  else if (staffToShow.length === 0) {
    return (
      <Alert variant="primary">
        Es gibt keine Übereinstimmungen.
      </Alert>
    );
  }
  

  return (
    <>
      <Table striped hover>
        <thead>
          <tr>
            <th onClick={() => handleSort("vorname")} style={{cursor:"pointer"}}>
                Vorname {sortProperty === "vorname" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("nachname")} style={{cursor:"pointer"}}>
                Nachname {sortProperty === "nachname" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("anschrift")} style={{cursor:"pointer"}}>
              Anschrift {sortProperty === "anschrift" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("PLZ")} style={{cursor:"pointer"}}>
              PLZ {sortProperty === "PLZ" && (sortOrder === "asc" ? <BsSortNumericDown /> : <BsSortNumericUp />)}
            </th>
            <th onClick={() => handleSort("ort")} style={{cursor:"pointer"}}>
              Ort {sortProperty === "ort" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {staffToShow.map(([key, employee], index) => (
            <tr key={index}>
              <td valign="middle">{employee?.vorname}</td>
              <td valign="middle">{employee?.nachname}</td>
              <td valign="middle">{employee?.anschrift || ""}</td>
              <td valign="middle">{employee?.PLZ || ""}</td>
              <td valign="middle">{employee?.ort || ""}</td>
              <td valign="middle" align="end">
                <Badge bg={color(employee?.status)} style={{fontWeight:500, textTransform: "capitalize", marginRight:"1rem"}} pill>
                  { employee?.status }
                </Badge>
                  <OverlayTrigger overlay={renderTooltip("Details öffnen")}>
                    <Button onClick={() => handleClick({ id: key, ...staff[key] })} variant="outline-primary">
                      Details
                    </Button>
                  </OverlayTrigger>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination className="text-center">
        {
          (currentPage > 1) && <>
            <Pagination.First onClick={() => handlePageChange(1)}/>
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}/>
          </>
        }
        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
          <Pagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Pagination.Item>
        ))}
        {
          (currentPage < totalPages) && <>
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}/>
            <Pagination.Last onClick={() => handlePageChange(totalPages)}/>
          </>
        }
        </Pagination>
      </div>
      {Object.keys(selected).length > 0 && showDetail && (
        <ShowDetail showModal={showDetail} handleClose={handleClose} employee={selected} subPage={subPage} />
      )}
    </>
  );
};

export default FilterAndSort;
