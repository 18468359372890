import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "./dashboard.css";

const LimitedOffer = () => {

  const {testDate} = useSelector(state => state.crm.settings?.settings);

  const [limitedOffer, setLimitedOffer] = useState(false);
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const daysBefore = 7;

  const convertToDateObject = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6) - 1;
    const day = dateString.substring(6, 8);
    return new Date(year, month, day);
  };

  const calculateCountdown = () => {
    const targetDate = convertToDateObject(testDate);
    const fiveDaysBeforeTarget = new Date(targetDate.getTime() - daysBefore * 24 * 60 * 60 * 1000);

    const currentDate = new Date();
    const timeDifference = fiveDaysBeforeTarget - currentDate;

    if (timeDifference <= 0) {
      setLimitedOffer(false);
      return;
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    setLimitedOffer(days >= 5);
    setCountdown({ days, hours, minutes, seconds });
  };

  useEffect(() => {
    const targetDate = convertToDateObject(testDate);
    const fiveDaysBeforeTarget = new Date(targetDate.getTime() - daysBefore * 24 * 60 * 60 * 1000);
    if (fiveDaysBeforeTarget <= new Date()) {
      setLimitedOffer(false);
      return;
    }

    calculateCountdown();

    const timer = setInterval(() => {
      calculateCountdown();
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, []);

  if(!limitedOffer) return "";


  return (
    <Col xs={12} className="mb-3">
      <Card style={{overflow:"hidden"}}>
        <Card.Body className="discountOffer py-4">
          <Card.Title>Befristetes Angebot</Card.Title>
          <h1 className="my-3">Jetzt 10% sparen!</h1>
          <p>Heute erste Rechnung bezahlen und 10% sparen.</p>
          <Button variant="accent1" size="lg" className="mb-2 text-white" style={{width: "50vw"}}>Jetzt bezahlen</Button>
          <p className="fw-semibold">Angebot verfällt in {countdown.days > 0 && (countdown.days > 1) ? countdown.days + " Tagen" : countdown.days + " Tag"}<br/>
            {countdown.hours} Stunden, {countdown.minutes} Minuten, {countdown.seconds} Sekunden.</p>
          <p style={{margin:0, fontSize: "12px", opacity: .7}}>Hinweis: Damit entfällt die kostenlose Kündigungsmöglichkeit</p>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default LimitedOffer;
