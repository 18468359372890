import { useState, useEffect } from "react";
import { Alert, Card, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../../redux/actions/database/loader";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Error from "../../../APP/Messages/Error";
import Loading from "../../../APP/Messages/Loading";


const Measurments = ({projectId}) => {

  const dispatch = useDispatch();
  const {measurements, measurementsLoaded, measurementsError} = useSelector(state => state.erp);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if(!measurementsLoaded) {
      dispatch(loader("measurements"));
    }
  }, [measurementsLoaded, dispatch]);

  useEffect(() => {
    if(measurementsLoaded && measurementsError === null) {
      const result = [];
      for (const i in measurements) {
        const item = measurements[i];
        if(item.projectId === projectId) {
          result.push({...item, id: i});
        }
      }
      setData(result.reverse());
    }

  }, [measurements, measurementsLoaded, measurementsError, projectId]);

  if(!measurementsLoaded) {
    return <Loading />;
  }
  if(measurementsError !== null) {
    return <Error />;
  }

  const renderTimestamp = (date) => {
    const thisDate = (date === null || date === undefined) ? new Date() : new Date(date);
    return (thisDate.getDate()).toString().padStart(2,"0") + "." + (thisDate.getMonth() + 1).toString().padStart(2,"0") + "." + thisDate.getFullYear();
  }

  return (
    <Card>
      <Card.Body>
        <div className="d-flex mb-2">
          <p className="my-1 fw-semibold">Aufmaße</p>
          <div style={{flex: "1 0 0", display: "flex", justifyContent: "flex-end"}}>
            <Button size="sm" as={Link} to={"/measurments/addproject/"+projectId} variante="outline-primary">Neu hinzufügen</Button>
          </div>
        </div>
        
        { data.length === 0 ? <Alert variant="primary">Es sind keine Aufmaße vorhanden.</Alert> :
          
          <Table hover>
            <tbody>
              {
                 data.map((doc, index) => <tr key={index} style={{cursor:"pointer"}} onClick={() => navigate("/measurments/edit/" + doc?.id)}>
                  <td style={{cursor:"pointer"}}>{ (doc?.status ? doc.status + "es " : "") }Aufmaß { doc?.doc } vom { renderTimestamp((doc?.docDate ? doc.docDate : doc.createdAt)) }</td>
                 </tr>)
              }
            </tbody>
          </Table> 
         
        }
      </Card.Body>
    </Card>
  )
}

export default Measurments