import Container from "react-bootstrap/Container";
import { Outlet } from "react-router-dom";
import SEO from "../../../components/SEO";
import { useSelector } from "react-redux";
import Footer from "../Footer/Footer";
import Menu from "../Menu/Menu";

const UI = () => {

  const companyName = useSelector(state => state.crm.settings.companyName);
  const { sales } = useSelector(state => state.auth.tokens);

  return (
    <Container fluid>
      <SEO title={ sales? "DEMO" : companyName } first={true} />
      <Menu />
      <Outlet/>
      <Footer />
    </Container>
  )
}

export default UI