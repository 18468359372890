import React, { useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import FormControl from 'react-bootstrap/FormControl';
import { BsXCircleFill } from 'react-icons/bs';

const InputEmail = ({list = [], update = () => {}, tabIndex = null}) => {

  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState([...list]);

  const handleBlur = () => {
    if (update && validateEmail(email)) {
      addEmail();
    }
  };
  
  const addEmail = () => {
    if (validateEmail(email)) {
      if (!emailList.includes(email)) { // Überprüfe, ob die E-Mail bereits in der Liste ist
        setEmailList([...emailList, email]);
      } 
      setEmail('');
    }
  };
  

  const removeEmail = (index) => {
    const updatedEmailList = [...emailList];
    updatedEmailList.splice(index, 1);
    setEmailList(updatedEmailList);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ' || event.key === ';' || event.key === ',') {
      event.preventDefault();
      addEmail();
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  // Funktion zur E-Mail-Validierung mit einem Regulären Ausdruck (Regex)
  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  useEffect(() => {
    update(emailList);
    // eslint-disable-next-line
  }, [emailList])

  return (
    <div>
      <div className="d-flex" style={{flexWrap: "wrap"}}>
        {
          emailList.map((e, index) => 
          <Badge bg={validateEmail(e) ? "primary" : "danger"} style={{fontWeight:400, fontSize: ".9rem", margin:"0 5px 5px 0"}} key={index}>
            {e}
            <BsXCircleFill style={{cursor: "pointer", marginLeft: "5px"}} onClick={() => removeEmail(index)} />
          </Badge>
        )
        }
      </div>
      <FormControl
        type="email"
        value={email}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        placeholder="E-Mail Adresse"
        tabIndex={tabIndex}
      />
    </div>
  )
}

export default InputEmail