import { useSelector, useDispatch } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { authListener } from '../../redux/actions/auth/listener';
import { publicRoutes } from '../../config/routes';
import WerkAPP from '../../modules/WerkAPP';
//import { logout } from '../../redux/actions/auth/logout';

const AutoLogin = () => {

  const user = useSelector(state => state.auth);
  const router = createBrowserRouter(publicRoutes);
  const dispatch = useDispatch();
  if(!user.isLoaded) {
    dispatch(authListener());
  }
  //dispatch(logout());
  if(!user.isLoggedIn) {
    return (<RouterProvider router={router} />);
  }
  return (
    <WerkAPP/>
  )
}

export default AutoLogin