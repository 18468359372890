import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const Verwalten = () => {

  const {rights, sales} = useSelector(state => state.auth.tokens);

  return (
    <div className="menuNav">
      <p className="menuNavBtn">Verwalten</p>
      <div className="menuSubmenu">
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("crm") && rights["crm"] > 0)) && <NavLink to="/contacts" className="menuSubBtn">Kontaktverwaltung
          <span className="menuSubBtnSmall">Kontakte erstellen und finden</span></NavLink>
        }
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("cpm") && rights["cpm"] > 0)) && <NavLink to="/projects" className="menuSubBtn">Projektverwaltung
          <span className="menuSubBtnSmall">Projekte und Baustellen verwalten</span></NavLink>
        }
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("dsm") && rights["dsm"] > 0)) && <NavLink to="/staff" className="menuSubBtn">Mitarbeiterverwaltung
          <span className="menuSubBtnSmall">Gewerbliche Mitarbeiter verwalten</span></NavLink>
        }
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("eam") && rights["eam"] > 0)) && <NavLink to="/articles" className="menuSubBtn">Artikel & Leistungen
          <span className="menuSubBtnSmall">Artikelstammdaten</span></NavLink>
        }
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("erm") && rights["erm"] > 0)) && <NavLink to="/leads" className="menuSubBtn">Anfragen erfassen
          <span className="menuSubBtnSmall">Anfragen schnell erfassen</span></NavLink>
        }
      </div>
    </div>);
}

export default Verwalten