import {useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { defaultData, driverClasses, defaultStates } from "./defaultData";
import { useDispatch } from "react-redux";
import { updater } from "../../../redux/actions/database/updater";

const EditStaff = ({obj, handleClose}) => {

  const {actualStaff, staffId} = obj;
  const [staff, setStaff] = useState({...defaultData, ...actualStaff});
  const [saved, setSaved] = useState(actualStaff);
  const [isDirty, setDirty] = useState(false);
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const handleCheckbox = e => {
    const arr = [...staff.fS];
    if(e.target.checked) {
      arr.push(e.target.value);
      setStaff(prev => ({...prev, fS: arr}));
    } else {
      const value = e.target.value;
      setStaff(prev => ({...prev, fS: arr.filter(item => item !== value)}));
    }
    setDirty(true);
  }
  const handleChange = e => {
    setDirty(true);
    setStaff(prev => ({...prev, [e.target.name]: e.target.value }));
  }
  const handleReset = e => {
    if(window.confirm("Alle Eingaben verwerfen?")) {
      setStaff({...defaultData, ...actualStaff});
      setSaved(actualStaff);
      setValidated(false);
      setDirty(false);
      handleClose();
    }
  }
  const handleSave = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    dispatch(updater('staff', staff, staffId, saved));
    setSaved(staff);
    setDirty(false);
    setValidated(false);
    handleClose();
  }

  return (
    <Form onSubmit={handleSave} noValidate validated={validated}>
      <Row>
        <Col xs={12} md={4}>
          <h6>Daten zur Person</h6>
          <FormGroup>
            <Form.Text>Anrede</Form.Text>
            <Form.Select name="anrede" value={staff.anrede} onChange={handleChange} required>
              <option value="">Bitte wählen</option>
              <option value="Herr">Herr</option>
              <option value="Frau">Frau</option>
            </Form.Select>
          </FormGroup>
          <FormGroup>
            <Form.Text>Vorname</Form.Text>
            <Form.Control name="vorname" value={staff.vorname} onChange={handleChange} required />
          </FormGroup>
          <FormGroup>
            <Form.Text>Nachname</Form.Text>
            <Form.Control name="nachname" value={staff.nachname} onChange={handleChange} required />
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Form.Text>Geburtsdatum</Form.Text>
                <Form.Control type="date" name="geburtsdatum" value={staff.geburtsdatum} onChange={handleChange} />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Status</Form.Text>
                <Form.Select name="status" value={staff.status} onChange={handleChange}>
                  {
                    defaultStates.map((status, id) => <option key={id} value={status}>{ status }</option>)
                  }
                </Form.Select>
              </FormGroup>
            </Col>
          </Row>
          
        </Col>
        <Col xs={12} md={4}>
          <h6>Anschrift</h6>
          <FormGroup>
            <Form.Text>Straße, Nr.</Form.Text>
            <Form.Control name="anschrift" pattern="[a-zA-ZäöüÄÖÜß0-9\s/-]+" value={staff.anschrift} onChange={handleChange}/>
          </FormGroup>
          <Row>
            <Col xs={4}>
              <FormGroup>
                <Form.Text>PLZ</Form.Text>
                <Form.Control pattern="[0-9]{5}" name="PLZ" value={staff.PLZ} onChange={handleChange}/>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Ort</Form.Text>
                <Form.Control name="ort" pattern="[A-Za-zäöüßÄÖÜ-]+\s*([A-Za-zäöüßÄÖÜ-]+\s*)*" value={staff.ort} onChange={handleChange}/>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Form.Text>Land</Form.Text>
            <Form.Control name="land" pattern="[A-Za-zäöüßÄÖÜ-]+\s*([A-Za-zäöüßÄÖÜ-]+\s*)*" value={staff.land} onChange={handleChange}/>
          </FormGroup>
        </Col>
        <Col xs={12} md={4}>
          <h6>Weitere Daten</h6>
          <Row>
            <Col>
              <FormGroup>
                <Form.Text>Eintrittsdatum</Form.Text>
                <Form.Control type="date" name="eintrittsdatum" value={staff.eintrittsdatum} onChange={handleChange} required />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Austrittsdatum</Form.Text>
                <Form.Control type="date" name="austrittsdatum" value={staff.austrittsdatum} onChange={handleChange} />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Form.Text>Führerschein</Form.Text>
            <div className="inline-checkbox">
            {
              driverClasses.map((klasse, id) => <Form.Check inline type="checkbox" checked={staff.fS.includes(klasse)} value={klasse} key={id} label={klasse} onChange={handleCheckbox} />)
            }
            </div>
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Form.Text>Führerschein gültig bis</Form.Text>
                <Form.Control type="date" name="fSGültigkeit" value={staff.fSGültigkeit} onChange={handleChange} />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Sichtprüfung</Form.Text>
                <Form.Select name="fSPrüfen" value={staff.fSPrüfen} onChange={handleChange}>
                  <option value="">Bitte wählen</option>
                  <option value="3">Alle 3 Monate</option>
                  <option value="6">Alle 6 Monate</option>
                  <option value="9">Alle 9 Monate</option>
                  <option value="12">Alle 12 Monate</option>
                </Form.Select>
              </FormGroup>
            </Col>
          </Row>
          
        </Col>
      </Row>
      <Row>
      <Col align="end">
        <ButtonGroup>
          <Button onClick={handleReset} disabled={!isDirty} variant="outline-primary">Verwerfen</Button>
          <Button type="submit" disabled={!isDirty}>Speichern</Button>
        </ButtonGroup>
      </Col>
    </Row>
    </Form>
  )
}

export default EditStaff