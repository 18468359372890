import { useState } from "react";
import { news } from "./newsData.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./news.css";

const News = () => {

  const [newsOpen, setNewsOpen] = useState(1);

  return (
    <>
    <h4 className="pageTitle">Neuigkeiten</h4>
    <Row>
      <Col xs={12} md={4} lg={2} className="d-flex flex-column gap-2 justify-content-start align-items-start">
        {
          news.map((obj, index) => <div key={index} onClick={() => setNewsOpen(index)} className={"newsPrev" + (newsOpen === index ? " active" : "")}>
            <p className="newsPrevTitle">Version { obj.version} { obj.date === "geplant" ? obj.date : "veröffentlicht am: " + obj.date }</p>
            <p className="newsPrevText">{ obj.intro.length > 100 ? obj.intro.substring(0, 100 - 3) + "..." : obj.intro  }</p>
          </div>)
        }
      </Col>
      <Col xs={12} md={6}><div className="newsFull">
        {
          (newsOpen !== "" && news[newsOpen] !== null) && <>
            <p className="newsFullTitle">Version { news[newsOpen].version} { news[newsOpen].date === "geplant" ? news[newsOpen].date : "veröffentlicht am: " + news[newsOpen].date }</p>
            <p className="newsFullIntro">{ news[newsOpen].intro }</p>
              {
                news[newsOpen].newFunctions.length > 0 && <div>
                  <p className="newsFullTitle">Neue Funktionen</p>
                  <ul>
                    {
                      news[newsOpen].newFunctions.map((item, idx) => <li key={idx}>{ item }</li>)
                    }
                  </ul>
                </div>
              }
              {
                news[newsOpen].improvements.length > 0 && <div>
                  <p className="newsFullTitle">Verbesserungen</p>
                  <ul>
                    {
                      news[newsOpen].improvements.map((item, idx) => <li key={idx}>{ item }</li>)
                    }
                  </ul>
                </div>
              }
              {
                news[newsOpen].bugFixes.length > 0 && <div>
                  <p className="newsFullTitle">Fehlerbehebung</p>
                  <ul>
                    {
                      news[newsOpen].bugFixes.map((item, idx) => <li key={idx}>{ item }</li>)
                    }
                  </ul>
                </div>
              }
          </>
        }
        </div>
      </Col>
    </Row>
    </>
  )
}

export default News