export const defaultTL = {
  art: "Löhne",
  desc: "",
  menge: 0,
  EP: 0,
  ZS: 0,
  VP: 0
};
export const defaultStatus = ["Artikel","Gelöscht"];
// Artikel, Kombiartikel
export const defaultTyp = ["Löhne", "Stoffe", "Geräte", "Fremdleistung", "Sonstige"];
export const defaultArt = ["Normal", "Mietleistung"];
export const defaultEinheit = ["cm", "m", "stgm", "m²", "m³", "l", "kg", "min", "h", "Tag", "St", "VE", "Palette", "psch"];
export const einheitsTyp = {cm: "l", m: "l", stgm: "h", "m²": "lh" , "m³": "lbh", l: "e", kg: "e", min: "e", h: "e", Tag: "e", St: "", VE: "e", Palette: "e", psch: ""};
export const defaultMieteinheit = ["Tg", "Wo", "Mo"];
export const umrechnungMiete = {min: (24*60), h: 24, WrkTg: (1/(7*6)), ArbTg: (1/(7*5)), Tg: 1, Wo: (1/7), Mo: (1/(365*12))};
// Ausgangseinheit * faktor / neue Einheit
// Grundeinheit = 1 Tag
export const defaultData = {
  EK: 0,
  SK: 0,
  Lohn: 0,
  Zeit: 0,
  Stoffe: 0,
  Geräte: 0,
  Fremdleistung: 0,
  Sonstige: 0,
  ZS: 0,
  VK: 0,
  USt: 0.19,
  art: "",
  gvhEinheit: null,
  gvHMenge: null,
  kalkulation: "Teilleistungsrechnung",
  status: defaultStatus[0],
  kurztext: "",
  langtext: "",
  teilleistungen: [defaultTL],
  lv: "",
  newLV: "",
  suchWorte: "",
  einheit: "",
  mietEinheit: null,
  mietPreis: null,
};
export const defaultArticle = {
  status: defaultStatus[0],
  art: defaultArt[0],
  kurztext: "",
  langtext: "",
  lv: "",
  newLV: "",
  einheit: defaultEinheit[1],
  VorhalteDauer: 0,
  VorhalteEinheit: defaultMieteinheit[1],
  TK: {
    EP: "0,000",
    NumericEP: 0,

    Zeit: "0,000",
    NumericZeit: 0,
    Stundenlohn: "0,000",
    NumericStundenlohn: 0,
    NumericLohn: 0,

    Stoffkosten: "0,000",
    NumericStoffkosten: 0,
    NumericStoffe: 0,
    Gerätekosten: "0,000",
    NumericGerätekosten: 0,
    NumericGeräte: 0,
    Sonstigekosten: "0,000",
    NumericSonstigekosten: 0,
    NumericSonstige: 0,
    Fremdkosten: "0,000",
    NumericFremdkosten: 0,
    NumericFremd: 0,
    StoffZuschlag: "0,000",
    NumericStoffZuschlag: 0,
    GeräteZuschlag: "0,000",
    NumericGeräteZuschlag: 0,
    SonstigeZuschlag: "0,000",
    NumericSonstigeZuschlag: 0,
    FremdZuschlag: "0,000",
    NumericFremdZuschlag: 0,
  }
};
