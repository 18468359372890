import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { defaultData } from "./defaultData";
import { loader } from "../../../redux/actions/database/loader";

import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";

import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { saveDB } from "../../../functions/database/save";
import { serverTimestamp } from "firebase/database";


const Add = ({show, onHide}) => {
  
  const now = new Date();
  const today = now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2, "0") + "-" + now.getDate().toString().padStart(2,"0");

  const [data, setData] = useState(defaultData);
  const [isDirty, setDirty] = useState(false);
  const [validated, setValidated] = useState(false);
  const [persons, setPersons] = useState([]);
  const {uid, tokens} = useSelector(state => state.auth);
  const {sales, rights} = tokens;
  const {team, teamLoaded, teamError} = useSelector(state => state.crm);
  const {staff, staffLoaded, staffError} = useSelector(state => state.dispo);

  console.log(sales, rights);
  const dispatch = useDispatch();


  const orderedPersons = () => {
    
    const timestamp = parseIntFromDate(today);

    let arr = [];
    if(team !== null && team !== undefined && Object.keys(team).length > 0) {
      arr = [...arr, ...Object.entries(team)];
    }
    if(staff !== null && staff !== undefined && Object.keys(staff).length > 0) {
      arr = [...arr, ...Object.entries(staff)];
    }
    arr = arr.filter(([,a]) => {
      const austritt = parseIntFromDate(a?.austrittsdatum);
      if (austritt === null) return true;
      return austritt > timestamp;});
    arr.sort(([,a],[,b]) => a?.nachname.localeCompare(b?.nachname));
    return arr;
  }  

  useEffect(() => {
    if(!staffLoaded) {
      dispatch(loader("staff"));
    }
    if(!teamLoaded) {
      dispatch(loader("team"));
    }
    if(staffLoaded && teamLoaded) {
      setPersons(orderedPersons());
    }
    // eslint-disable-next-line
  }, [teamLoaded, staffLoaded]);

  if (teamError !== null || staffError !== null) return <Error />;
  if (!staffLoaded || !teamLoaded) return <Loading />;

  const parseIntFromDate = (date) => {
    if(date === undefined || date === null || date === "") return null;
    return parseInt(date.replaceAll("-",""));
  }

  const handleChange = e => {
    const {name, value} = e.target;
    setDirty(true);
    setData(prev => ({...prev, [name]: value}));
  }

  const handleReset = e => {
    e.preventDefault();
    e.stopPropagation();
    if(isDirty) {
      if(window.confirm("Alle Eingaben verwerfen?")) {
        setDirty(false);
        setData(defaultData);
        onHide();
      }
      return;
    }
    else {
      setData(defaultData);
      onHide();
      return;
    }
  }

  const handleSubmit = e => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
      return;  
    }
    let obj = {...data};
    if (data.anlass === "") return;
    
    else if(data.anlass === "urlaub") {
      obj.acceptedAt = serverTimestamp();
      obj.acceptedBy = uid;
      obj.acceptedReasons = data.grund;
      obj.anlass = null;
      obj.grund = null;
    }
    else if (data.anlass === "antrag") {
      obj.anlass = null;
    }
    else if (data.anlass === "krank") {
      obj.krank = true;
      obj.anlass = null;
    }
    saveDB("vacations", obj);
    setData(defaultData);
    setDirty(false);
    setValidated(false);
    onHide();
  }

  return (
    <Modal show={show} onHide={onHide}>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Modal.Header closeButton>Neue Abwesenheit</Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Text>Mitarbeiter</Form.Text>
          <Form.Select name="uid" value={data.uid} onChange={handleChange} required >
            <option value="">Bitte wählen</option>
            {
              persons.map(([id, person], _) => <option key={id} value={id}>{person?.nachname}{ person?.vorname && ", " + person.vorname}</option>)
            }
          </Form.Select>
        </Form.Group>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Text>Von</Form.Text>
              <Form.Control type="date" name="start" value={data.start} onChange={handleChange} required />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Text>Bis</Form.Text>
              <Form.Control type="date" min={data.start} name="ende" value={data.ende} onChange={handleChange} required />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3">
          <Form.Text>Anlass</Form.Text>
          <Form.Select name="anlass" value={data.anlass} onChange={handleChange} required>
            <option value="">Bitte wählen</option>
            <option value="krank">Krankheit</option>
            <option value="urlaub">Urlaub (Genehmigt)</option>
            <option value="antrag">Urlaub (Antrag)</option>
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Text>Anmerkung</Form.Text>
          <Form.Control as="textarea" rows="3" value={data.grund} name="grund" onChange={handleChange} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Col align="end">
          <ButtonGroup>
            <Button onClick={handleReset} variant="outline-primary">Verwerfen</Button>
            <Button type="submit" disabled={!isDirty}>Speichern</Button>
          </ButtonGroup>
        </Col>
      </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default Add