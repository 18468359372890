import { getDatabase, push, ref } from "firebase/database";
import { useState } from "react";
import { Alert, Button, FormGroup, Table } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";

const Support = () => {

  const url = window.location.href;
  const auth = useSelector(state => state.auth);
  const [bugForm, setBugForm] = useState({contact: "", errorMsg: ""});
  const [bugSent, setBugSent] = useState(false);

  const handleChange = e => setBugForm(prev => ({...prev, [e.target.name]: e.target.value}));

  const submitBug = e => {
    const data = {
      user: auth.uid,
      name: auth.displayName,
      client: auth.tokens?.client || "sales",
      contact: bugForm.contact,
      errorDesc: bugForm.errorMsg,
      url: url
    };
    push(ref(getDatabase(),"support"),data)
    .then(() => {setBugSent(true); setBugForm({contact: "", errorMsg: ""}); })
    .catch(err => console.error(err));
  }

  return (
    <Accordion>
      <Accordion.Item eventKey="hilfe">
        <Accordion.Header>Hilfe anfordern</Accordion.Header>
        <Accordion.Body>
          <h6>Kontaktmöglichkeiten:</h6>
          <Table>
            <tbody>
              <tr>
                <td>
                  WhatsApp Chat
                </td>
                <td>
                  <a href="https://wa.me/4915238527072">+49 1523 85 27072</a><br/>
                  <span className="text-muted" style={{fontSize:"10px"}}>(Keine Anrufe möglich)</span>
                </td>
              </tr>
              <tr>
                <td>
                  E-mail
                </td>
                <td>
                  <a href="mailto:support@werkapp.de">support@werkapp.de</a>
                </td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="fehler">
        <Accordion.Header>Einen Fehler melden</Accordion.Header>
        <Accordion.Body>
          {
            bugSent && <Alert variant="success">Danke für Ihre Hilfe! Wir kümmern uns schnellstmöglich um den Fehler.</Alert>
          }
          Eine Fehler melden, damit wir diesen schnell beheben können.
          <FormGroup className="my-2">
            <Form.Text>Ihr Name</Form.Text>
            <Form.Control value={auth.displayName} disabled />
          </FormGroup> 
          <FormGroup className="my-2">
            <Form.Text>Wie können wir Sie bei Rückfragen erreichen?</Form.Text>
            <Form.Control name="contact" value={bugForm.contact} onChange={handleChange} />
          </FormGroup> 
          <FormGroup className="my-2">
            <Form.Text>Fehlerbeschreibung</Form.Text>
            <Form.Control name="errorMsg" value={bugForm.errorMsg} onChange={handleChange} as="textarea" rows={3} />
          </FormGroup>
          <Button onClick={submitBug} className="my-2">Absenden</Button>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default Support