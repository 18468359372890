export const defaultData = {
  status: "",
  anrede: "",
  vorname: "",
  name: "",
  adresszusatz: "",
  anschrift: "",
  PLZ: "",
  ort: "",
  land: "DEUTSCHLAND",
  telefon: "",
  email: "",
  webseite: "",
  branche: "",
  rechtsform: "",
  erstkontakt: "",
};
export const defaultStates = ["Kunde", "Architekt", "Lieferant", "Sonstige"];