import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../redux/actions/database/loader";
import { BsSortAlphaDown, BsSortAlphaUp, BsSortNumericUp, BsSortNumericDown } from "react-icons/bs";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Error from "../../APP/Messages/Error";
import Loading from "../../APP/Messages/Loading";
import { convertDateFormat } from "../../../functions/datetime/convertDateFormat";
import { objToYMDString } from "../../../functions/datetime/objToYMDString";
import { strToInteger } from "../../../functions/datetime/strtoIntger";
import TaskBadge from "../Helper/TaskBadge";

const FilterAndSort = ({ q = null, status = null, modalAdd, modalDetail}) => {
  const dispatch = useDispatch();
  const { tasks, tasksLoaded, tasksError } = useSelector(state => state.dispo);
  const [sortedContacts, setSortedContacts] = useState([]);
  const [sortProperty, setSortProperty] = useState("datum");
  const [sortOrder, setSortOrder] = useState("asc");
  const pageSize = 25;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSort = (property) => {
    if (property === sortProperty) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortProperty(property);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    if (!tasksLoaded) {
      dispatch(loader("tasks"));
    }
  }, [tasksLoaded, dispatch]);

  useEffect(() => {
    let sorted = Object.entries(tasks).sort(([, a], [, b]) => {
      if (a && b) {
        const xA = a.datum || "";
        const xB = b.datum || "";
        return xA.localeCompare(xB);
      }
      return 0;
    });

    if (sortProperty) {
      sorted.sort(([, a], [, b]) => {
        const valueA = a && a[sortProperty] ? a[sortProperty].toLowerCase() : "";
        const valueB = b && b[sortProperty] ? b[sortProperty].toLowerCase() : "";

        if (sortOrder === "asc") {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      });
    }
    if(status) {
      sorted = sorted.filter(([_, task]) => {
        const today = strToInteger(objToYMDString(new Date()));
        if (status === "Erledigt" && task.hasOwnProperty("erledigt")) {
          return true;
        } else if (status === "Ohne Termin" && !task.hasOwnProperty("datumInt")) {
          return true;
        } else if (status === "In Bearbeitung" && task.datumInt <= today && task.hasOwnProperty("staff") && task.staff.length > 0) {
          return true;
        } else if (status === "In Planung" && task.datumInt > today && task.hasOwnProperty("staff") && task.staff.length > 0) {
          return true;
        } else if (status === "Überfällig" && !task.hasOwnProperty("erledigt") && task.datumInt <= today && (!task.hasOwnProperty("staff") || task.staff.length === 0)) {
          return true;
        } else if (status === "Nicht besetzt" && task.datumInt > today && (!task.hasOwnProperty("staff") || task.staff.length === 0)) {
          return true;
        } else if (status === "Alle") {
          return true;
        } else if (status === "Kritisch" && !task.hasOwnProperty("erledigt") && (
          (!task.hasOwnProperty("datumInt")) ||
          (task.datumInt <= today && (!task.hasOwnProperty("staff") || task.staff.length === 0)) ||
          (task.datumInt > today && (!task.hasOwnProperty("staff") || task.staff.length === 0))
        )) {
          return true;
        }
        return false;
      });
    }
    if (q) {
      sorted = sorted.filter(([, task]) => {
        const searchQuery = q
          .toLowerCase()
          .replace(/[^a-z0-9]/gi, "")
          .replace(/strasse|straße|str./g, "str");
        
        const projektName = task.projektName || "";
        const beschreibung = task.beschreibung || "";
        const address = task.anschrift || "";
        const zipCodeAndCity = (task.PLZ || "") + " " + (task.ort || "");

        return (
          projektName.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          beschreibung.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          address.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          zipCodeAndCity.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery)
        );
      });
    }

    setSortedContacts(sorted);
  }, [tasks, sortProperty, sortOrder, q, status]);

  if (tasksError !== null) {
    return <Error />;
  }

  if (!tasksLoaded) {
    return <Loading />;
  }

  const totalContacts = sortedContacts.length;
  const totalPages = Math.ceil(totalContacts / pageSize);
  const tasksToShow = sortedContacts.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const renderTooltip = (title) => (
    <Tooltip id="button-tooltip" placement="top" delay={{ show: 250, hide: 400 }}>
      {title}
    </Tooltip>
  );

  if (Object.keys(tasks).length === 0) {
    return (
      <Alert variant="primary">
        Sie haben noch keine Aufgaben, legen Sie jetzt eine an.
        <br />
        <br />
        <Button variant="primary" onClick={modalAdd}>
          Neue Aufgabe
        </Button>
      </Alert>
    );
  }
  else if (tasksToShow.length === 0) {
    return (
      <Alert variant="primary">
        Es gibt keine Übereinstimmungen.
      </Alert>
    );
  }
  

  return (
    <>
      <Table striped hover>
        <thead>
          <tr>
            <th onClick={() => handleSort("projektName")} style={{cursor:"pointer"}}>
                Projekt {sortProperty === "projektName" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("anschrift")} style={{cursor:"pointer"}}>
              Anschrift {sortProperty === "anschrift" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("ort")} style={{cursor:"pointer"}}>
              Ort {sortProperty === "ort" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("beschreibung")} style={{cursor:"pointer"}}>
                Beschreibung {sortProperty === "beschreibung" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("datum")} style={{cursor:"pointer"}}>
              Geplant {sortProperty === "datum" && (sortOrder === "asc" ? <BsSortNumericDown /> : <BsSortNumericUp />)}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tasksToShow.map(([key, task], index) => (
            <tr key={index}>
              <td valign="middle"><span className="fw-semibold">{task?.projektName}</span> {task?.projektBeschreibung}<br/><span className="text-muted">{ task?.auftraggeber }</span></td>
              <td valign="middle">{task?.anschrift || ""}</td>
              <td valign="middle">{ ((task?.PLZ) ? task.PLZ + ", " : null) }{task?.ort || ""}</td>
              <td valign="middle">{task?.beschreibung}</td>
              <td valign="middle">{convertDateFormat(task?.datum) || ""}</td>
              <td valign="middle" align="end">
                <TaskBadge task={task} />
                  <OverlayTrigger overlay={renderTooltip("Details öffnen")}>
                    <Button onClick={() => modalDetail({ id: key, el: tasks[key] })} variant="outline-primary">
                      Details
                    </Button>
                  </OverlayTrigger>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination className="text-center">
        {
          (currentPage > 1) && <>
            <Pagination.First onClick={() => handlePageChange(1)}/>
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}/>
          </>
        }
        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
          <Pagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Pagination.Item>
        ))}
        {
          (currentPage < totalPages) && <>
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}/>
            <Pagination.Last onClick={() => handlePageChange(totalPages)}/>
          </>
        }
        </Pagination>
      </div>
    </>
  );
};

export default FilterAndSort;
