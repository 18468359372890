import { getIdTokenResult, getAuth } from "firebase/auth";
import { getDatabase, ref, push, set, serverTimestamp} from "firebase/database";
import removeEmptyValues from "../objects/removeEmptyValues";

export const saveDB = async (target, object, overwrite = true) => {
  
  const user = getAuth().currentUser;
  const claims = await getIdTokenResult(user);

  const {client, sales} = claims.claims;
  
  const databaseId = (sales === undefined) ? client : user.uid;
  if(sales === undefined && (client === null || client === undefined)) return {error: true};


  return new Promise((resolve, reject) => {  
    
    const timestamp = Math.floor(Date.now() / 1000);
    const newRef = overwrite ? push(ref(getDatabase(), databaseId + "/" + target)) : ref(getDatabase(), databaseId + "/" + target);
    const cleanObject = removeEmptyValues(object);
    const additional = overwrite ? {...cleanObject, addedBy: user.uid, addedAt: serverTimestamp()} : object;
    set(newRef, additional)
    .then(() => resolve({error: false, key: newRef.key, obj: {...cleanObject, addedBy: user.uid, addedAt: timestamp}}))
    .catch(err => reject({error: true, message: err.message}));
  });
};

export const historPush = async (changes, id, target) => {
  const user = getAuth().currentUser;
  const claims = await getIdTokenResult(user);
  
  const {client, sales} = claims.claims;
  
  const databaseId = (sales === undefined) ? client : user.uid;
  if(sales === undefined && (client === null || client === undefined)) return {error: true};

  const historyRef = ref(getDatabase(), databaseId + "/history");
  push(historyRef, {
    at: serverTimestamp(),
    by: user.uid,
    target: id,
    typ: target,
    changes: changes
  });

}