import initialState from './initialState';
import types from '../../types';

export default function dispo(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_STAFF_SUCCESS:
      return {
        ...state,
        staffLoaded: true,
        staffError: null,
        staff: {...state.staff, ...action.payload},
      };
    case types.LOAD_STAFF_FAILURE:
      return {
        ...state,
        staffLoaded: true,
        staffError: action.error,
      };
    case types.LOAD_TASKS_SUCCESS:
      return {
        ...state,
        tasksLoaded: true,
        tasksError: null,
        tasks: {...state.tasks, ...action.payload},
      };
    case types.LOAD_TASKS_FAILURE:
      return {
        ...state,
        tasksLoaded: true,
        tasksError: action.error,
      };
    case types.LOAD_ABSENCE_SUCCESS:
      return {
        ...state,
        absenceLoaded: true,
        absenceError: null,
        absence: action.payload,
      };
    case types.LOAD_ABSENCE_FAILURE:
      return {
        ...state,
        absenceLoaded: true,
        absenceError: action.error,
      };
    case types.AUTH_SIGNOUT:
      return {
        ...initialState
      };
    default: return state;
  }
}