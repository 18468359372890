import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { loader } from "../../../redux/actions/database/loader";
import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { convertDateFormat } from "../../../functions/datetime/convertDateFormat";
import TaskBadge from "../../DISPO/Helper/TaskBadge";
import Documents from "./Projektmanager/Documents";
import Offers from "./Projektmanager/Offers";
import Measurments from "./Projektmanager/Measurments";
import Bills from "./Projektmanager/Bills";

const ProjectManager = () => {
  const { action } = useParams();
  const dispatch = useDispatch();
  const { rights, sales } = useSelector(state => state.auth.tokens);
  const { projects, projectsLoaded, projectsError } = useSelector(state => state.crm);
  const { tasks, tasksLoaded, tasksError } = useSelector(state => state.dispo);
  useEffect(() => {
    if(!projectsLoaded) {
      dispatch(loader("projects"));
    }
    if(!tasksLoaded && (rights["cpm"] > 0 || sales)) {
      dispatch(loader("tasks"));
    }
    // eslint-disable-next-line
  }, [projectsLoaded, tasksLoaded, dispatch]);

  if(projectsError !== null || (tasksError !== null && (rights["cpm"] > 0 || sales))) {
    return (<Error />);
  }

  if(!projectsLoaded || (!tasksLoaded && rights["cpm"] > 0)) {
    return (<Loading />);
  }

  const filterProjects = () => {
    if (action !== null && projects.hasOwnProperty(action)) return {...projects[action], id: action};
    else return {};
  }
  const project = filterProjects();

  const filterTasks = () => {
    let arr = [];
    if(Object.keys(project).length === 0) return arr;
    arr = Object.values(tasks).filter(task => {
      if(task.projectId === action) return true;
      else return false;
    });
    arr.sort((a, b) => {return a.datum.localeCompare(b.datum)});
    return arr;
  }
  const filteredTasks = filterTasks();

  if(Object.keys(project).length === 0) return <div>Nicht gefunden</div>;

  return (
    <>
    <Row>
      <Col xs={12} md={4}>
        <Card>
          <Card.Body>
            <Form.Group className="mb-2">
              <Form.Text>Projektname:</Form.Text>
              <Form.Control value={project?.name} size="sm" readOnly />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Text>Bezeichnung / Bauteil:</Form.Text>
              <Form.Control value={ (project?.bezeichnung ? project.bezeichnung + " / " : "" ) + (project?.bauteil || "")} size="sm" readOnly />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Text>Anschrift:</Form.Text>
              <Form.Control value={ project?.anschrift } size="sm" readOnly />
            </Form.Group>
            <Row>
              <Col xs={4}>
                <Form.Group className="mb-2">
                  <Form.Text>Postleitzahl:</Form.Text>
                  <Form.Control value={ project?.PLZ } size="sm" readOnly />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-2">
                  <Form.Text>Ort:</Form.Text>
                  <Form.Control value={ project?.ort } size="sm" readOnly />
                </Form.Group>
              </Col>
            </Row>
            {
              (project?.maßnahmennummer && project.maßnahmennummer !== "") &&
              <Form.Group className="mb-2">
                <Form.Text>Maßnahmennummer:</Form.Text>
                <Form.Control value={ project.maßnahmennummer } size="sm" readOnly />
              </Form.Group>
            }
            {
              (project?.vergabenummer && project.vergabenummer !== "") &&
              <Form.Group className="mb-2">
                <Form.Text>Vergabenummer:</Form.Text>
                <Form.Control value={ project.vergabenummer } size="sm" readOnly />
              </Form.Group>
            }
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={4}>
      <Card>
          <Card.Body>
            <Form.Group className="mb-2">
              <Form.Text>Bauherr / Auftraggeber:</Form.Text>
              <Form.Control value={ project?.contact?.name } size="sm" readOnly />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Text>E-Mail:</Form.Text>
              <Form.Control value={ project?.contact?.email } size="sm" readOnly />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Text>Anschrift:</Form.Text>
              <Form.Control value={ project?.contact?.anschrift } size="sm" readOnly />
            </Form.Group>
            <Row>
              <Col xs={4}>
                <Form.Group className="mb-2">
                  <Form.Text>Postleitzahl:</Form.Text>
                  <Form.Control value={ project?.contact?.PLZ } size="sm" readOnly />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-2">
                  <Form.Text>Ort:</Form.Text>
                  <Form.Control value={ project?.ort } size="sm" readOnly />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={4}><Card>
          <Card.Body>
            <Form.Group className="mb-2">
              <Form.Text>Architekt / Planer:</Form.Text>
              <Form.Control value={ project?.architect?.name } size="sm" readOnly />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Text>E-Mail:</Form.Text>
              <Form.Control value={ project?.architect?.email } size="sm" readOnly />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Text>Anschrift:</Form.Text>
              <Form.Control value={ project?.architect?.anschrift } size="sm" readOnly />
            </Form.Group>
            <Row>
              <Col xs={4}>
                <Form.Group className="mb-2">
                  <Form.Text>Postleitzahl:</Form.Text>
                  <Form.Control value={ project?.architect?.PLZ } size="sm" readOnly />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-2">
                  <Form.Text>Ort:</Form.Text>
                  <Form.Control value={ project?.ort } size="sm" readOnly />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <Row className="my-4">
      <Col xs={12} md={6} >
        <Documents project={project} />
      </Col>
      <Col xs={12} md={6}>
        <Offers projectId={action} />
      </Col>
    </Row>
    <Row className="my-4">
      <Col xs={12} md={6} >
        <Measurments projectId={action} />
      </Col>
      <Col xs={12} md={6} >
        <Bills projectId={action} />
      </Col>
    </Row>
    <Card className="my-4">
      <Card.Body>
        <Card.Title>Aufgaben</Card.Title>
        <Table responsive striped>
          <thead>
            <tr>
              <th>Datum</th>
              <th>Beschreibung</th>
              <th style={{maxWidth:"300px"}}>Aufgabe</th>
              <th>Zuständig</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {
              filteredTasks.map((task, taskIndex) => <tr key={taskIndex}>
                <td>{ convertDateFormat(task?.datum || null)}</td>
                <td>{ task?.beschreibung }</td>
                <td style={{maxWidth:"300px",wordWrap:"break-word"}}>{ task?.aufgabe }</td>
                <td>{ task?.ansprechpartnerIntern }</td>
                <td><TaskBadge task={task} /></td>
              </tr>)
            }
          </tbody>
        </Table>
      </Card.Body>
    </Card>
    
  </>
  )
}

export default ProjectManager