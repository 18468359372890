import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const WerkAPP = () => {

  const {rights, sales} = useSelector(state => state.auth.tokens);
  const companyName = useSelector(state => state.crm.settings.companyName);
  const {displayName} = useSelector(state => state.auth);

  return (
    <div className="menuNav">
      <div className="menuLogo">
        Werk<span>APP</span>
        <p className="menuLogoSubtitle">{ sales? displayName + " | DEMO" : companyName }</p>
      </div>
      <div className="menuMainSubmenu">
        <NavLink to="/" className="menuSubBtn">Dashboard
          <span className="menuSubBtnSmall">Überblick über WerkAPP</span>
        </NavLink>
        <NavLink to="/products" className="menuSubBtn">WerkAPP Module
          <span className="menuSubBtnSmall">Alle WerkAPP Module kennenlernen</span>
        </NavLink>
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("wam") && rights["wam"] > 0)) && <NavLink to="/modules" className="menuSubBtn">Lizenzverwaltung
          <span className="menuSubBtnSmall">Module zubuchen oder abbestellen</span></NavLink>
        }
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("wam") && rights["wam"] > 0)) && <NavLink to="/billing" className="menuSubBtn">Monatsrechnungen
          <span className="menuSubBtnSmall">WerkAPP Rechnung einsehen</span></NavLink>
        }
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("cur") && rights["cur"] > 0)) && <NavLink to="/users" className="menuSubBtn">Benutzer und Rechte
          <span className="menuSubBtnSmall">Zugriffe freigeben oder sperren</span></NavLink>
        }
        {
          ((sales!==undefined && sales === true) || (rights.hasOwnProperty("css") && rights["css"] > 0)) && <NavLink to="/settings" className="menuSubBtn">Stammdaten
          <span className="menuSubBtnSmall">Stammdaten und Systemeinstellungen</span></NavLink>
        }
      </div>
    </div>);
}

export default WerkAPP