import { getAuth, getIdTokenResult } from 'firebase/auth';
import { getDatabase, ref, get, query, orderByChild, equalTo } from 'firebase/database';

export const loaderOnce = async (where, child=null, equals=null) => {
  const user = getAuth().currentUser;
  const claims = await getIdTokenResult(user);

  const {client, sales} = claims.claims;
  
  const databaseId = (sales === undefined) ? client : user.uid;
  if(sales === undefined && (client === null || client === undefined)) return {error: true};

  return new Promise((resolve, reject) => {
    
    let sourceRef = ref(getDatabase(), databaseId + "/" + where);
    if(child !== null && equals !== null) { sourceRef = query(sourceRef, orderByChild(child), equalTo(equals)); }
    get(sourceRef)
      .then((result) => {
        resolve({error: false, result: result.exportVal()});
      })
      .catch((error) => {
        reject({error: true, message: error.message});
      });
  })
};