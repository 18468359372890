import { useState } from "react";
import History from "../../APP/History/History";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import EditStaff from "./EditStaff";
import Details from "./Details";

const ShowDetail = (props) => {

  const {showModal, handleClose, employee, subPage} = props;
  const [show, setShow] = useState((subPage!==null ? subPage : "details"));

  return (
    <Modal show={showModal} onHide={handleClose} centered size={((show==="details" || show==="edit")?"xl":null)} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>{ employee.nachname + ', ' + employee.vorname }{ (show === "edit" && " - Bearbeiten")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { (show === "details") && <Details staff={employee} /> }
        { (show === "history") && <History obj={employee} type="staff" />}
        { (show === "edit") && <EditStaff obj={{actualStaff: employee, staffId: employee.id}} handleClose={handleClose} />}
      </Modal.Body>
      <Modal.Footer>
        {
          (show === "details") && <>
        <ButtonGroup>
          <Button variant="outline-primary" onClick={() => {setShow("holidays")}}>
            Urlaubsplan
          </Button>
          <Button variant="outline-primary" onClick={() => {setShow("outage")}}>
            Abwesenheit
          </Button>
          <Button variant="outline-primary" onClick={() => {setShow("history")}}>
            Historie
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button variant="outline-primary" onClick={() => {setShow("edit")}}>
            Bearbeiten
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Schließen
          </Button>
        </ButtonGroup></>
        }
        {
          (show !== "details") && <Button variant="outline-primary" onClick={() => {setShow("details")}}>
          Zurück
        </Button>
        }
      </Modal.Footer>
    </Modal>
  )
}

export default ShowDetail