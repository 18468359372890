import {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { defaultData, defaultStates } from "./defaultData";
import { saver } from "../../../redux/actions/database/saver";
import { useSelector, useDispatch } from "react-redux";
import ProjectsMini from "../../CRM/Projects/ProjectsMini";
import { updater } from "../../../redux/actions/database/updater";
import History from "../../APP/History/History";
import PersonsList from "../Persons/PersonsList";
import ProjectsList from "../Projects/ProjectsList";

const MultiTool = ({obj = null, objId = null, mode = "detail", closeModal}) => {
  // obj = the saved object
  // objId = the ID from the object
  // mode = detail / edit / add
  // Get redux states
  const { rechtsformen, branchen, quellen } = useSelector(state => state.crm.settings);
  // State for view modus
  const [showMode, setMode] = useState(mode);
  // Show Mode for History
  const [page, setPage] = useState("");
  // State for Editing active or not.
  const [editMode, setEdit] = useState(false);
  // Set old data if there is some.
  const [contact, setContact] = useState((obj !== null ? {...defaultData, ...obj}:{...defaultData}));
  // State to see if there are any changes.
  const [isDirty, setDirty] = useState(false);
  // State to validate the form.
  const [validated, setValidated] = useState(false);
  // State to handle the child modal for Projects or Clients.
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  // Closer for child modal
  const onHide = () => setShowModal(false);
  // Select handler from child modal
  const onSelect = id => {
    setDirty(true);
    setContact(prev => ({...prev, contactId: id }));
    setShowModal(false);
  }
  // handle changes in the form.
  const handleChange = e => {
    setDirty(true);
    setContact(prev => ({...prev, [e.target.name]: e.target.value }));
  }
  const handleReset = e => {
    if(window.confirm("Alle Eingaben verwerfen?")) {
      if(showMode === "edit") {
        setContact({...defaultData, ...obj});
        setValidated(false);
        setDirty(false);
        setMode("detail");
      }
      else {
        setContact({...defaultData});
        setValidated(false);
        setDirty(false);
        closeModal();
      }
    }
  }
  const handleSave = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    } 
    if(showMode === "edit" && objId !== null) {
      dispatch(updater("contacts", contact, objId, obj));
    }
    if(showMode === "add") {
      saver("contacts",contact);
    }
    setContact(contact)
    setDirty(false);
    setValidated(false);
    setMode("detail");
  }

  useEffect(() => {
    setEdit((showMode === "edit" || showMode === "add"));
  }, [showMode, mode]);

  if(page === "history") {
    return <History obj={{...obj, id: objId}} type="contacts" goBack={() => setPage("")} />;
  }

  return (
    <>
      <Form onSubmit={handleSave} noValidate validated={validated} as={Row}>
        <Col className="mb-4" xs={12} md={6} lg={editMode ? 4 : 3}>
          <h6>Kundendaten</h6>
          <FormGroup>
            <Form.Text>Kontakttyp</Form.Text>
            {
                  !editMode ? <Form.Control plaintext readOnly value={contact.status} /> :
            <Form.Select name="status" readOnly={!editMode} value={contact.status} onChange={handleChange} required>
              <option value="">Bitte wählen</option>
              {
                defaultStates.map((status, index) => <option key={index} value={status}>{status}</option>)
              }
            </Form.Select>
            }
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Form.Text>Anrede</Form.Text>
                {
                  !editMode ? <Form.Control plaintext readOnly value={contact.anrede} /> :
                <Form.Select name="anrede" readOnly={!editMode} value={contact.anrede} onChange={handleChange} required>
                  <option value="">Bitte wählen</option>
                  <option value="Firma">Firma</option>
                  <option value="Herr">Herr</option>
                  <option value="Frau">Frau</option>
                </Form.Select>
                }
              </FormGroup>
            </Col>
            {
            (contact.anrede === "Herr" || contact.anrede === "Frau") && <Col>
              <FormGroup>
                <Form.Text>Vorname</Form.Text>
                <Form.Control name="vorname" readOnly={!editMode} plaintext={!editMode} value={contact.vorname} onChange={handleChange} required/>
              </FormGroup>
            </Col>
            }
          </Row>
          <FormGroup>
            <Form.Text>Name</Form.Text>
            <Form.Control name="name" readOnly={!editMode} plaintext={!editMode} value={contact.name} onChange={handleChange} required/>
          </FormGroup>
        </Col>
        <Col className="mb-4" xs={12} md={6} lg={editMode ? 4 : 3}>
          <h6>Adressdaten</h6>
          <FormGroup>
            <Form.Text>Adresszusatz</Form.Text>
            <Form.Control name="adresszusatz" readOnly={!editMode} plaintext={!editMode} value={contact.adresszusatz} onChange={handleChange} placeholder="c/o ..."/>
          </FormGroup>
          <FormGroup>
            <Form.Text>Anschrift</Form.Text>
            <Form.Control name="anschrift" readOnly={!editMode} plaintext={!editMode} value={contact.anschrift} onChange={handleChange} required />
          </FormGroup>
          <Row>
            <Col xs={4}>
              <FormGroup>
                <Form.Text>PLZ</Form.Text>
                <Form.Control name="PLZ" readOnly={!editMode} plaintext={!editMode} value={contact.PLZ} onChange={handleChange} required />
              </FormGroup>
            </Col><Col>
              <FormGroup>
                <Form.Text>Ort</Form.Text>
                <Form.Control name="ort" readOnly={!editMode} plaintext={!editMode} value={contact.ort} onChange={handleChange} required />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col className="mb-4" xs={12} md={6} lg={editMode ? 4 : 3}>
          <h6>Kontaktdaten</h6>
          <FormGroup>
            <Form.Text>Telefon (Zentrale)</Form.Text>
            <Form.Control name="telefon" readOnly={!editMode} plaintext={!editMode} value={contact.telefon} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Form.Text>E-Mail (Zentrale)</Form.Text>
            <Form.Control name="email" readOnly={!editMode} plaintext={!editMode} value={contact.email} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Form.Text>Webseite</Form.Text>
            <Form.Control name="webseite" readOnly={!editMode} plaintext={!editMode} value={contact.webseite} onChange={handleChange} />
          </FormGroup>
        </Col>
        <Col className="mb-4" xs={12} md={6} lg={3}>
          <h6>Statistische Daten</h6>
          <FormGroup>
            <Form.Text>Rechtsform</Form.Text>
            {
              !editMode ? <Form.Control plaintext readOnly value={rechtsformen?.[contact.rechtsform]} /> :
            <Form.Select name="rechtsform" readOnly={!editMode} value={contact.rechtsform} onChange={handleChange}>
              <option value="">Bitte wählen</option>
              {
                rechtsformen.map(([key, value], index) => <option key={index} value={key}>{value}</option>)
              }
            </Form.Select>
            }
          </FormGroup>
          <FormGroup>
            <Form.Text>Branche</Form.Text>
            {
              !editMode ? <Form.Control plaintext readOnly value={contact.branche} name="branche" onChange={handleChange} /> :
            <Form.Select name="branche" readOnly={!editMode} value={contact.branche} onChange={handleChange}>
              <option value="">Bitte wählen</option>
              {
                branchen.map((value, index) => <option key={index} value={value}>{value}</option>)
              }
            </Form.Select>
            }
          </FormGroup>
          <FormGroup>
            <Form.Text>Erstkontakt</Form.Text>
            {
              !editMode ? <Form.Control plaintext readOnly value={contact.erstkontakt} /> :
            <Form.Select name="erstkontakt" readOnly={!editMode} value={contact.erstkontakt} onChange={handleChange}>
              <option value="">Bitte wählen</option>
              {
                quellen.map((value, index) => <option key={index} value={value}>{value}</option>)
              }
            </Form.Select>}
          </FormGroup>
        </Col>
      </Form>
    <Row>
        {
          (objId !== null && !editMode) && <>
        <Col className="mb-4" xs={12} md={4}>
          <h6>Ansprechpartner</h6>
          <PersonsList contactId={objId} />
        </Col>
        <Col className="mb-4" xs={12} md={true}>
          <h6>Projekte</h6>
          <ProjectsList contactId={objId} />
        </Col></>
        }
      </Row>
      <Row className="mt-4">
      <Col align="end">
        {
          (editMode) ? <ButtonGroup>
            {
              (showMode === "add") ? <Button onClick={handleReset} disabled={!isDirty} variant="outline-primary">Verwerfen</Button> :
              (isDirty) ? <Button onClick={handleReset} variant="outline-primary">Verwerfen</Button> :
              <Button onClick={() => setMode("detail")} variant="outline-primary">Abbrechen</Button>
            } 
            <Button onClick={handleSave} disabled={!isDirty}>Speichern</Button>
          </ButtonGroup> :
          <ButtonGroup>
            <Button onClick={() => setPage("history") } variant="outline-primary">Historie</Button>
            <Button onClick={() => setMode("edit")} variant="outline-primary">Bearbeiten</Button>
            <Button onClick={closeModal}>Schließen</Button>
          </ButtonGroup>
        }
        
      </Col>
    </Row>
    <ProjectsMini show={showModal} onHide={onHide} onSelect={onSelect} />
    </>
  )
}

export default MultiTool