import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "../../../redux/actions/database/loader";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";
import "./a4editor.css";
import { FaCog, FaTimes } from "react-icons/fa";
import { TbRowInsertTop, TbRowInsertBottom, TbTrashX, TbPrinter } from "react-icons/tb";
import {BsCaretDownFill, BsCaretUpFill} from "react-icons/bs";
import { defaultArticle, defaultData } from "./defaultData";
import { updateDB } from "../../../functions/database/update";
import { ButtonGroup } from "react-bootstrap";
import EditTitle from "./editor/EditTitle";
import ContactsMini from "../../CRM/Contacts/ContactsMini";
import { saveDB } from "../../../functions/database/save";
import EditSettings from "./editor/EditSettings";
import EditPosition from "./editor/EditPosition";
import Document from "./editor/Document";

const digits3 = {
  style: "decimal",
  useGrouping: true,
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
  minimumIntegerDigits: 1,
  currency: undefined,
  currencyDisplay: "symbol",
  notation: "standard",
  compactDisplay: "short",
  localeMatcher: "best fit",
  numberingSystem: "latn",
  signDisplay: "auto",
  signStyle: "standard",
};
const digits2 = {...digits3, minimumFractionDigits: 2, maximumFractionDigits: 2};


const Edit = () => {

  const {id} = useParams();
  
  const {invoices, invoicesLoaded, invoicesError} = useSelector(state => state.erp);
  const {settings, settingsLoaded, settingsError} = useSelector(state => state.crm);
  const auth = useSelector(state => state.auth);
  const {sales, rights} = auth.tokens;
  const [offer, setOffer] = useState(defaultData);
  const letterRef = useRef(null);
  const [letterHeight, setLetterHeight] = useState(0);
  const dispatch = useDispatch();

  const editorRef = useRef(null); 
  const toolboxRef = useRef(null);

  const [helper, setHelper] = useState();

  const updateLetterHeight = () => {
    const heightPx = letterRef.current.clientHeight;
    const heightMm = Math.round(heightPx * 0.26);
    setLetterHeight(Math.ceil(heightMm / 272));
  };
  useEffect(() => {
    updateLetterHeight();
  });

  useEffect(() => {
    if(!invoicesLoaded) {
      dispatch(loader("invoices"));
    }
  }, [invoicesLoaded, dispatch]);

  useEffect(() => {
    if(!settingsLoaded) {
      dispatch(loader("root","settings"));
    }
  }, [settingsLoaded, dispatch]);

  useEffect(() => {
    if(invoices.hasOwnProperty(id)) {
      setOffer(prev => ({...prev, ...invoices[id]}));
    }
    // eslint-disable-next-line
  }, [invoices]);

  if(!invoicesLoaded || !settingsLoaded) return <Loading />;
  if(invoicesError !== null || settingsError !== null) return <Error />;

  if(!invoices.hasOwnProperty(id)) {
    return (<Alert variant="primary">Es wurde kein Objekt gefunden</Alert>);
  }

  const stdHeader = (offer?.contact?.anrede === "Firma" ? "Sehr geehrte Damen und Herren" : (offer?.contact?.anrede === "Herr" ? "Sehr geehrter Herr " + offer.contact.name : (offer?.contact?.anrede === "Frau" ? "Sehr geehrte Frau " + offer.contact.name : ""))) + ",\n\n" + settings.texte.Rechnungen.Kopftexte.WerkAPP.text;
  
  const stdFooter = settings.texte.Rechnungen.Schlusstexte.WerkAPP.text + "\n\n" + (auth?.signatur ? auth.signatur + " " : "") + auth.displayName + (auth?.funktion ? "\n" + auth.funktion : "") + "\n" + settings?.companyName;
  
  const ruecksender = settings?.companyName + (settings?.anschrift ? " - " + settings.anschrift : "") + (settings?.PLZ ? " - " + settings.PLZ + " " : "") + (settings?.ort ? settings.ort : "")

  const letterFooter = settings?.companyName + (settings?.anschrift ? " | " + settings.anschrift : "") + (settings?.PLZ ? " | " + settings.PLZ + " " : "") + (settings?.ort ? settings.ort : "");

  const ReplyHelper = () => {
    
    const [reply, setReply] = useState(settings?.settings?.briefpapier?.ruecksender ? settings?.settings?.briefpapier?.ruecksender : ruecksender);

    const save = () => {
      updateDB("root/settings", { ruecksender: reply}, "briefpapier");
    }

    return (
      <div>
      <Form.Group>
        <Form.Text>Rücksende Adresse</Form.Text>
        <Form.Control size="sm" value={reply} onChange={e => setReply(e.target.value)}/>
      </Form.Group>
      <p className="my-2" style={{fontSize:"0.875rem"}}>Achtung, eine Änderung wird in den Stammdaten gespeichert und wird für alle zukünftigen Dokumente übernommen.</p>
      <Button size="sm" variant="outline-primary" onClick={save}>Speichern</Button>
      </div>
    );
  }
  const FooterHelper = () => {
    const [temp, setTemp] = useState((settings?.settings?.briefpapier?.footer !== null && settings?.settings?.briefpapier?.footer !== undefined)? [...Object.values(settings.settings.briefpapier.footer)]:[{text: letterFooter, textAlign: "left"}]);

    const changeCols = e => {
      const emptyElement = {text: "", textAlign: "left"};
      const cols = parseInt(e.target.value);
      const arr = [...temp];
 
      if (cols > arr.length) {
        for (let i = arr.length; i < cols; i++) {
          arr.push(emptyElement);
        }
      } else if (cols < arr.length) {
        arr.splice(cols);
      }
      setTemp(arr);
    }
    const handleText = (index, value) => {
      const arr = [...temp];
      arr[index] = { ...arr[index], text: value };
      setTemp(arr);
    };

    const handleAlign = (index, value) => {
      const arr = [...temp];
      arr[index] = { ...arr[index], textAlign: value };
      setTemp(arr);
    };
    const save = () => {
      saveDB("root/settings/briefpapier/footer", temp, false)
      .then()
      .catch((err) => console.log(err));
    }

    return (<>
      <Form.Control as="select" className="mb-2" size="sm" value={temp.length} onChange={changeCols}>
        <option value="1">1 Spalte</option>
        <option value="2">2 Spalten</option>
        <option value="3">3 Spalten</option>
        <option value="4">4 Spalten</option>
      </Form.Control>
      <Row className="mb-4">
      {
        temp.map((col ,index) => <Col key={index}>
          <Form.Control as="select" size="sm" className="mb-2" value={col.textAlign} onChange={e => handleAlign(index, e.target.value)}>
            <option value="left">Linksbündig</option>
            <option value="center">Zentriert</option>
            <option value="right">Rechtsbündig</option>
          </Form.Control>
          <Form.Control as="textarea" rows="3" style={{resize:"none",textAlign:col.textAlign}} size="sm" value={col.text} onChange={e => handleText(index, e.target.value)}/>    
        </Col>)
      }


      </Row>
      <p className="my-2" style={{fontSize:"0.875rem"}}>Achtung, eine Änderung wird in den Stammdaten gespeichert und wird für alle zukünftigen Dokumente übernommen.</p>
      <Button size="sm" onClick={save}>Speichern</Button>
      </>
    );
  }
const moveUp = (index, e) => {
  e.preventDefault();
  e.stopPropagation();
  if (index > 0 && index < offer.articles.length) {
      const arr = [...offer.articles];
      const temp = arr[index];
      arr[index] = arr[index - 1];
      arr[index - 1] = temp;
      setOffer(prev => ({...prev, articles: arr}));
      setHelper(prev => (index === prev ? index - 1 : prev));
  }
};
const moveDown = (index, e) => {
  e.preventDefault();
  e.stopPropagation();
    if (index >= 0 && index < offer.articles.length - 1) {
        const arr = [...offer.articles];
        const temp = arr[index];
        arr[index] = arr[index + 1];
        arr[index + 1] = temp;
        setOffer(prev => ({...prev, articles: arr}));
        setHelper(prev => (index === prev ? parseInt(index) + 1 : prev));
    }
};
const addFirst = () => {
  setOffer(prev => ({...prev, articles: [{...defaultArticle}]}));
  setHelper(0);
}
const addBefore = (index, e) => {
  e.preventDefault();
  e.stopPropagation();
    if (index >= 0 && index < offer.articles.length) {
        const arr = [...offer.articles];
        arr.splice(index, 0, {...defaultArticle});
        setOffer(prev => ({...prev, articles: arr}));
        setHelper(index);
    }
};
const addAfter = (index, e = null) => {
  if(e !== null) {
    e.preventDefault();
    e.stopPropagation();
  }
    if (index >= 0 && index < offer.articles.length) {
        const arr = [...offer.articles];
        arr.splice(index + 1, 0, {...defaultArticle});
        const newIndex = parseInt(index) + 1;
        setHelper(newIndex);
        setOffer(prev => ({...prev, articles: arr}));
    }
};
const deletePosition = (index, e) => {
  e.preventDefault();
  e.stopPropagation();
    if (index >= 0 && index < offer.articles.length) {
      if(window.confirm("Die gewählte Position endgültig löschen?")) {
        const arr = [...offer.articles];
        arr.splice(index, 1);

        const sum = {SummeNetto: 0, SummeUSt: 0, SummeBrutto: 0};
        for (const i in arr) {
          const item = arr[i];
          if(item.typ !== "TS" && item.typ !== "TK" && item.typ !== "BP") {
            sum.SummeNetto += (item.TK.NumericMenge * item.TK.NumericEP);
          }
        }
        sum.SummeUSt = sum.SummeNetto * 0.19;
        sum.SummeBrutto = sum.SummeNetto + sum.SummeUSt;
        setOffer(prev => ({...prev, articles: arr, ...sum}));
        updateDB("invoices", {...offer, articles: arr, ...sum}, id);
        setHelper();
      }
    }
}

  const EditClient = () => {
    const [contactTemp, setContactTemp] = useState({...defaultData.contact, ...offer?.contact});
    const [architectTemp, setArchitectTemp] = useState({...defaultData.contact, ...offer?.architect});
    const [contactId, setContactId] = useState(offer?.contactId || "");
    const [architectId, setArchitectId] = useState(offer?.architectId || "");
    const [isContactDirty, setIsContactDirty] = useState(false);
    const [isArchitectDirty, setIsArchitectDirty] = useState(false);
    const [showContacts, setShowContacts] = useState(false);
    const [showArchitects, setShowArchitects] = useState(false);

    const handleChange = e => {
      const {name, value} = e.target;
      setIsContactDirty(true);
      setContactTemp(prev => ({...prev, [name]: value}));
    }
    const handleArchitectChange = e => {
      const {name, value} = e.target;
      setIsArchitectDirty(true);
      setArchitectTemp(prev => ({...prev, [name]: value}));
    }
    const addNewContact = () => {
      if(window.confirm("Alle Kundendaten im Angebot und Zuordnung zum Kunden werden dadurch verworfen.")) {
        setContactTemp({...defaultData.contact});
        setContactId("new");
      }
    }
    const saveNewContact = () => {
      saveDB("contacts", {...contactTemp, status: "Kunde"})
      .then((result) => {
        setContactId(result.key);
        setContactTemp({...result.obj});
        setIsContactDirty(false);
      })
      .catch((err) => { console.error(err);});
    }
    const addNewArchitect = () => {
      if(window.confirm("Alle Architektendaten im Angebot und Zuordnung zum Planer werden dadurch verworfen.")) {
        setArchitectTemp({...defaultData.contact});
        setArchitectId("new");
      }
    }
    const saveNewArchitect = () => {
      saveDB("contacts", {...architectTemp, status: "Architekt"})
      .then((result) => {
        setArchitectId(result.key);
        setArchitectTemp({...result.obj});
        setIsArchitectDirty(false);
      })
      .catch((err) => { console.error(err);});
    }
    const setClient = ({contactId, contact}) => {
      if(window.confirm("Den Kunden " + contact.name + " im Angebot hinterlegen und zuordnen?")) {
        setContactTemp({...defaultData.contact, ...contact});
        setContactId(contactId);
        setIsContactDirty(false);
      }
      setShowContacts(false);
    }
    const setArchitect = ({contactId, contact}) => {
      if(window.confirm("Den Planer " + contact.name + " im Angebot hinterlegen und zuordnen?")) {
        setArchitectTemp({...defaultData.contact, ...contact});
        setArchitectId(contactId);
        setIsArchitectDirty(false);
      }
      setShowArchitects(false);
    }
    const handleSave = () => {
      updateDB("invoices", {...offer, contact: contactTemp, contactId: contactId, architect: architectTemp, architectId: architectId}, id, offer);
    }
    return (<>
      <p className="fw-semibold mt-4 mb-1">Auftraggeber / Bauherr:</p>
      <ButtonGroup size="sm" className="mb-2">
        <Button size="sm" variant="outline-primary" onClick={() => setShowContacts(true)}>Vorhandenen Kunde suchen</Button>
        <Button variant="outline-primary" onClick={addNewContact}>Neuen Kontakt erstellen</Button>
      </ButtonGroup>
      <Row>
        <Col>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Text>Anrede</Form.Text>
                <Form.Control as="select" size="sm" value={contactTemp.anrede} name="anrede" onChange={handleChange}>
                  <option value="">Bitte wählen</option>
                  <option value="Firma">Firma</option>
                  <option value="Herr">Herr</option>
                  <option value="Frau">Frau</option>
                </Form.Control>
              </Form.Group>  
            </Col>
            <Col>
            {
              (contactTemp.anrede !== "Firma" && contactTemp.anrede !== "" ) &&
              <Form.Group>
                <Form.Text>Vorname</Form.Text>
                <Form.Control size="sm" value={contactTemp.vorname} name="vorname" onChange={handleChange} />
              </Form.Group>  
            }
            </Col>
          </Row>
          <Form.Group className="mb-2">
            <Form.Text>Name</Form.Text>
            <Form.Control size="sm" value={contactTemp.name} name="name" onChange={handleChange} />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Text>Anschrift</Form.Text>
            <Form.Control size="sm" value={contactTemp.anschrift} name="anschrift" onChange={handleChange} />
          </Form.Group>
          <Row>
            <Col xs={4}>
              <Form.Group className="mb-2">
                <Form.Text>Postleitzahl</Form.Text>
                <Form.Control size="sm" value={contactTemp.PLZ} name="PLZ" onChange={handleChange} />
              </Form.Group>  
            </Col>
            <Col>
              <Form.Group className="mb-2">
                <Form.Text>Ort</Form.Text>
                <Form.Control size="sm" value={contactTemp.ort} name="ort" onChange={handleChange} />
              </Form.Group>  
            </Col>
          </Row>
        </Col>
        <Col className="d-flex flex-column justify-content-end">
          {
            (contactId === "new" || isContactDirty) && <Button variant="outline-primary" size="sm" className="mb-2" onClick={saveNewContact}>Als neuen Kontakt speichern</Button>
          }
          <Form.Group className="mb-2">
            <Form.Text>E-Mail</Form.Text>
            <Form.Control size="sm" value={contactTemp.email} name="email" onChange={handleChange} />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Text>Telefon</Form.Text>
            <Form.Control size="sm" value={contactTemp.telefon} name="telefon" onChange={handleChange} />
          </Form.Group>
        </Col>
      </Row>
      <Button className="mb-2" size="sm" onClick={handleSave}>Angebot speichern</Button>
      <p className="fw-semibold mt-4 mb-1">Architekt / Planer:</p>
      <ButtonGroup size="sm" className="mb-2">
        <Button size="sm" variant="outline-primary" onClick={() => setShowArchitects(true)}>Vorhandenen Architekt suchen</Button>
        <Button variant="outline-primary" onClick={addNewArchitect}>Neuen Architekt erstellen</Button>
      </ButtonGroup>
      <Row>
        <Col>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Text>Anrede</Form.Text>
                <Form.Control as="select" size="sm" value={architectTemp.anrede} name="anrede" onChange={handleArchitectChange}>
                  <option value="">Bitte wählen</option>
                  <option value="Firma">Firma</option>
                  <option value="Herr">Herr</option>
                  <option value="Frau">Frau</option>
                </Form.Control>
              </Form.Group>  
            </Col>
            <Col>
            {
              (architectTemp.anrede !== "Firma" && architectTemp.anrede !== "" ) &&
              <Form.Group>
                <Form.Text>Vorname</Form.Text>
                <Form.Control size="sm" value={architectTemp.vorname} name="vorname" onChange={handleArchitectChange} />
              </Form.Group>  
            }
            </Col>
          </Row>
          <Form.Group className="mb-2">
            <Form.Text>Name</Form.Text>
            <Form.Control size="sm" value={architectTemp.name} name="name" onChange={handleArchitectChange} />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Text>Anschrift</Form.Text>
            <Form.Control size="sm" value={architectTemp.anschrift} name="anschrift" onChange={handleArchitectChange} />
          </Form.Group>
          <Row>
            <Col xs={4}>
              <Form.Group className="mb-2">
                <Form.Text>Postleitzahl</Form.Text>
                <Form.Control size="sm" value={architectTemp.PLZ} name="PLZ" onChange={handleArchitectChange} />
              </Form.Group>  
            </Col>
            <Col>
              <Form.Group className="mb-2">
                <Form.Text>Ort</Form.Text>
                <Form.Control size="sm" value={architectTemp.ort} name="ort" onChange={handleArchitectChange} />
              </Form.Group>  
            </Col>
          </Row>
        </Col>
        <Col className="d-flex flex-column justify-content-end">
          {
            (architectId === "new" || isArchitectDirty) && <Button variant="outline-primary" size="sm" className="mb-2" onClick={saveNewArchitect}>Als neuen Kontakt speichern</Button>
          }
          <Form.Group className="mb-2">
            <Form.Text>E-Mail</Form.Text>
            <Form.Control size="sm" value={architectTemp.email} name="email" onChange={handleArchitectChange} />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Text>Telefon</Form.Text>
            <Form.Control size="sm" value={architectTemp.telefon} name="telefon" onChange={handleArchitectChange} />
          </Form.Group>
        </Col>
      </Row>
      <Button className="mb-2" size="sm" onClick={handleSave}>Angebot speichern</Button>
      {
        showArchitects && <ContactsMini show={showArchitects} onHide={() => setShowArchitects(false)} onSelect={setArchitect} />
      }
      {
        showContacts && <ContactsMini show={showContacts} onHide={() => setShowContacts(false)} onSelect={setClient} />
      }</>)
  }
  const EditHeader = () => {

    const [temp, setTemp] = useState(offer?.headerText || stdHeader);
    const handleSave = () => {
      updateDB("invoices", {...offer, headerText: temp}, id, offer);
    }
    return (<>
    <Form.Group className="mb-2">
      <Form.Text>Kopftext</Form.Text>
      <Form.Control as="textarea" size="sm" rows="4" value={temp} onChange={e => setTemp(e.target.value)} />
    </Form.Group>
    <Button size="sm" onClick={handleSave}>Speichern</Button></>)
  };
  const EditFooter = () => {

    const [temp, setTemp] = useState(offer?.footerText || stdFooter);
    const handleSave = () => {
      updateDB("invoices", {...offer, footerText: temp}, id, offer);
    }
    return (<>
    <Form.Group className="mb-2">
      <Form.Text>Schlußtext</Form.Text>
      <Form.Control as="textarea" size="sm" rows="4" value={temp} onChange={e => setTemp(e.target.value)} />
    </Form.Group>
    <Button size="sm" onClick={handleSave}>Speichern</Button></>)
  };
  
  const renderTimestamp = (date) => {
    const thisDate = (date === null || date === undefined) ? new Date() : new Date(date);
    return (thisDate.getDate()).toString().padStart(2,"0") + "." + (thisDate.getMonth() + 1).toString().padStart(2,"0") + "." + thisDate.getFullYear();
  }

  let calc = { title: { open: false, name: "", index: 0, article: 0, sum: 0, }, subtitle: { name: "", open: false, index: 0, article: 0, sum: 0, }, subsubtitle: { name: "", open: false, index: 0, article: 0, sum: 0, }, total: { sum: 0, article: 0, lines: 0, } };
  
  const RenderPositions = ({prefix, article, index}) => {
    
    const title = (article.typ === "TK" || article.typ === "TS");
    let titleText = "";
    let pos = "";
    let sum = 0;
    let qty = (article.hasOwnProperty("lines") ? Object.values(article.lines).map(item => item.totalQty).reduce((a, b) => a + b, 0) : 0);

    if(article.typ === "TK") {
      if (calc.subtitle.open) {
        calc.subsubtitle.index++;
        calc.subsubtitle.name = article.kurztext;
        calc.subsubtitle.open = true;
      } else if (calc.title.open) {
        calc.subtitle.index++;
        calc.subtitle.name = article.kurztext;
        calc.subtitle.open = true;
      } else {
        calc.title.index++;
        calc.title.name = article.kurztext;
        calc.title.open = true;
      }
      pos = (calc.title.open ? ("00" + calc.title.index).slice(-2) : "")
          + (calc.subtitle.open ? "."+("00" + calc.subtitle.index).slice(-2) : "")
          + (calc.subsubtitle.open ? "."+("00" + calc.subsubtitle.index).slice(-2) : "");
      titleText = "Titel " + article.kurztext;
    } else if (article.typ === "TS") {
      pos = (calc.title.open ? ("00" + calc.title.index).slice(-2) : "")
          + (calc.subtitle.open ? "."+("00" + calc.subtitle.index).slice(-2) : "")
          + (calc.subsubtitle.open ? "."+("00" + calc.subsubtitle.index).slice(-2) : "");
      titleText = "Titelsumme " + (calc.subsubtitle.open ? calc.subsubtitle.name : (calc.subtitle.open ? calc.subtitle.name : (calc.title.open ? calc.title.name : "")));
      if (calc.subsubtitle.open) {
        sum = calc.subsubtitle.sum;
        calc.subsubtitle.sum = 0;
        calc.subsubtitle.article = 0;
        calc.subsubtitle.open = false;
      } else if (calc.subtitle.open) {
        sum = calc.subtitle.sum;
        calc.subtitle.sum = 0;
        calc.subtitle.article = 0;
        calc.subtitle.open = false;
      } else if (calc.title.open) {
        sum = calc.title.sum;
        calc.title.sum = 0;
        calc.title.article = 0
        calc.title.open = false;
      }
    } else {
      const articlePrice = (article.TK.NumericEP * qty);
      if (calc.subsubtitle.open) {
        calc.subsubtitle.sum += articlePrice;
        calc.subtitle.sum += articlePrice;
        calc.title.sum += articlePrice;
        calc.total.sum += articlePrice;
        calc.subsubtitle.article++;
      } else if (calc.subtitle.open) {
        calc.subtitle.sum += articlePrice;
        calc.title.sum += articlePrice;
        calc.total.sum += articlePrice;
        calc.subtitle.article++;
      } else if (calc.title.open) {
        calc.title.sum += articlePrice;
        calc.total.sum += articlePrice;
        calc.title.article++;
      } else {
        calc.total.sum += articlePrice;
        calc.total.article++;
      }
      pos = (calc.title.open ? ("00" + calc.title.index).slice(-2) + "." : "")
          + (calc.subtitle.open ? ("00" + calc.subtitle.index).slice(-2) + "." : "")
          + (calc.subsubtitle.open ? ("00" + calc.subsubtitle.index).slice(-2) + "." : "")
          + (calc.subsubtitle.open ? ("000" + calc.subsubtitle.article).slice(-3) :
              (calc.subtitle.open ? ("000" + calc.subtitle.article).slice(-3) :
                (calc.title.open ? ("000" + calc.title.article).slice(-3) : ("000" + calc.total.article).slice(-3))));
      
    }

    if(title) {
      return (<tr key={index} style={{fontWeight: "bold"}} className={"EditorTablePositions" + (helper === index ? " active" : "")}>
        <td className="EditorTablePosId">
          { prefix }{ pos }
        </td>
        <td colSpan="3">{ titleText }</td>
        {
          article.typ === "TS" ? <td className="EditorTableNumbers">{ sum.toLocaleString("de-DE", digits2) }</td> : <td/>
        }
      </tr>);
    }
    return (<><tr key={index} className={"EditorTablePositions" + (helper === index ? " active" : "")}>
    <td className="EditorTablePosId">
      { prefix }{ pos }
    </td>
    <td className="EditorTablePosId">{ offer?.langtext ? (article.langtext === "" ? article.kurztext : article.langtext) : article.kurztext }</td>
    <td className="EditorTableNumbers"><span>{ qty.toLocaleString("de-DE", digits3) + " " + article.einheit }</span></td>
    <td className="EditorTableNumbers">
      { article.TK.NumericEP.toLocaleString("de-DE", digits3) }
    </td>
    <td className="EditorTableNumbers">
      { (qty * article.TK.NumericEP).toLocaleString("de-DE", digits2) }
    </td>
    </tr>
    </>)
  }
  const isDoc = (offer.hasOwnProperty("doc") && offer.doc !== "");

  const InvoiceRender = (props) => {
    const {article, index} = props;
    const prefix = (index === 0) ? "HA-" : "NA" + index + "-";
    return (<>
      {
        article.map((pos, idx) => <RenderPositions prefix={prefix} article={pos} index={idx} key={idx}/>)
      }
      </>
    );
  }

  return (<>
    <div className="Editor" ref={editorRef}>
      <div className="EditorLetter" >
        <div className={"EditorStyleButton"+ (helper === "Grundeinstellungen" ? " active" : "")} onClick={() => setHelper("Grundeinstellungen")}><FaCog /></div>
        <div className={"EditorPrintButton"+ (helper === "Dokument" ? " active" : "")} onClick={() => setHelper("Dokument")}><TbPrinter /></div>
        {
          Array.from(Array(letterHeight).keys()).map((_, index) => <div
            key={index} className="EditorLetterPage"
            style={
              {
                borderColor: (index === 0 ? "transparent" : "#ccc"),
              }
            }>
            {
              (letterHeight - 1) !== index && <div className="EditorLetterPageInfo">Neue Seite</div>
            }
            </div>)
        }
        <div className="EditorLetterOverlay" ref={letterRef}>
          
          <div className={"EditorLetterReply EditorClickable" + (helper === "Rücksendeadresse" ? " active" : "")} onClick={() => setHelper("Rücksendeadresse")}>
            { settings?.settings?.briefpapier?.ruecksender !== null ? settings?.settings?.briefpapier?.ruecksender : ruecksender }
          </div>
          <div className={"EditorLetterRecipient EditorClickable" + (helper === "Empfänger" ? " active" : "")} onClick={() => setHelper("Empfänger")}>
            { offer.contact?.anrede }<br/>
            { offer.contact.hasOwnProperty("vorname") ? offer.contact.vorname + " " : ""}
            { offer.contact.name }<br/>
            { offer.contact.anschrift }<br/>
            { offer.contact.PLZ } { offer.contact.ort }<br/>
            <br/>
             { (offer.contact?.email && offer.contact?.email !== "") ? "per E-Mail: " + offer.contact.email : "" }
          </div>
          <div className="EditorLetterSender ">
            Sachbearbeiter { (auth?.anrede && auth.anrede === "Frau" ? "in: ":": ") + auth?.displayName }<br/>
            E-Mail: {auth?.email}<br/>
            {(auth?.telefon ? <>Telefon: {auth.telefon}<br/></>: "")}
            <br/>
            Datum: { renderTimestamp(offer?.docDate) }
          </div>
          <div className={"EditorLetterTitle EditorClickable" + (helper === "Dokumententitel" ? " active" : "")} onClick={() => setHelper("Dokumententitel")}>
            Rechnung: { offer?.doc || <i className="text-danger">* In Bearbeitung! Rechnungsnummer wird bei Fertigstellung vergeben *</i>}<br/>
            Projekt: { (offer?.anschrift || "Anschrift") + ", " + (offer?.PLZ || "PLZ") + " " + (offer?.ort || "Ort") }<br/>
            { (offer?.massnahmenNummer && offer?.massnahmenNummer !== "" && offer?.massnahmenNummer !== " ") ? <>Maßnahmennumer: {offer.massnahmenNummer}<br/></> : "" }
            { (offer?.vergabeNummer && offer?.vergabeNummer !== "" && offer?.vergabeNummer !== " ") ? <>Vergabenummer: {offer.vergabeNummer}<br/></> : "" }
          </div>
          <div className={"EditorLetterHeadertext EditorClickable" + (helper === "Kopftext" ? " active" : "")} onClick={() => setHelper("Kopftext")}>
            { offer?.headerText ? offer.headerText : stdHeader }
          </div>
          <div className="EditorLetterPositions">
            <table className="EditorTable">
              <thead>
                <tr>
                  <th style={{width: "20mm"}}>Pos.</th>
                  <th>Beschreibung</th>
                  <th style={{width: "25mm", textAlign: "right"}}>Menge</th>
                  <th style={{width: "25mm", textAlign: "right"}}>EP €</th>
                  <th style={{width: "25mm", textAlign: "right"}}>Gesamt €</th>
                </tr>
              </thead>
              <tbody>
                {
                  offer.hasOwnProperty("measurements") && Object.values(offer.measurements).map((pos, index) => <InvoiceRender article={pos} index={index} key={index}/>)
                }
              </tbody>
              {
                offer.articles.length === 0 && <tbody>
                  <tr className="EditorTablePositions">
                    <td colSpan="5" className="p-3 text-center fw-semibold" onClick={addFirst}>Hier klicken, um mit der Kalkulation zu beginnen.</td>
                  </tr>
                </tbody>
              }
              {
                (offer?.vorgaben?.NumericNachlass && offer.vorgaben?.NumericNachlass > 0) ? <tfoot onClick={() => setHelper("Grundeinstellungen")}>
                  <tr>
                    <td colSpan="4" className="EditorTableNumbers">abzgl. {(offer.vorgaben.NumericNachlass).toLocaleString("de-DE",digits2)} % Nachlass</td>
                    <td className="EditorTableNumbers">- { (offer.SummeNetto * (offer.vorgaben.NumericNachlass / 100)).toLocaleString("de-DE", digits2) } €</td>  
                  </tr>
                  <tr style={{fontWeight:"bold"}}>
                    <td colSpan="4" className="EditorTableNumbers">Rechnungssumme netto</td>
                    <td className="EditorTableNumbers">{ (offer.SummeNetto - (offer.sumNetto * (offer.vorgaben.NumericNachlass / 100))).toLocaleString("de-DE", digits2) } €</td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="EditorTableNumbers">zzgl. 19 % Umsatzsteuer</td>
                    <td className="EditorTableNumbers">{ (offer.SummeUSt - (offer.SummeUSt * (offer.vorgaben.NumericNachlass / 100))).toLocaleString("de-DE", digits2) } €</td>
                  </tr>
                  <tr style={{fontWeight:"bold"}}>
                    <td colSpan="4" className="EditorTableNumbers">Rechnungssumme brutto</td>
                    <td className="EditorTableNumbers">{ (offer.SummeBrutto - (offer.SummeBrutto * (offer.vorgaben.NumericNachlass / 100))).toLocaleString("de-DE", digits2) } €</td>
                  </tr>
                </tfoot> : <tfoot onClick={() => setHelper("Grundeinstellungen")}>
                  <tr style={{fontWeight:"bold"}}>
                    <td colSpan="4" className="EditorTableNumbers">Rechnungssumme Netto</td>
                    <td className="EditorTableNumbers">{ offer.SummeNetto.toLocaleString("de-DE", digits2) } €</td>
                  </tr>
                  <tr>
                    <td colSpan="4" className="EditorTableNumbers">+19% Umsatzsteuer</td>
                    <td className="EditorTableNumbers">{ offer.SummeUSt.toLocaleString("de-DE", digits2) } €</td>
                  </tr>
                  <tr style={{fontWeight:"bold"}}>
                    <td colSpan="4" className="EditorTableNumbers">Rechnungssumme Brutto</td>
                    <td className="EditorTableNumbers">{ offer.SummeBrutto.toLocaleString("de-DE", digits2) } €</td>
                  </tr>
                </tfoot>
              }
            </table>
          </div>
          <div style={{marginBottom:"3mm"}} onClick={() => setHelper("Grundeinstellungen")}>
            Zahlungsbedingungen: Abschlagsrechnungen sind{
              offer.vorgaben.NumericZielAR > 0 ? " innerhalb von " + offer.vorgaben.NumericZielAR + " Tagen" : " sofort"
            }, Schlussrechnungen{
              offer.vorgaben.NumericZielSR > 0 ? " innerhalb von " + offer.vorgaben.NumericZielSR + " Tagen jeweils " : " sofort "
            }nach Rechnungslegung fällig.{
              (offer.vorgaben.NumericSkonto > 0 && offer.vorgaben.NumericSkontoTage > 0) ? " Bei Zahlung innerhalb von " + offer.vorgaben.NumericSkontoTage +
              " Tagen nach Rechnungslegung wird ein Skonto in Höhe von " + (offer.vorgaben.NumericSkonto).toLocaleString("de-DE",digits2) + " % gewährt." : ""
            }
          </div>
          <div className={"EditorLetterFootertext EditorClickable" + (helper === "Schlußtext" ? " active" : "")} onClick={() => setHelper("Schlußtext")}>
            { offer?.footerText ? offer.footerText : stdFooter }
          </div>
        </div>

        <div className={"EditorLetterFooter EditorClickable" + (helper === "Fußzeile" ? " active" : "")} onClick={() => setHelper("Fußzeile")}>
        { (settings?.settings?.briefpapier?.footer !== null && settings?.settings?.briefpapier?.footer !== undefined)?
          Object.values(settings.settings.briefpapier.footer).map((col, index) => <div key={index} style={{textAlign: col.textAlign}}>{col.text}</div>)
          :
          <div>Hier klicken, um die Fußzeile anzupassen!</div>
          }
          </div>
      </div>
      <div className="EditorToolbox"  ref={toolboxRef}>
        {
          helper !== undefined && <div className="EditorToolboxClose" onClick={() => setHelper()}><FaTimes /></div>
        }
        <p className="fw-semibold">{ helper !== undefined ? (typeof helper === "number" ? "Position" : helper) + " bearbeiten:" : "Werkzeugkasten"}</p>
        {
          helper === undefined && (isDoc ? <p>Das Dokument kann nicht mehr bearbeitet werden.</p> : <p>Ein Element im Dokument anklicken, um es zu bearbeiten.<br/><br/>Die Vorschau der Seitenumbrüche kann abweichen.</p>)
        }
        {
         (typeof helper === "number") && (
         ((rights.eom < 3 || !sales)) ? <p>Das Dokument kann nicht mehr bearbeitet werden.</p> :
         <EditPosition offer={offer} setHelper={setHelper} index={helper} id={id} />)
        }
        {
          helper === "Kopftext" && (
            (isDoc || (rights.eom < 3 || !sales)) ? <p>Das Dokument kann nicht mehr bearbeitet werden.</p> : <EditHeader />)
        }
        {
          helper === "Schlußtext" && (
            (isDoc || (rights.eom < 3 || !sales)) ? <p>Das Dokument kann nicht mehr bearbeitet werden.</p> : <EditFooter />)
        }
        {
          helper === "Empfänger" && (
            (isDoc || (rights.eom < 3 || !sales)) ? <p>Das Dokument kann nicht mehr bearbeitet werden.</p> : <EditClient />)
        }
        {
          helper === "Rücksendeadresse" && (
            (isDoc || (rights.css < 3 || !sales)) ? <p>Das Dokument kann nicht mehr bearbeitet werden.</p> : <ReplyHelper />)
        }
        {
          helper === "Dokumententitel" && (
            (isDoc || (rights.eom < 3 || !sales)) ? <p>Das Dokument kann nicht mehr bearbeitet werden.</p> : <EditTitle offer={offer} />)
        }
        {
          helper === "Grundeinstellungen" && (
            (isDoc || (rights.eom < 3 || !sales)) ? <p>Das Dokument kann nicht mehr bearbeitet werden.</p> : <EditSettings offer={offer} id={id} />)
        }
        {
          helper === "Fußzeile" && (
            (isDoc || (rights.css < 3 || !sales)) ? <p>Das Dokument kann nicht mehr bearbeitet werden.</p> :<FooterHelper />)
        }
        {
          helper === "Dokument" && <Document offer={offer} id={id} setHelper={setHelper} />
        }
      </div>
    </div>
    </>);
}

export default Edit