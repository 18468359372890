import { useEffect, useState } from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import permissions from "./permissions"
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../redux/actions/database/loader";

const WerkAPP = () => {
  const { settingsLoaded } = useSelector(state => state.crm);
  const [isloaded, setLoaded] = useState(false);
  const [routes, setRoutes] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    permissions()
      .then(result => {
        const allowedRoutes = createBrowserRouter(result);
        setRoutes(allowedRoutes);
        setLoaded(true);
      });
    dispatch(loader('root','settings'));
  }, [dispatch]);

  if(!settingsLoaded || !isloaded) {
    return (<Modal show={true} centered={true}>
      <Modal.Body className="text-center">
        <Spinner animation="border" variant="primary" />
        <br/>Einen Moment, der Inhalt wird geladen.
      </Modal.Body>
    </Modal>);
  }
  return (
    <RouterProvider router={routes}/>
  )
}

export default WerkAPP;