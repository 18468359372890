import React from 'react'
import { Alert } from 'react-bootstrap'

const Unauthorized = () => {
  return (
    <Alert variant="danger">
      Sie verfügen nicht über die notwendige Berechtigung um diesen Vorgang auszuführen.
    </Alert>
  )
}

export default Unauthorized