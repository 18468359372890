import { getAuth, getIdTokenResult } from "firebase/auth"
import { getDatabase, ref, runTransaction } from "firebase/database";

export const documentNumber = async (type) => {

  const user = getAuth().currentUser;
  const claims = await getIdTokenResult(user)
  const {client, sales} = claims.claims;

  const databaseId = (sales === undefined) ? client : user.uid;
  if(sales === undefined && (client === null || client === undefined)) return {error: true};

  const reference = ref(getDatabase(), databaseId + "/root/settings/nummernKreise/" + type);

  try {
    const result = await runTransaction(reference, (currentValue) => {
      if (currentValue === null) return 1;
      else return currentValue + 1;
    });
    return result.snapshot.exportVal();
  } catch (error) {
    console.log(error);
    return error;
  }
}