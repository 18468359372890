import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector } from "react-redux";
import LimitedOffer from "./LimitedOffer";
import News from "./News";

const Dashboard = () => {

  const {limits, usage} = useSelector(state => state.crm.settings);
  const {sales, rights} = useSelector(state => state.auth.tokens);
  const displayName = useSelector(state => state.auth.displayName);

  if(sales) {
    return (
      <Row>
        <Col xs={12} md={12}>
          <div style={{ display: "flex", flexDirection:"column", justifyContent: "center", alignItems: "center"}}>
            <h1>Vorführversion</h1>
            <p>Dies Version ist ausschließlich für die Vorführung lizenziert.</p>
            <p>Der Funktionsumfang ist eingeschränkt,</p>
              <ul>
                <li>Nutzung kann nicht angezeigt werden</li>
                <li>Cloud-Mails können nicht verschickt werden</li>
                <li>Dokumente werden nicht dauerhaft gespeichert</li>
              </ul>
            <p>Lizenziert für: {displayName}</p>
          </div>
        </Col>
        <Col xs={12}>
          <News />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      {
        (rights.hasOwnProperty("wam") && rights.wam > 0) && <LimitedOffer />
      }
      <Col xs={12} md={4}>
        <Card>
          <Card.Body>
            <Card.Title>Nutzung im aktuellen Monat</Card.Title>
            {
              Object.entries(limits).map(([key, benefit], index) => <div key={index}>
                {benefit.name}
                <ProgressBar
                  variant={((usage[key] / benefit.qty) * 100 > 100)?"danger":(((usage[key] / benefit.qty) * 100 > 90) ? "warning" : "primary")}
                  now={(usage[key] / benefit.qty) * 100}
                  label={ Math.round((usage[key] / benefit.qty) * 100) + "%"}
                />
                {
                  (benefit.step < 1) ?
                  <p style={{fontSize:"12px",textAlign:"right"}}>{usage[key].toFixed(2).replace(".",",")} / {benefit.qty.toFixed(2).replace(".",",")} {benefit.format}</p>
                  :
                  <p style={{fontSize:"12px",textAlign:"right"}}>{usage[key]} / {benefit.qty} {benefit.format}</p>
                }
              </div>)
            }
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={4}>
        <News />
      </Col>
    </Row>
    
  )
}

export default Dashboard