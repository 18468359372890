import { Button, ButtonGroup } from "react-bootstrap";
import { BsFillCheckSquareFill, BsFillXSquareFill, BsFillExclamationSquareFill } from "react-icons/bs";
import { createBusinessLetterPDF } from "../../../../functions/pdf/letter5008";
import { documentNumber } from "../../../../functions/database/documentNumber";
import { useSelector } from "react-redux";
import { updateDB } from "../../../../functions/database/update";
import { serverTimestamp } from "firebase/database";
import { useState } from "react";
import CloudMailer from "../../../APP/CloudMail/CloudMailer";
import { saveDB } from "../../../../functions/database/save";

const Document = ({offer, id, setHelper}) => {

  const {settings} = useSelector(state => state.crm);
  const {displayName} = useSelector(state => state.auth);
  const [cloudMailerOpen, setCloudMailerOpen] = useState(false);
  const [cloudMailerData, setCloudMailerData] = useState({to: [], cc: [], bcc: [], title: "", message: "", files: []});

  const getNumber = async () => {
    if(!offer.hasOwnProperty("doc")) {
      try {
        const number = await documentNumber("rechnung");
        const docString = settings.settings.nummernKreisString.rechnung;
        let result = "";
        const today = new Date();
        docString.forEach((string) => {
          if (string === "YY") result += today.getFullYear().toString().slice(-2);
          else if (string === "YYYY") result += today.getFullYear();
          else if (string === "MM") result += (today.getMonth() + 1).toString().padStart(2,"0");
          else if (string === "DD") result += today.getDate().toString().padStart(2,"0");
          else if (string === "num3") result += number.toString().padStart(3, "0");
          else if (string === "num4") result += number.toString().padStart(4, "0");
          else if (string === "num5") result += number.toString().padStart(5, "0");
          else if (string === "num6") result += number.toString().padStart(6, "0");
          else if (string === "num7") result += number.toString().padStart(7, "0");
          else result += string;
        });
        await updateDB("invoices", {
          doc: result,
          status: "Rechnung",
          docDate: serverTimestamp(),
        }, id);
        today.setDate(today.getDate() + (offer?.daysToPay || 14));
        await updateDB("projects/" + offer.projectId, {
          date: serverTimestamp(),
          doc: result,
          netto: offer.SummeNetto,
          brutto: offer.SummeBrutto,
          dueDate: today.getTime(),
        }, "bills");
        await saveDB("invoicesOverview", {
          date: serverTimestamp(),
          doc: result,
          netto: offer.SummeNetto,
          brutto: offer.SummeBrutto,
          dueDate: today.getTime(),
          bill: id,
          project: offer.projectId,
          client: offer?.contactId || null,
        });
        return result;
      } catch (error) {
        console.error(error);
      }
    } else return offer.doc;
  }
  const openFile = async (blank = false) => {
    createBusinessLetterPDF(offer, offer.id, "rechnung", offer.doc, offer.docDate, blank)
    .then((data) => {
      const blob = new Blob([data.pdf], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    })
  }
  const downloadFile = async (blank = false) => {
    createBusinessLetterPDF(offer, offer.id, "rechnung", offer.doc, offer.docDate, blank)
    .then((data) => {
      const blob = new Blob([data.pdf], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = data.name + (blank ? "_blanko" : "") + '.pdf';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  };
  const handleCloseCloudMailer = () => {
    setCloudMailerOpen(false);
    setCloudMailerData({to: [], cc: [], bcc: [], title: "", message: "", files: []});
  }

  const handleMail = (blank = false) => {
    createBusinessLetterPDF(offer, offer.id, "rechnung", offer.doc, offer.docDate, blank)
      .then((data) => {
        const blob = new Blob([data.pdf], { type: "application/pdf" });
        const filesList = [{name: data.name + ".pdf", content: blob}];
        setCloudMailerData(prev => ({...prev,
            to: (offer.hasOwnProperty("contact") ? (offer.contact.hasOwnProperty("email") ? [offer.contact.email] : []) : []),
            cc: (offer.hasOwnProperty("architect") ? (offer.architect.hasOwnProperty("email") && offer.architect.email !== "" ? [offer.architect.email] : []) : []),
            target: offer.id,
            title: "Rechnung",
            message: "Sehr geehrte Damen und Herren,\n\nim Anhang erhalten Sie die Rechnung für unsere erbrachten Leistungen.\nGerne stehen wir bei Fragen zur Verfügung.\n\nMit freundlichen Grüßen\n\n"+displayName,
            files: filesList
          }));
        setCloudMailerOpen(true);
        
    });
  }
  const createDocument = async () => {
    await getNumber();
  }


  return (
    <div>
      { !offer.hasOwnProperty("doc") && <>
      <p style={{fontSize:".875rem"}}>Hinweis: Wenn das Dokument einmal fertiggestellt ist, kann es nicht mehr verändert werden.</p>
      <p className="fw-semibold mt-4 mb-2">Prüfung</p>
      {
        (offer.contact?.name && offer.contact.name !== "" && offer.contact?.anschrift && offer.contact.anschrift !== "") ? 
        <p className="my-1 text-success" onClick={() => setHelper("Empfänger")} style={{cursor:"pointer"}}><BsFillCheckSquareFill /> Emfänger vorhanden</p> :
        <p className="my-1 text-danger" onClick={() => setHelper("Empfänger")} style={{cursor:"pointer"}}><BsFillXSquareFill /> Kein Emfänger vorhanden</p>
      }
      {
        (offer.contact?.email && offer.contact.email !== "") ? 
        <p className="my-1 text-success" onClick={() => setHelper("Empfänger")} style={{cursor:"pointer"}}><BsFillCheckSquareFill /> E-Mail Adresse vorhanden</p> :
        <p className="my-1 text-primary" onClick={() => setHelper("Empfänger")} style={{cursor:"pointer"}}><BsFillExclamationSquareFill /> Keine E-Mail Adresse vorhanden</p>
      }
      {
        (offer?.anschrift && offer.anschrift !== "") ? 
        <p className="my-1 text-success" onClick={() => setHelper("Empfänger")} style={{cursor:"Dokumententitel"}}><BsFillCheckSquareFill /> Projektangaben vorhanden</p> :
        <p className="my-1 text-danger" onClick={() => setHelper("Empfänger")} style={{cursor:"Dokumententitel"}}><BsFillXSquareFill /> Projektangaben unvollständig</p>
      }
      {
        (!offer?.articles || offer.articles.length === 0) ? 
        <p className="my-1 text-danger"><BsFillXSquareFill /> Angebot enthält keine Positionen</p> :
        ""
      }
      {
        (offer.SummeNetto < 1) ? 
        <p className="my-1 text-primary"><BsFillExclamationSquareFill /> Angebotssumme ist sehr gering</p> :
        ""
      }
      {
        (offer?.vorgaben?.NumericNachlass > 10) ? 
        <p className="my-1 text-primary" onClick={() => setHelper("Grundeinstellungen")} style={{cursor:"pointer"}}><BsFillExclamationSquareFill /> Nachlass beträgt über 10%</p> :
        ""
      }
      {
        (offer?.vorgaben?.NumericSkonto > 3) ? 
        <p className="my-1 text-primary" onClick={() => setHelper("Grundeinstellungen")} style={{cursor:"pointer"}}><BsFillExclamationSquareFill /> Skonto beträgt mehr als 3%</p> :
        ""
      }
      {
        (offer?.vorgaben?.NumericZielAR > 30 || offer?.vorgaben?.NumericZielSR > 40) ? 
        <p className="my-1 text-primary" onClick={() => setHelper("Grundeinstellungen")} style={{cursor:"pointer"}}><BsFillExclamationSquareFill /> Zahlungsziel ist sehr lang</p> :
        ""
      }
      {
        (!offer.contact?.name || offer.contact.name === "" || !offer.contact?.anschrift || offer.contact.anschrift === "" ||
        !offer?.anschrift || offer.anschrift === "" || !offer?.articles || offer.articles.length === 0) ?
        <p className="my-3 text-danger">Bitte beheben Sie zuerst die rot hervorgehobenen Fehler, um das Dokument fertig zu stellen.</p> :
        <Button size="sm" onClick={createDocument}>Dokument fertigstellen</Button>
      }</>
      }
      {
        offer.hasOwnProperty("doc") && <>
        <p className="mt-3 mb-2 fw-semibold">PDF im Browser ansehen</p>
        <ButtonGroup>
          <Button variant="outline-primary" size="sm" onClick={() => openFile(true)}>Blanko</Button>
          <Button variant="outline-primary" size="sm" onClick={() => openFile(false)}>Normal</Button>
        </ButtonGroup>
        <p className="mt-3 mb-2 fw-semibold">PDF herunterladen</p>
        <ButtonGroup>
          <Button variant="outline-primary" size="sm" onClick={() => downloadFile(true)}>Blanko</Button>
          <Button variant="outline-primary" size="sm" onClick={() => downloadFile(false)}>Normal</Button>
        </ButtonGroup>
        <p className="mt-3 mb-2 fw-semibold">PDF versenden</p>
        <ButtonGroup>
          <Button variant="outline-primary" size="sm" onClick={() => handleMail(false)}>Per Cloud-Mail</Button>
        </ButtonGroup>
        {
          cloudMailerOpen && <CloudMailer
          to={cloudMailerData.to}
          cc={cloudMailerData.cc}
          bcc={cloudMailerData.bcc}
          title={cloudMailerData.title}
          message={cloudMailerData.message}
          files={cloudMailerData.files}
          target={cloudMailerData.target}
          show={cloudMailerOpen}
          handleClose={handleCloseCloudMailer} />
        }
        </>
      }
    </div>
  )
}

export default Document