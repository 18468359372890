import { useState } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const DialogVerloren = ({show, onClose, onSave }) => {
  const [reason, setReason] = useState("Preis zu hoch");

  const handleSave = () => {
    onSave(reason);
    setReason("");
    onClose();
  };

  return (
    <Modal show={show} backdrop="static" onClose={onClose}>
      <Modal.Body>
        <Form.Group className="mb-4">
          <Form.Label>Aus welchem Grund wurde das Angebot verloren?</Form.Label>
          <Form.Control as="select" value={reason} onChange={(e) => setReason(e.target.value)}>
            <option value="Preis zu hoch">Preis zu hoch</option>
            <option value="Konkurrenzangebot">Konkurrenzangebot</option>
            <option value="Nicht passend">Nicht passend</option>
            <option value="Anderer">Anderer</option>
          </Form.Control>
        </Form.Group>
        <Row>
          <Col align="end">
            <ButtonGroup>
              <Button variant="outline-primary" onClick={onClose}>Abbrechen</Button>
              <Button variant="primary" onClick={handleSave}>Speichern</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>

  );
};

export default DialogVerloren;
