export const news = [
  {
    version: "4.0.0",
    date: "geplant",
    intro: "Mit WerkAPP 4.0.0 wird das Controlling Modul veröffentlicht. Damit wird ein Kalkulationsnachweis für die Angebotsabgabe, als auch eine echte Nachkalkulation möglich. Ebenfalls lassen sich viele Auswertungen (Beispielsweise Kundenstatistiken, Zahlungsverhalten) einfach per Knopfdruck erzeugen.",
    newFunctions: [
      "Kalkulationsnachweis",
      "Artikelkalkulation",
      "Kundenauswertung",
    ],
    improvements: [
    ],
    bugFixes: [
    ],
  },
  {
    version: "3.0.0",
    date: "10.08.2023",
    intro: "Mit dem Update 3.0.0 wurde die Rechnungslegung und Angebotserstellung (Modul Angebot & Rechnung) veröffentlicht. Es wurde außerdem die Artikelverwaltung, sowie die Kalkulation von Artikeln eingeführt. Dabei lag der Fokus auf einer praxisbezogenen Kalkulation nach Teilleistungsrechnung. So kann bei der Kalkulation von Artikeln, als auch im Angebot zwischen Lohnkosten, Stoffkosten u.a. unterschieden werden. Damit legen wir den Grundstein für eine spätere Verwendung der Daten in der Auswertung und Nachkalkulation, welche mit dem Modul Controlling kommen wird.",
    newFunctions: [
      "Artikelverwaltung",
      "Artikelkalkulation",
      "Angebotserstellung",
      "E-Mail versand über Cloud Mailer",
      "Aufmaßerstellung",
      "Rechnungslegung",
      "Zahlungen erfassen",
      "Mahnwesen"
    ],
    improvements: [
      "Stammdaten können direkt im Dokument angepasst werden.",
      "Neue Nutzer erhalten eine E-Mail zur Passwort vergabe."
    ],
    bugFixes: [
      "Fehlende Angaben in den Stammdaten werden ignoriert."
    ],
  },
  {
    version: "2.0.0",
    date: "10.07.2023",
    intro: "Mit dem Update 2.0.0 wurde die Stundenerfassung und die Mitarbeiterverwaltung eingeführt. In diesem Zuge wurde auch die mobile WebApp für gewerbliche Nutzer vorgestellt.",
    newFunctions: [
      "Mitarbeiterverwaltung",
      "Mobile WebApp",
      "Urlaubsplan",
      "Krankmeldungen",
      "Projekt Disposition",
      "Aufgaben",
      "Vorbereitung zum mobilen Aufmaß"
    ],
    improvements: [
      "Die Rechtverwaltung wurde verbessert.",
      "Das Menü wurde auf kleinen Bildschirmen verbessert."
    ],
    bugFixes: [
    ],
  },
  {
    version: "1.0.0",
    date: "10.06.2023",
    intro: "Die erste Version von WerkAPP ist fertig und wird veröffentlicht.",
    newFunctions: [
      "Kontaktverwaltung",
      "Projektverwaltung",
    ],
    improvements: [
    ],
    bugFixes: [
    ],
  }
];