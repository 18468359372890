import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Unauthorized from "../../APP/Messages/Unauthorized";
import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";
import { saveDB } from "../../../functions/database/save";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loader } from "../../../redux/actions/database/loader";
import { defaultMeasurementData } from "./defaultData";

const AddNew = () => {

  const {id} = useParams();
  const {tokens, displayName} = useSelector(state => state.auth);
  const {sales, rights} = tokens;
  const {offers, offersLoaded, offersError} = useSelector(state => state.erp);
  const {projects, projectsLoaded, projectsError} = useSelector(state => state.crm);
  const [saved, setSaved] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if(!offersLoaded) {
      dispatch(loader("offers"));
    }
    if(!projectsLoaded) {
      dispatch(loader("projects"));
    }
  }, [dispatch, offersLoaded, projectsLoaded]);

  useEffect(() => {
    if(id !== "" && offers !== null && projects !== null && !saved) {
      const offersObj = {};
      for (const i in offers) {
        const item = offers[i];
        if(item.hasOwnProperty("projectId") && item.projectId === id) {
          offersObj[i] = [...item.articles];
        }
      }
      const projectData = (projects.hasOwnProperty(id) ? {...projects[id]} : {});
      if(Object.keys(offersObj).length > 0 && Object.keys(projectData).length > 0) {
        saveDB("measurements", {
          ...defaultMeasurementData,
          ...projectData,
          status: "Offen",
          creator: displayName,
          offers: {...offersObj},
          projectId: id,
        })
        .then((results) => {
          setSaved(true);
          navigate("/measurments/edit/"+results.key);
        })
        .catch((err) => {
          console.error(err);
        });
      }
    }
    // eslint-disable-next-line
  }, [id, navigate, offers, projects, saved]);

  if(sales !== true && !rights.hasOwnProperty("eom")) return <Unauthorized license={'erp'} />
  else if(sales !== true && rights["eom"] < 3) return <Unauthorized />;  
 
  if(!offersLoaded || !projectsLoaded) {
    return <Loading />;
  }
  if(offersError !== null || projectsError !== null) {
    return <Error />;
  }
  

  return <Loading />;
}

export default AddNew