import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../redux/actions/database/loader";
import { BsSortAlphaDown, BsSortAlphaUp, BsSortNumericUp, BsSortNumericDown } from "react-icons/bs";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Error from "../../APP/Messages/Error";
import Loading from "../../APP/Messages/Loading";

const FilterAndSort = ({ q = null, status = null, modalAdd, modalDetail}) => {
  const dispatch = useDispatch();
  const { contacts, contactsLoaded, contactsError } = useSelector(state => state.crm);
  const [sortedContacts, setSortedContacts] = useState([]);
  const [sortProperty, setSortProperty] = useState("datum");
  const [sortOrder, setSortOrder] = useState("asc");
  const pageSize = 25;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSort = (property) => {
    if (property === sortProperty) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortProperty(property);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    if (!contactsLoaded) {
      dispatch(loader("contacts"));
    }
  }, [contactsLoaded, dispatch]);

  useEffect(() => {
    let sorted = Object.entries(contacts).sort(([, a], [, b]) => {
      if (a && b) {
        const xA = a.name || "";
        const xB = b.name || "";
        return xA.localeCompare(xB);
      }
      return 0;
    });

    if (sortProperty) {
      sorted.sort(([, a], [, b]) => {
        const valueA = a && a[sortProperty] ? a[sortProperty].toLowerCase() : "";
        const valueB = b && b[sortProperty] ? b[sortProperty].toLowerCase() : "";

        if (sortOrder === "asc") {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      });
    }
    if(status) {
      sorted = sorted.filter(([_, contact]) => {
        if(status === "Alle") {
          return true;
        }
        else if (contact.hasOwnProperty("status") && contact.status === status) {
          return true;
        }
        return false;
      });
    }
    if (q) {
      sorted = sorted.filter(([, contact]) => {
        const searchQuery = q
          .toLowerCase()
          .replace(/[^a-z0-9]/gi, "")
          .replace(/strasse|straße|str./g, "str");
        
        const name = contact.name || "";
        const beschreibung = contact.beschreibung || "";
        const address = contact.anschrift || "";
        const zipCodeAndCity = (contact.PLZ || "") + " " + (contact.ort || "");

        return (
          name.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          beschreibung.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          address.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          zipCodeAndCity.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery)
        );
      });
    }

    setSortedContacts(sorted);
  }, [contacts, sortProperty, sortOrder, q, status]);

  if (contactsError !== null) {
    return <Error />;
  }

  if (!contactsLoaded) {
    return <Loading />;
  }

  const totalContacts = sortedContacts.length;
  const totalPages = Math.ceil(totalContacts / pageSize);
  const contactsToShow = sortedContacts.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const renderTooltip = (title) => (
    <Tooltip id="button-tooltip" placement="top" delay={{ show: 250, hide: 400 }}>
      {title}
    </Tooltip>
  );

  if (Object.keys(contacts).length === 0) {
    return (
      <Alert variant="primary">
        Sie haben noch keine Kontakte, legen Sie jetzt eine an.
        <br />
        <br />
        <Button variant="primary" onClick={modalAdd}>
          Neuer Kontakt
        </Button>
      </Alert>
    );
  }
  else if (contactsToShow.length === 0) {
    return (
      <Alert variant="primary">
        Es gibt keine Übereinstimmungen.
      </Alert>
    );
  }
  

  return (
    <>
      <Table striped hover>
        <thead>
          <tr>
            <th onClick={() => handleSort("status")} style={{cursor:"pointer"}}>
                Typ {sortProperty === "status" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("name")} style={{cursor:"pointer"}}>
                Name {sortProperty === "name" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("anschrift")} style={{cursor:"pointer"}}>
              Anschrift {sortProperty === "anschrift" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("PLZ")} style={{cursor:"pointer"}}>
              PLZ {sortProperty === "PLZ" && (sortOrder === "asc" ? <BsSortNumericDown /> : <BsSortNumericUp />)}
            </th>
            <th onClick={() => handleSort("ort")} style={{cursor:"pointer"}}>
              Ort {sortProperty === "ort" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {contactsToShow.map(([key, contact], index) => (
            <tr key={index}>
              <td valign="middle">{ contact?.status || "" }</td>
              <td valign="middle">{ contact?.name + ( contact?.vorname ? ", " + contact.vorname : "" ) }</td>
              <td valign="middle">{ contact?.anschrift || ""}</td>
              <td valign="middle">{ contact?.PLZ || "" }</td>
              <td valign="middle">{ contact?.ort || "" }</td>
              <td valign="middle" align="end">
                  <OverlayTrigger overlay={renderTooltip("Details öffnen")}>
                    <Button onClick={() => modalDetail({ id: key, el: contacts[key] })} variant="outline-primary">
                      Details
                    </Button>
                  </OverlayTrigger>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination className="text-center">
        {
          (currentPage > 1) && <>
            <Pagination.First onClick={() => handlePageChange(1)}/>
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}/>
          </>
        }
        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
          <Pagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Pagination.Item>
        ))}
        {
          (currentPage < totalPages) && <>
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}/>
            <Pagination.Last onClick={() => handlePageChange(totalPages)}/>
          </>
        }
        </Pagination>
      </div>
    </>
  );
};

export default FilterAndSort;
