import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AddNew from "./AddNew";
import Edit from "./Edit";
import Unauthorized from "../../APP/Messages/Unauthorized";
import FilterAndSort from "./FilterAndSort";

const Index = () => {

  // Grab ID from the URL
  const {action} = useParams();
  const {sales, rights} = useSelector(state => state.auth.tokens);

  if(sales !== true && !rights.hasOwnProperty("eom")) return <Unauthorized license={'erp'} />
  else if(sales !== true && rights["eom"] === 0) return <Unauthorized />;  
 
  // Add new Offer/Lead 
  if(action === "add") {
    return <AddNew />;
  }
  else if(action === "edit") {
    return <Edit />
  }
  return (<FilterAndSort />);
}

export default Index