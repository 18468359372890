import { getIdTokenResult, getAuth } from "firebase/auth";
import { getDatabase, ref, push, serverTimestamp, remove } from "firebase/database";

export const removeItem = async (target, id) => {
  
  const user = getAuth().currentUser;
  const claims = await getIdTokenResult(user);
  
  const {client, sales} = claims.claims;
  
  const databaseId = (sales === undefined) ? client : user.uid;
  if(sales === undefined && (client === null || client === undefined)) return {error: true};


  return new Promise((resolve, reject) => {  
    
    remove(ref(getDatabase(), databaseId + "/" + target + "/" + id))
    .then(() => resolve({error: false}))
    .catch(err => reject({error: true, message: err.message}));
  });
};

export const historPush = async (target, changes) => {
  const user = getAuth().currentUser;
  const claims = await getIdTokenResult(user);
  const {client} = claims.claims;
  if(client === null || client === undefined) return;
  const historyRef = ref(getDatabase(), client + "/history/" + target);
  push(historyRef, {
    at: serverTimestamp(),
    by: user.uid,
    changes: changes
  });

}