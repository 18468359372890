import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loader } from "../../../redux/actions/database/loader";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {BsArrowDownSquare, BsFiletypePdf, BsEnvelopeAt, BsSortAlphaDown, BsSortAlphaUp, BsSortNumericDown, BsSortNumericUp} from "react-icons/bs";
import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { defaultData, defaultStates } from "./defaultData";
import { createBusinessLetterPDF } from "../../../functions/pdf/letter5008";
import { updateDB } from "../../../functions/database/update";
import CloudMailer from "../../APP/CloudMail/CloudMailer";
import { saveDB } from "../../../functions/database/save";

const FilterAndSort = () => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {displayName} = useSelector(state => state.auth);
  const {settings} = useSelector(state => state.crm);
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortASC, setSortASC] = useState(true);
  const [status, setStatus] = useState({name: "", search: "Alle"});
  const {invoices, invoicesLoaded, invoicesError} = useSelector(state => state.erp);
  const [filtered, setFiltered] = useState([]);
  const [cloudMailerOpen, setCloudMailerOpen] = useState(false);
  const [cloudMailerData, setCloudMailerData] = useState({to: [], cc: [], bcc: [], title: "", message: "", files: []});

  const handleCloseCloudMailer = () => {
    setCloudMailerOpen(false);
    setCloudMailerData({to: [], cc: [], bcc: [], title: "", message: "", files: []});
  }

  const handleMail = (obj) => {
    createBusinessLetterPDF(obj, obj.id, "rechnung", obj.doc, obj.docDate)
      .then((data) => {
        const blob = new Blob([data.pdf], { type: "application/pdf" });
        const filesList = [{name: data.name + ".pdf", content: blob}];
        setCloudMailerData(prev => ({...prev,
            to: (obj.hasOwnProperty("contact") ? ((obj.contact.hasOwnProperty("email") && obj.contact.email !== "") ? [obj.contact.email] : []) : []),
            cc: (obj.hasOwnProperty("architect") ? ((obj.architect.hasOwnProperty("email") && obj.architect.email !== "") ? [obj.architect.email] : []) : []),
            target: obj.id,
            title: "Rechnung: " + ((obj?.name && obj.name !== "") ? obj.name + " | " : "")
                  + ((obj?.anschrift && obj.anschrift !== "") ? obj.anschrift + ", " : "")
                  + ((obj?.PLZ && obj.PLZ !== "") ? obj.PLZ + " " : "")
                  + ((obj?.ort && obj.ort !== "") ? obj.ort : ""),
            message: "Sehr geehrte Damen und Herren,\n\nim Anhang erhalten Sie Ihr persönliches Rechnung.\nGerne stehen wir bei Fragen zur Verfügung.\n\nMit freundlichen Grüßen\n\n"+displayName,
            files: filesList
          }));
        setCloudMailerOpen(true);
        
    });
  }

  const handleDecline = (obj) => {
    updateDB("invoices", {status: "Verworfen"}, obj.id);
  }

  const handleSort = (string) => {
    setSortBy(prev => {
      if(prev === string) {
        setSortASC(prev => !prev);
        return prev;
      }
      else {
        setSortASC(true);
        return string;
      }
    })
  }

  const addNew = () => {
    saveDB("invoices", {...defaultData, vorgaben: {...defaultData.vorgaben, ...settings.settings?.kalkulation}})
    .then((result) => {
      navigate("/invoices/edit/"+result.key);
    })
  }
  const copyinvoice = (invoice) => {
    console.log(invoice);
    saveDB("invoices", {
      ...defaultData, ...invoice,
      vorgaben: {...defaultData.vorgaben, ...invoice?.vorgaben, ...settings.settings?.kalkulation},
      doc: null, docDate: null, status: "Rechnung"})
    .then((result) => {
      navigate("/invoices/edit/"+result.key);
    })
  }

  useEffect(() => {
    if(!invoicesLoaded) {
      dispatch(loader("invoices"));
    }
  }, [invoicesLoaded, dispatch]);
  
  useEffect(() => {

    const performFiltering = () => {
      const arr = [];
      Object.entries(invoices).forEach(([id, invoice]) => {
        if(status.name === "" || invoice.status === status.name) arr.push({...invoice, id: id});
      });
      return arr;
    }
    let filteredinvoices = performFiltering(status);
    
    const performSearch = (arr) => {
      const searchQuery = query.toLowerCase().replace(/[^a-z0-9]/gi, "").replace(/strasse|straße|str./g, "str");
      return arr.filter((invoice) => {
        const customer = invoice?.contact?.name || "";
        const architect = invoice?.architect?.name || "";
        const project = invoice.projectName || "";
        const massnahmenNummer = invoice.massnahmenNummer || "";
        const vergabeNummer = invoice.vergabeNummer || "";
        const address = invoice.anschrift || "";
        const zipCodeAndCity = (invoice.PLZ || "") + " " + (invoice.ort || "");
        const doc = invoice.doc || "";
        return (
          customer.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          architect.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          project.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          massnahmenNummer.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          vergabeNummer.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          address.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          zipCodeAndCity.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          doc.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery)
        );
      });
    }
    filteredinvoices = performSearch(filteredinvoices);

    const performSort = (arr) => {
      return arr.sort((a, b) => {
        let valueA, valueB;
        if (sortBy === "client") {
          valueA = a && a.contact.name ? a.contact.name.toLowerCase() : "";
          valueB = b && b.contact.name ? b.contact.name.toLowerCase() : "";
        } else if (sortBy === "abgabe") {
          valueA = a && a.abgabeDatum ? (a.abgabeDatum + a?.abgabeZeit).replace(/[^a-z0-9]/gi, "") : "";
          valueB = b && b.abgabeDatum ? (b.abgabeDatum + b?.abgabeZeit).replace(/[^a-z0-9]/gi, "") : "";
        } else {
          valueA = a && a[sortBy] ? a[sortBy].toLowerCase() : "";
          valueB = b && b[sortBy] ? b[sortBy].toLowerCase() : "";
        }
        if (sortASC) {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      });
    }
    filteredinvoices = performSort(filteredinvoices);
    setFiltered(filteredinvoices);
  }, [invoices, status, query, sortBy, sortASC]);

  if(!invoicesLoaded) return <Loading />;
  if(invoicesError !== null) return <Error />;

  const proceedToinvoice = (obj) => {
    updateDB("invoices", {status: "Rechnung"}, obj.id);
    navigate("/invoices/edit/" + obj.id);
  };

  const openFile = async (obj) => {
    createBusinessLetterPDF(obj, obj.id, "rechnung", obj.doc, obj.docDate)
    .then((data) => {
      const blob = new Blob([data.pdf], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    })
  }
  const downloadFile = async (obj) => {
    createBusinessLetterPDF(obj, obj.id, "rechnung", obj.doc, obj.docDate)
    .then((data) => {
      const blob = new Blob([data.pdf], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = data.name + '.pdf';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }); 
  };
  const renderDate = (date) => {
    if(date === null || date === undefined) return "";
    const [year, month, day] = date.split('-');
    return day + "." + month + "." + year;
  }

  return (
    <>
    <h4 className="pageTitle my-3">Rechnungsverwaltung</h4>
    <Card>
      <Card.Header>
        <Row>
          <Col>
            <InputGroup>
              <Form.Control placeholder="Suche" type="text" value={query} onChange={e => setQuery(e.target.value)} />
              <Dropdown>
                <Dropdown.Toggle variant="outline-primary">
                  { status.search }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setStatus({name: "", search: "Alle"})}>Alle</Dropdown.Item>
                  {
                    defaultStates.map((el, index) => <Dropdown.Item key={index} onClick={() => setStatus(el)}>{el.search}</Dropdown.Item>)
                  }
                </Dropdown.Menu>
              </Dropdown>
            </InputGroup>
          </Col>
          <Col align="end">
            <Button variant="outline-primary" onClick={addNew}>Neu hinzufügen</Button>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Table striped style={{fontSize:"0.875rem"}}>
          <thead>
            <tr>
              <th>
                <p
                  style={(sortBy === "status") ? {color: "var(--primary)",margin:0, padding: 0}:{margin:0, padding: 0}}
                  onClick={() => handleSort("status")}>
                    Status
                {(sortBy === "status") && (sortASC ? <BsSortAlphaDown style={{marginLeft:"10px"}}/> : <BsSortAlphaUp style={{marginLeft:"10px"}}/>)}
                </p>
              </th>
              <th>
                <p
                  style={(sortBy === "abgabe") ? {color: "var(--primary)",margin:0, padding: 0}:{margin:0, padding: 0}}
                  onClick={() => handleSort("abgabe")}>
                    Abgabe
                {(sortBy === "abgabe") && (sortASC ? <BsSortNumericDown style={{marginLeft:"10px"}}/> : <BsSortNumericUp style={{marginLeft:"10px"}}/>)}
                </p>
              </th>
              <th>
                <p
                  style={(sortBy === "client") ? {color: "var(--primary)",margin:0, padding: 0}:{margin:0, padding: 0}}
                  onClick={() => handleSort("client")}>
                    Kunde
                {(sortBy === "client") && (sortASC ? <BsSortAlphaDown style={{marginLeft:"10px"}}/> : <BsSortAlphaUp style={{marginLeft:"10px"}}/>)}
                </p>
                Planer
              </th>
              <th>
                <p
                  style={(sortBy === "name") ? {color: "var(--primary)",margin:0, padding: 0}:{margin:0, padding: 0}}
                  onClick={() => handleSort("name")}>
                    Projekt
                {(sortBy === "name") && (sortASC ? <BsSortAlphaDown style={{marginLeft:"10px"}}/> : <BsSortAlphaUp style={{marginLeft:"10px"}}/>)}
                </p>
                <p
                  style={(sortBy === "anschrift") ? {color: "var(--primary)",margin:0, padding: 0}:{margin:0, padding: 0}}
                  onClick={() => handleSort("anschrift")}>
                    Anschrift
                {(sortBy === "anschrift") && (sortASC ? <BsSortAlphaDown style={{marginLeft:"10px"}}/> : <BsSortAlphaUp style={{marginLeft:"10px"}}/>)}
                </p>
                <p
                  style={(sortBy === "PLZ") ? {color: "var(--primary)",margin:"0 5px 0 0", padding: 0, display: "inline"}:{margin:"0 5px 0 0", padding: 0, display: "inline"}}
                  onClick={() => handleSort("PLZ")}>
                    PLZ
                {(sortBy === "PLZ") && (sortASC ? <BsSortNumericDown style={{marginLeft:"10px"}}/> : <BsSortNumericUp style={{marginLeft:"10px"}}/>)}
                </p>
                <p
                  style={(sortBy === "ort") ? {color: "var(--primary)",margin:0, padding: 0, display: "inline"}:{margin:0, padding: 0, display: "inline"}}
                  onClick={() => handleSort("ort")}>
                    Ort
                {(sortBy === "ort") && (sortASC ? <BsSortAlphaDown style={{marginLeft:"10px"}}/> : <BsSortAlphaUp style={{marginLeft:"10px"}}/>)}
                </p>
              </th>
              <th/>
            </tr>
          </thead>
          <tbody>
            {
              filtered.map((invoice, index) => <tr key={index}>
                <td>
                  <span className="fw-semibold">
                  {
                    (invoice.status === "Rechnung" ? ((!invoice.hasOwnProperty("doc") || invoice?.doc === "") ? "In Bearbeitung" : "Rechnung Fertig" ) : invoice.status)
                  }
                  </span>
                  { invoice.doc ? <><br/><span
                  className="link" title="Datei herunterladen" onClick={() => downloadFile(invoice)}><BsArrowDownSquare /></span> <span 
                  className="link" title="Datei öffnen" onClick={() => openFile(invoice)}><BsFiletypePdf /></span> <span
                  className="link" title="Per Cloud Mail versenden" onClick={() => handleMail(invoice)}><BsEnvelopeAt /></span>
                  <br/>{ invoice.doc }</> : "" }
                </td>
                <td>{ renderDate(invoice.abgabeDatum) } { invoice?.abgabeZeit ? <><br/>{invoice.abgabeZeit} Uhr</> : ""}</td>
                <td>{ invoice.contact.name }{ (invoice.hasOwnProperty("architect") ? <><br/>{ invoice.architect.name }</> : "" ) }</td>
                <td>{ (invoice?.name && invoice.name !== "") && <>{invoice.name}<br/></> }
                    { (invoice?.anschrift && invoice.anschrift!== "") && <>{invoice.anschrift}<br/></> }
                    { (invoice?.PLZ && invoice.PLZ !== "") && invoice.PLZ + " "}{ invoice.ort }</td>
                {
                  (invoice.status === "In Bearbeitung" && (!invoice.hasOwnProperty("doc") || invoice?.doc === "")) && <td>
                    <Link to={"/invoices/edit/" + invoice.id}>Rechnung bearbeiten</Link><br/>
                    <Link onClick={() => copyinvoice(invoice)}>Als Vorlage nutzen</Link><br/>
                    <Link onClick={() => handleDecline(invoice)}>Rechnung verwerfen</Link>
                  </td>
                }
                {
                  (invoice.status === "Versandt" || (invoice.status === "Rechnung" && (invoice.hasOwnProperty("doc") && invoice?.doc !== ""))) && <td>
                    <Link to={"/invoices/edit/" + invoice.id}>Details ansehen</Link><br/>
                    <Link onClick={() => copyinvoice(invoice)}>Als Vorlage nutzen</Link>
                  </td>
                }
                {
                  (invoice.status === "Keine Abgabe" || invoice.status === "Auftrag") && <td>
                    <Link onClick={() => copyinvoice(invoice)}>Als Vorlage nutzen</Link><br/>
                    <Link to={"/invoices/edit/" + invoice.id}>Details ansehen</Link><br/>
                  </td>
                }
                {
                  (invoice.status === "Verworfen") && <td>
                    <Link to={"/invoices/edit/" + invoice.id}>Details ansehen</Link><br/>
                    <Link onClick={() => copyinvoice(invoice)}>Als Vorlage nutzen</Link>
                  </td>
                }
              </tr>)
            }
          </tbody>
        </Table>
      </Card.Body>
    </Card>
    {
      cloudMailerOpen && <CloudMailer
      to={cloudMailerData.to}
      cc={cloudMailerData.cc}
      bcc={cloudMailerData.bcc}
      title={cloudMailerData.title}
      message={cloudMailerData.message}
      files={cloudMailerData.files}
      target={cloudMailerData.target}
      show={cloudMailerOpen}
      handleClose={handleCloseCloudMailer} />
    }
    </>
  )
}

export default FilterAndSort