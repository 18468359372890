import {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { defaultData } from "./defaultData";
import { saver } from "../../../redux/actions/database/saver";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../redux/actions/database/loader";
import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";
import { updater } from "../../../redux/actions/database/updater";
import History from "../../APP/History/History";
import ContactsMini from "../../CRM/Contacts/ContactsMini";

const MultiTool = ({obj = null, objId = null, mode = "detail", closeModal}) => {
  
  const { settings, settingsLoaded, settingsError } = useSelector(state => state.crm);
  const auth = useSelector(state => state.auth);
  const {sales, rights} = useSelector(state => state.auth.tokens);
  
  // State for view modus
  const [showMode, setMode] = useState(mode);
  // Show Mode for History
  const [page, setPage] = useState("");
  // State for Editing active or not.
  const [editMode, setEdit] = useState(false);
  // Set old data if there is some.
  const [offers, setOffers] = useState((obj !== null ? {...defaultData, ...obj}:{...defaultData}));
  // State to see if there are any changes.
  const [isDirty, setDirty] = useState(false);
  // State to validate the form.
  const [validated, setValidated] = useState(false);
  // State to handle the child modal for Projects or Clients.
  const [showContactModal, setContactModal] = useState(false);
  const [showArchitectModal, setArchitectModal] = useState(false);

  const dispatch = useDispatch();
  // Closer for child modal
  const onHide = () => {
    setArchitectModal(false);
    setContactModal(false);
  }
  // Select handler from child modal
  const onSelectContact = obj => {
    const {contactId, contact} = obj;
    setDirty(true);
    setOffers(prev => ({...prev, contactId: contactId, contact: {
      name: contact?.name || "",
      anrede: contact?.anrede || "",
      vorname: contact?.vorname || "",
      anschrift: contact?.anschrift || "",
      PLZ: contact?.PLZ || "",
      ort: contact?.ort || "",
      telefon: contact?.telefon || "",
      email: contact?.email || "",
    }}));
    setContactModal(false);
  }
  const onSelectArchitect = obj => {
    const {contactId, contact} = obj;
    setDirty(true);
    setOffers(prev => ({...prev, architectId: contactId, architect: {
      name: contact?.name || "",
      anrede: contact?.anrede || "",
      vorname: contact?.vorname || "",
      anschrift: contact?.anschrift || "",
      PLZ: contact?.PLZ || "",
      ort: contact?.ort || "",
      telefon: contact?.telefon || "",
      email: contact?.email || "",
    }}));
    setArchitectModal(false);
  }

  // handle changes in the form.
  const handleChange = e => {
    setDirty(true);
    setOffers(prev => ({...prev, [e.target.name]: e.target.value }));
  }
  const handleReset = e => {
    if(window.confirm("Alle Eingaben verwerfen?")) {
      if(showMode === "edit") {
        setOffers({...defaultData, ...obj});
        setValidated(false);
        setDirty(false);
        setMode("detail");
      }
      else {
        setOffers({...defaultData});
        setValidated(false);
        setDirty(false);
        closeModal();
      }
    }
  }
  const handleSave = e => {
    console.log(e === undefined);
    if(e !== undefined) {
      e.preventDefault();
      e.stopPropagation();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        setValidated(true);
        return;
      } else if(offers.contactId === "") {
        setValidated(true);
        return;
      }
    }
    const cleanData = {...offers};
    if((showMode === "edit" || e === undefined) && objId !== null) {
      dispatch(updater("offers", cleanData, objId, obj));
    }
    if(showMode === "add") {
      console.log("save");
      saver("offers", cleanData);
    }
    setOffers(cleanData)
    setDirty(false);
    setValidated(false);
    setMode("detail");
  }
  const handleDecline = () => {
    if(window.confirm("Diese Anfrage nicht beantworten?")) {
      setOffers(prev => ({...prev, status: "Kein Angebot", abgelehnt: auth?.displayName}));
      dispatch(updater("offers", {...offers, status: "Kein Angebot", abgelehnt: auth?.displayName}, objId, obj));
      closeModal();
    } else return;
  }
  const handleOffer = () => {
    if(window.confirm("Diese Anfrage anbieten?")) {
      setOffers(prev => ({...prev, status: "Offen", bestätigt: auth?.displayName}));
      dispatch(updater("offers", {...offers, status: "Offen", bestätigt: auth?.displayName}, objId, obj));
      closeModal();
    } else return;
  }
  useEffect(()=>{
    if(!settingsLoaded) {
      dispatch(loader("settings"));
    }
  },[settingsLoaded, dispatch]);

  useEffect(() => {
    setEdit((showMode === "edit" || showMode === "add"));
  }, [showMode, mode]);

  if(!settingsLoaded) {
    return <Loading />;
  } else if (settingsError !== null) {
    return <Error />;
  }

  const SearchContact = () => { return (<Col><Alert variant="warning" className="text-center"><p>Es muss ein Kontakt ausgewählt sein.</p><Button variant="warning" onClick={() => setContactModal(true)}>Kontakt suchen</Button></Alert></Col>);}
  const SearchArchitect = () => { return (<Col><Alert variant="primary" className="text-center"><p>Gibt es einen Architekten?</p><Button variant="outline-primary" onClick={() => setArchitectModal(true)}>Architekt suchen</Button></Alert></Col>);}
  const ContactDetail = () => {
    if ( offers.contactId === "" && editMode) return <SearchContact />;
    const { contact } = offers;
    return (
      <Col>
        <p className="fw-semibold">Auftraggeber</p>
        <p>{ (contact?.vorname) ? <>{contact.anrede}<br/>{contact.vorname} {contact.name}</> : (contact?.name || null) }</p>
        <p>{ contact?.anschrift || null }</p>
        <p>{ (contact?.PLZ) ? contact.PLZ + " " : null }{ contact?.ort || null }</p>
        { editMode && <Button variant="outline-primary" className="mt-2" size="sm" onClick={() => setContactModal(true)}>Ändern</Button> }
      </Col>);
  }
  const ArchitectDetail = () => {
    if ( offers.architectId === "" && editMode) return <SearchArchitect />;
    const { architect } = offers;
    return (
      <Col>
        <p className="fw-semibold">Architekt / Planer</p>
        <p>{ (architect?.vorname) ? <>{architect.anrede}<br/>{architect.vorname} {architect.name}</> : (architect?.name || null) }</p>
        <p>{ architect?.anschrift || null }</p>
        <p>{ (architect?.PLZ) ? architect.PLZ + " " : null }{ architect?.ort || null }</p>
        { editMode && <Button variant="outline-primary" className="mt-2" size="sm" onClick={() => setArchitectModal(true)}>Ändern</Button> }
      </Col>);
  }

  if(page === "history") {
    return <History obj={{...obj, id: objId}} type="offers" goBack={() => setPage("")} />;
  }


  return (
    <Form onSubmit={handleSave} noValidate validated={validated}>

      <Row>
        <Col xs={12} md={4}>
          <p className="fw-semibold">Projektadresse</p>
          <FormGroup>
            <Form.Text>Bezeichnung</Form.Text>
            <Form.Control name="name" readOnly={!editMode} plaintext={!editMode} value={offers.name} onChange={handleChange} required />
          </FormGroup>
          <FormGroup>
            <Form.Text>Anschrift</Form.Text>
            <Form.Control name="anschrift" readOnly={!editMode} plaintext={!editMode} value={offers.anschrift} onChange={handleChange} required />
          </FormGroup>
          <Row>
            <Col xs={4}>
              <FormGroup>
                <Form.Text>PLZ</Form.Text>
                <Form.Control name="PLZ" readOnly={!editMode} plaintext={!editMode} value={offers.PLZ} onChange={handleChange} required />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Ort</Form.Text>
                <Form.Control name="ort" readOnly={!editMode} plaintext={!editMode} value={offers.ort} onChange={handleChange} required />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Form.Text>Land</Form.Text>
            <Form.Control name="land" readOnly={!editMode} plaintext={!editMode} value={offers.land} onChange={handleChange} />
          </FormGroup>
        </Col>
        <ContactDetail />
        <ArchitectDetail />
      </Row>
      <hr className="my-4"/>
      <Row>
        <Col>
          <FormGroup>
            <Form.Text>Art der Vergabe</Form.Text>
            <Form.Select name="angebotsTyp" disabled={!editMode} value={offers.angebotsTyp} onChange={handleChange} required>
              <option value="">{(editMode ? "Bitte wählen" : "") }</option>
              {
                settings.angebotsTypen.map((value, index) => <option key={index} value={value}>{value}</option>)
              }
            </Form.Select>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Form.Text>Quelle</Form.Text>
            <Form.Select name="herkunft" disabled={!editMode} value={offers.herkunft} onChange={handleChange} required>
              <option value="">{(editMode ? "Bitte wählen" : "") }</option>
              {
                settings.quellen.map((value, index) => <option key={index} value={value}>{value}</option>)
              }
            </Form.Select>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Form.Text>Leistung</Form.Text>
            <Form.Control name="leistung" value={offers.leistung} readOnly={!editMode} plaintext={!editMode} onChange={handleChange} />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Form.Text>Los</Form.Text>
            <Form.Control name="los" value={offers.los} readOnly={!editMode} plaintext={!editMode} onChange={handleChange} />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} md={4}>
          <FormGroup>
            <Form.Text>Abgabedatum</Form.Text>
            <Row>
              <Col>
              {
                (!editMode && offers.abgabeDatum === "") ? <Form.Control readOnly plaintext value="" /> : <Form.Control type="date" name="abgabeDatum" value={offers.abgabeDatum} readOnly={!editMode} plaintext={!editMode} onChange={handleChange} />
              }
              </Col>
              <Col>
              {
                (!editMode && offers.abgabeZeit === "") ? <Form.Control readOnly plaintext value="" /> : <Form.Control type="time" name="abgabeZeit" value={offers.abgabeZeit} readOnly={!editMode} plaintext={!editMode} onChange={handleChange} />
              }
              </Col>
            </Row>
          </FormGroup>
          <Row className="mt-3">
            <Col>
              <FormGroup>
                <Form.Text>Beginn</Form.Text>
                {
                  (!editMode && offers.beginnDatum === "") ? <Form.Control readOnly plaintext value="" /> : <Form.Control type="date" name="beginnDatum" value={offers.beginnDatum} readOnly={!editMode} plaintext={!editMode} onChange={handleChange} />
                }
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Form.Text>Ausführungsende</Form.Text>
                {
                  (!editMode && offers.endDatum === "") ? <Form.Control readOnly plaintext value="" /> : <Form.Control type="date" name="endDatum" value={offers.endDatum} readOnly={!editMode} plaintext={!editMode} onChange={handleChange} />
                }
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col>
          <FormGroup>
            <Form.Text>Maßnahmenummer</Form.Text>
            <Form.Control name="massnahmenNummer" value={offers.massnahmenNummer} readOnly={!editMode} plaintext={!editMode} onChange={handleChange} />
          </FormGroup>
          <FormGroup className="mt-3">
            <Form.Text>Vergabenummer</Form.Text>
            <Form.Control name="vergabeNummer" value={offers.vergabeNummer} readOnly={!editMode} plaintext={!editMode} onChange={handleChange} />
          </FormGroup>
        </Col>
      </Row>
      
      <Row className="mt-4">
      {
        (sales || (rights.hasOwnProperty("eam") && rights["eam"] >= 3)) && <Col><ButtonGroup>
          <Button variant="outline-danger" onClick={handleDecline}>Kein Angebot abgeben</Button>
          <Button variant="outline-primary" onClick={handleOffer}>Angebot erstellen</Button>
        </ButtonGroup>
      </Col>
      }
      <Col align="end">
        {
          (editMode) ? <ButtonGroup>
            {
              (showMode === "add") ? <Button onClick={handleReset} disabled={!isDirty} variant="outline-primary">Verwerfen</Button> :
              (isDirty) ? <Button onClick={handleReset} variant="outline-primary">Verwerfen</Button> :
              <Button onClick={() => setMode("detail")} variant="outline-primary">Abbrechen</Button>
            } 
            <Button type="submit" disabled={!isDirty}>Speichern</Button>
          </ButtonGroup> :
          <ButtonGroup>
            <Button onClick={() => setPage("history") } variant="outline-primary">Historie</Button>
            {
              (sales || rights["eam"] >= 3) && <Button onClick={() => setMode("edit")} variant="outline-primary">Bearbeiten</Button>
            }
            <Button onClick={closeModal}>Schließen</Button>
          </ButtonGroup>
        }
      </Col>
    </Row>
    <ContactsMini show={showContactModal} onHide={onHide} onSelect={onSelectContact} />
    <ContactsMini show={showArchitectModal} onHide={onHide} onSelect={onSelectArchitect} />
  </Form>
  )
}

export default MultiTool