import Auth from "../modules/Auth/Auth";
import FirstLogin from "../modules/Auth/FirstLogin";


export const publicRoutes = [
  {
    path: "/firstLogin/:emailAddr?",
    element: <FirstLogin />
  },
  {
    path: "*",
    index: true,
    element: <Auth />,
  },
];