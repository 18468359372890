import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { loader } from "../../../redux/actions/database/loader";
import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";
import ListGroup from "react-bootstrap/ListGroup";
import Alert from "react-bootstrap/Alert";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";

const ContactsMini = ({show, onHide, onSelect, addNew=null}) => {
  
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const {contacts, contactsLoaded, contactsError} = useSelector(state => state.crm);
  const [sortedContacts, setSortedContacts] = useState([]);


  useEffect(() => {
    if(!contactsLoaded) {
      dispatch(loader("contacts"));
    }
  }, [contactsLoaded, dispatch]);

  useEffect(() => {
    let sorted = Object.entries(contacts).sort(([, a], [, b]) => {
      if (a && b) {
        const nameA = a.name || "";
        const nameB = b.name || "";
        return nameA.localeCompare(nameB);
      }
      return 0;
    });
    if (query.length >= 1) {
      sorted = sorted.filter(([, contact]) => {
        const searchQuery = query
          .toLowerCase()
          .replace(/[^a-z0-9]/gi, "")
          .replace(/strasse|straße|str./g, "str");

        const name = contact.name || "";
        const vorname = contact.vorname || "";
        const address = contact.anschrift || "";
        const zipCodeAndCity = (contact.PLZ || "") + " " + (contact.ort || "");

        return (
          name.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          vorname.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          address.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          zipCodeAndCity.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery)
        );
      });
    }

    setSortedContacts(sorted);
  }, [contacts, query]);

  const AddNewItem = () => {
    if(addNew !== null) {
      return (<ListGroup.Item onClick={addNew}>
        <div className="fw-semibold">Neuen Kontakt erstellen.</div>
      </ListGroup.Item>);
    }
    return null;
  }

  if(contactsError !== null || contactsError !== null) {
    return (<Modal show={show} onHide={onHide} scrollable><Modal.Header><FormControl value={query} onChange={e => {setQuery(e.target.value);}} placeholder="Projekt Suche"/></Modal.Header><Modal.Body><Error /></Modal.Body></Modal>);
  } else if(!contactsLoaded || !contactsLoaded) {
    return (<Modal show={show} onHide={onHide} scrollable><Modal.Header><FormControl value={query} onChange={e => {setQuery(e.target.value);}} placeholder="Projekt Suche"/></Modal.Header><Modal.Body><Loading /></Modal.Body></Modal>);
  } else if (sortedContacts.length === 0) {
    return (<Modal show={show} onHide={onHide} scrollable><Modal.Header><FormControl value={query} onChange={e => {setQuery(e.target.value);}} placeholder="Projekt Suche"/></Modal.Header><Modal.Body><Alert variant="primary">Es sind keine Daten vorhanden</Alert><ListGroup variant="flush"><AddNewItem /></ListGroup></Modal.Body></Modal>);
  }
  
  const Contact = ({id}) => {
    if(contacts.hasOwnProperty(id)) {
      const contact = contacts[id];
      if(contact.hasOwnProperty("vorname")) {
        return (<>{ contact?.anrede || ""} { contact.vorname } { contact?.name }</>)
      }
      return <>{ contact.name }</>;
    }
    return null;
  }

  return (
    <Modal show={show} onHide={onHide} scrollable>
      <Modal.Header>
        <FormControl value={query} onChange={e => {setQuery(e.target.value);}} placeholder="Kontakt Suche"/>
      </Modal.Header>
      <Modal.Body className="p-0">
        <ListGroup variant="flush">
        {
            (sortedContacts.length > 0) && sortedContacts
              .map(([id, contact], idx) => <ListGroup.Item key={idx} style={{fontSize: ".9rem"}} action onClick={() => onSelect({contactId: id, contact: contact})}>
                  
                    <div className="fw-semibold"><Contact id={id} /></div>
                    { (contact?.anschrift) ? contact.anschrift + ", " : "" }{ (contact?.PLZ) ? contact.PLZ + " " : null }{ contact?.ort || null }<br/>
                  </ListGroup.Item>
              )
          }
          <AddNewItem />
        </ListGroup>
      </Modal.Body>
    </Modal>
  )
}

export default ContactsMini