import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";
import Unauthorized from "../../APP/Messages/Unauthorized";
import AddProject from "./AddProject";
import ProjectManager from "./ProjectManager";
import FilterAndSort from "./FilterAndSort";
import { FormSelect, InputGroup } from "react-bootstrap";

const Projects = () => {
  
  const states = ["offen", "laufend", "abgeschlossen","alle"];
  const {action} = useParams();
  const {rights, sales} = useSelector(state => state.auth.tokens);
  const [show, setShow] = useState(false);
  const [q, setQ] = useState("");
  const [status, setStatus] = useState(states[1]);

  const toggleModal = () => setShow(!show);


  if(sales !== true && !rights.hasOwnProperty("cpm")) return <Unauthorized license={'crm'} />
  else if(sales !== true && rights["cpm"] === 0) return <Unauthorized />;

  return (<>
    <h4 className="pageTitle my-3">Projektverwaltung</h4>
    { (action !== undefined && action.length > 18) && <ProjectManager /> }
    { (action === undefined || (action !== null && action.length < 18)) && <><Card>
      <Card.Header>
        <Row>
          <Col xs={8} md={5}>
            <InputGroup>
            <FormControl value={q} onChange={e => {setQ(e.target.value)}} placeholder="Suche"/>
            <FormSelect style={{maxWidth:"200px"}} value={status} onChange={e => {setStatus(e.target.value)}}>
              {
                states.map((x,id) => <option key={id} value={x}>{x}</option>)
              }
            </FormSelect>
            </InputGroup>
          </Col>
          {
            (sales || rights["cpm"] > 3) && <Col xs={4} md={7} align="end"><Button variant="outline-primary" onClick={toggleModal}>Neues Projekt</Button></Col>
          }
        </Row>
      </Card.Header>
      <Card.Body>
        <FilterAndSort q={q} status={status} toggleModal={toggleModal} />
      </Card.Body>
      <Card.Footer align="end">
        
             { (sales || rights["cpm"] > 3) && <Button variant="outline-primary" onClick={toggleModal}>Neues Projekt</Button> }
        
      </Card.Footer>
    </Card>
    <Modal show={show} onHide={toggleModal} size="xl">
      <Modal.Header closeButton>
        Neues Projekt anlegen
      </Modal.Header>
      <Modal.Body>
        <AddProject onHide={toggleModal} />
      </Modal.Body>
    </Modal></> }
  </>);
}

export default Projects