import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AddNew from "./AddNew";
import Unauthorized from "../../APP/Messages/Unauthorized";
import FilterAndSort from "./FilterAndSort";
import Create from "./create/Create";
import "./measurements.css";

const Index = () => {

  // Grab ID from the URL
  const {action, id} = useParams();
  const {sales, rights} = useSelector(state => state.auth.tokens);

  if(!sales && !rights.hasOwnProperty("emm")) return <Unauthorized license={'erp'} />
  else if(!sales && rights.emm === 0) return <Unauthorized />;  
 
  // Add new Offer/Lead 

  else if(action === "edit") {
    return <Create />
  }
  if(action === "addproject" && id !== "") {
    return <AddNew />;
  }

  return (<FilterAndSort />);
}

export default Index