import { Helmet } from 'react-helmet-async';

const SEO = ({title = '', first = false}) => {
  const titleLong = (first && title.length > 0) ? title + ' @ WerkAPP' : 'WerkAPP' + (title.length > 0 ? ' | '+title : '');
  return (
  <Helmet>
    <title>{titleLong}</title>
  </Helmet>
  );
}
export default SEO;