import { useEffect, useState } from "react";
import { endAt, get, getDatabase, orderByChild, query, ref } from "firebase/database";
import Accordion from "react-bootstrap/Accordion";
import { useSelector } from "react-redux";
import Loading from "../Messages/Loading";
import Error from "../Messages/Error";
import { useDispatch } from "react-redux";
import { loader } from "../../../redux/actions/database/loader";


const Modules = () => {

  
  const {team, teamLoaded, teamError, settings} = useSelector(state => state.crm);
  const {licenses} = settings;
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [setUserRights] = useState({});
  const dispatch = useDispatch();


  const reOrdered = () => {
    const obj = {};
    for (const pid in licenses) {
      const product = licenses[pid];
      for (const uid in product) {
        if(!obj.hasOwnProperty(uid)) { obj[uid] = {}; }

        Object.assign(obj[uid], {[pid]: {
          
        }});

      }
    }
    return obj;
  }
  useEffect(() => {
    setUserRights(reOrdered());
    // eslint-disable-next-line
  }, [team, licenses]);

  useEffect(() => {
    if(!teamLoaded) {
      dispatch(loader("team"));
    }
    // eslint-disable-next-line
  }, [teamLoaded]);

  useEffect(() => {
    const today = new Date();
    const timestamp = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2,"0") + "-" + today.getDate().toString().padStart(2,"0");
    if(loading) {
      get(query(ref(getDatabase(),"public/products"),orderByChild("disponibleFrom"),endAt(timestamp)))
      .then((res) => {
        console.log(res.exportVal());
        setProducts(res.exportVal());
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        console.error(err);
      })
    }
    // eslint-disable-next-line
  }, []);

  if(loading || !teamLoaded) return <Loading />;
  if(error || teamError !== null) return <Error />;
/*
  const renderDate = date => {
    const regexPattern = /(\d{4})(\d{2})(\d{2})/;
    const matchArray = date.match(regexPattern);
    if (matchArray) {
      const [, year, month, day] = matchArray;
      return day + "." + month + "." + year;
    }
    return "";
  }

  const handleCancel = (uid, pid) => {
    if(window.confirm("Soll der Zugriff auf WerkAPP " + products[pid].name + " für " + team[uid].displayName + " sofort gesperrt werden?")) {
      console.log("Gelöscht!");
    }
  }
  */

  

  return (
    <>
    <h4 className="pageTitle my-3">Lizenzverwaltung</h4>
    {
      Object.keys(team).length > 0 && <Accordion alwaysOpen>
        {
          Object.entries(team).map(([uid, person],) => <Accordion.Item key={uid} eventKey={uid}>
              <Accordion.Header>{ person.displayName } ({ person.email })</Accordion.Header>
              <Accordion.Body>
                <Accordion alwaysOpen>
                  {
                    Object.entries(products).map(([pid, product],) => <Accordion.Item key={pid} eventKey={pid}>
                      <Accordion.Header>{ product.name }</Accordion.Header>
                      <Accordion.Body>
                        {

                        }
                      </Accordion.Body>
                    </Accordion.Item>)
                  }
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>)
        }
      </Accordion>
    }
    </>
  )
}

export default Modules