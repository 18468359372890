import types from '../../types';

const initialState = {
  contacts: {},
  contactsLoaded: false,
  contactsError: null,
};


export default function data(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_CONTACTS_SUCCESS:
      return {
        ...state,
        contactsLoaded: true,
        contactsError: null,
        contacts: {...action.payload},
      };
    case types.LOAD_CONTACTS_FAILURE:
      return {
        ...state,
        contactsLoaded: true,
        contactsError: action.error,
      };
    case types.AUTH_SIGNOUT:
      return {
        ...initialState
      };
    default: return state;
  }
}