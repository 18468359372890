import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  UI,
  Invoices,
  Offer,
  Payments,
  Overdue,
  Contacts,
  Projects,
  Settings,
  Users,
  Dashboard,
  Hours,
  Measurments,
  DispoBoard,
  Staff,
  Tasks,
  Billing,
  MyMessages,
  MyTasks,
  News,
  Modules,
  Usage,
  Handover,
  ProjectTasks,
  Ideas,
  Security,
  UserSettings,
  Articles,
  Leads,
  VacationUser,
  AbsencePlan,
  AbsenceUser,
  Products
} from "./index";
import Unauthorized from "./APP/Messages/Unauthorized";

const modules = [
  {
    key: "crm",
    path: "contacts/:action?/:id?",
    element: <Contacts />,
  },
  {
    key: "cpm",
    path: "projects/:action?",
    element: <Projects />,
  },
  {
    key: "css",
    path: "settings/:action?",
    element: <Settings />,
  },
  {
    key: "cum",
    path: "users/:action?",
    element: <Users />,
  },
  {
    key: "cur",
    path: "permissions/:action?",
    element: <p>User Permissions</p>,
  },
  {
    key: "bill",
    path: "invoices/:action?/:id?",
    element: <Invoices />,
  },
  {
    key: "eam",
    path: "articles/:action?",
    element: <Articles />,
  },
  {
    key: "erm",
    path: "leads/:action?",
    element: <Leads />,
  },
  {
    key: "emm",
    path: "measurments/:action?/:id?",
    element: <Measurments />,
  },
  {
    key: "eom",
    path: "offers/:action?/:id?",
    element: <Offer />,
  },
  {
    key: "ovd",
    path: "overdue/:action?",
    element: <Overdue />,
  },
  {
    key: "pay",
    path: "payments/:action?",
    element: <Payments />,
  },
  {
    key: "dpd",
    path: "handover/:action?",
    element: <Handover />,
  },
  {
    key: "dhm",
    path: "hours/:action?",
    element: <Hours />,
  },
  {
    key: "dpd",
    path: "dispoboard/:action?",
    element: <DispoBoard />,
  },
  {
    key: "dvp",
    path: "absence",
    element: <AbsencePlan />,
  },
  {
    key: "dsp",
    path: "sickness/:action?",
    element: <p>Sickness planner</p>,
  },
  {
    key: "dpd",
    path: "projecttasks/:action?",
    element: <ProjectTasks />,
  },
  {
    key: "dsm",
    path: "staff/:action?",
    element: <Staff />,
  },
  {
    key: "dpd",
    path: "tasks/:action?",
    element: <Tasks />,
  },
  {
    index: true,
    element: <Dashboard />,
  }
];


const emptyRoutes = [{
  path: "/",
  element: <UI />,
  children: [
    {
      path: "billing/:action?",
      element: <Billing />
    },
    {
      path: "ideas/:action?",
      element: <Ideas />
    },
    {
      path: "modules/:action?",
      element: <Modules />
    },
    {
      path: "mymessages/:action?",
      element: <MyMessages />
    },
    {
      path: "mytasks/:action?",
      element: <MyTasks />
    },
    {
      path: "news/:action?",
      element: <News />
    },
    {
      path: "security/:action?",
      element: <Security />
    },
    {
      path: "usage/:action?",
      element: <Usage />
    },
    {
      path: "usersettings/:action?",
      element: <UserSettings />
    },
    {
      path: "user/vacation",
      element: <VacationUser />,
    },
    {
      path: "user/absence",
      element: <AbsenceUser />,
    },
    {
      path: "products",
      element: <Products />,
    },
    {
      index: true,
      element: <Dashboard />,
    }
  ]
},{
  path: "*",
  element: <UI />,
  children: [
    {
      path: "*",
      index: true,
      element: <Unauthorized />,
    }
  ]
}];

const permissions = () => {
  return new Promise(resolve => {

    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      const defaultRoutes = emptyRoutes
      if (user) {

        user.getIdTokenResult(true).then((customClaims) => {

          if (customClaims.claims.hasOwnProperty("rights")) {
            
            const rights = customClaims.claims.rights;

            for ( const modul in modules) {
              const key = modules[modul]?.key || null;
              if(rights.hasOwnProperty(key)) {
                defaultRoutes[0].children.unshift(modules[modul]);
              }
            }
          }
          else if (customClaims.claims.hasOwnProperty("sales") && customClaims.claims.sales === true) {
            for ( const modul in modules) {
              defaultRoutes[0].children.unshift(modules[modul]);
            }
          }
          unsubscribe();
          resolve(defaultRoutes);
        });
      } else {
        unsubscribe();
        resolve(defaultRoutes);
      }
    });
  });
}


export default permissions;