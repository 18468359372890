import { combineReducers } from 'redux';

import auth from './auth';
import crm from './crm';
import erp from './erp';
import dispo from './dispo';
import data from "./data";

export default combineReducers({
  auth,
  crm,
  data,
  erp,
  dispo
})