import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../redux/actions/database/loader";
import { BsSortAlphaDown, BsSortAlphaUp, BsSortNumericDown, BsSortNumericUp } from "react-icons/bs";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Error from "../../APP/Messages/Error";
import Loading from "../../APP/Messages/Loading";
import { convertDateFormat } from "../../../functions/datetime/convertDateFormat";
import { ButtonGroup } from "react-bootstrap";

const FilterAndSort = ({ q = null, status = null, art = null, modalAdd, modalDetail}) => {
  const dispatch = useDispatch();
  const { offers, offersLoaded, offersError } = useSelector(state => state.erp);
  const [sortedOffers, setSortedOffers] = useState([]);
  const [sortProperty, setSortProperty] = useState("abgabeDatum");
  const [sortOrder, setSortOrder] = useState("asc");
  const pageSize = 25;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSort = (property) => {
    if (property === sortProperty) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortProperty(property);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    if (!offersLoaded) {
      dispatch(loader("offers"));
    }
  }, [offersLoaded, dispatch]);

  useEffect(() => {
    let sorted = Object.entries(offers).sort(([, a], [, b]) => {
      if (a && b) {
        const xA = a.status || "";
        const xB = b.status || "";
        return xA.localeCompare(xB);
      }
      return 0;
    });

    if (sortProperty) {
      sorted.sort(([, a], [, b]) => {
        let valueA, valueB;
        if(sortProperty === "contactname") {
          valueA = a && a.contact.name ? a.contact.name.toLowerCase() : "";
          valueB = b && b.contact.name ? b.contact.name.toLowerCase() : "";
        } else {
          valueA = a && a[sortProperty] ? a[sortProperty].toLowerCase() : "";
          valueB = b && b[sortProperty] ? b[sortProperty].toLowerCase() : "";
        }
        if (sortOrder === "asc") {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      });
    }
    if(status && status !== "Alle") {
      sorted = sorted.filter(([_, offer]) => {
        return offer.status === status;
      });
    }
    if (q) {
      sorted = sorted.filter(([, offer]) => {
        const searchQuery = q.toLowerCase().replace(/[^a-zäöü0-9]/gi, "")
        
        const kurztext = offer.kurztext || "";

        return (
          kurztext.toLowerCase().replace(/[^a-zäöü0-9]/gi, "").includes(searchQuery)
        );
      });
    }

    setSortedOffers(sorted);
  }, [offers, sortProperty, sortOrder, q, status, art]);

  if (offersError !== null) {
    return <Error />;
  }

  if (!offersLoaded) {
    return <Loading />;
  }

  const totalOffers = sortedOffers.length;
  const totalPages = Math.ceil(totalOffers / pageSize);
  const offersToShow = sortedOffers.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const renderTooltip = (title) => (
    <Tooltip id="button-tooltip" placement="top" delay={{ show: 250, hide: 400 }}>
      {title}
    </Tooltip>
  );
  

  if (Object.keys(offers).length === 0) {
    return (
      <Alert variant="primary">
        Sie haben noch keine Anfragen, legen Sie jetzt eine an.
        <br />
        <br />
        <Button variant="primary" onClick={modalAdd}>
          Neue Anfrage
        </Button>
      </Alert>
    );
  }
  else if (offersToShow.length === 0) {
    return (
      <Alert variant="primary">
        Es gibt keine Übereinstimmungen.
      </Alert>
    );
  }
  const renderContact = (c) => {
    if (c?.vorname) {
      return (c?.anrede + " " || "") + (c?.vorname + " " || "") + (c?.name);
    } else {
      return c?.name || "";
    }
  };
  

  return (
    <>
      <Table striped hover>
        <thead>
          <tr>
            <th onClick={() => handleSort("status")} style={{cursor:"pointer"}}>
              Status {sortProperty === "status" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("contactname")} style={{cursor:"pointer"}}>
                Von {sortProperty === "contactname" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("angebotsTyp")} style={{cursor:"pointer"}}>
              Art {sortProperty === "angebotsTyp" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("anschrift")} style={{cursor:"pointer"}}>
              Objekt {sortProperty === "anschrift" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("ort")} style={{cursor:"pointer"}}>
              PLZ, Ort {sortProperty === "ort" && (sortOrder === "asc" ? <BsSortAlphaDown /> : <BsSortAlphaUp />)}
            </th>
            <th onClick={() => handleSort("abgabeDatum")} style={{cursor:"pointer"}}>
              Abgabe {sortProperty === "abgabeDatum" && (sortOrder === "asc" ? <BsSortNumericDown /> : <BsSortNumericUp />)}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {offersToShow.map(([key, offer], index) => (
            <tr key={index}>
              <td valign="middle">{ offer?.status }</td>
              <td valign="middle">{ renderContact(offer?.contact) }</td>
              <td valign="middle">{ offer?.angebotsTyp }</td>
              <td valign="middle">{ offer?.anschrift }</td>
              <td valign="middle">{ offer?.PLZ } { offer?.ort }</td>
              <td valign="middle">{ convertDateFormat(offer?.abgabeDatum) } { (offer?.abgabeZeit)? " - " + offer.abgabeZeit : "" }</td>
              <td valign="middle" align="end"></td>
              <td valign="middle" align="end">
                <ButtonGroup>
                  <OverlayTrigger overlay={renderTooltip("Details öffnen")}>
                    <Button onClick={() => modalDetail({ id: key, el: offers[key] })} variant="outline-primary">
                      Details
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination className="text-center">
        {
          (currentPage > 1) && <>
            <Pagination.First onClick={() => handlePageChange(1)}/>
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}/>
          </>
        }
        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
          <Pagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Pagination.Item>
        ))}
        {
          (currentPage < totalPages) && <>
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}/>
            <Pagination.Last onClick={() => handlePageChange(totalPages)}/>
          </>
        }
        </Pagination>
      </div>
    </>
  );
};

export default FilterAndSort;
