import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loader } from "../../../redux/actions/database/loader";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {BsArrowDownSquare, BsFiletypePdf, BsEnvelopeAt, BsSortAlphaDown, BsSortAlphaUp, BsSortNumericDown, BsSortNumericUp} from "react-icons/bs";
import Loading from "../../APP/Messages/Loading";
import Error from "../../APP/Messages/Error";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { defaultData, defaultStates } from "./defaultData";
import { createBusinessLetterPDF } from "../../../functions/pdf/letter5008";
import { updateDB } from "../../../functions/database/update";
import CloudMailer from "../../APP/CloudMail/CloudMailer";
import { saveDB } from "../../../functions/database/save";
import DialogVerloren from "./DialogVerloren";
import ProceedToProject from "./ProceedToProject";

const FilterAndSort = () => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {displayName} = useSelector(state => state.auth);
  const {settings} = useSelector(state => state.crm);
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortASC, setSortASC] = useState(true);
  const [status, setStatus] = useState({name: "", search: "Alle"});
  const {offers, offersLoaded, offersError} = useSelector(state => state.erp);
  const [filtered, setFiltered] = useState([]);
  const [cloudMailerOpen, setCloudMailerOpen] = useState(false);
  const [cloudMailerData, setCloudMailerData] = useState({to: [], cc: [], bcc: [], title: "", message: "", files: []});
  const [actualOffer, setActualOffer] = useState("");
  const [showDialogLost, setShowDialogLost] = useState(false);
  const [showDialogProject, setShowDialogProject] = useState(false);

  const handleCloseCloudMailer = () => {
    setCloudMailerOpen(false);
    setCloudMailerData({to: [], cc: [], bcc: [], title: "", message: "", files: []});
  }

  const handleMail = (obj) => {
    createBusinessLetterPDF(obj, obj.id, "angebot", obj.doc, obj.docDate)
      .then((data) => {
        const blob = new Blob([data.pdf], { type: "application/pdf" });
        const filesList = [{name: data.name + ".pdf", content: blob}];
        setCloudMailerData(prev => ({...prev,
            to: (obj.hasOwnProperty("contact") ? ((obj.contact.hasOwnProperty("email") && obj.contact.email !== "") ? [obj.contact.email] : []) : []),
            cc: (obj.hasOwnProperty("architect") ? ((obj.architect.hasOwnProperty("email") && obj.architect.email !== "") ? [obj.architect.email] : []) : []),
            target: obj.id,
            title: "Angebot: " + ((obj?.name && obj.name !== "") ? obj.name + " | " : "")
                  + ((obj?.anschrift && obj.anschrift !== "") ? obj.anschrift + ", " : "")
                  + ((obj?.PLZ && obj.PLZ !== "") ? obj.PLZ + " " : "")
                  + ((obj?.ort && obj.ort !== "") ? obj.ort : ""),
            message: "Sehr geehrte Damen und Herren,\n\nim Anhang erhalten Sie Ihr persönliches Angebot.\nGerne stehen wir bei Fragen zur Verfügung.\n\nMit freundlichen Grüßen\n\n"+displayName,
            files: filesList
          }));
        setCloudMailerOpen(true);
        
    });
  }

  const handleDecline = (obj) => {
    setCloudMailerData(prev => ({...prev,
        to: (obj.hasOwnProperty("contact") ? ((obj.contact.hasOwnProperty("email") && obj.contact.email !== "") ? [obj.contact.email] : []) : []),
        cc: (obj.hasOwnProperty("architect") ? ((obj.architect.hasOwnProperty("email") && obj.architect.email !== "") ? [obj.architect.email] : []) : []),
        target: obj.id,
        title: "Leider kein Angebot für Projekt " + ((obj?.name && obj.name !== "") ? obj.name + " | " : "")
        + ((obj?.anschrift && obj.anschrift !== "") ? obj.anschrift + ", " : "")
        + ((obj?.PLZ && obj.PLZ !== "") ? obj.PLZ + " " : "")
        + ((obj?.ort && obj.ort !== "") ? obj.ort : ""),
        message: "Sehr geehrte Damen und Herren,\n\nwir bedanken uns für die Anfrage zu oben genanntem Projekt.\nLeider können wir dieses mal kein wirtschaftliches Angebot abgeben.\nGerne stehen wir aber für zukünftige Anfragen zur Verfügung.\n\nMit freundlichen Grüßen\n\n"+displayName,
        files: []
      }));
    setCloudMailerOpen(true);
    if(obj.status !== "Keine Abgabe") {
      updateDB("offers", {status: "Keine Abgabe"}, obj.id);
    }
  }

  const handleSort = (string) => {
    setSortBy(prev => {
      if(prev === string) {
        setSortASC(prev => !prev);
        return prev;
      }
      else {
        setSortASC(true);
        return string;
      }
    })
  }

  const addNew = () => {
    saveDB("offers", {...defaultData, vorgaben: {...defaultData.vorgaben, ...settings.settings?.kalkulation}})
    .then((result) => {
      navigate("/offers/edit/"+result.key);
    })
  }
  const copyOffer = (offer) => {
    console.log(offer);
    saveDB("offers", {
      ...defaultData, ...offer,
      vorgaben: {...defaultData.vorgaben, ...offer?.vorgaben, ...settings.settings?.kalkulation},
      doc: null, docDate: null, status: "Angebot"})
    .then((result) => {
      navigate("/offers/edit/"+result.key);
    })
  }

  useEffect(() => {
    if(!offersLoaded) {
      dispatch(loader("offers"));
    }
  }, [offersLoaded, dispatch]);
  
  useEffect(() => {

    const performFiltering = () => {
      const arr = [];
      Object.entries(offers).forEach(([id, offer]) => {
        if(status.name === "" || offer.status === status.name) arr.push({...offer, id: id});
      });
      return arr;
    }
    let filteredOffers = performFiltering(status);
    
    const performSearch = (arr) => {
      const searchQuery = query.toLowerCase().replace(/[^a-z0-9]/gi, "").replace(/strasse|straße|str./g, "str");
      return arr.filter((offer) => {
        const customer = offer?.contact?.name || "";
        const architect = offer?.architect?.name || "";
        const project = offer.projectName || "";
        const massnahmenNummer = offer.massnahmenNummer || "";
        const vergabeNummer = offer.vergabeNummer || "";
        const address = offer.anschrift || "";
        const zipCodeAndCity = (offer.PLZ || "") + " " + (offer.ort || "");
        const doc = offer.doc || "";
        return (
          customer.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          architect.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          project.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          massnahmenNummer.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          vergabeNummer.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          address.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          zipCodeAndCity.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery) ||
          doc.toLowerCase().replace(/[^a-z0-9]/gi, "").includes(searchQuery)
        );
      });
    }
    filteredOffers = performSearch(filteredOffers);

    const performSort = (arr) => {
      return arr.sort((a, b) => {
        let valueA, valueB;
        if (sortBy === "client") {
          valueA = a && a.contact.name ? a.contact.name.toLowerCase() : "";
          valueB = b && b.contact.name ? b.contact.name.toLowerCase() : "";
        } else if (sortBy === "abgabe") {
          valueA = a && a.abgabeDatum ? (a.abgabeDatum + a?.abgabeZeit).replace(/[^a-z0-9]/gi, "") : "";
          valueB = b && b.abgabeDatum ? (b.abgabeDatum + b?.abgabeZeit).replace(/[^a-z0-9]/gi, "") : "";
        } else {
          valueA = a && a[sortBy] ? a[sortBy].toLowerCase() : "";
          valueB = b && b[sortBy] ? b[sortBy].toLowerCase() : "";
        }
        if (sortASC) {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      });
    }
    filteredOffers = performSort(filteredOffers);
    setFiltered(filteredOffers);
  }, [offers, status, query, sortBy, sortASC]);

  if(!offersLoaded) return <Loading />;
  if(offersError !== null) return <Error />;

  const proceedToOffer = (obj) => {
    updateDB("offers", {status: "Angebot"}, obj.id);
    navigate("/offers/edit/" + obj.id);
  };

  const openFile = async (obj) => {
    createBusinessLetterPDF(obj, obj.id, "angebot", obj.doc, obj.docDate)
    .then((data) => {
      const blob = new Blob([data.pdf], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    })
  }
  const downloadFile = async (obj) => {
    createBusinessLetterPDF(obj, obj.id, "angebot", obj.doc, obj.docDate)
    .then((data) => {
      const blob = new Blob([data.pdf], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = data.name + '.pdf';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }); 
  };
  const renderDate = (date) => {
    if(date === null || date === undefined) return "";
    const [year, month, day] = date.split('-');
    return day + "." + month + "." + year;
  }
  const offerLost = (offerId) => {
    setActualOffer(offerId);
    setShowDialogLost(true);
  }
  const saveLost = (reason) => {
    if(actualOffer !== "") {
      updateDB("offers", {
        status: "Verloren",
        reason: reason
      }, actualOffer);
    }
    setActualOffer("");
    setShowDialogLost(false);
  }
  const proceedToProject = (offer) => {
    setActualOffer({...offer});
    setShowDialogProject(true);
  }

  return (
    <>
    <h4 className="pageTitle my-3">Angebotsverwaltung</h4>
    <Card>
      <Card.Header>
        <Row>
          <Col>
            <InputGroup>
              <Form.Control placeholder="Suche" type="text" value={query} onChange={e => setQuery(e.target.value)} />
              <Dropdown>
                <Dropdown.Toggle variant="outline-primary">
                  { status.search }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setStatus({name: "", search: "Alle"})}>Alle</Dropdown.Item>
                  {
                    defaultStates.map((el, index) => <Dropdown.Item key={index} onClick={() => setStatus(el)}>{el.search}</Dropdown.Item>)
                  }
                </Dropdown.Menu>
              </Dropdown>
            </InputGroup>
          </Col>
          <Col align="end">
            <Button variant="outline-primary" onClick={addNew}>Neu hinzufügen</Button>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Table striped style={{fontSize:"0.875rem"}}>
          <thead>
            <tr>
              <th>
                <p
                  style={(sortBy === "status") ? {color: "var(--primary)",margin:0, padding: 0}:{margin:0, padding: 0}}
                  onClick={() => handleSort("status")}>
                    Status
                {(sortBy === "status") && (sortASC ? <BsSortAlphaDown style={{marginLeft:"10px"}}/> : <BsSortAlphaUp style={{marginLeft:"10px"}}/>)}
                </p>
              </th>
              <th>
                <p
                  style={(sortBy === "abgabe") ? {color: "var(--primary)",margin:0, padding: 0}:{margin:0, padding: 0}}
                  onClick={() => handleSort("abgabe")}>
                    Abgabe
                {(sortBy === "abgabe") && (sortASC ? <BsSortNumericDown style={{marginLeft:"10px"}}/> : <BsSortNumericUp style={{marginLeft:"10px"}}/>)}
                </p>
              </th>
              <th>
                <p
                  style={(sortBy === "client") ? {color: "var(--primary)",margin:0, padding: 0}:{margin:0, padding: 0}}
                  onClick={() => handleSort("client")}>
                    Kunde
                {(sortBy === "client") && (sortASC ? <BsSortAlphaDown style={{marginLeft:"10px"}}/> : <BsSortAlphaUp style={{marginLeft:"10px"}}/>)}
                </p>
                Planer
              </th>
              <th>
                <p
                  style={(sortBy === "name") ? {color: "var(--primary)",margin:0, padding: 0}:{margin:0, padding: 0}}
                  onClick={() => handleSort("name")}>
                    Projekt
                {(sortBy === "name") && (sortASC ? <BsSortAlphaDown style={{marginLeft:"10px"}}/> : <BsSortAlphaUp style={{marginLeft:"10px"}}/>)}
                </p>
                <p
                  style={(sortBy === "anschrift") ? {color: "var(--primary)",margin:0, padding: 0}:{margin:0, padding: 0}}
                  onClick={() => handleSort("anschrift")}>
                    Anschrift
                {(sortBy === "anschrift") && (sortASC ? <BsSortAlphaDown style={{marginLeft:"10px"}}/> : <BsSortAlphaUp style={{marginLeft:"10px"}}/>)}
                </p>
                <p
                  style={(sortBy === "PLZ") ? {color: "var(--primary)",margin:"0 5px 0 0", padding: 0, display: "inline"}:{margin:"0 5px 0 0", padding: 0, display: "inline"}}
                  onClick={() => handleSort("PLZ")}>
                    PLZ
                {(sortBy === "PLZ") && (sortASC ? <BsSortNumericDown style={{marginLeft:"10px"}}/> : <BsSortNumericUp style={{marginLeft:"10px"}}/>)}
                </p>
                <p
                  style={(sortBy === "ort") ? {color: "var(--primary)",margin:0, padding: 0, display: "inline"}:{margin:0, padding: 0, display: "inline"}}
                  onClick={() => handleSort("ort")}>
                    Ort
                {(sortBy === "ort") && (sortASC ? <BsSortAlphaDown style={{marginLeft:"10px"}}/> : <BsSortAlphaUp style={{marginLeft:"10px"}}/>)}
                </p>
              </th>
              <th/>
            </tr>
          </thead>
          <tbody>
            {
              filtered.map((offer, index) => <tr key={index}>
                <td>
                  <span className="fw-semibold">
                  {
                    (offer.status === "Angebot" ? ((!offer.hasOwnProperty("doc") || offer?.doc === "") ? "In Bearbeitung" : "Angebot Fertig" ) : offer.status)
                  }
                  </span>
                  { offer.doc ? <><br/><span
                  className="link" title="Datei herunterladen" onClick={() => downloadFile(offer)}><BsArrowDownSquare /></span> <span 
                  className="link" title="Datei öffnen" onClick={() => openFile(offer)}><BsFiletypePdf /></span> <span
                  className="link" title="Per Cloud Mail versenden" onClick={() => handleMail(offer)}><BsEnvelopeAt /></span>
                  <br/>{ offer.doc }</> : "" }
                </td>
                <td>{ renderDate(offer.abgabeDatum) } { offer?.abgabeZeit ? <><br/>{offer.abgabeZeit} Uhr</> : ""}</td>
                <td>{ offer.contact.name }{ (offer.hasOwnProperty("architect") ? <><br/>{ offer.architect.name }</> : "" ) }</td>
                <td>{ (offer?.name && offer.name !== "") && <>{offer.name}<br/></> }
                    { (offer?.anschrift && offer.anschrift!== "") && <>{offer.anschrift}<br/></> }
                    { (offer?.PLZ && offer.PLZ !== "") && offer.PLZ + " "}{ offer.ort }</td>
                {
                  (offer.status === "Anfrage") && <td>
                    <Link onClick={() => proceedToOffer(offer)}>Angebot erstellen</Link><br/>
                    <Link onClick={() => handleDecline(offer)}>Anfrage ablehnen</Link>
                  </td>    
                }
                {
                  (offer.status === "Angebot" && (!offer.hasOwnProperty("doc") || offer?.doc === "")) && <td>
                    <Link to={"/offers/edit/" + offer.id}>Angebot bearbeiten</Link><br/>
                    <Link onClick={() => copyOffer(offer)}>Als Vorlage nutzen</Link><br/>
                    <Link onClick={() => handleDecline(offer)}>Anfrage ablehnen</Link>
                  </td>
                }
                {
                  (offer.status === "Versandt" || (offer.status === "Angebot" && (offer.hasOwnProperty("doc") && offer?.doc !== ""))) && <td>
                    <Link to={"/offers/edit/" + offer.id}>Details ansehen</Link><br/>
                    <Link onClick={() => copyOffer(offer)}>Als Vorlage nutzen</Link><br/>
                    <Link onClick={() => proceedToProject(offer)}>Auftrag</Link> / <Link onClick={() => offerLost(offer.id)}>Absage</Link>
                  </td>
                }
                {
                  (offer.status === "Keine Abgabe" || offer.status === "Auftrag") && <td>
                    <Link onClick={() => copyOffer(offer)}>Als Vorlage nutzen</Link><br/>
                    <Link to={"/offers/edit/" + offer.id}>Details ansehen</Link><br/>
                    <Link onClick={() => handleDecline(offer)}>Absage E-Mail</Link>
                  </td>
                }
                {
                  (offer.status === "Verloren") && <td>
                    <Link to={"/offers/edit/" + offer.id}>Details ansehen</Link><br/>
                    <Link onClick={() => copyOffer(offer)}>Als Vorlage nutzen</Link>
                  </td>
                }
              </tr>)
            }
          </tbody>
        </Table>
      </Card.Body>
    </Card>
    {
      cloudMailerOpen && <CloudMailer
      to={cloudMailerData.to}
      cc={cloudMailerData.cc}
      bcc={cloudMailerData.bcc}
      title={cloudMailerData.title}
      message={cloudMailerData.message}
      files={cloudMailerData.files}
      target={cloudMailerData.target}
      show={cloudMailerOpen}
      handleClose={handleCloseCloudMailer} />
    }
    <DialogVerloren show={showDialogLost} onClose={() => setShowDialogLost(false)} onSave={saveLost}/>
    <ProceedToProject show={showDialogProject} onHide={() => {setShowDialogProject(false); setActualOffer("");}} obj={actualOffer} objId={actualOffer?.id} />
    </>
  )
}

export default FilterAndSort