import { BsFileEarmark } from "react-icons/bs";

const FileIcons = ({typ}) => {

  return (
  <div className={"fileIcon " + typ.toLowerCase()} title={typ.toUpperCase() + " Datei"}>
    <BsFileEarmark />
    <p>{ typ.substring(0, 4) }</p>
  </div>
  )
}

export default FileIcons