import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loaderOnce } from "../../../functions/database/loaderOnce";
import Alert from "react-bootstrap/Alert";
import Accordion from "react-bootstrap/Accordion";
import Col from 'react-bootstrap/Col';
import { BsArrowRight } from "react-icons/bs";
import { loader } from "../../../redux/actions/database/loader";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

const History = (props) => {

  const {obj, type, goBack} = props;
  const {team, teamLoaded} = useSelector(state => state.settings);
  const [history, setHistory] = useState({});
  const [hasError, setError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const typeName = {
    contacts: "Kontakt",
    persons: "Anprechpartner",
    team: "Benutzer",
    staff: "Mitarbeiter",
    tasks: "Aufgabe",
    articles: "Artikel",
    offers: "Anfrage / Angebot",
  };
  useEffect(() => {
    if(!isLoaded && (obj.id !== null && obj.id !== undefined)) {
      loaderOnce("history", "target", obj.id)
        .then((result) => {
          setHistory((result.result === null ? {} : result.result));
          setError(false);
          setIsLoaded(true);
        }).catch((error) => {
          console.error(error.message);
          setError(true);
          setIsLoaded(true);
        });
    }
    if(!teamLoaded) {
      dispatch(loader("team"));
    }
  }, [dispatch, isLoaded, teamLoaded, obj.id, type]);

  if(!isLoaded || !teamLoaded) {
    return (<Alert variant="primary">Daten werden geladen...</Alert>)
  }
  if(hasError) {
    return (<Alert variant="danger">Etwas ist schiefgelaufen...</Alert>);
  }

  const formatTimestamp = timestamp => {
    const dateObj = new Date(timestamp); // umwandeln in Millisekunden
    const year = dateObj.getFullYear();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2); // Monate beginnen bei 0
    const day = ("0" + dateObj.getDate()).slice(-2);
    const hours = ("0" + dateObj.getHours()).slice(-2);
    const minutes = ("0" + dateObj.getMinutes()).slice(-2);
    const seconds = ("0" + dateObj.getSeconds()).slice(-2);
    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  }
  const getDisplayName = uid => {
    if(!teamLoaded) { return; }
    if(uid === undefined) return "System";
    for (const user in team) {
      if(user === uid) {
        return team[user].displayName
      }
    }
    return "Unbekannt";
  }

  const RenderTLRow = props => {
    const {after, before} = props.obj;
    const long = (Object.keys(after).length > Object.keys(before).length ? after : before);
    const objKeys = Object.values(long);
    const renderString = (el, after=false) => {
      const regex = new RegExp(/^[+-]?\d+(\.\d+)?$/);
      if(el === undefined || el === null) return (after ? "gelöscht" : "hinzugefügt");
      else if(parseInt(el) === 0) return (after ? "gelöscht" : "hinzugefügt");
      else if(regex.test(el)) {
        return parseInt(el).toFixed(3);
      }
      else {
        return el;
      }
    }
    return objKeys.map((obj, id) => <div key={id}>
      {
      Object.entries(obj).map(([key, _], idx) => 
      <Row key={id + "-" + idx}>
      <Col align="end">
        <b style={{textTransform:"capitalize", marginRight:".5rem"}}>
          { key }
        </b>
      </Col>
      <Col>
        { renderString(before?.[id]?.[key], false) }
      </Col>
      <Col>
        <BsArrowRight className="mr-2"/>
        { renderString(after?.[id]?.[key], true) }
      </Col>
    </Row>)}
    </div>
    );
  }

  const RenderChange = ({change}) => {
    if(change?.key === "teilleistungen") {
      return (
        <>
        <Row>
          <Col xs={12}>
            <b style={{textTransform:"capitalize", marginRight:".5rem"}}>{ change.key }</b>
          </Col>
        </Row>
        <RenderTLRow obj={change} />
        </>
      );
    }
    return (<Row>
      <Col><b style={{textTransform:"capitalize", marginRight:".5rem"}}>{ change.key }</b></Col>
      <Col>{ change?.before || "hinzugefügt" }</Col>
      <Col>
        <BsArrowRight className="mr-2"/>
        { change?.after || "gelöscht"}
      </Col>
    </Row>)
  }

  return (
    <Accordion defaultActiveKey="none">
      <Accordion.Item eventKey="created">
        <Accordion.Header>
            <Col>
            { getDisplayName(obj?.addedBy) }
            </Col>
            <Col>
              { formatTimestamp(obj?.addedAt) }
            </Col>
        </Accordion.Header>
        <Accordion.Body style={{fontSize:"10pt"}}>
          { typeName[type] || ""} wurde angelegt.
        </Accordion.Body>
      </Accordion.Item>
      {
          (Object.keys(history).length > 0) ?
          Object.values(history).map((change, index) => <Accordion.Item key={index} eventKey={index}>
            <Accordion.Header>
              <Col>
              { getDisplayName(change?.by) }
              </Col>
              <Col>
                { formatTimestamp(change?.at) }
              </Col>
            </Accordion.Header>
            <Accordion.Body style={{fontSize:"10pt"}}>
              {
                Object.values(change.changes).map((entry, id) => <RenderChange change={entry} key={id} />)
              }
                { /*
                  (typeof edit?.before === "object" || typeof edit?.after === "object") ? <p key={index +'-'+ id} style={{marginBottom:0}}>
                  <b style={{textTransform:"capitalize", marginRight:".5rem"}}>{edit.key}:</b>
                  {(edit.hasOwnProperty("before") ? ((typeof edit?.before === "object") ? Object.values(edit.before).join(", ") : edit.before) : "hinzugefügt")}
                  <BsArrowRight className="mx-2"/>
                  {(edit.hasOwnProperty("after") ? ((typeof edit?.after === "object") ? Object.values(edit.after).join(", ") : edit.after) : "gelöscht")}
                  </p> :
                  <p key={index +'-'+ id} style={{marginBottom:0}}>
                      <b style={{textTransform:"capitalize", marginRight:".5rem"}}>{edit.key}:</b>
                      {(edit.hasOwnProperty("before") ? ((typeof edit?.before === "object") ? Object.values(edit.before).join(", ") : edit.before) : "hinzugefügt")}
                      <BsArrowRight className="mx-2"/>
                      {(edit.hasOwnProperty("after") ? ((typeof edit?.after === "object") ? Object.values(edit.after).join(", ") : edit.after) : "gelöscht")}
                      </p>
                }
                )
                 */ 
                }
              
            </Accordion.Body>
          </Accordion.Item>)
          :null
        }
        {
          (goBack !== undefined && goBack !== null) && <Row className="mt-3">
          <Col align="end">
            <Button variant="outline-primary" onClick={goBack}>Zurück</Button>
          </Col>
        </Row>
        }
    </Accordion>
  )
}

export default History